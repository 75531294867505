// https://stackoverflow.com/questions/42773306/react-router-doesnt-load-images-properly-with-nested-routes
const brandTypes = [
  {
    id: '1',
    name: 'Nominativa',
    icon: 'TextFieldsOutlined',
    helperText: '[Solo palabra(s)]',
    helperPopOverText:
      'Elige este tipo de marca para proteger una o varias palabras que no incluyan un gráfico o logotipo, ni tengan un diseño especial. Podrás usar esta marca bajo cualquier combinación de tipo, tamaño y color de letra que desees.',
    helperImages: [],
    requireName: true,
    requireFiles: false,
    showHasTransliteration: false,
  },
  {
    id: '2',
    name: 'Innominada',
    icon: 'BubbleChart',
    helperText: '[Imagen que no lleva texto]',
    helperPopOverText:
      'Elige este tipo de marca para proteger un gráfico o logotipo que no incluya texto ni palabra alguna.',
    helperImages: ['/nike.png', '/apple.png', '/pepsi.png', '/twitter.png'],
    requireName: false,
    requireFiles: true,
    showHasTransliteration: true,
  },
  {
    id: '3',
    name: 'Mixta',
    icon: 'FormatShapesOutlined',
    helperText: '[Texto e imagen]',
    helperPopOverText:
      'Elige este tipo de marca para proteger la combinación de una o varias palabras con un diseño especial, o bien, acompañadas de un gráfico o logotipo.',
    helperImages: ['/youtube.png', '/adidas.png', '/lacoste.png', '/bimbo.png'],
    requireName: true,
    requireFiles: true,
    showHasTransliteration: true,
  },
  {
    id: '4',
    name: 'Tridimensional',
    icon: 'LayersOutlined',
    helperPopOverText:
      'Elige este tipo de marca si tienes un producto cuya forma o figura es original y novedosa. No puede ser la forma habitual del producto.',
    helperImages: ['/perfume.png', '/kisses.png', '/calavera.png', '/oreo.png'],
    requireName: false,
    requireFiles: true,
    showHasTransliteration: true,
  },
  {
    id: '5',
    name: 'Aviso Comercial',
    icon: 'ChatOutlined',
    helperText: '[Slogan]',
    helperPopOverText:
      'Elige esta opción para proteger una frase o expresión memorable que será usada de forma complementaria a la marca de tus productos / servicios o de tu establecimiento, con la finalidad que los consumidores recuerden tu negocio.',
    helperImages: [],
    requireName: true,
    requireFiles: false,
    showHasTransliteration: true,
  },
]

export default brandTypes
