import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { PropTypes } from 'prop-types'
import { FormControl, FormHelperText, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import _ from 'lodash'
import { REACT_APP_TINYMCE_API_KEY } from '../../config'

const useStyles = makeStyles(() => ({
  editor: {
    width: '100%',
  },
}))

const RichTextEditor = ({
  initialValue,
  initialConfig,
  control,
  name,
  size,
  variant,
  required,
  disabled,
  fullWidth,
  rules,
  errors,
}) => {
  /* eslint-disable-next-line no-unused-vars */
  const onChange = (content, editor) => {}
  const error = _.get(errors, name)
  const classes = useStyles()
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        /* eslint-disable-next-line no-unused-vars */
        render={({ onChange: onChangeByControl, value }) => {
          return (
            <FormControl
              size={size}
              variant={variant}
              className={clsx(classes.editor)}
              error={!!error}
              required={required}
              disabled={disabled}
              fullWidth={fullWidth}>
              <Editor
                initialValue={initialValue}
                init={initialConfig}
                apiKey={REACT_APP_TINYMCE_API_KEY}
                onEditorChange={(event) => {
                  if (onChange) onChange(event)
                  onChangeByControl(event)
                }}
              />
              {error?.message ? (
                <FormHelperText>{error.message}</FormHelperText>
              ) : null}
            </FormControl>
          )
        }}
      />
    </>
  )
}

RichTextEditor.propTypes = {
  initialValue: PropTypes.string,
  initialConfig: PropTypes.shape({}),
  control: PropTypes.shape({}),
  name: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  rules: PropTypes.shape({}),
  errors: PropTypes.shape({}),
}

RichTextEditor.defaultProps = {
  initialValue: undefined,
  initialConfig: undefined,
  control: undefined,
  name: undefined,
  size: 'small',
  variant: 'outlined',
  required: undefined,
  disabled: undefined,
  fullWidth: undefined,
  rules: undefined,
  errors: undefined,
}

export default RichTextEditor
