import { API } from 'aws-amplify'
import PropTypes from 'prop-types'
import Debug from 'debug'
import {
  searchProductsAndServicesQuery,
  searchProductsAndServicesEnhancedQuery,
} from '../../graphql/custom-queries'

const debug = Debug('services:registration:classes')

// https://github.com/aws-amplify/amplify-cli/issues/1576#issuecomment-635128542
// https://github.com/aws-amplify/amplify-cli/issues/1576#issuecomment-635128542
const PUBLIC_AUTH_MODE = 'AWS_IAM'
const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const searchProductsAndServices = async ({
  name,
  classID,
  limit = 100,
  authenticated,
}) => {
  const nameFilter = {
    or: [{ name: { matchPhrasePrefix: name } }, { name: { match: name } }],
  }
  const filter =
    typeof classID !== 'undefined' && classID != null && classID.trim() !== ''
      ? { ...nameFilter, classID: { eq: classID.trim() } }
      : nameFilter
  const sort = {
    field: 'classID',
    direction: 'asc',
  }
  try {
    const { data } = await API.graphql({
      query: searchProductsAndServicesQuery,
      variables: {
        filter,
        sort,
        limit,
      },
      authMode: authenticated ? PRIVATE_AUTH_MODE : PUBLIC_AUTH_MODE,
    })
    const items = data?.searchClassProductOrServices
      ? data.searchClassProductOrServices.items
      : []
    return items
  } catch (e) {
    debug('error', e)
    return []
  }
}

searchProductsAndServices.propTypes = {
  name: PropTypes.bool.isRequired,
  classID: PropTypes.string,
}

// @see https://medium.com/@tobinc/aws-amplify-graphql-with-geo-point-and-custom-resources-free-elasticsearch-provider-d1742fbc4ceb
// @see https://dev.to/tingtingjh/aws-amplify-elasticsearch-query-for-interface-union-type-with-and-or-operations-1ogp
// @see https://medium.com/@gerard.sans/finding-the-nearest-locations-around-you-using-aws-amplify-part-2-ce4603605be6
// @see https://medium.com/@gerard.sans/aws-appsync-velocity-templates-guide-55b9d2bff053
// @see https://github.com/gsans/amplify-london-cycles
// @see https://github.com/aws-amplify/amplify-cli/issues/673
// @see https://stackoverflow.com/questions/57204857/how-to-send-json-array-through-graphql-and-aws-appsync-to-add-data-to-dynamo-tab
// @see https://www.elastic.co/es/blog/starts-with-phrase-matching/
// @see https://stackoverflow.com/questions/29741641/elasticsearch-starts-with-first-word-in-phrases

export const searchProductsAndServicesEnhanced = async ({
  name,
  classID,
  limit = 100,
  authenticated,
}) => {
  const nameFilter = {
    bool: {
      should: [
        {
          match: {
            name: {
              query: name,
              boost: 2,
            },
          },
        },
        {
          match: {
            'name.folded': {
              query: name,
              max_expansions: 100,
              boost: 1,
            },
          },
        },
        {
          match_phrase_prefix: {
            name: {
              query: name,
              max_expansions: 100,
              boost: 10,
            },
          },
        },
        {
          match_phrase_prefix: {
            'name.folded': {
              query: name,
              max_expansions: 100,
              boost: 8,
            },
          },
        },
        {
          match_phrase_prefix: {
            'name.startswith': {
              query: name,
              max_expansions: 100,
              boost: 10,
            },
          },
        },
      ],
      boost: 1,
    },
  }

  const classIDDefined =
    typeof classID !== 'undefined' && classID != null && classID.trim() !== ''

  const classFilter = classIDDefined
    ? {
        match: {
          'classID.keyword': {
            query: classID,
          },
        },
      }
    : undefined

  const filter = {
    bool: {
      must: [nameFilter],
    },
  }

  if (classIDDefined) {
    filter.bool.must.push(classFilter)
  }

  const sort = [
    {
      _score: {
        order: 'desc',
      },
    },
    {
      _id: {
        order: 'asc',
      },
    },
  ]

  try {
    const { data } = await API.graphql({
      query: searchProductsAndServicesEnhancedQuery,
      variables: {
        filter: JSON.stringify(filter),
        sort: JSON.stringify(sort),
        limit,
      },
      authMode: authenticated ? PRIVATE_AUTH_MODE : PUBLIC_AUTH_MODE,
    })
    const items = data?.searchClassProductOrServicesEnhanced
      ? data.searchClassProductOrServicesEnhanced.items
      : []
    return items
  } catch (e) {
    debug('error', e)
    return []
  }
}

searchProductsAndServicesEnhanced.propTypes = {
  name: PropTypes.bool.isRequired,
  classID: PropTypes.string,
}
