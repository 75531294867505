import React from 'react'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import {
  useMediaQuery,
  useTheme,
  Paper,
  Grid,
  Chip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  paymentRequestStatus,
  concepts,
  PAYMENT_TX_STATUS_PAID,
} from '../../config'
import { Title } from '../common'

import { dateFormat, currencyFormat } from '../common/Forms'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: 'bold',
  },
  pending: {
    backgroundColor: '#E3CB1F',
  },
  accepted: {
    backgroundColor: '#3CBB44',
  },
  rejected: {
    backgroundColor: '#E3522B',
  },
  centered: {
    textAlign: 'center',
  },
  status: {
    display: 'flex',
    justifyContent: 'center',
  },
  statusChip: {
    fontWeight: 'bold',
    color: '#FFF',
  },
  divider: {
    height: theme.spacing(3),
  },
  paid: {
    backgroundColor: '#3CBB44',
  },
  notPaid: {
    backgroundColor: '#E3522B',
  },
}))

const PaymentRequestDetail = (props) => {
  const { enabled, paymentRequest, closeHandler } = props
  const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('xs'))
  const classes = useStyles()

  const handleClose = () => {
    closeHandler(false)
  }

  return (
    <div>
      <Dialog
        open={enabled}
        fullScreen={!!isMobileDevice}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Paper elevation={0}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Title>Solicitud de Pago</Title>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography className={clsx(classes.centered)}>
                    <span className={clsx(classes.subtitle)}>Concepto: </span>
                    {concepts.professionalServices.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography className={clsx(classes.centered)}>
                    <span className={clsx(classes.subtitle)}>Fecha: </span>
                    {paymentRequest
                      ? dateFormat(paymentRequest?.createdAt, 'datetime')
                      : null}{' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography className={clsx(classes.centered)}>
                    <span className={clsx(classes.subtitle)}>Monto: </span>
                    {currencyFormat(paymentRequest?.amount, '$', 2)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography className={clsx(classes.centered)}>
                    <span className={clsx(classes.subtitle)}>
                      Derechos del IMPI:{' '}
                    </span>
                    {currencyFormat(paymentRequest?.impiRights, '$', 2)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid item container className={clsx(classes.status)}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className={clsx(classes.centered)}>
                        <span className={clsx(classes.subtitle)}>
                          Estatus:{' '}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={clsx(classes.status)}>
                      <Chip
                        className={clsx(
                          classes[
                            paymentRequest?.status
                              ? paymentRequest?.status
                              : 'pending'
                          ],
                          classes.statusChip
                        )}
                        label={
                          paymentRequest?.status
                            ? paymentRequestStatus[paymentRequest?.status].label
                            : 'Pendiente'
                        }
                      />
                    </Grid>
                    <div className={clsx(classes.divider)} />
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={clsx(classes.status)}>
                      <Chip
                        className={clsx(
                          classes[
                            paymentRequest?.paymentTransaction?.status
                              ? paymentRequest?.paymentTransaction?.status
                              : 'pending'
                          ],
                          classes.statusChip
                        )}
                        label={
                          paymentRequest?.paymentTransaction?.status ===
                          PAYMENT_TX_STATUS_PAID
                            ? 'Pagado'
                            : 'No Pagado'
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            color="primary"
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

PaymentRequestDetail.propTypes = {
  enabled: PropTypes.bool,
  paymentRequest: PropTypes.shape({}),
  closeHandler: PropTypes.func,
}

PaymentRequestDetail.defaultProps = {
  enabled: false,
  paymentRequest: undefined,
  closeHandler: undefined,
}

export default PaymentRequestDetail
