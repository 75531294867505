import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { MainAuthenticator } from './MainAuthenticator'

const useStyles = makeStyles(() => ({
  loginComponent: {
    width: '100%',
  },
}))

const AuthEntry = (props) => {
  const {
    authState,
    customAuthData,
    firstSignUp,
    showCancelButton,
    theme,
  } = props
  const classes = useStyles()

  return (
    <MainAuthenticator
      authState={authState}
      customAuthData={customAuthData}
      theme={theme}
      firstSignUp={firstSignUp}
      showCancelButton={showCancelButton}>
      <div className={classes.loginComponent} />
    </MainAuthenticator>
  )
}

AuthEntry.propTypes = {
  authState: PropTypes.oneOf([
    'signIn',
    'signUp',
    'confirmSignIn',
    'confirmSignUp',
    'forgotPassword',
    'requireNewPassword',
    'verifyContact',
    'signedIn',
    'signedUp',
    'signedOut',
    'loading',
    'TOTPSetup',
  ]),
  customAuthData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  theme: PropTypes.shape({}),
  firstSignUp: PropTypes.bool.isRequired,
  showCancelButton: PropTypes.bool,
}

AuthEntry.defaultProps = {
  authState: 'signIn',
  customAuthData: undefined,
  theme: undefined,
  showCancelButton: true,
}

export default AuthEntry
