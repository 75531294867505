/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f0153d05-3198-471b-912b-7584f03c7a0a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_aZmgrmw6M",
    "aws_user_pools_web_client_id": "1sk2rnb1v64l7p2q6endrasb0l",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://fjngtmgqkb.execute-api.us-east-1.amazonaws.com/qa",
            "region": "us-east-1"
        },
        {
            "name": "trademarkPlatformOpenPayApi",
            "endpoint": "https://1zn5yhy55i.execute-api.us-east-1.amazonaws.com/qa",
            "region": "us-east-1"
        },
        {
            "name": "trademarkPlatformLocations",
            "endpoint": "https://2u4lr3usmj.execute-api.us-east-1.amazonaws.com/qa",
            "region": "us-east-1"
        },
        {
            "name": "trademarkPlatformEmailService",
            "endpoint": "https://6hdh5b77m2.execute-api.us-east-1.amazonaws.com/qa",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://dsq63xvtwrhvrcmegug5urqaxm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "trademarkplatformf86941fadafb4f1094e702518094e8234302-qa",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
