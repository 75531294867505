import React from 'react'
import Popover from '@material-ui/core/Popover'
import { Button, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

/* eslint-disable-next-line no-unused-vars */
const useStyles = makeStyles((theme) => ({
  content: {
    padding: '25px',
  },
}))

const PopOverModal = (props) => {
  const classes = useStyles()
  const { id, buttonVariant, buttonClass, buttonText, renderContent } = props

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Button
        aria-describedby={id}
        variant={buttonVariant}
        className={buttonClass}
        onClick={handleClick}>
        {buttonText}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        {renderContent(classes.content, handleClose)}
      </Popover>
    </div>
  )
}

PopOverModal.propTypes = {
  id: PropTypes.string.isRequired,
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string,
  renderContent: PropTypes.func.isRequired,
}

PopOverModal.defaultProps = {
  buttonClass: undefined,
  buttonVariant: 'outlined',
}

export default PopOverModal
