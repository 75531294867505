import { useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { useLayoutContext } from '../components/context'

export const useAmplifyAuth = () => {
  const {
    authenticated,
    setAuthenticated,
    showAuthUI,
    setShowAuthUI,
    currentUser,
    setCurrentUser,
    authError,
    setAuthError,
  } = useLayoutContext()

  const validateAuthenticatedUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      setCurrentUser(user)
      setAuthenticated(true)
      setShowAuthUI(false)
    } catch (err) {
      setCurrentUser(null)
      setAuthenticated(false)
      setShowAuthUI(false)
    }
  }

  useEffect(() => {
    validateAuthenticatedUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHubCapsule = (capsule) => {
    // eslint-disable-next-line no-unused-vars
    const { channel, payload, source } = capsule
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          // eslint-disable-next-line no-case-declarations
          setCurrentUser(payload.data)
          setAuthenticated(true)
          setShowAuthUI(false)
          break
        case 'signOut':
          setCurrentUser(null)
          setAuthenticated(false)
          setShowAuthUI(false)
          break
        case 'signIn_failure':
        case 'signUp_failure':
        case 'completeNewPassword_failure':
        case 'forgotPassword_failure':
        case 'forgotPasswordSubmit_failure':
        case 'cognitoHostedUI_failure':
        case 'customState_failure':
          setCurrentUser(null)
          setAuthenticated(false)
          setAuthError(payload.data)
          break
        default:
          setAuthError(payload.data)
          break
      }
    }
  }

  Hub.listen('auth', onHubCapsule)

  return {
    authenticated,
    setAuthenticated,
    showAuthUI,
    setShowAuthUI,
    currentUser,
    setCurrentUser,
    authError,
    setAuthError,
  }
}

export default useAmplifyAuth
