/* eslint-disable import/no-named-as-default */
import React from 'react'
import PropTypes from 'prop-types'

import { BaseAuthenticator } from './BaseAuthenticator'
// import MaterialSignUp from './MaterialSignUp'
import MaterialSignIn from './MaterialSignIn'
import MaterialConfirmSignIn from './MaterialConfirmSignIn'
import MaterialForgotPassword from './MaterialForgotPassword'
import MaterialVerifyContact from './MaterialVerifyContact'
// import MaterialConfirmSignUp from './MaterialConfirmSignUp'
import MaterialRequireNewPassword from './MaterialRequireNewPassword'
import { signUpConfig as defaultSignUpConfig } from './common/default-sign-up-config'
import MaterialDeniedAccessComponent from './MaterialDeniedAccessComponent'
import { ACCEPTED_COGNITO_GROUPS } from '../../config'

export const MainAuthenticator = (props) => {
  const {
    authState,
    customAuthData,
    signUpConfig = defaultSignUpConfig,
    // firstSignUp,
    showCancelButton,
  } = props
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <BaseAuthenticator
      includeGreetings={false}
      authenticatorComponents={[
        // eslint-disable-next-line react/jsx-key
        // For console user no sign up is permitted
        // <MaterialSignUp
        //   override="SignUp"
        //   signUpConfig={signUpConfig}
        //   showCancelButton={showCancelButton}
        // />,
        // eslint-disable-next-line react/jsx-key
        <MaterialSignIn
          override="SignIn"
          federated={null}
          showCancelButton={showCancelButton}
          customAuthData={customAuthData}
        />,
        // eslint-disable-next-line react/jsx-key
        <MaterialConfirmSignIn override="ConfirmSignIn" />,
        // eslint-disable-next-line react/jsx-key
        <MaterialForgotPassword
          override="ForgotPassword"
          customAuthData={customAuthData}
        />,
        // eslint-disable-next-line react/jsx-key
        // For console user no sign up is permitted
        // <MaterialConfirmSignUp
        //   override="ConfirmSignUp"
        //   firstSignUp={firstSignUp}
        // />,
        // eslint-disable-next-line react/jsx-key
        <MaterialRequireNewPassword override="RequireNewPassword" />,
        // eslint-disable-next-line react/jsx-key
        <MaterialVerifyContact override="VerifyContact" />,
      ]}
      authState={authState}
      federated={null}
      theme={null}
      signUpConfig={signUpConfig}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

MainAuthenticator.propTypes = {
  Comp: PropTypes.elementType,
  DeniedAccessComp: PropTypes.elementType,
  acceptedCognitoGroups: PropTypes.arrayOf([PropTypes.string]),
  authState: PropTypes.oneOf([
    'signIn',
    'signUp',
    'confirmSignIn',
    'confirmSignUp',
    'forgotPassword',
    'requireNewPassword',
    'verifyContact',
    'signedIn',
    'signedUp',
    'signedOut',
    'loading',
    'TOTPSetup',
  ]),
  customAuthData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  theme: PropTypes.shape({}),
  signUpConfig: PropTypes.shape({}),
  firstSignUp: PropTypes.bool,
  showCancelButton: PropTypes.bool,
}

MainAuthenticator.defaultProps = {
  Comp: undefined,
  // eslint-disable-next-line react/display-name
  DeniedAccessComp: MaterialDeniedAccessComponent,
  acceptedCognitoGroups: JSON.parse(ACCEPTED_COGNITO_GROUPS),
  authState: 'signIn',
  customAuthData: undefined,
  theme: undefined,
  signUpConfig: defaultSignUpConfig,
  firstSignUp: true,
  showCancelButton: false,
}

const withMainAuthenticator = (Comp) => {
  const Wrapped = (props) => {
    const { authState, customAuthData } = props
    // eslint-disable-next-line react/destructuring-assignment
    return (
      <MainAuthenticator
        Comp={Comp}
        DeniedAccessComp={MaterialDeniedAccessComponent}
        acceptedCognitoGroups={JSON.parse(ACCEPTED_COGNITO_GROUPS)}
        authState={authState}
        customAuthData={customAuthData}
        {...props}
      />
    )
  }

  Wrapped.propTypes = {
    authState: PropTypes.string,
    customAuthData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    theme: PropTypes.shape({}),
    signUpConfig: PropTypes.shape({}),
    firstSignUp: PropTypes.bool,
    showCancelButton: PropTypes.bool,
  }

  Wrapped.defaultProps = {
    authState: 'signIn',
    customAuthData: undefined,
    theme: PropTypes.shape({}),
    signUpConfig: defaultSignUpConfig,
    firstSignUp: true,
    showCancelButton: true,
  }

  return Wrapped
}

export default withMainAuthenticator
