/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n, Logger } from '@aws-amplify/core'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import Auth from '@aws-amplify/auth'
import PropTypes from 'prop-types'
import {
  Container,
  Grid,
  InputAdornment,
  IconButton,
  Typography,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InfoIcon from '@material-ui/icons/Info'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import parseHtml from 'html-react-parser'
import { FormField } from '../common'
import { withMaterialStyles } from './MainMaterialStyles'
import LoaderButton from '../common/LoaderButton'
import { isValidAWSCognitoPassword } from '../../regex.validations'
import { AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE } from '../../config/constants'

const logger = new Logger('ForgotPassword')

const MaterialChangePassword = ({
  classes,
  currentUser,
  variant,
  loading,
  setLoading,
  onSuccess,
  openPasswordInformation,
}) => {
  const {
    handleSubmit,
    control,
    errors,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      username: currentUser?.username,
      currentPassword: undefined,
      password: undefined,
      confirmPassword: undefined,
    },
  })
  const { username } = currentUser || {}
  const { enqueueSnackbar } = useSnackbar()
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  useEffect(() => {
    setValue('username', username)
  }, [setValue, username])

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword)
  }

  const handleMouseDownCurrentPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault()
  }

  const renderHeader = () => {
    return <span className={classes.title}>{I18n.get('Change Password')}</span>
  }

  const validatePasswordStrength = (password) => {
    return isValidAWSCognitoPassword.test(password)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      // eslint-disable-next-line no-unused-vars
      const { currentPassword, password, confirmPassword } = data
      if (!validatePasswordStrength(password)) {
        enqueueSnackbar(
          parseHtml(AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE),
          {
            preventDuplicate: true,
            variant: 'warning',
          }
        )
        setLoading(false)
        return
      }
      const updatedPassword = await Auth.changePassword(
        currentUser,
        currentPassword,
        password
      )
      logger.log('updatedPassword', updatedPassword)
      if (updatedPassword === 'SUCCESS') {
        enqueueSnackbar('Contraseña actualizada exitosamente', {
          preventDuplicate: true,
          variant: 'success',
        })
        if (onSuccess) {
          await onSuccess()
        }
      } else {
        enqueueSnackbar('Error al cambiar al contraseña', {
          preventDuplicate: true,
          variant: 'warning',
        })
      }
    } catch (e) {
      logger.log('e', e)
      const message =
        e?.code && e?.code === 'NotAuthorizedException'
          ? I18n.get(e?.message)
          : 'Error al cambiar al contraseña'
      enqueueSnackbar(message, {
        preventDuplicate: true,
        variant: 'warning',
      })
    }
    setLoading(false)
  }

  const renderBody = () => {
    return (
      <>
        <Grid item xs={12} className={classes.mediumSpacing}>
          <FormField
            id="username"
            name="username"
            label={I18n.get('Username')}
            type="text"
            size="small"
            required
            disabled={username && username !== ''}
            variant={variant}
            autoComplete="off"
            autoFocus={false}
            fullWidth
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Ingresa tu nombre de usuario',
              },
            }}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} className={classes.mediumSpacing}>
          <FormField
            id="currentPassword"
            name="currentPassword"
            label={I18n.get('Current Password')}
            type={showCurrentPassword ? 'text' : 'password'}
            size="small"
            required
            variant={variant}
            autoComplete="off"
            autoFocus={false}
            fullWidth
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Ingresa tu contraseña actual',
              },
              validate: {
                noMatchesCurrentPassword: (value) => {
                  const { password } = getValues()
                  return (
                    password !== value || 'Las contraseñas deben ser distintas'
                  )
                },
                // eslint-disable-next-line react/display-name
                matchesPasswordRules: (value) => {
                  return (
                    validatePasswordStrength(value) || (
                      <Typography variant="subtitle2">
                        {parseHtml(
                          AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE
                        )}
                      </Typography>
                    )
                  )
                },
              },
            }}
            errors={errors}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCurrentPassword}
                    onMouseDown={handleMouseDownCurrentPassword}>
                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => {
                      openPasswordInformation()
                    }}>
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={async () => {
              if (errors?.password) await trigger('password')
            }}
            onBlur={async () => {
              if (errors?.password) await trigger('password')
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.mediumSpacing}>
          <FormField
            id="password"
            name="password"
            label={I18n.get('Password')}
            type={showPassword ? 'text' : 'password'}
            size="small"
            required
            variant={variant}
            autoComplete="off"
            autoFocus={false}
            fullWidth
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Ingresa tu nueva contraseña',
              },
              validate: {
                // eslint-disable-next-line react/display-name
                matchesPasswordRules: (value) => {
                  return (
                    validatePasswordStrength(value) || (
                      <Typography variant="subtitle2">
                        {parseHtml(
                          AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE
                        )}
                      </Typography>
                    )
                  )
                },
                noMatchesCurrentPassword: (value) => {
                  const { currentPassword } = getValues()
                  return (
                    currentPassword !== value ||
                    'Las contraseñas deben ser distintas'
                  )
                },
                matchesPassword: (value) => {
                  const { confirmPassword } = getValues()
                  return (
                    confirmPassword === value || 'Las contraseñas no coinciden'
                  )
                },
              },
            }}
            errors={errors}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => {
                      openPasswordInformation()
                    }}>
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={async () => {
              if (errors?.currentPassword) await trigger('currentPassword')
            }}
            onBlur={async () => {
              if (errors?.currentPassword) await trigger('currentPassword')
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.mediumSpacing}>
          <FormField
            id="confirmPassword"
            name="confirmPassword"
            label={I18n.get('Confirm Password')}
            type={showConfirmPassword ? 'text' : 'password'}
            size="small"
            required
            variant={variant}
            autoComplete="off"
            autoFocus
            fullWidth
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Confirma tu nueva contraseña',
              },
              validate: {
                // eslint-disable-next-line react/display-name
                matchesPasswordRules: (value) => {
                  return (
                    validatePasswordStrength(value) || (
                      <Typography variant="subtitle2">
                        {parseHtml(
                          AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE
                        )}
                      </Typography>
                    )
                  )
                },
                matchesPassword: (value) => {
                  const { password } = getValues()
                  return password === value || 'Las contraseñas no coinciden'
                },
              },
            }}
            errors={errors}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}>
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => {
                      openPasswordInformation()
                    }}>
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={async () => {
              if (errors?.password) await trigger('password')
            }}
            onBlur={async () => {
              if (errors?.password) await trigger('password')
            }}
          />
        </Grid>
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
        <Grid item xs={12} sm={12} className={classes.largeSpacing}>
          <LoaderButton
            type="submit"
            loading={loading}
            buttonText={I18n.get('Change')}
          />
        </Grid>
      </>
    )
  }

  const render = username
  if (!render) return null

  return (
    <Container className={classes.mainMaterialLayoutContainer} maxWidth="xs">
      <Container spacing={3}>
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(onSubmit)()
            e.stopPropagation()
          }}>
          <Grid item name="headerSection">
            {renderHeader()}
          </Grid>
          <Grid item name="bodySection">
            {renderBody()}
          </Grid>
          <Grid item name="footerSection">
            {renderFooter()}
          </Grid>
        </form>
      </Container>
    </Container>
  )
}

MaterialChangePassword.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.string,
    mainMaterialLayoutContainer: PropTypes.string,
    mediumSpacing: PropTypes.string,
    largeSpacing: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    username: PropTypes.string,
    attributes: PropTypes.shape({
      email: PropTypes.string,
      email_verified: PropTypes.bool,
      sub: PropTypes.string,
    }),
  }).isRequired,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'contained']),
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  onSuccess: PropTypes.func,
  openPasswordInformation: PropTypes.func,
}

MaterialChangePassword.defaultProps = {
  classes: undefined,
  variant: 'outlined',
  loading: undefined,
  setLoading: () => {},
  onSuccess: () => {},
  openPasswordInformation: () => {},
}

export default withMaterialStyles(MaterialChangePassword)
