import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import clsx from 'clsx'
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormLabel,
  makeStyles,
  FormControl,
  RadioGroup,
  Radio,
  FormGroup,
  Select,
  Switch,
  InputLabel,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Grid,
  FormHelperText,
} from '@material-ui/core'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { parseISO, parse, format as dateFNSFormat } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  required: {
    color: theme.palette.error.main,
  },
  menuItem: {
    padding: theme.spacing(0.8),
  },
  option: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
}))

// eslint-disable-next-line no-unused-vars
const FormLine = ({ children, className }) => (
  <FormControl className={clsx('form-group', className)}>
    {children}
  </FormControl>
)

FormLine.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
}

FormLine.defaultProps = {
  className: undefined,
}

// eslint-disable-next-line no-unused-vars
const FormField = (props) => {
  const classes = useStyles()

  const {
    id,
    name,
    label,
    type,
    options,
    native,
    placeholder,
    className,
    labelClassName,
    labelPlacement,
    color,
    icon,
    menuItemClassName,
    required,
    disabled,
    autoComplete,
    autoFocus,
    variant,
    size,
    fullWidth,
    inputRef,
    control,
    rules,
    inputProps,
    InputProps,
    InputLabelProps,
    IconComponent,
    endAdornment,
    multiline,
    rows,
    multiple,
    renderValue,
    displayEmpty,
    onChange,
    onBlur,
    onFocus,
    errors,
  } = props

  const generateOptions = (selectOptions, nativeOptions) => {
    if (!options || options.length === 0) {
      if (nativeOptions) {
        return [
          <option
            key="keyFor-no-options"
            className={clsx(classes.menuItem, menuItemClassName)}
            value={null}>
            Sin opciones
          </option>,
        ]
      }
      return [
        <MenuItem
          key="keyFor-no-options"
          className={clsx(classes.menuItem, menuItemClassName)}
          value={null}>
          <Grid container>
            <Grid item xs={12}>
              <ListItemText primary="Sin opciones" />
            </Grid>
          </Grid>
        </MenuItem>,
      ]
    }
    return selectOptions.map((option) => {
      if (nativeOptions) {
        return (
          <option
            key={`keyFor-${option.value}`}
            className={clsx(classes.menuItem, menuItemClassName)}
            value={option.value}>
            {option.label}
          </option>
        )
      }
      return (
        <MenuItem
          key={`keyFor-${option.value}`}
          className={clsx(classes.menuItem, menuItemClassName)}
          value={option.value}>
          <Grid container>
            {option.icon ? (
              <Grid item xs={2}>
                <ListItemIcon className={clsx(classes.option)}>
                  {option.icon}
                </ListItemIcon>
              </Grid>
            ) : null}
            <Grid item xs={option.icon ? 10 : 12}>
              <ListItemText primary={option.label} />
            </Grid>
          </Grid>
        </MenuItem>
      )
    })
  }

  const defaultRenderValue = (selected) => {
    const option = options?.find((o) => o.value === selected)
    if (!option) return null
    return (
      <>
        {native ? (
          <span>{option?.label}</span>
        ) : (
          <span>
            {option?.icon ? option.icon : null}
            {option?.label}
          </span>
        )}
      </>
    )
  }

  const renderValueFunc = renderValue || defaultRenderValue

  // eslint-disable-next-line no-unused-vars
  const error = _.get(errors, name)

  if (type === 'select') {
    return (
      <FormControl
        size={size}
        variant={variant}
        className={clsx(className)}
        error={!!error}
        required={required}
        disabled={disabled}
        fullWidth={fullWidth}>
        <InputLabel id={id} className={clsx(labelClassName)}>
          {label}
        </InputLabel>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ onChange: onChangeByControl, value }) => {
            return (
              <Select
                id={id}
                labelId={id}
                label={label}
                variant={variant}
                error={!!error}
                disabled={disabled}
                displayEmpty={displayEmpty}
                type={type}
                value={value || ''}
                renderValue={renderValueFunc}
                className={clsx(labelClassName)}
                onChange={(event) => {
                  if (onChange) onChange(event)
                  onChangeByControl(event.target.value)
                }}
                onBlur={onBlur}
                onFocus={onFocus}
                multiple={multiple}
                endAdornment={endAdornment}
                IconComponent={IconComponent}
                native={native}>
                {generateOptions(options, native)}
              </Select>
            )
          }}
        />
        {error?.message ? (
          <FormHelperText>{error.message}</FormHelperText>
        ) : null}
      </FormControl>
    )
  }

  if (type === 'checkbox') {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ onChange: onChangeByControl, value }) => {
          return (
            <div className={clsx(className)} key={`keyFor${id}`}>
              <FormControlLabel
                className={clsx(labelClassName)}
                control={
                  <Checkbox
                    id={id}
                    size={size}
                    color={color}
                    inputProps={inputProps}
                    disabled={disabled}
                    onChange={(event) => {
                      if (onChange) onChange(event)
                      onChangeByControl(event.target.checked)
                    }}
                    checked={value}
                  />
                }
                label={
                  <>
                    {icon && <span>{icon}</span>}
                    {label}
                  </>
                }
                labelPlacement={labelPlacement}
              />
            </div>
          )
        }}
      />
    )
  }

  if (type === 'switch') {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ onChange: onChangeByControl, value }) => {
          return (
            <div className={clsx(className)} key={`keyFor${id}`}>
              <FormControlLabel
                className={clsx(labelClassName)}
                control={
                  <Switch
                    id={id}
                    size={size}
                    color={color}
                    inputProps={inputProps}
                    disabled={disabled}
                    onChange={(event) => {
                      if (onChange) onChange(event)
                      onChangeByControl(event.target.checked)
                    }}
                    checked={value}
                  />
                }
                label={
                  <>
                    {icon && <span>{icon}</span>}
                    {label}
                  </>
                }
                labelPlacement={labelPlacement}
              />
            </div>
          )
        }}
      />
    )
  }

  return (
    <>
      {control ? (
        <Controller
          control={control}
          name={name}
          rules={rules}
          // eslint-disable-next-line no-unused-vars
          render={({ onChange: onChangeByControl, value }) => {
            return (
              <TextField
                error={!!error}
                id={id}
                name={name}
                value={value}
                label={label}
                type={type}
                placeholder={placeholder}
                className={className}
                required={required}
                disabled={disabled}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                variant={variant}
                size={size}
                fullWidth={fullWidth}
                inputProps={inputProps}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={InputProps}
                InputLabelProps={
                  type === 'date' || type === 'time'
                    ? { ...InputLabelProps, shrink: true }
                    : { ...InputLabelProps }
                }
                multiline={multiline}
                rows={rows}
                helperText={error?.message}
                onChange={(event) => {
                  if (onChange) onChange(event)
                  onChangeByControl(event.target.value)
                }}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            )
          }}
        />
      ) : (
        <TextField
          error={!!error}
          id={id}
          name={name}
          label={label}
          type={type}
          placeholder={placeholder}
          className={className}
          required={required}
          disabled={disabled}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          variant={variant}
          size={size}
          fullWidth={fullWidth}
          inputRef={inputRef}
          inputProps={inputProps}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={InputProps}
          InputLabelProps={
            type === 'date' || type === 'time'
              ? { ...InputLabelProps, shrink: true }
              : { ...InputLabelProps }
          }
          multiline={multiline}
          rows={rows}
          helperText={error?.message}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      )}
    </>
  )
}

FormField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  type: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    })
  ),
  native: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['top', 'start', 'bottom', 'end']),
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  menuItemClassName: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  inputRef: PropTypes.elementType,
  control: PropTypes.shape({}),
  rules: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  InputLabelProps: PropTypes.shape({}),
  IconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  endAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  SelectProps: PropTypes.shape({}),
  multiline: PropTypes.bool,
  rows: PropTypes.string,
  multiple: PropTypes.bool,
  renderValue: PropTypes.func,
  displayEmpty: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  errors: PropTypes.shape({}),
}

FormField.defaultProps = {
  label: undefined,
  type: undefined,
  options: undefined,
  native: undefined,
  placeholder: undefined,
  className: undefined,
  labelClassName: undefined,
  labelPlacement: 'end',
  color: 'primary',
  icon: undefined,
  menuItemClassName: undefined,
  required: undefined,
  disabled: undefined,
  autoComplete: undefined,
  autoFocus: undefined,
  variant: 'outlined',
  size: undefined,
  fullWidth: true,
  inputRef: undefined,
  control: undefined,
  rules: undefined,
  inputProps: undefined,
  InputProps: undefined,
  InputLabelProps: undefined,
  IconComponent: undefined,
  endAdornment: undefined,
  SelectProps: undefined,
  multiline: false,
  rows: undefined,
  multiple: false,
  renderValue: undefined,
  displayEmpty: false,
  onChange: undefined,
  onBlur: undefined,
  onFocus: undefined,
  errors: undefined,
}

const FormFieldDateTimePicker = (props) => {
  const {
    id,
    name,
    label,
    type,
    placeholder,
    className,
    required,
    disabled,
    autoComplete,
    autoFocus,
    variant,
    size,
    fullWidth,
    control,
    rules,
    inputProps,
    InputProps,
    InputLabelProps,
    InputAdornmentProps,
    inputVariant,
    autoOk,
    disableFuture,
    disablePast,
    format,
    invalidDateMessage,
    invalidLabel,
    mask,
    maxDate,
    maxDateMessage,
    minDate,
    minDateMessage,
    cancelLabel,
    clearable,
    clearLabel,
    okLabel,
    showTodayButton,
    todayLabel,
    onChange,
    onBlur,
    onFocus,
    errors,
  } = props

  const error = _.get(errors, name)

  if (type === 'date') {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        // eslint-disable-next-line no-unused-vars
        render={({ onChange: onChangeByControl, value }) => {
          return (
            <KeyboardDatePicker
              error={!!error}
              id={id}
              name={name}
              value={value}
              label={label}
              placeholder={placeholder}
              className={className}
              required={required}
              disabled={disabled}
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              inputVariant={inputVariant}
              variant={variant}
              size={size}
              fullWidth={fullWidth}
              inputProps={inputProps}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={InputProps}
              InputLabelProps={{ ...InputLabelProps, shrink: true }}
              InputAdornmentProps={InputAdornmentProps}
              helperText={error?.message}
              onChange={(event) => {
                if (onChange) onChange(event)
                onChangeByControl(event)
              }}
              onBlur={onBlur}
              onFocus={onFocus}
              autoOk={autoOk}
              disableFuture={disableFuture}
              disablePast={disablePast}
              format={format}
              invalidDateMessage={invalidDateMessage}
              invalidLabel={invalidLabel}
              mask={mask}
              maxDate={maxDate}
              maxDateMessage={maxDateMessage}
              minDate={minDate}
              minDateMessage={minDateMessage}
              cancelLabel={cancelLabel}
              clearable={clearable}
              clearLabel={clearLabel}
              okLabel={okLabel}
              showTodayButton={showTodayButton}
              todayLabel={todayLabel}
            />
          )
        }}
      />
    )
  }
  return null
}

FormFieldDateTimePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  variant: PropTypes.oneOf(['dialog', 'inline', 'static']),
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  control: PropTypes.shape({}),
  rules: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  InputLabelProps: PropTypes.shape({}),
  InputAdornmentProps: PropTypes.shape({}),
  inputVariant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  autoOk: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  format: PropTypes.string,
  invalidDateMessage: PropTypes.string,
  invalidLabel: PropTypes.string,
  mask: PropTypes.string,
  maxDate: PropTypes.shape({}),
  maxDateMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  minDate: PropTypes.shape({}),
  minDateMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  cancelLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  clearable: PropTypes.bool,
  clearLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  okLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  showTodayButton: PropTypes.bool,
  todayLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  errors: PropTypes.shape({}),
}

FormFieldDateTimePicker.defaultProps = {
  label: undefined,
  type: undefined,
  placeholder: undefined,
  className: undefined,
  required: undefined,
  disabled: undefined,
  autoComplete: undefined,
  autoFocus: undefined,
  variant: 'dialog',
  size: undefined,
  fullWidth: true,
  control: undefined,
  rules: undefined,
  inputProps: undefined,
  InputProps: undefined,
  InputLabelProps: undefined,
  InputAdornmentProps: undefined,
  inputVariant: 'outlined',
  autoOk: undefined,
  disableFuture: undefined,
  disablePast: undefined,
  format: undefined,
  invalidDateMessage: undefined,
  invalidLabel: undefined,
  mask: undefined,
  maxDate: undefined,
  maxDateMessage: undefined,
  minDate: undefined,
  minDateMessage: undefined,
  cancelLabel: undefined,
  clearable: undefined,
  clearLabel: undefined,
  okLabel: undefined,
  showTodayButton: undefined,
  todayLabel: undefined,
  onChange: undefined,
  onBlur: undefined,
  onFocus: undefined,
  errors: undefined,
}

const FormFieldMultipleOptions = (props) => {
  const {
    id,
    name,
    label,
    labelClassName,
    options,
    type,
    className,
    color,
    required,
    disabled,
    variant,
    size,
    fullWidth,
    labelPlacement,
    row,
    control,
    rules,
    value,
    inputProps,
    onChange,
    errors,
  } = props
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles()
  const error = _.get(errors, name)

  // https://github.com/react-hook-form/react-hook-form/issues/1517#issuecomment-662386647
  // https://codesandbox.io/s/material-demo-54nvi?file=/demo.js
  // https://codesandbox.io/s/material-demo-ofk6d?file=/demo.js
  // https://codesandbox.io/s/material-demo-forked-h75ri?file=/demo.js
  const handleSelectedOption = (checkedName) => {
    const newNames = value?.includes(checkedName)
      ? value?.filter((checkedValue) => checkedValue !== checkedName)
      : [...(value ?? []), checkedName]
    return newNames
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      // eslint-disable-next-line no-unused-vars
      render={({ onChange: onChangeByControl, value: valueByControl }) => {
        return (
          <>
            <FormControl
              id={id}
              component="fieldset"
              size={size}
              variant={variant}
              className={clsx(className)}
              error={!!error}
              required={required}
              disabled={disabled}
              fullWidth={fullWidth}>
              <FormLabel component="legend">{label || name}</FormLabel>
              {error?.message ? (
                <FormHelperText>{error.message}</FormHelperText>
              ) : null}
              {/* eslint-disable-next-line no-nested-ternary */}
              {type === 'radio' ? (
                <RadioGroup
                  name={name}
                  row={row}
                  onChange={(event) => {
                    if (onChange) onChange(event)
                    onChangeByControl(event.target.value)
                  }}
                  value={valueByControl}>
                  {Array.isArray(options) &&
                    options.length > 0 &&
                    options.map(
                      (
                        { label: labelOption, value: valueOption, icon },
                        index
                      ) => (
                        <div
                          className={clsx(className)}
                          key={`keyFor${labelOption}`}>
                          <FormControlLabel
                            className={clsx(labelClassName)}
                            value={valueOption}
                            control={
                              <Radio
                                id={`inputFor${name}_${index}`}
                                // name={name}
                                size={size}
                                color={color}
                                // inputRef={inputRef}
                                inputProps={inputProps}
                                disabled={disabled}
                              />
                            }
                            label={
                              <>
                                {icon && <span>{icon}</span>}
                                {labelOption}
                              </>
                            }
                            labelPlacement={labelPlacement}
                          />
                        </div>
                      )
                    )}
                </RadioGroup>
              ) : type === 'checkbox' ? (
                <FormGroup
                  name={name}
                  size={size}
                  variant={variant}
                  error={!!error}
                  required={required}
                  disabled={disabled}
                  fullWidth={fullWidth}
                  row={row}>
                  {Array.isArray(options) &&
                    options.length > 0 &&
                    options.map(
                      (
                        { label: labelOption, value: valueOption, icon },
                        index
                      ) => (
                        <div
                          className={clsx(className)}
                          key={`keyFor${labelOption}`}>
                          <FormControlLabel
                            className={clsx(labelClassName)}
                            value={valueOption}
                            control={
                              <Checkbox
                                id={`inputFor${name}_${index}`}
                                // value={value}
                                // name={name}
                                size={size}
                                color={color}
                                // inputRef={inputRef}
                                inputProps={inputProps}
                                disabled={disabled}
                                onChange={(event) => {
                                  if (onChange) onChange(event)
                                  onChangeByControl(
                                    handleSelectedOption(event.target.value)
                                  )
                                }}
                                checked={value?.includes(valueOption)}
                              />
                            }
                            label={
                              <>
                                {icon && <span>{icon}</span>}
                                {labelOption}
                              </>
                            }
                            labelPlacement={labelPlacement}
                          />
                        </div>
                      )
                    )}
                </FormGroup>
              ) : (
                <FormGroup
                  size={size}
                  variant={variant}
                  error={!!error}
                  required={required}
                  disabled={disabled}
                  fullWidth={fullWidth}
                  row={row}>
                  {Array.isArray(options) &&
                    options.length > 0 &&
                    options.map(
                      (
                        { label: labelOption, value: valueOption, icon },
                        index
                      ) => (
                        <div
                          className={clsx(className)}
                          key={`keyFor${labelOption}`}>
                          <FormControlLabel
                            className={clsx(labelClassName)}
                            value={valueOption}
                            control={
                              <Switch
                                id={`inputFor${name}_${index}`}
                                // value={value}
                                // name={name}
                                size={size}
                                color={color}
                                // inputRef={inputRef}
                                inputProps={inputProps}
                                disabled={disabled}
                                onChange={(event) => {
                                  if (onChange) onChange(event)
                                  onChangeByControl(
                                    handleSelectedOption(event.target.value)
                                  )
                                }}
                                checked={value?.includes(valueOption)}
                              />
                            }
                            label={
                              <>
                                {icon && <span>{icon}</span>}
                                {labelOption}
                              </>
                            }
                            labelPlacement={labelPlacement}
                          />
                        </div>
                      )
                    )}
                </FormGroup>
              )}
            </FormControl>
          </>
        )
      }}
    />
  )
}

FormFieldMultipleOptions.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.oneOf(['radio', 'checkbox', 'switch']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    })
  ).isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  size: PropTypes.oneOf(['small', 'medium']),
  fullWidth: PropTypes.bool,
  labelPlacement: PropTypes.oneOf(['top', 'start', 'bottom', 'end']),
  row: PropTypes.bool,
  control: PropTypes.shape({}),
  rules: PropTypes.shape({}),
  value: PropTypes.arrayOf(PropTypes.string),
  inputProps: PropTypes.shape({}),
  onChange: PropTypes.func,
  errors: PropTypes.shape({}),
}

FormFieldMultipleOptions.defaultProps = {
  label: undefined,
  labelClassName: undefined,
  type: undefined,
  className: undefined,
  color: 'primary',
  required: undefined,
  disabled: undefined,
  variant: 'outlined',
  size: 'medium',
  fullWidth: true,
  labelPlacement: 'end',
  row: false,
  control: undefined,
  rules: undefined,
  value: undefined,
  inputProps: undefined,
  onChange: undefined,
  errors: undefined,
}

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, format, mask, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      // eslint-disable-next-line no-unused-vars
      // onChange={(event) => {}}
      onValueChange={(values) => {
        if (onChange)
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
      }}
      format={format}
      mask={mask}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  mask: PropTypes.string,
}

NumberFormatCustom.defaultProps = {
  onChange: () => {},
  format: undefined,
  mask: undefined,
}

const limit = (val, max) => {
  let newValue = val
  if (val.length === 1 && val[0] > max[0]) {
    newValue = `0${val}`
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      newValue = '01'

      // this can happen when user paste number
    } else if (val > max) {
      newValue = max
    }
  }

  return newValue
}

export const cardExpiryFormat = (val) => {
  const month = limit(val.substring(0, 2), '12')
  const year = val.substring(2, 4)
  // return month + (year.length ? `/${year}` : '')
  return (
    month.padEnd(2, 'M') + (year.length ? `/${year.padEnd(2, 'Y')}` : '/YY')
  )
}

const currencyFormat = (num, prefix, decimalScale) => {
  return (
    <NumberFormat
      value={num}
      displayType="text"
      thousandSeparator
      prefix={prefix}
      decimalScale={decimalScale}
      fixedDecimalScale
      renderText={(formattedValue) => formattedValue}
    />
  )
}

const dateFormat = (date, type, fromCustomFormat) => {
  let newDate

  if (type === 'datetime') {
    newDate = fromCustomFormat
      ? parse(date, fromCustomFormat, new Date())
      : parseISO(date)
  } else {
    newDate = parse(date, fromCustomFormat || 'yyyy-MM-ddxxx', new Date())
  }

  const stringFormat =
    type === 'datetime' ? 'dd/MM/yyyy HH:mm:ss' : 'dd/MM/yyyy'

  return dateFNSFormat(newDate, stringFormat)
}

export {
  FormLine,
  FormField,
  FormFieldDateTimePicker,
  FormFieldMultipleOptions,
  NumberFormatCustom,
  currencyFormat,
  dateFormat,
}
