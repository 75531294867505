import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  useMediaQuery,
  Container,
  Button,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import MUIDataTable from 'mui-datatables'
import { useForm } from 'react-hook-form'
import { navigate } from '@reach/router'
import clsx from 'clsx'
// eslint-disable-next-line import/no-extraneous-dependencies
import { I18n } from '@aws-amplify/core'
import { useSnackbar } from 'notistack'
import Title from '../common/text/Title'
import {
  useLayoutContext,
  useTrademarkSearchContext,
  useTrademarkContext,
} from '../context'
import {
  mapIcon,
  FormField,
  LoaderButton,
  FormFieldMultipleOptions,
} from '../common'
import BrandTypeModal from './BrandTypeModal'
import brandTypes from '../brandTypes'
import {
  kindOfPerson as kindOfPersonCatalog,
  PHYSICAL_PERSON,
  MAX_ROWS_PER_LOAD,
  searchOperatorOptions,
} from '../../config'
import { searchBrandsEnhanced } from '../../services'
import { useInfoModal } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
  brandSelectIcon: {
    fontSize: theme.typography.fontSize,
    marginRight: theme.typography.fontSize,
  },
  endAdornment: {
    marginRight: theme.spacing(1.5),
  },
  searchOperator: {
    '& > legend': {
      textAlign: 'left',
    },
  },
  gridList: {
    marginTop: theme.spacing(4),
  },
  tableHeader: {
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  tableCell: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  buttonLoadMore: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.common.white,
    },
    textTransform: 'none',
  },
  oddRow: {
    [theme.breakpoints.down('xs')]: {
      '& td': { backgroundColor: theme.palette.common.white },
    },
  },
  evenRow: {
    [theme.breakpoints.down('xs')]: {
      '& td': { backgroundColor: theme.palette.grey[100] },
    },
  },
  editIconButton: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
}))

const BrandsList = () => {
  const classes = useStyles()
  const theme = useTheme()

  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  const brandOptions = brandTypes.map((option) => {
    const { Icon } = mapIcon(option)
    return {
      value: option.id,
      label: option.name,
      icon: <Icon className={classes.brandSelectIcon} />,
    }
  })
  const kindOfPersonOptions = kindOfPersonCatalog.map((option) => {
    return {
      value: option.value,
      label: I18n.get(option.label),
    }
  })

  const { loading, setLoading } = useLayoutContext()
  const {
    brandSearchValues,
    setBrandSearchValues,
    defaultSearchValues,
  } = useTrademarkSearchContext()
  const { setBrand } = useTrademarkContext()
  const { enqueueSnackbar } = useSnackbar()
  const { register, handleSubmit, control, errors, watch, reset } = useForm({
    defaultValues: { ...brandSearchValues },
  })
  const applicantKind = watch('applicantKind')
  const searchOperatorWatch = watch('searchOperator')
  const currenFilters = watch()
  const [selectedBrandType, setSelectedBrandType] = useState(
    brandTypes.find((b) => b.id === brandSearchValues?.brandType)
  )
  const { openModal } = useInfoModal()
  const [data, setData] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [previousToken, setPreviousToken] = useState(null)
  const [nextToken, setNextToken] = useState(null)
  const [hasMoreRows, setHasMoreRows] = useState(null)

  const brandTypeAssigned = async (event) => {
    const { value } = event.target
    const selectBrandType = brandTypes.find((b) => b.id === value)
    setSelectedBrandType(selectBrandType)
  }

  const selectBrand = (dataIndex) => {
    const { id } = data[dataIndex]
    setBrand(null)
    navigate(`trademarks/${id}/detail`)
  }

  // eslint-disable-next-line no-unused-vars
  const renderActionButton = (dataIndex, rowIndex) => {
    return (
      <IconButton
        size="small"
        aria-label="Seleccionar marca"
        className={clsx(classes.editIconButton)}
        onClick={() => selectBrand(dataIndex)}>
        <EditIcon fontSize="inherit" />
      </IconButton>
    )
  }

  const setCellHeaderProps = () => {
    return {
      className: clsx(classes.tableHeader),
    }
  }

  const setCellProps = () => {
    return {
      className: clsx(classes.tableCell),
    }
  }

  const columns = [
    {
      name: 'brandName',
      label: 'Marca',
      options: {
        filter: true,
        filterType: 'textField',
        setCellHeaderProps,
        sort: true,
      },
    },
    {
      name: 'className',
      label: 'Clase',
      options: {
        filter: true,
        filterType: 'multiselect',
        setCellHeaderProps,
        sort: true,
      },
    },
    {
      name: 'typeName',
      label: 'Tipo',
      options: {
        filter: true,
        setCellHeaderProps,
        sort: true,
      },
    },
    {
      name: 'applicantName',
      label: 'Solicitante',
      options: {
        filter: true,
        setCellHeaderProps,
        sort: true,
      },
    },
    {
      name: 'actions',
      label: 'Acciones',
      options: {
        customBodyRenderLite: renderActionButton,
        empty: true,
        download: false,
        filter: false,
        print: false,
        searchable: false,
        setCellHeaderProps,
        setCellProps,
        sort: false,
        viewColumns: false,
      },
    },
  ]

  const options = {
    downloadOptions: {
      filename: 'Descarga-Búsqueda-de-Marcas.csv',
    },
    elevation: 1,
    enableNestedDataAccess: '.', // allows nested data separated by "." (see column names and the data structure above)
    filter: true,
    filterType: 'dropdown',
    fixedHeader: true,
    pagination: false,
    responsive: 'simple',
    searchPlaceholder: 'Buscar en los datos cargados',
    searchProps: {
      variant: 'outlined',
      size: 'small',
    },
    selectableRows: 'none',
    selectableRowsHeader: false,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        className: clsx({
          [classes.evenRow]: rowIndex % 2 === 0,
          [classes.oddRow]: rowIndex % 2 !== 0,
        }),
      }
    },
    // tableBodyHeight: '400px',
    textLabels: {
      body: {
        noMatch: 'Realizar una búsqueda para cargar información',
        toolTip: 'Ordenamiento',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV de los registros cargados',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar registros cargados',
      },
      filter: {
        all: 'Todos',
        title: 'Filtros',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar Columnas',
        titleAria: 'Mostrar/Ocultar Columnas',
      },
      selectedRows: {
        text: 'registro(s) seleccionados',
        delete: 'Eliminar',
        deleteAria: 'Eliminar registros seleccionados',
      },
    },
  }

  const cleanSearch = () => {
    setBrandSearchValues(null)
    reset({
      ...defaultSearchValues,
    })
    setData([])
    setPreviousToken(null)
    setNextToken(null)
    setHasMoreRows(false)
  }

  const searchBrandsByFilters = async (firstSearch) => {
    setLoading(true)
    setHasMoreRows(true)
    const {
      searchOperator,
      brandType,
      certificate,
      requestNumber,
      brandName,
      applicantName,
      applicantSurname,
      applicantSecondSurname,
    } = currenFilters
    const brands = await searchBrandsEnhanced({
      searchOperator,
      typeID: brandType,
      certificate,
      requestNumber,
      brandName,
      applicantName,
      applicantSurname,
      applicantSecondSurname,
      limit: MAX_ROWS_PER_LOAD,
      nextToken: firstSearch ? undefined : nextToken,
    })
    if (
      !brands ||
      brands?.error ||
      !brands?.items ||
      brands?.items?.length === 0
    ) {
      setHasMoreRows(false)
      if (firstSearch) {
        setData([])
        enqueueSnackbar('No existen resultados', {
          preventDuplicate: true,
          variant: 'warning',
        })
      } else {
        enqueueSnackbar('Ya no existen más resultados', {
          preventDuplicate: true,
          variant: 'warning',
        })
      }
      setLoading(false)
      return
    }

    if (brands?.total < MAX_ROWS_PER_LOAD) {
      setHasMoreRows(false)
    }
    const mappedData = brands?.items.map((b) => {
      const applicantNameMapped = [
        b.brandData?.applicant?.name,
        b.brandData?.applicant?.surname,
        b.brandData?.applicant?.secondSurname,
      ]
        .filter(Boolean)
        .join(' ')
        .trim()
      return {
        id: b.id,
        brandName: b.brandData?.name,
        className: b.class?.name,
        typeName: b.brandData?.type?.name,
        applicantName: applicantNameMapped,
      }
    })
    let newData = mappedData
    if (firstSearch) {
      setPreviousToken(null)
      setNextToken(brands?.nextToken)
    } else {
      setPreviousToken(nextToken)
      setNextToken(brands?.nextToken)
      newData = [...data, ...mappedData]
    }
    setData(newData)
    setLoading(false)
  }

  const openInformationModal = ({ id, title, text, images }) => {
    openModal({
      title: '',
      content: (
        <BrandTypeModal id={id} title={title} text={text} images={images} />
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  useEffect(() => {
    setBrand(null)
    if (brandSearchValues) {
      searchBrandsByFilters(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line no-unused-vars
  const onSubmit = async (dataFilters) => {
    setLoading(true)
    setBrandSearchValues({ ...dataFilters })
    await searchBrandsByFilters(true)
    setLoading(false)
  }

  const renderSearchOperator =
    (currenFilters?.brandName && currenFilters?.brandName !== '') ||
    (currenFilters?.applicantName && currenFilters?.applicantName !== '') ||
    (currenFilters?.applicantSurname &&
      currenFilters?.applicantSurname !== '') ||
    (currenFilters?.applicantSecondSurname &&
      currenFilters?.applicantSecondSurname !== '') ||
    (currenFilters?.certificate && currenFilters?.certificate !== '') ||
    (currenFilters?.requestNumber && currenFilters?.requestNumber !== '')

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>Marcas</Title>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                id="brandType"
                name="brandType"
                type="select"
                required={false}
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin)}
                label="Tipo de marca"
                labelClassName={clsx(classes.margin, classes.noLabel)}
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: 'Selecciona un tipo de marca',
                  },
                }}
                errors={errors}
                options={brandOptions}
                // eslint-disable-next-line no-unused-vars
                renderValue={(selected) => {
                  // const brandType = brandTypes.find((b) => b.id === selected)
                  const { Icon: BrandIcon } = mapIcon(selectedBrandType)
                  return (
                    <span>
                      <BrandIcon className={classes.brandSelectIcon} />
                      {selectedBrandType.name}
                    </span>
                  )
                }}
                onChange={brandTypeAssigned}
                IconComponent={() => {
                  return null
                }}
                endAdornment={
                  selectedBrandType ? (
                    <InputAdornment
                      position="end"
                      className={classes.endAdornment}>
                      <IconButton
                        aria-label="info"
                        size="small"
                        onClick={() => {
                          openInformationModal({
                            id: selectedBrandType.id,
                            title: selectedBrandType.name,
                            text: selectedBrandType.helperPopOverText,
                            images: selectedBrandType.helperImages,
                          })
                        }}>
                        <InfoIcon fontSize="inherit" />
                      </IconButton>
                    </InputAdornment>
                  ) : null
                }
              />
            </Grid>
            <Hidden xsDown>
              <Grid item sm={6} />
            </Hidden>
            <Grid item xs={12} sm={6}>
              <FormField
                id="certificate"
                name="certificate"
                label="Certificado"
                type="text"
                required={false}
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin, classes.noLabel)}
                inputRef={register({
                  required: {
                    value: false,
                    message: 'Ingresa el No. de Certificado',
                  },
                })}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                id="requestNumber"
                name="requestNumber"
                label="No. de Solicitud"
                type="text"
                required={false}
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin, classes.noLabel)}
                inputRef={register({
                  required: {
                    value: false,
                    message: 'Ingresa el No. de Solicitud',
                  },
                })}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormField
                id="brandName"
                name="brandName"
                label="Marca"
                type="text"
                required={false}
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin, classes.noLabel)}
                inputRef={register({
                  required: {
                    value: false,
                    message: 'Ingresa el nombre de la marca',
                  },
                })}
                errors={errors}
                multiline
                rows="2"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                id="applicantKind"
                name="applicantKind"
                type="select"
                required={false}
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin)}
                label="Tipo de persona"
                labelClassName={clsx(classes.margin, classes.noLabel)}
                control={control}
                rules={{
                  required: {
                    value: false,
                    message: 'Selecciona el tipo de persona',
                  },
                }}
                errors={errors}
                options={kindOfPersonOptions}
              />
            </Grid>
            <Hidden xsDown>
              <Grid item sm={6} />
            </Hidden>
            {applicantKind ? (
              <>
                <Grid item xs={12} md={12}>
                  <FormField
                    id="applicantName"
                    name="applicantName"
                    label={
                      applicantKind === PHYSICAL_PERSON
                        ? 'Nombre(s)'
                        : 'Razón social'
                    }
                    type="text"
                    required={false}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    inputRef={register({
                      required: {
                        value: false,
                        message:
                          applicantKind === PHYSICAL_PERSON
                            ? 'Ingresa el nombre'
                            : 'Ingresa la razón social',
                      },
                      minLength: {
                        value: 3,
                        message: 'Debe ser mayor o igual a 3 caracteres',
                      },
                    })}
                    errors={errors}
                  />
                </Grid>
                {applicantKind === PHYSICAL_PERSON ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        id="applicantSurname"
                        name="applicantSurname"
                        label="Primer apellido"
                        type="text"
                        required={false}
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        inputRef={register({
                          required: {
                            value: false,
                            message: 'Ingresa el primer apellido',
                          },
                          minLength: {
                            value: 2,
                            message: 'Debe ser mayor o igual a 2 caracteres',
                          },
                        })}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        id="applicantSecondSurname"
                        name="applicantSecondSurname"
                        label="Segundo apellido"
                        type="text"
                        required={false}
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        inputRef={register({
                          required: {
                            value: false,
                            message: 'Ingresa el segundo apellido',
                          },
                          minLength: {
                            value: 2,
                            message: 'Debe ser mayor o igual a 2 caracteres',
                          },
                        })}
                        errors={errors}
                      />
                    </Grid>
                  </>
                ) : null}
              </>
            ) : null}
            {renderSearchOperator && (
              <Grid item xs={12} sm={12}>
                <FormFieldMultipleOptions
                  id="searchOperator"
                  name="searchOperator"
                  label="Tipo de búsqueda (sólo para texto)"
                  type="radio"
                  required
                  variant="outlined"
                  size="small"
                  fullWidth
                  row={!isMobileDevice}
                  className={clsx(
                    classes.margin,
                    classes.noLabel,
                    classes.searchOperator
                  )}
                  options={searchOperatorOptions}
                  errors={errors}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Selecciona un tipo de búsqueda',
                    },
                  }}
                  value={searchOperatorWatch}
                />
              </Grid>
            )}
            <Grid item xs={2} sm={3} md={4} lg={4} />
            <Grid item xs={8} sm={3} md={2} lg={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => cleanSearch()}>
                Limpiar
              </Button>
            </Grid>
            <Hidden smUp>
              <Grid item xs={2} />
            </Hidden>
            <Hidden smUp>
              <Grid item xs={2} />
            </Hidden>
            <Grid item xs={8} sm={3} md={2} lg={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth>
                Buscar
              </Button>
            </Grid>
            <Grid item xs={2} sm={3} md={4} lg={4} />

            <Grid item xs={12} sm={12} className={classes.gridList}>
              <MUIDataTable
                title="Marcas"
                data={data}
                columns={columns}
                options={options}
              />
            </Grid>

            {data && data?.length > 0 && hasMoreRows && (
              <>
                <Grid item xs={2} sm={4} md={4} lg={5} />
                <Grid item xs={8} sm={4} md={4} lg={2}>
                  <LoaderButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.buttonLoadMore}
                    clickHandler={async () => {
                      await searchBrandsByFilters()
                    }}
                    loading={loading}
                    buttonText="Cargar más"
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4} lg={5} />
              </>
            )}
          </Grid>
        </Container>
      </div>
    </form>
  )
}

export default BrandsList
