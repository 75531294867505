import { API } from 'aws-amplify'
import Debug from 'debug'
import { OPENPAY_ERRORS, PAYMENT_METHOD_STORE } from '../../config'

const debug = Debug('services:payment:payment')

const API_NAME = 'trademarkPlatformOpenPayApi'
const PAYMENT_ENDPOINT_CARD = '/cardPayment'
const PAYMENT_ENDPOINT_DEPOSIT = '/bankPayment'
const PAYMENT_ENDPOINT_STORE = '/storePayment'
const PAYMENT_ENDPOINT_TX = '/paymentTransaction'

export const createCardPayment = async ({
  id,
  token,
  deviceId,
  isAuthenticated,
}) => {
  try {
    const payload = {
      body: {
        id,
        token,
        deviceId,
        isAuthenticated,
      },
      headers: {},
    }
    const paymentResponse = await API.post(
      API_NAME,
      PAYMENT_ENDPOINT_CARD,
      payload
    )
    return paymentResponse
  } catch (e) {
    debug('e', e)
    const error = { errorMessage: 'Error al enviar la solicitud de pago' }
    if (e?.request) {
      const requestResponse = JSON.parse(e.request.response)
      if (requestResponse?.errorCode) {
        error.errorMessage = OPENPAY_ERRORS[requestResponse.errorCode]
      }
    }
    return error
  }
}

export const createStoreOrDepositPayment = async ({
  id,
  isAuthenticated,
  paymentMethod,
}) => {
  try {
    const payload = {
      body: {
        id,
        isAuthenticated,
      },
      headers: {},
    }
    const paymentResponse = await API.post(
      API_NAME,
      paymentMethod === PAYMENT_METHOD_STORE
        ? PAYMENT_ENDPOINT_STORE
        : PAYMENT_ENDPOINT_DEPOSIT,
      payload
    )
    return paymentResponse
  } catch (e) {
    debug('e', e)
    const error = { errorMessage: 'Error al enviar la solicitud de pago' }
    if (e?.request) {
      const requestResponse = JSON.parse(e.request.response)
      if (requestResponse?.errorCode) {
        error.errorMessage = OPENPAY_ERRORS[requestResponse.errorCode]
      }
    }
    return error
  }
}

export const readPaymentTransactionStatus = async ({ id }) => {
  try {
    const payload = {
      headers: {},
    }
    const paymentTXResponse = await API.get(
      API_NAME,
      `${PAYMENT_ENDPOINT_TX}/${id}`,
      payload
    )
    return paymentTXResponse
  } catch (e) {
    debug('e', e)
    const error = {
      errorMessage: 'Error al recuperar el estatus de la transaccion',
    }
    return error
  }
}
