/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n, Logger } from '@aws-amplify/core'
import { ConfirmSignUp } from 'aws-amplify-react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import Auth from '@aws-amplify/auth'

import PropTypes from 'prop-types'

import { TextField, Link, Grid } from '@material-ui/core'

import { auth } from 'aws-amplify-react/lib/Amplify-UI/data-test-attributes'

import MainMaterialLayout from './MainMaterialLayout'

import { withMaterialStyles } from './MainMaterialStyles'

import LoaderButton from '../common/LoaderButton'

const logger = new Logger('ConfirmSignUp')
export class MaterialConfirmSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.validAuthStates = ['confirmSignUp']
    this.renderHeader = this.renderHeader.bind(this)
    this.renderBody = this.renderBody.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.confirm = this.confirm.bind(this)
    this.resendCode = this.resendCode.bind(this)
    this.resend = this.resend.bind(this)
  }

  // eslint-disable-next-line class-methods-use-this
  renderHeader() {
    return (
      <span className={this.props.classes.title}>
        {I18n.get('Confirm Sign Up')}
      </span>
    )
  }

  resend() {
    // eslint-disable-next-line no-underscore-dangle
    const _this = this
    const username = this.usernameFromAuthData() || this.inputs.username
    if (!Auth || typeof Auth.resendSignUp !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }
    Auth.resendSignUp(username)
      // eslint-disable-next-line no-unused-vars
      .then((err) => {
        _this.props.notificationTrigger('El código se ha enviado')
        return logger.debug('code resent')
      })
      .catch((err) => {
        return _this.error(err)
      })
  }

  resendCode() {
    this.resend()
  }

  confirm() {
    this.setState({ loading: true })
    const username = this.usernameFromAuthData() || this.inputs.username
    const { code } = this.inputs
    if (!Auth || typeof Auth.confirmSignUp !== 'function') {
      this.setState({ loading: false })
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }

    // eslint-disable-next-line no-unused-expressions
    this.props.firstSignUp
      ? Auth.confirmSignUp(username, code)
          .then(() => {
            this.props.notificationTrigger('La verificación fue exitosa!')
            this.changeState('signedUp')
          })
          .catch((err) => {
            this.setState({ loading: false })
            this.error(err)
          })
      : Auth.confirmSignUp(username, code)
          .then(() => {
            if (
              this.props.signUpAuthData &&
              Object.keys(this.props.signUpAuthData).length > 0 &&
              this.props.signUpAuthData.username &&
              this.props.signUpAuthData.password
            ) {
              Auth.signIn(
                this.props.signUpAuthData.username,
                this.props.signUpAuthData.password
              )
                .then((user) => {
                  this.setState({ loading: false })
                  return user
                })
                .catch((err) => {
                  this.setState({ loading: false })
                  this.error(err)
                })
            }
          })
          .catch((err) => {
            this.setState({ loading: false })
            this.error(err)
          })
  }

  // eslint-disable-next-line class-methods-use-this
  renderBody() {
    const { variant } = this.props
    const username = this.usernameFromAuthData()
    return (
      <>
        <Grid item className={this.props.classes.mediumSpacing} xs={12}>
          <TextField
            id="username"
            name="username"
            label={I18n.get('Username')}
            size="small"
            onChange={this.handleInputChange}
            type="text"
            disabled={username}
            value={username || ''}
            variant={variant}
            fullWidth
            required
          />
        </Grid>
        <Grid item className={this.props.classes.mediumSpacing} xs={12}>
          <TextField
            id="code"
            name="code"
            label={I18n.get('Code')}
            size="small"
            onChange={this.handleInputChange}
            data-test={auth.confirmSignUp.confirmationCodeInput}
            type="text"
            variant={variant}
            fullWidth
            required
          />
          <p className={this.props.classes.forgotPasswordText}>
            {I18n.get('Lost your code?')}{' '}
            <Link
              className={this.props.classes.subtextLink}
              onClick={this.resendCode}
              data-test={auth.confirmSignUp.subtextLink}>
              <span
                className={
                  this.state.loading ? this.props.classes.disabled : null
                }>
                {I18n.get('Resend Code')}
              </span>
            </Link>
          </p>
        </Grid>
      </>
    )
  }

  renderFooter() {
    return (
      <>
        <Grid item xs={12} sm={12} className={this.props.classes.largeSpacing}>
          <LoaderButton
            clickHandler={this.confirm}
            loading={this.state.loading}
            buttonText={I18n.get('Confirm')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <p className={this.props.classes.subtext}>
            <Link
              className={this.props.classes.subtextLink}
              onClick={() => this.changeState('signIn')}
              data-test={auth.confirmSignUp.backToSignInLink}>
              <span
                className={
                  this.state.loading ? this.props.classes.disabled : null
                }>
                {I18n.get('Return to Login')}
              </span>
            </Link>
          </p>
        </Grid>
      </>
    )
  }

  showComponent() {
    const { classes, hide } = this.props
    if (hide && hide.includes(MaterialConfirmSignUp)) {
      return null
    }
    return (
      <MainMaterialLayout
        Header={this.renderHeader}
        Body={this.renderBody}
        Footer={this.renderFooter}
        classes={classes}
      />
    )
  }
}

MaterialConfirmSignUp.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'contained']),
  firstSignUp: PropTypes.bool,
}

MaterialConfirmSignUp.defaultProps = {
  variant: 'outlined',
  firstSignUp: true,
}

export default withMaterialStyles(MaterialConfirmSignUp)
