import { API } from 'aws-amplify'
import Debug from 'debug'
import { feesByTypeAndStatus } from '../../graphql/queries'
import { FEE_STATUS_ENABLED } from '../../config'

const debug = Debug('services:payment:fees')

// https://github.com/aws-amplify/amplify-cli/issues/1576#issuecomment-635128542
// https://github.com/aws-amplify/amplify-cli/issues/1576#issuecomment-635128542
const PUBLIC_AUTH_MODE = 'AWS_IAM'
const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const feeByTypeAndStatus = async ({ type, authenticated }) => {
  try {
    const status = {
      eq: FEE_STATUS_ENABLED,
    }
    const { data } = await API.graphql({
      query: feesByTypeAndStatus,
      variables: {
        type,
        status,
      },
      authMode: authenticated ? PRIVATE_AUTH_MODE : PUBLIC_AUTH_MODE,
    })
    const fee =
      data?.feesByTypeAndStatus?.items?.length > 0
        ? data?.feesByTypeAndStatus?.items[0]
        : null
    return fee
  } catch (e) {
    debug('e', e)
    const error = { errorMessage: `Error al recuperar las tarifas [${type}]` }
    return error
  }
}
