/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import { ConfirmSignIn } from 'aws-amplify-react'
import PropTypes from 'prop-types'

import { TextField, Button, Link, Grid } from '@material-ui/core'

import MainMaterialLayout from './MainMaterialLayout'

import { withMaterialStyles } from './MainMaterialStyles'

export class MaterialConfirmSignIn extends ConfirmSignIn {
  constructor(props) {
    super(props)
    this.validAuthStates = ['confirmSignIn']
    this.renderHeader = this.renderHeader.bind(this)
    this.renderBody = this.renderBody.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
  }

  // eslint-disable-next-line class-methods-use-this
  renderHeader() {
    return (
      <span className={this.props.classes.title}>
        {I18n.get('Confirm Code')}
      </span>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  renderBody() {
    const { variant } = this.props
    return (
      <>
        <Grid item className={this.props.classes.mediumSpacing} xs={12}>
          <TextField
            id="mfaCode"
            name="code"
            label={I18n.get('Code')}
            type="text"
            size="small"
            variant={variant}
            fullWidth
            required
          />
        </Grid>
      </>
    )
  }

  renderFooter() {
    return (
      <>
        <Grid item xs={12} sm={12} className={this.props.classes.largeSpacing}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.submit()}
            fullWidth>
            {I18n.get('Confirm')}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <p>
            <Link
              className={this.props.classes.backToLoginLink}
              onClick={() => this.changeState('signIn')}>
              {I18n.get('Return to Login')}
            </Link>
          </p>
        </Grid>
      </>
    )
  }

  showComponent() {
    const { classes, hide } = this.props
    if (hide && hide.includes(MaterialConfirmSignIn)) {
      return null
    }
    return (
      <MainMaterialLayout
        Header={this.renderHeader}
        Body={this.renderBody}
        Footer={this.renderFooter}
        classes={classes}
      />
    )
  }
}

MaterialConfirmSignIn.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'contained']),
}

MaterialConfirmSignIn.defaultProps = {
  variant: 'outlined',
}

export default withMaterialStyles(MaterialConfirmSignIn)
