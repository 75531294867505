/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n, Logger } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'
import { SignIn, ForgotPassword, SignUp } from 'aws-amplify-react'

import PropTypes from 'prop-types'

import {
  TextField,
  Grid,
  Link,
  InputAdornment,
  IconButton,
  Hidden,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InfoIcon from '@material-ui/icons/Info'

import MainMaterialLayout from './MainMaterialLayout'

import { withMaterialStyles } from './MainMaterialStyles'

import LoaderButton from '../common/LoaderButton'

const logger = new Logger('MaterialSignUp')
export class MaterialSignIn extends SignIn {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showPassword: false,
      storedEmail: this.props.customAuthData?.username,
      emailInput: '',
    }
    this.validAuthStates = ['signIn', 'signedOut', 'signedUp']
    this.renderHeader = this.renderHeader.bind(this)
    this.renderBody = this.renderBody.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.login = this.login.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this)
  }

  initValueEvent() {
    const evt = {
      target: {
        name: 'username',
        value: this.state.storedEmail,
      },
    }
    if (this.state.storedEmail) this.handleInputChange(evt)
  }

  handleInputChange(evt) {
    if (evt.target.value && !this.state.storedEmail)
      if (evt.target.name === 'username') {
        this.setState({ emailInput: evt.target.value })
      }
    this.inputs = this.inputs || {}
    // eslint-disable-next-line no-underscore-dangle
    const _a = evt.target
    const { name } = _a
    const { value } = _a
    const { type } = _a
    const { checked } = _a
    // eslint-disable-next-line camelcase
    const check_type = ['radio', 'checkbox'].includes(type)
    // eslint-disable-next-line camelcase
    this.inputs[name] = check_type ? checked : value
    // eslint-disable-next-line camelcase
    this.inputs.checkedValue = check_type ? value : null
  }

  cancelAuth() {
    this.props.authToggle(false)
  }

  async login(event) {
    this.setState({ loading: true })
    try {
      await this.signIn(event)
      this.setState({ loading: false })
    } catch (err) {
      this.setState({ loading: false })
    }
  }

  async signIn(event) {
    // avoid submitting the form
    if (event) {
      event.preventDefault()
    }

    const username =
      this.getUsernameFromInput() ||
      this.state.emailInput ||
      this.state.storedEmail ||
      ''
    const { password } = this.inputs

    if (!Auth || typeof Auth.signIn !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }
    this.setState({ loading: true })
    try {
      const user = await Auth.signIn(username, password)
      logger.debug(user)
      if (
        user.challengeName === 'SMS_MFA' ||
        user.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        logger.debug(`confirm user with ${user.challengeName}`)
        this.changeState('confirmSignIn', user)
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        logger.debug('require new password', user.challengeParam)
        this.changeState('requireNewPassword', user)
      } else if (user.challengeName === 'MFA_SETUP') {
        logger.debug('TOTP setup', user.challengeParam)
        this.changeState('TOTPSetup', user)
      } else if (
        user.challengeName === 'CUSTOM_CHALLENGE' &&
        user.challengeParam &&
        user.challengeParam.trigger === 'true'
      ) {
        logger.debug('custom challenge', user.challengeParam)
        this.changeState('customConfirmSignIn', user)
      } else {
        this.checkContact(user)
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        this.props.setSignUpAuthData({
          username,
          password,
        })
        logger.debug('the user is not confirmed')
        this.changeState('confirmSignUp', { username })
      } else if (err.code === 'PasswordResetRequiredException') {
        logger.debug('the user requires a new password')
        this.changeState('forgotPassword', { username })
      } else {
        this.error(err)
      }
    } finally {
      this.setState({ loading: false })
    }
  }

  handleClickShowPassword = () => {
    const currentShowPassword = this.state.showPassword
    this.setState({ showPassword: !currentShowPassword })
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  // eslint-disable-next-line class-methods-use-this
  renderHeader() {
    return (
      <span className={this.props.classes.title}>{I18n.get('Sign In')}</span>
    )
  }

  renderBody() {
    this.initValueEvent()
    const {
      variant,
      hide = [],
      override = [],
      openPasswordInformation,
    } = this.props
    const hideForgotPassword =
      !override.includes('ForgotPassword') &&
      hide.some((component) => component === ForgotPassword)
    return (
      <form component="fieldset">
        <Grid item xs={12} className={this.props.classes.mediumSpacing}>
          <TextField
            id="username"
            name="username"
            label={I18n.get('Username')}
            size="small"
            onChange={this.handleInputChange}
            type="text"
            defaultValue={
              this.state.storedEmail && this.state.storedEmail !== ''
                ? this.state.storedEmail
                : null
            }
            disabled={this.state.storedEmail}
            variant={variant}
            autoFocus
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} className={this.props.classes.smallSpacing}>
          <TextField
            id="password"
            name="password"
            label={I18n.get('Password')}
            size="small"
            onChange={this.handleInputChange}
            type={this.state.showPassword ? 'text' : 'password'}
            variant={variant}
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}>
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => {
                      openPasswordInformation()
                    }}>
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {!hideForgotPassword && (
            <p className={this.props.classes.forgotPasswordText}>
              {I18n.get('Forgot your password? ')}{' '}
              <Link
                className={this.props.classes.resetPasswordLink}
                onClick={() => this.changeState('forgotPassword')}>
                <span
                  className={
                    this.state.loading ? this.props.classes.disabled : null
                  }>
                  {I18n.get('Reset password')}
                </span>
              </Link>
            </p>
          )}
        </Grid>
      </form>
    )
  }

  renderFooter() {
    const { showCancelButton, hide = [], override = [] } = this.props
    const hideSignUp =
      !override.includes('SignUp') &&
      hide.some((component) => component === SignUp)
    return (
      <>
        <Grid container spacing={2} className={this.props.classes.largeSpacing}>
          {showCancelButton && (
            <Grid item xs={12} sm={6}>
              <LoaderButton
                variant="contained"
                color="secondary"
                loading={this.state.loading}
                clickHandler={() => this.cancelAuth()}
                buttonText={I18n.get('Cancel Sign In')}
                fullWidth
              />
            </Grid>
          )}
          {!showCancelButton && (
            <Hidden xsDown>
              <Grid item sm={3} />
            </Hidden>
          )}
          <Grid item xs={12} sm={6}>
            <LoaderButton
              clickHandler={this.login}
              loading={this.state.loading}
              buttonText={I18n.get('Sign In')}
            />
          </Grid>
          {!showCancelButton && (
            <Hidden xsDown>
              <Grid item sm={3} />
            </Hidden>
          )}
        </Grid>
        {!hideSignUp && (
          <Grid item xs={12} sm={6}>
            <p className={this.props.classes.forgotPasswordText}>
              {I18n.get('No account? ')}{' '}
              <Link
                className={this.props.classes.resetPasswordLink}
                onClick={() => this.changeState('signUp')}>
                <span
                  className={
                    this.state.loading ? this.props.classes.disabled : null
                  }>
                  {I18n.get('Sign Up')}
                </span>
              </Link>
            </p>
          </Grid>
        )}
      </>
    )
  }

  showComponent() {
    const { hide, classes } = this.props
    if (hide && hide.includes(MaterialSignIn)) {
      return null
    }
    return (
      <MainMaterialLayout
        Header={this.renderHeader}
        Body={this.renderBody}
        Footer={this.renderFooter}
        classes={classes}
      />
    )
  }
}

MaterialSignIn.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'contained']),
  customAuthData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showCancelButton: PropTypes.bool,
}

MaterialSignIn.defaultProps = {
  variant: 'outlined',
  customAuthData: undefined,
  showCancelButton: true,
}

export default withMaterialStyles(MaterialSignIn)
