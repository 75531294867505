import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  arrowDownContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  'arrow-down': {
    animation: '$move-arrow-down 1s ease-out infinite',
    height: theme.spacing(1),
    opacity: 0,
    position: 'fixed',
    transform: 'scale(0.3)',
    zIndex: 9999,
    width: theme.spacing(5),
    '&:first-child': {
      animation: '$move-arrow-down 3s ease-out 1s infinite',
    },
    '&:nth-child(2)': {
      animation: '$move-arrow-down 3s ease-out 2s infinite',
    },
    '&::before,&::after': {
      background: theme.palette.primary.main,
      /* https://stackoverflow.com/questions/53768260/css-pseudo-selectors-with-material-ui/53772369#53772369 */
      content: '""',
      height: '100%',
      width: '50%',
      position: 'fixed',
    },
    '&::before': {
      left: 0,
      transform: 'skewY(30deg)',
    },
    '&::after': {
      right: 0,
      transform: 'skewY(-30deg)',
    },
  },
  '@keyframes move-arrow-down': {
    '0%': {
      opacity: 0,
      transform: 'translate(0, -20px)',
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
      transform: 'translate(0, 20px)',
    },
  },
}))

export const ArrowDownAnimated = () => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.arrowDownContainer)}>
      <div className={classes['arrow-down']} />
    </div>
  )
}

export default ArrowDownAnimated
