import React from 'react'
import { Button, Container, Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { FormField, Title, RichTextEditor, LoaderOverlay } from '../common'

/* eslint-disable-next-line no-unused-vars */
import {
  richTextEditorConfig,
  REACT_APP_NOREPLY_EMAIL_ADDRESS,
} from '../../config'
import { sendEmail } from '../../services'
import { useLayoutContext } from '../context'

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(2),
  },
  divider: {
    height: theme.spacing(3),
  },
  button: {
    width: '100%',
  },
}))

const BrandContactModal = (props) => {
  const { closeHandler, brand } = props
  const { handleSubmit, control, errors } = useForm({})
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { loading, setLoading } = useLayoutContext()

  const handleEmailSend = async (from, to, subject, message) => {
    try {
      await sendEmail(from, to, subject, message)
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al enviar el correo', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await handleEmailSend(
        REACT_APP_NOREPLY_EMAIL_ADDRESS,
        brand?.owner,
        data.subject,
        data.editor
      )
      enqueueSnackbar('¡El correo de contacto fue enviado de forma exitosa!', {
        preventDuplicate: true,
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al envíar un correo de contacto', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    closeHandler(false)
    setLoading(false)
  }

  const handleClose = () => {
    closeHandler(false)
  }

  const renderModalContent = () => {
    return (
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField
              id="from"
              name="from"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="De"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              inputProps={{ value: REACT_APP_NOREPLY_EMAIL_ADDRESS }}
              disabled
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <FormField
              id="subject"
              name="subject"
              type="text"
              required
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="Asunto"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Ingresa el asunto',
                },
              }}
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <FormField
              id="to"
              name="to"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="Para"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              inputProps={{ value: brand?.owner }}
              disabled
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <RichTextEditor
              initialConfig={
                richTextEditorConfig.trademarkPlatformConsoleConfig
              }
              control={control}
              name="editor"
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: 'Ingresa el cuerpo del mensaje',
                },
              }}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                className={clsx(classes.button)}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={clsx(classes.button)}>
                Envíar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Container className={clsx(classes.mainContent)}>
      <LoaderOverlay open={loading} />
      <Title>Contactar al Cliente</Title>
      <div className={clsx(classes.divider)} />
      {renderModalContent()}
    </Container>
  )
}

BrandContactModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}

export default BrandContactModal
