import { API } from 'aws-amplify'
import Debug from 'debug'

const debug = Debug('services:email:email')

const API_NAME = 'trademarkPlatformEmailService'
const SEND_EMAIL_ENDPOINT = '/email'
export const sendEmail = async (
  srcAddress,
  destinationAddress,
  subject,
  message
) => {
  try {
    const payload = {
      body: {
        srcAddress,
        destinationAddress,
        subject,
        message,
      },
      headers: {},
    }
    const sendEmailResponseResponse = await API.post(
      API_NAME,
      SEND_EMAIL_ENDPOINT,
      payload
    )
    return sendEmailResponseResponse
  } catch (e) {
    debug('e', e)
    const error = { errorMessage: 'Error al enviar el correo' }
    return error
  }
}
