import * as icons from '@material-ui/icons/'

export * from './country-dial-codes'
export { default as countries } from './country-dial-codes'
export * from './icons'
export * from './Forms'
export * from './text'
export { default as FileUpload } from './FileUpload'
export { default as PhoneField } from './PhoneField'
export { default as LoaderButton } from './LoaderButton'
export { default as LoaderOverlay } from './LoaderOverlay'
export { default as PopOverModal } from './PopOverModal'
export { default as TextLogo } from './TextLogo'
export { default as Copyright } from './Copyright'
export { default as SearchField } from './SearchField'
export { default as RichTextEditor } from './RichTextEditor'
export { default as ConfirmDialog } from './ConfirmDialog'

export const mapIcon = (item) => {
  if (!item.icon) return item
  const value = { ...item }
  value.Icon = icons[item.icon]
  return value
}
