import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    textTransform: 'uppercase',
  },
}))

const Title = ({ children }) => {
  const classes = useStyles()

  return (
    <Typography
      variant="h6"
      align="center"
      className={classes.title}
      gutterBottom>
      {children}
    </Typography>
  )
}

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

Title.defaultProps = {
  children: undefined,
}

export default Title
