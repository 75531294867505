import { API } from 'aws-amplify'
import { v4 as uuidV4 } from 'uuid'
import Debug from 'debug'
import {
  createTrademarkPreApplication,
  createTrademarkPreApplicationAnonymous,
  createTrademarkApplicationPs,
  createTrademarkApplicationAnonymousPs,
} from '../../graphql/mutations'

const debug = Debug('services:registration:registration')

// https://github.com/aws-amplify/amplify-cli/issues/1576#issuecomment-635128542
const PUBLIC_AUTH_MODE = 'AWS_IAM'
const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const postTrademarkPreApplication = async ({
  name,
  email,
  typeID,
  files,
  applicantName,
  applicantSurname,
  applicantSecondSurname,
  applicantKind,
  countryCallingCode,
  phoneNumber,
  trademarkApplicationStatus,
  trademarkApplicationStage,
  authenticated,
}) => {
  try {
    // const paymentTransactionID = authenticated ? '' : undefined
    const finalFiles = files?.length > 0 ? files : undefined
    const item = {
      name,
      email,
      typeID,
      files: finalFiles,
      applicantName,
      applicantSurname,
      applicantSecondSurname,
      applicantKind,
      countryCallingCode,
      phoneNumber,
      trademarkApplicationStatus,
      trademarkApplicationStage,
      paymentTransactionID: uuidV4(),
    }
    const result = await API.graphql({
      query: authenticated
        ? createTrademarkPreApplication
        : createTrademarkPreApplicationAnonymous,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: authenticated ? PRIVATE_AUTH_MODE : PUBLIC_AUTH_MODE,
    })
    const dataKey = authenticated
      ? 'createTrademarkPreApplication'
      : 'createTrademarkPreApplicationAnonymous'
    if (result.data) return result.data[dataKey]
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const postTrademarkApplicationProductsServices = async ({
  productServiceID,
  preApplicationID,
  owner,
  authenticated,
}) => {
  try {
    const preApplicationIDKey = authenticated
      ? 'trademarkPreApplicationID'
      : 'trademarkPreApplicationAnonymousID'
    const item = {
      productServiceID,
      [preApplicationIDKey]: preApplicationID,
      owner,
    }
    const result = await API.graphql({
      query: authenticated
        ? createTrademarkApplicationPs
        : createTrademarkApplicationAnonymousPs,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: authenticated ? PRIVATE_AUTH_MODE : PUBLIC_AUTH_MODE,
    })
    const dataKey = authenticated
      ? 'createTrademarkApplicationPs'
      : 'createTrademarkApplicationAnonymousPs'
    if (result.data) return result.data[dataKey]
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}
