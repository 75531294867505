import { API } from 'aws-amplify'
import Debug from 'debug'

const debug = Debug('services:payment:payment')

const API_NAME = 'trademarkPlatformLocations'
const COUNTRIES_ENDPOINT = '/countries'
const POSTAL_CODES_ENDPOINT = '/mxPostalCodes'

export const getCountries = async () => {
  try {
    const payload = {
      body: {},
      headers: {},
    }
    const paymentResponse = await API.get(API_NAME, COUNTRIES_ENDPOINT, payload)
    return paymentResponse
  } catch (e) {
    debug('e', e)
    const error = { errorMessage: 'Error al recuperar los países' }
    return error
  }
}

export const getDataFromPostalCode = async ({ postalCode }) => {
  try {
    const payload = {
      body: {},
      headers: {},
    }
    const paymentResponse = await API.get(
      API_NAME,
      `${POSTAL_CODES_ENDPOINT}/${postalCode}`,
      payload
    )
    return paymentResponse
  } catch (e) {
    debug('e', e)
    const error = { errorMessage: 'Error al recuperar los códigos postales' }
    return error
  }
}
