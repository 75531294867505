import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useNavigate, useParams } from '@reach/router'
import {
  Card,
  Button,
  Container,
  Grid,
  Typography,
  CardHeader,
  Chip,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  AppBar,
  Tab,
  Divider,
} from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import clsx from 'clsx'
import _ from 'lodash'
import { parse } from 'date-fns'
import { useSnackbar } from 'notistack'
import {
  trademarkDetailActions,
  TRADEMARK_LIFECYCLE_STAGES,
  APPLICANT_TYPE_APPLICANT,
  APPLICANT_TYPE_APPLICANT_BILLING,
  BRAND_STAGE_RECEIVED,
  BRAND_STAGE_IN_PROCESS,
  BRAND_STAGE_PRESENTED,
  BRAND_STAGE_PAID,
  PAYMENT_TX_STATUS_PAID,
  BRAND_STATUS_CANCELLED,
  BRAND_STATUS_DENIED,
  brandStatuses,
  BRAND_STAGE_FINALIZED,
  REACT_APP_NOREPLY_EMAIL_ADDRESS,
  actionEmailSubjects,
  actionEmailTemplates,
  notifications,
} from '../../config'
import { Title, LoaderOverlay, ConfirmDialog } from '../common'
import { useTrademarkContext, useLayoutContext } from '../context'
import {
  getBrandById,
  getCountries,
  putBrand,
  sendEmail,
  generateNotification,
} from '../../services'
import BrandActionModal from './BrandActionModal'
import BrandImpiModal from './BrandImpiModal'
import BrandContactModal from './BrandContactModal'
import BrandPaymentRequestModal from './BrandPaymentRequestModal'
import BrandFinalizeModal from './BrandFinalizeModal'
import BrandForm from './BrandForm'
import ApplicantDataForm from './ApplicantDataForm'
import PaymentRequestList from './PaymentRequestList'
import CancelBrandDialog from './CancelBrandDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
  },
  subtitle: {
    color: theme.palette.secondary.light,
    fontWeight: 500,
    fontSize: '0.9rem',
  },
  enabled: {
    display: 'block',
  },
  disabled: {
    display: 'none',
  },
  actionsContainer: {
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
  statusChipContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
  brandFieldsPanel: {
    padding: theme.spacing(0),
  },
  tab: {
    textTransform: 'none',
  },
  active: {
    color: '#3CBB44 !important',
  },
  actionButtons: {
    justifyContent: 'center',
  },
  brandStatusTitle: {
    textAlign: 'center',
  },
  created: {
    backgroundColor: '#4A90E4',
  },
  granted: {
    backgroundColor: '#3CBB44',
  },
  denied: {
    backgroundColor: '#D8522E',
  },
  cancelled: {
    backgroundColor: '#B9AEAB',
  },
  back: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  statusChip: {
    fontWeight: 'bold',
    color: '#FFF',
  },
  tabletButton: {
    width: '100%',
    height: theme.spacing(8),
  },
}))

const BrandDetail = () => {
  const [prompConfirmation, setPropmtConfirmation] = useState(false)
  const [cancelConfirmation, setCancelConfirmation] = useState(false)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams()
  const { brand, setBrand, brandFormIsDirty } = useTrademarkContext()
  const { loading, setLoading } = useLayoutContext()
  const [activeAction, setActiveAction] = useState(null)
  const [selectedTab, setSelectedTab] = React.useState('1')
  const [countryOptions, setCountryOptions] = React.useState(null)
  const [nationalityOptions, setNationalityOptions] = React.useState(null)
  const navigate = useNavigate()

  const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('xs'))
  const isTablet = useMediaQuery(useTheme().breakpoints.down('md'))
  const isNotCellphone = useMediaQuery(useTheme().breakpoints.up('sm'))

  const getBrandData = async (brandId) => {
    setLoading(true)
    try {
      const fetchedBrandData = await getBrandById(brandId)
      setBrand(null)
      if (fetchedBrandData?.brandData?.dateOfInitialUse) {
        fetchedBrandData.brandData.dateOfInitialUse = parse(
          fetchedBrandData?.brandData?.dateOfInitialUse,
          'yyyy-MM-ddxxx',
          new Date()
        )
      }
      if (fetchedBrandData?.registrationDateAbroad) {
        fetchedBrandData.registrationDateAbroad = parse(
          fetchedBrandData.registrationDateAbroad,
          'yyyy-MM-ddxxx',
          new Date()
        )
      }
      setBrand({ ...fetchedBrandData })
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al buscar los datos de la marca', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    setLoading(false)
  }

  /* eslint-disable-next-line consistent-return */
  const handleEmailSend = async (from, to, subject, message) => {
    try {
      const sendEmailResponse = await sendEmail(from, to, subject, message)
      return sendEmailResponse
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al enviar el correo', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
  }

  /* eslint-disable-next-line consistent-return */
  const sendNotification = async () => {
    try {
      const notification = notifications.registrationProcessInitiated(
        brand?.id,
        brand?.brandData?.name,
        brand?.class?.name
      )
      const notificationTitle = notification.title
      const notificationBody = notification.body
      // eslint-disable-next-line no-unused-vars
      const sendNotificationResponse = await generateNotification(
        brand?.id,
        notificationTitle,
        notificationBody,
        brand?.brandData?.owner
      )
    } catch (error) {
      return error
    }
  }

  /* eslint-disable-next-line consistent-return */
  const initiateBrandRegistrationProcess = async () => {
    setLoading(true)
    try {
      await putBrand({
        id: brand?.id,
        brandStage: BRAND_STAGE_IN_PROCESS,
        _version: brand?._version,
      })
      await getBrandData(brand?.id)
      enqueueSnackbar('¡El registro de la marca se inició de forma exitosa!', {
        preventDuplicate: true,
        variant: 'success',
      })

      try {
        // eslint-disable-next-line no-unused-vars
        const emailSendResponse = await handleEmailSend(
          REACT_APP_NOREPLY_EMAIL_ADDRESS,
          brand?.owner,
          actionEmailSubjects.registrationProcessStarted,
          actionEmailTemplates.registrationProcessStarted(
            brand?.id,
            brand?.brandData?.name,
            brand?.class?.name
          )
        )
        enqueueSnackbar(
          '¡El correo de notificación de inicio de registro de marca se envió de forma exitosa!',
          {
            preventDuplicate: true,
            variant: 'success',
          }
        )

        try {
          await sendNotification()
        } catch (error) {
          enqueueSnackbar('Ocurrió un error al crear la notificación', {
            preventDuplicate: true,
            variant: 'error',
          })
        }
      } catch (error) {
        enqueueSnackbar('Ocurrió un error al enviar el correo', {
          preventDuplicate: true,
          variant: 'error',
        })
      }
    } catch (error) {
      enqueueSnackbar(
        'Ocurrió un error al iniciar el proceso de registro de tu marca',
        {
          preventDuplicate: true,
          variant: 'error',
        }
      )
      return error
    }
    setLoading(false)
  }

  /* eslint-disable-next-line no-unused-vars */
  const handleConfirmation = () => {
    setPropmtConfirmation(true)
  }

  const handleReturn = () => {
    navigate('/trademarks')
  }

  const handleCancelBrand = () => {
    // setPropmtConfirmation(false)
    setCancelConfirmation(true)
  }

  /* eslint-disable-next-line consistent-return */
  const cancelBrand = async () => {
    setLoading(true)
    try {
      const cancelBrandResponse = await putBrand({
        id: brand?.id,
        brandStage: BRAND_STAGE_RECEIVED,
        brandStatus: BRAND_STATUS_CANCELLED,
        _version: brand?._version,
      })
      enqueueSnackbar('La marca ha sido cancelada', {
        preventDuplicate: true,
        variant: 'success',
      })
      await getBrandData(brand?.id)
      setLoading(false)
      return cancelBrandResponse
    } catch (error) {
      setLoading(false)
      enqueueSnackbar('Ocurrió un error al cancelar la marca', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
  }

  /* eslint-disable-next-line consistent-return */
  const handleBrandDenied = async () => {
    setLoading(true)
    try {
      const cancelBrandResponse = await putBrand({
        id: brand?.id,
        brandStage: BRAND_STAGE_FINALIZED,
        brandStatus: BRAND_STATUS_DENIED,
        _version: brand?._version,
      })
      enqueueSnackbar('La marca ha sido registrada como rechazada', {
        preventDuplicate: true,
        variant: 'success',
      })
      await getBrandData(brand?.id)
      setLoading(false)
      return cancelBrandResponse
    } catch (error) {
      setLoading(false)
      enqueueSnackbar('Ocurrió un error al registrar la marca como rechazada', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
  }

  /* eslint-disable-next-line consistent-return */
  const evaluateBrandStage = (currentBrandStage, paymentStatus) => {
    if (paymentStatus !== PAYMENT_TX_STATUS_PAID) {
      return BRAND_STAGE_RECEIVED
    }
    if (paymentStatus === PAYMENT_TX_STATUS_PAID) {
      return currentBrandStage
    }
  }

  useEffect(() => {
    getBrandData(params.trademarkId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchCountries = useMemo(
    () =>
      _.throttle(async (request, callback) => {
        const data = await getCountries()
        const sortedData = _.sortBy(data, (item) => {
          return item.name
        })
        callback(sortedData)
      }, 1000),
    []
  )

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    fetchCountries({}, (countries) => {
      const cOptions = countries?.map((c) => {
        return {
          value: c?.code,
          label: c?.name,
        }
      })
      const nOptions = _.chain(countries)
        .sortBy((item) => item.nationality)
        .map((c) => {
          return {
            value: c?.code,
            label: c?.nationality,
          }
        })
        .value()
      setCountryOptions(cOptions)
      setNationalityOptions(nOptions)
    })
  }, [fetchCountries])

  const handleSelectedTab = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const actionButtons = [
    {
      button: (
        <Button
          variant="contained"
          color="primary"
          className={clsx(
            isTablet && isNotCellphone ? classes.tabletButton : null
          )}
          disabled={brandFormIsDirty}
          onClick={() => initiateBrandRegistrationProcess()}
          fullWidth>
          Iniciar Registro
        </Button>
      ),
      enabled:
        evaluateBrandStage(
          brand?.brandStage,
          brand?.trademarkApplication?.paymentTransaction?.status
        ) === BRAND_STAGE_PAID,
    },
    {
      button: (
        <Button
          variant="contained"
          color="primary"
          className={clsx(
            isTablet && isNotCellphone ? classes.tabletButton : null
          )}
          disabled={brandFormIsDirty}
          onClick={() =>
            setActiveAction(trademarkDetailActions.initializeImpiProcess.value)
          }
          fullWidth>
          {trademarkDetailActions.initializeImpiProcess.label}
        </Button>
      ),
      enabled:
        evaluateBrandStage(
          brand?.brandStage,
          brand?.trademarkApplication?.paymentTransaction?.status
        ) === BRAND_STAGE_IN_PROCESS,
    },
    {
      button: (
        <Button
          variant="contained"
          color="primary"
          className={clsx(
            isTablet && isNotCellphone ? classes.tabletButton : null
          )}
          disabled={brandFormIsDirty}
          onClick={() => setActiveAction(trademarkDetailActions.contact.value)}
          fullWidth>
          {trademarkDetailActions.contact.label}
        </Button>
      ),
      enabled: true,
    },
    {
      button: (
        <Button
          variant="contained"
          color="primary"
          className={clsx(
            isTablet && isNotCellphone ? classes.tabletButton : null
          )}
          disabled={brandFormIsDirty}
          onClick={() =>
            setActiveAction(trademarkDetailActions.generatePaymentRequest.value)
          }
          fullWidth>
          {trademarkDetailActions.generatePaymentRequest.label}
        </Button>
      ),
      enabled: true,
    },
    {
      button: (
        <Button
          variant="contained"
          color="primary"
          className={clsx(
            isTablet && isNotCellphone ? classes.tabletButton : null
          )}
          disabled={brandFormIsDirty}
          onClick={() =>
            setActiveAction(trademarkDetailActions.finalizeProcess.value)
          }
          fullWidth>
          {trademarkDetailActions.finalizeProcess.label}
        </Button>
      ),
      enabled:
        evaluateBrandStage(
          brand?.brandStage,
          brand?.trademarkApplication?.paymentTransaction?.status
        ) === BRAND_STAGE_PRESENTED,
    },
    {
      button: (
        <Button
          variant="contained"
          color="secondary"
          className={clsx(
            isTablet && isNotCellphone ? classes.tabletButton : null
          )}
          disabled={brandFormIsDirty}
          onClick={() => handleBrandDenied()}
          fullWidth>
          {trademarkDetailActions.brandDenied.label}
        </Button>
      ),
      enabled:
        evaluateBrandStage(
          brand?.brandStage,
          brand?.trademarkApplication?.paymentTransaction?.status
        ) === BRAND_STAGE_PRESENTED,
    },
  ]

  const renderStepper = () => {
    const currentStage = evaluateBrandStage(
      brand?.brandStage,
      brand?.trademarkApplication?.paymentTransaction?.status
    )
    return (
      <Stepper
        activeStep={TRADEMARK_LIFECYCLE_STAGES.findIndex((stage) => {
          return stage.stage === currentStage
        })}
        alternativeLabel>
        {TRADEMARK_LIFECYCLE_STAGES.map((stage) => (
          <Step key={stage.stage}>
            <StepLabel
              StepIconProps={{
                classes: { completed: classes.active },
              }}>
              <span classes={clsx(classes.mobileStepLabel)}>
                {stage.stage === currentStage ? stage.label : ''}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }

  const renderActionsBox = () => {
    return (
      <Card className={clsx(classes.actionsContainer)}>
        <CardHeader subheader="Acciones" />
        <Grid container spacing={2} className={clsx(classes.actionButtons)}>
          <Grid item xs={0} md={3} lg={3} />
          <Grid item xs={12} md={12} lg={12}>
            <Typography className={clsx(classes.brandStatusTitle)}>
              Estatus de la marca:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            className={clsx(isMobileDevice ? classes.statusChipContainer : '')}>
            <Chip
              className={clsx(classes[brand?.brandStatus], classes.statusChip)}
              label={brandStatuses[brand?.brandStatus]?.label}
            />
          </Grid>
          <Grid item xs={0} md={3} lg={3} />
        </Grid>
        {renderStepper()}
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            md={12}
            lg={12}
            spacing={1}
            className={clsx(classes.actionButtons)}>
            {brand?.brandStatus !== BRAND_STATUS_CANCELLED ? (
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={clsx(
                    isTablet && isNotCellphone ? classes.tabletButton : null
                  )}
                  disabled={brandFormIsDirty}
                  onClick={() => handleCancelBrand()}
                  fullWidth>
                  Cancelar Marca
                </Button>
              </Grid>
            ) : null}
            {actionButtons.map((button, index) => {
              return button.enabled ? (
                /* eslint-disable-next-line react/no-array-index-key */
                <Grid key={index} item xs={12} sm={3} md={3} lg={3}>
                  {button.button}
                </Grid>
              ) : null
            })}
          </Grid>
        </Grid>
      </Card>
    )
  }

  /* eslint-disable-next-line consistent-return */
  const renderActionModal = (action, closeHandler) => {
    switch (action) {
      case trademarkDetailActions.initializeImpiProcess.value:
        return (
          <BrandActionModal
            title="Registro de Marca ante el IMPI"
            content={<BrandImpiModal />}
            cancelButtonText="Cancelar"
            saveButtonText="Guardar"
            enabled
            closeHandler={closeHandler}
            notificationsEnabled
            brand={brand}
            updateBrand={getBrandData}
          />
        )
      case trademarkDetailActions.contact.value:
        return (
          <BrandActionModal
            title="Contactar al Cliente"
            content={<BrandContactModal />}
            cancelButtonText="Cancelar"
            saveButtonText="Envíar"
            enabled
            closeHandler={closeHandler}
            notificationsEnabled={false}
            brand={brand}
            updateBrand={getBrandData}
          />
        )
      case trademarkDetailActions.generatePaymentRequest.value:
        return (
          <BrandActionModal
            title="Generar Orden de Pago"
            content={<BrandPaymentRequestModal />}
            cancelButtonText="Cancelar"
            saveButtonText="Generar"
            enabled
            closeHandler={closeHandler}
            notificationsEnabled
            brand={brand}
            updateBrand={getBrandData}
          />
        )
      case trademarkDetailActions.finalizeProcess.value:
        return (
          <BrandActionModal
            title="Concluir Solicitud de Registro de Marca"
            content={<BrandFinalizeModal />}
            cancelButtonText="Cancelar"
            saveButtonText="Finalizar"
            enabled
            closeHandler={closeHandler}
            notificationsEnabled
            brand={brand}
            updateBrand={getBrandData}
          />
        )
      default:
        return null
    }
  }

  const render = brand
  if (!render) return null

  return (
    <>
      <div className={classes.root}>
        <Container>
          <LoaderOverlay open={loading} />
          <ConfirmDialog
            title="Cancelación de Marca"
            content="¿Estás seguro que deseas cancelar la marca?"
            enabled={prompConfirmation}
            onAccept={handleCancelBrand}
            closeHandler={setPropmtConfirmation}
          />
          <CancelBrandDialog
            enabled={cancelConfirmation}
            onAccept={cancelBrand}
            closeHandler={setCancelConfirmation}
            brand={brand}
          />
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                className={clsx(classes.back)}
                onClick={() => handleReturn()}>
                <ArrowBackIosIcon />
              </Grid>
              <Grid
                item
                className={clsx(classes.back)}
                onClick={() => handleReturn()}>
                <Typography>Regresar</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>Detalle de la marca</Title>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="center"
                className={classes.title}
                gutterBottom>
                <ClassOutlinedIcon fontSize="small" /> {brand.brandData.name}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                className={classes.subtitle}
                gutterBottom>
                [{brand.class.name}]
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              {renderActionsBox()}
            </Grid>
            <Grid item xs={12} sm={12}>
              <PaymentRequestList
                paymentRequests={brand?.paymentRequests?.items}
              />
            </Grid>
            <Grid item xs={12}>
              <TabContext value={selectedTab}>
                <AppBar position="static" color="default" variant="outlined">
                  <TabList
                    value={selectedTab}
                    onChange={handleSelectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={isMobileDevice ? 'fullWidth' : 'standard'}
                    centered
                    aria-label="scrollable auto tabs classes">
                    <Tab
                      key="1"
                      icon={<ClassOutlinedIcon fontSize="small" />}
                      label="Marca"
                      value="1"
                      disabled={brandFormIsDirty && selectedTab !== '1'}
                      className={clsx(classes.tab)}
                    />
                    <Tab
                      key="2"
                      icon={<ContactMailIcon fontSize="small" />}
                      label="Cliente"
                      value="2"
                      disabled={brandFormIsDirty && selectedTab !== '2'}
                      className={clsx(classes.tab)}
                    />
                    <Tab
                      key="3"
                      icon={<ReceiptIcon fontSize="small" />}
                      label="Facturación"
                      value="3"
                      disabled={brandFormIsDirty && selectedTab !== '3'}
                      className={clsx(classes.tab)}
                    />
                  </TabList>
                </AppBar>
                <TabPanel
                  key="1"
                  value="1"
                  className={classes.brandFieldsPanel}>
                  <BrandForm countries={countryOptions} />
                </TabPanel>
                <TabPanel
                  key="2"
                  value="2"
                  className={classes.brandFieldsPanel}>
                  <ApplicantDataForm
                    applicantType={APPLICANT_TYPE_APPLICANT}
                    countries={countryOptions}
                    nationalities={nationalityOptions}
                  />
                </TabPanel>
                <TabPanel
                  key="3"
                  value="3"
                  className={classes.brandFieldsPanel}>
                  <ApplicantDataForm
                    applicantType={APPLICANT_TYPE_APPLICANT_BILLING}
                    countries={countryOptions}
                    nationalities={nationalityOptions}
                  />
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>
        </Container>
      </div>
      {renderActionModal(activeAction, setActiveAction)}
    </>
  )
}

export default BrandDetail
