import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Checkbox,
  Chip,
  Paper,
  ListSubheader,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import clsx from 'clsx'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import _ from 'lodash'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import { Controller } from 'react-hook-form'
import { useSearchFieldContext } from '../context'
// eslint-disable-next-line import/no-named-as-default
import ArrowDownAnimated from './icons/arrowDownAnimated'

const componentName = 'SearchField'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  input: {
    [theme.breakpoints.down('sm')]: {
      // minHeight: theme.spacing(7),
    },
  },
  inputLabel: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(8),
    },
    // https://www.sipios.com/blog-tech/how-to-use-styled-components-with-material-ui-in-a-react-app
    // '&.MuiInputLabel-shrink': {
    '&[data-shrink="true"]': {
      marginRight: theme.spacing(0),
    },
  },
  inputLabelInformationIcon: {
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(9),
    },
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    minHeight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      minHeight: theme.spacing(7),
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
  /* Styles applied to the `listbox` component. */
  listbox: {
    width: '100%',
    listStyle: 'none',
    margin: 0,
    padding: '8px 0',
    maxHeight: '40vh',
    overflow: 'auto',
    justifyContent: 'center',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'auto',
    },
  },
  /* Styles applied to the chips group's label elements. */
  chipsGroupLabel: {
    backgroundColor: theme.palette.background.paper,
    top: -8,
  },
  /* Styles applied to the chips group's ul elements. */
  chipsGroupUl: {
    padding: 0,
    '&': {
      paddingLeft: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(6),
      },
    },
  },
  /* Styles applied to the tag elements, e.g. the chips. */
  tag: {
    margin: 3,
    maxWidth: 'calc(100% - 6px)',
  },
  /* Styles applied to the tag elements, e.g. the chips if `size="small"`. */
  tagSizeSmall: {
    margin: 2,
    maxWidth: 'calc(100% - 4px)',
  },
  error: {
    color: theme.palette.error.main,
  },
  arrowContainer: {
    alignItems: 'flex-start',
    top: 20,
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    position: 'fixed',
    width: '100%',
    zIndex: '9999',
  },
}))

const MyListBoxComponent = React.forwardRef(function ListboxComponent({
  ...props
}) {
  const { children, ...other } = props
  const classes = useStyles()
  return (
    <div {...other} className={clsx(classes.listbox)}>
      <div className={clsx(classes.arrowContainer)}>
        <ArrowDownAnimated />
      </div>
      {children}
    </div>
  )
})

const SearchField = (props) => {
  const {
    id,
    required,
    disabled,
    variant,
    className,
    fullWidth,
    label,
    name,
    size,
    noOptionsText,
    Icon,
    InputIcon,
    InfomationIcon,
    renderOption,
    renderTags,
    disableRenderTags,
    ChipProps,
    renderGroup,
    renderChipsPreviewGroup,
    renderChipOption,
    ListChipsPreviewComponent,
    ListChipsPreviewProps,
    getOptionLabel,
    getOptionSelected,
    filterOptions,
    groupBy,
    fetch,
    options,
    freeSolo,
    control,
    rules,
    errors,
    multiple,
    disableCloseOnSelect,
    autoComplete,
    includeInputInList,
    filterSelectedOptions,
    clearText,
    ctxDefined,
  } = props
  if (!ctxDefined) {
    throw new Error(
      `Context [SearchFieldContext] cannot be used outside the Provider, you need to wrap this component inside SearchFieldContextWrapper or wrap parent inside withSearchFieldContext`
    )
  }
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  // const [value, setValue] = useState(multiple ? [] : null)
  // const [inputValue, setInputValue] = useState('')
  // React Context Rocks!!!
  const { value, setValue, inputValue, setInputValue } = useSearchFieldContext()

  const [currentOptions, setCurrentOptions] = useState(options)

  const icon = <CheckBoxOutlineBlankIcon fontSize={size} />
  const checkedIcon = <CheckBoxIcon fontSize={size} />

  useEffect(() => {
    let active = true
    if (fetch) {
      setLoading(true)
      if (inputValue === '') {
        const optionsForMultiple = multiple ? [] : [value]
        setCurrentOptions(value ? optionsForMultiple : [])
        setLoading(false)
        return undefined
      }

      fetch({ name: inputValue }, (results) => {
        if (active) {
          let newOptions = []

          if (value) {
            const valueForFilterSelectedOptions = !filterSelectedOptions
              ? []
              : [value]
            newOptions = multiple ? [] : valueForFilterSelectedOptions
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setCurrentOptions(newOptions)
        }
        setLoading(false)
      })
    }
    return () => {
      active = false
    }
  }, [value, inputValue, fetch, multiple, filterSelectedOptions])

  const defaultRenderOption = (option, { searchValue, selected }) => {
    // https://github.com/moroshko/autosuggest-highlight
    const matches = match(option.name, searchValue)
    const parts = parse(option.name, matches)

    return (
      <Grid container alignItems="center">
        <>
          {!filterSelectedOptions ? (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          ) : null}
        </>
        <Grid item>
          <Icon className={classes.icon} />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={`'option_'${index}`}
              style={{ fontWeight: part.highlight ? 700 : 400 }}>
              {part.text}
            </span>
          ))}
          <Typography variant="body2" color="textSecondary">
            {option.label}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const defaultGetOptionLabel = (option) =>
    typeof option === 'string' ? option : option.label

  const defaultGetOptionSelected = (option, selectedValue) => {
    return isEqual(option, selectedValue)
  }

  const defaultFilterOptions = (x) => {
    if (!filterSelectedOptions) return x
    if (fetch) {
      let filteredOptions = []
      const valueForSingle = value ? [value] : []
      filteredOptions = differenceWith(
        x,
        multiple ? value : valueForSingle,
        isEqual
      )
      return filteredOptions
    }
    return x
  }

  const defaultRenderTags = (selectedValue, getTagProps) => {
    return selectedValue.map((option, index) => {
      return (
        <Chip
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          variant={variant}
          label={option.label}
          {...getTagProps({ index })}
        />
      )
    })
  }

  const getGroupedOptions = () => {
    let groupedOptions = value
    if (groupBy) {
      // used to keep track of key and indexes in the result array
      const indexBy = new Map()
      let warn = false

      const sortedOptions = value.sort((a, b) => {
        const groupA = groupBy(a)
        const groupB = groupBy(b)
        if (groupA > groupB) return 1
        return -1
      })
      groupedOptions = sortedOptions.reduce((acc, option, index) => {
        const group = groupBy(option)

        if (acc.length > 0 && acc[acc.length - 1].group === group) {
          acc[acc.length - 1].options.push(option)
        } else {
          if (process.env.NODE_ENV !== 'production') {
            if (indexBy.get(group) && !warn) {
              // eslint-disable-next-line no-console
              console.warn(
                `ppm-trademark-registration: The options provided combined with the \`groupBy\` method of ${componentName} returns duplicated headers.`,
                'You can solve the issue by sorting the options with the output of `groupBy`.'
              )
              warn = true
            }
            indexBy.set(group, true)
          }

          acc.push({
            key: index,
            index,
            group,
            options: [option],
          })
        }

        return acc
      }, [])
    }
    return groupedOptions
  }

  const handleTagDelete = ({ deleteByGroup, index, onChange }) => {
    let newValue
    if (deleteByGroup) {
      newValue = value.filter((option) => groupBy(option) !== deleteByGroup)
      setValue(newValue)
    } else {
      newValue = value.slice()
      newValue.splice(index, 1)
      setValue(newValue)
    }
    if (onChange) {
      // onChange(newValue.length > 0 ? newValue : undefined)
      onChange(newValue)
    }
  }

  const getTagProps = ({ deleteByGroup, index, onChange }) => ({
    key: index,
    onDelete: () => handleTagDelete({ deleteByGroup, index, onChange }),
  })

  const getCustomizedTagProps = ({ deleteByGroup, index, onChange }) => ({
    className: clsx(classes.tag, {
      [classes.tagSizeSmall]: size === 'small',
    }),
    disabled,
    ...getTagProps({ deleteByGroup, index, onChange }),
  })

  const defaultRenderChipsPreviewGroup = (
    { key, group, children },
    groupProps
  ) => {
    return (
      <>
        <li key={key}>
          <ListSubheader className={classes.chipsGroupLabel} component="div">
            <Chip
              label={group}
              key={key}
              variant={variant}
              size={size}
              {...groupProps}
              {...ChipProps}
            />
          </ListSubheader>
          <ul className={classes.chipsGroupUl}>{children}</ul>
        </li>
      </>
    )
  }

  defaultRenderChipsPreviewGroup.propTypes = {
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    group: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }

  const renderChipsPreviewGroupFunc =
    renderChipsPreviewGroup || defaultRenderChipsPreviewGroup

  const renderChipsPreviewGroupList = ({ key, group, children, onChange }) => {
    const groupProps = getCustomizedTagProps({
      deleteByGroup: group,
      onChange,
    })
    return renderChipsPreviewGroupFunc({ key, group, children }, groupProps)
  }

  const getOptionLabelFunc = getOptionLabel || defaultGetOptionLabel

  const defaultRenderChipOption = (option, optionProps) => {
    return (
      <Chip
        label={getOptionLabelFunc(option)}
        variant={variant}
        size={size}
        {...optionProps}
        {...ChipProps}
      />
    )
  }

  const renderChipOptionFunc = renderChipOption || defaultRenderChipOption

  const renderChipOptionList = (option, index, onChange) => {
    const optionProps = getCustomizedTagProps({
      index,
      onChange,
    })
    return renderChipOptionFunc(option, optionProps)
  }

  const renderChipsPreviewArea = (onChange) => {
    if (multiple) {
      if (value.length > 0) {
        const groupedOptions = getGroupedOptions()
        const chipsPreviewItems = groupedOptions.map((option, index) => {
          if (groupBy) {
            return renderChipsPreviewGroupList({
              key: option.key,
              group: option.group,
              children: option.options.map((innerOption, innerIndex) => {
                return renderChipOptionList(
                  innerOption,
                  option.index + innerIndex,
                  onChange
                )
              }),
              onChange,
            })
          }
          return renderChipOptionList(option, index, onChange)
        })
        return (
          <Paper variant={variant} className={classes.paper}>
            <ListChipsPreviewComponent
              className={clsx(classes.listbox)}
              {...ListChipsPreviewProps}>
              {chipsPreviewItems}
            </ListChipsPreviewComponent>
          </Paper>
        )
      }
      return <Paper variant={variant} className={classes.paper} />
    }
    return null
  }

  const getOptionSelectedFunc = getOptionSelected || defaultGetOptionSelected
  const filterOptionsFunc = filterOptions || defaultFilterOptions
  const renderOptionFunc = renderOption || defaultRenderOption
  const renderTagsFunc = renderTags || defaultRenderTags

  const error = _.get(errors, name)
  return (
    <>
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({ onChange, value: controlValue }) => {
          setValue(controlValue)
          return (
            <>
              <Autocomplete
                id={id}
                getOptionLabel={getOptionLabelFunc}
                getOptionSelected={getOptionSelectedFunc}
                groupBy={groupBy}
                filterOptions={filterOptionsFunc}
                options={currentOptions}
                size={size}
                multiple={multiple}
                ListboxComponent={MyListBoxComponent}
                disableCloseOnSelect={disableCloseOnSelect}
                disableClearable={disableRenderTags}
                autoComplete={autoComplete}
                includeInputInList={includeInputInList}
                filterSelectedOptions={filterSelectedOptions}
                clearText={clearText}
                // value={value}
                value={controlValue}
                noOptionsText={noOptionsText}
                freeSolo={freeSolo}
                onChange={(event, newValue) => {
                  /*
                  if (fetch) {
                    const valueForFilterSelectedOptions = !filterSelectedOptions
                      ? [...currentOptions]
                      : [newValue, ...currentOptions]
                    const valueForMultiple = multiple
                      ? newValue.concat(...currentOptions)
                      : valueForFilterSelectedOptions
                    setCurrentOptions(
                      newValue ? valueForMultiple : currentOptions
                    )
                  }
                  */
                  setValue(newValue)
                  onChange(newValue)
                }}
                onInputChange={(event, newInputValue, reason) => {
                  if (
                    reason === 'input' ||
                    (!disableCloseOnSelect && reason !== 'reset') ||
                    (!multiple && reason === 'reset' && newInputValue !== '')
                  ) {
                    setInputValue(newInputValue)
                  }
                }}
                onClose={() => {
                  if (fetch && multiple) {
                    setInputValue('')
                    setCurrentOptions([])
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={required}
                    disabled={disabled}
                    label={label}
                    fullWidth={fullWidth}
                    variant={variant}
                    size={size}
                    name={`${name}'Input'`}
                    className={clsx(className, classes.input)}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      className: clsx(
                        className,
                        classes.inputLabel,
                        InfomationIcon && classes.inputLabelInformationIcon
                      ),
                      error: !!error,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress
                              color="inherit"
                              size={18}
                              fontSize={size}
                            />
                          ) : (
                            <InputIcon
                              color="inherit"
                              size={15}
                              fontSize={size}
                            />
                          )}
                          {InfomationIcon && <InfomationIcon />}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                      className: clsx(className, classes.input),
                      error: !!error,
                    }}
                    helperText={error?.message}
                    FormHelperTextProps={{
                      error: !!error,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                      ...params.inputProps,
                      value: inputValue,
                    }}
                  />
                )}
                renderOption={(option, { selected }) => {
                  return renderOptionFunc(option, {
                    searchValue: inputValue,
                    selected,
                  })
                }}
                renderTags={!disableRenderTags ? renderTagsFunc : () => {}}
                ChipProps={ChipProps}
                renderGroup={renderGroup}
                popupIcon={fetch ? null : undefined}
              />
              {renderChipsPreviewArea(onChange)}
            </>
          )
        }}
      />
    </>
  )
}
SearchField.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  noOptionsText: PropTypes.string,
  Icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    null,
    undefined,
  ]),
  InputIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    null,
    undefined,
  ]),
  InfomationIcon: PropTypes.oneOfType([PropTypes.elementType, null, undefined]),
  renderOption: PropTypes.func,
  renderTags: PropTypes.func,
  disableRenderTags: PropTypes.bool,
  ChipProps: PropTypes.shape({}),
  renderGroup: PropTypes.func,
  renderChipsPreviewGroup: PropTypes.func,
  renderChipOption: PropTypes.func,
  ListChipsPreviewComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    null,
    undefined,
  ]),
  ListChipsPreviewProps: PropTypes.shape({}),
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  filterOptions: PropTypes.func,
  groupBy: PropTypes.func,
  fetch: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    null,
    undefined,
  ]),
  freeSolo: PropTypes.bool,
  control: PropTypes.shape({}),
  rules: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  multiple: PropTypes.bool.isRequired,
  disableCloseOnSelect: PropTypes.bool,
  autoComplete: PropTypes.bool,
  includeInputInList: PropTypes.bool,
  filterSelectedOptions: PropTypes.bool,
  clearText: PropTypes.string,
  ctxDefined: PropTypes.bool,
}
SearchField.defaultProps = {
  id: 'search',
  required: false,
  disabled: false,
  variant: 'outlined',
  className: undefined,
  fullWidth: false,
  label: '',
  name: 'search',
  size: 'small',
  noOptionsText: 'Enter a term to show the matches',
  Icon: WidgetsOutlinedIcon,
  InputIcon: SearchIcon,
  InfomationIcon: undefined,
  renderOption: undefined,
  renderTags: undefined,
  disableRenderTags: false,
  ChipProps: undefined,
  renderGroup: undefined,
  renderChipsPreviewGroup: undefined,
  renderChipOption: undefined,
  ListChipsPreviewComponent: 'ul',
  ListChipsPreviewProps: undefined,
  getOptionLabel: undefined,
  getOptionSelected: undefined,
  filterOptions: undefined,
  groupBy: undefined,
  fetch: undefined,
  options: [],
  freeSolo: false,
  control: undefined,
  rules: undefined,
  errors: undefined,
  disableCloseOnSelect: false,
  autoComplete: false,
  includeInputInList: false,
  filterSelectedOptions: false,
  clearText: 'Limpiar',
  ctxDefined: false,
}

export default SearchField
