import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useConfirm } from 'material-ui-confirm'

const useStyles = makeStyles((theme) => ({
  /* Styles applied to the information modal */
  modalInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div > div > div.MuiDialogTitle-root': {
      color: theme.palette.primary.main,
    },
  },
  modalConfirmationButton: {
    textTransform: 'none',
  },
  modalCancellationButton: {
    textTransform: 'none',
  },
  modalCancellationButtonNotDisplayed: {
    display: 'none',
  },
}))

const useInfoModal = () => {
  const classes = useStyles()
  const confirm = useConfirm()

  const openModal = ({
    title,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
  }) => {
    return confirm({
      title,
      description: content,
      dialogProps: {
        transitionDuration: { appear: 600, enter: 700, exit: 700 },
        fullWidth: true,
        maxWidth: 'md',
        className: clsx(classes.modalInfo),
        ...dialogProps,
      },
      confirmationText,
      confirmationButtonProps: {
        className: clsx(classes.modalConfirmationButton),
      },
      cancellationText,
      cancellationButtonProps: {
        className: clsx(
          classes.modalCancellationButton,
          (typeof cancellationText === 'undefined' ||
            cancellationText === null ||
            cancellationText === '') &&
            classes.modalCancellationButtonNotDisplayed
        ),
      },
    })
  }

  return { openModal }
}
export default useInfoModal
