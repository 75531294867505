import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

export const Copyright = (props) => {
  const { name, site } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Typography color="textSecondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={site}>
        {name}
      </Link>{' '}
      {new Date().getFullYear()}
      {/* {'.'} */}
    </Typography>
  )
}

Copyright.propTypes = {
  name: PropTypes.string,
  site: PropTypes.string,
}

Copyright.defaultProps = {
  name: undefined,
  site: 'https://material-ui.com/',
}

export default Copyright
