/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n, Logger } from '@aws-amplify/core'
import { VerifyContact } from 'aws-amplify-react'
import PropTypes from 'prop-types'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import Auth from '@aws-amplify/auth'

import { Link, Radio, Grid, InputLabel, TextField } from '@material-ui/core'

import { auth } from 'aws-amplify-react/lib/Amplify-UI/data-test-attributes'
import MainMaterialLayout from './MainMaterialLayout'
import { withMaterialStyles } from './MainMaterialStyles'
import LoaderButton from '../common/LoaderButton'

const logger = new Logger('VerifyContact')
export class MaterialVerifyContact extends VerifyContact {
  constructor(props) {
    super(props)
    this.state = { loading: false }
    this.validAuthStates = ['signIn', 'signedOut', 'signedUp']
    this.verifyView = this.verifyView.bind(this)
    this.submitView = this.submitView.bind(this)
    this.renderHeader = this.renderHeader.bind(this)
    this.renderBody = this.renderBody.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.verify = this.verify.bind(this)
    this.submit = this.submit.bind(this)
  }

  verify() {
    this.setState({ loading: true })
    const { contact, checkedValue } = this.inputs
    if (!contact) {
      this.setState({ loading: false })
      this.error('Neither Email nor Phone Number selected')
      return
    }

    if (!Auth || typeof Auth.verifyCurrentUserAttribute !== 'function') {
      this.setState({ loading: false })
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }

    Auth.verifyCurrentUserAttribute(checkedValue)
      .then((data) => {
        logger.debug(data)
        this.setState({ verifyAttr: checkedValue, loading: false })
      })
      .catch((err) => {
        this.setState({ loading: false })
        this.error(err)
      })
  }

  submit() {
    this.setState({ loading: true })
    const attr = this.state.verifyAttr
    const { code } = this.inputs
    if (!Auth || typeof Auth.verifyCurrentUserAttributeSubmit !== 'function') {
      this.setState({ loading: false })
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }
    Auth.verifyCurrentUserAttributeSubmit(attr, code)
      .then((data) => {
        logger.debug(data)
        this.changeState('signedIn', this.props.authData)
        this.setState({ verifyAttr: null, loading: false })
      })
      .catch((err) => {
        this.setState({ loading: false })
        this.error(err)
      })
  }

  verifyView() {
    const user = this.props.authData
    if (!user) {
      return null
    }
    const { unverified } = user
    if (!unverified) {
      return null
    }
    // eslint-disable-next-line camelcase
    const { email, phone_number } = unverified
    return (
      <div>
        {email ? (
          <Grid container spacing={4} xs={3}>
            <Grid item xs={2}>
              <Radio key="email" name="contact" value="email" color="primary" />
            </Grid>
            <Grid item xs={2}>
              <InputLabel className={this.props.classes.radioLabel}>
                Email
              </InputLabel>
            </Grid>
          </Grid>
        ) : null}
        {
          // eslint-disable-next-line camelcase
          phone_number ? (
            <Grid container spacing={4} xs={3}>
              <Grid item xs={2}>
                <Radio
                  key="phone_number"
                  name="contact"
                  value="phone_number"
                  color="primary"
                />
              </Grid>
              <Grid item xs={2}>
                <InputLabel className={this.props.classes.radioLabel}>
                  Phone Number
                </InputLabel>
              </Grid>
            </Grid>
          ) : null
        }
      </div>
    )
  }

  submitView() {
    const { variant } = this.props
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextField
            id="code"
            label={I18n.get('Code')}
            name="code"
            type="text"
            size="small"
            variant={variant}
            autoComplete="off"
            fullWidth
            required
            onChange={this.handleInputChange}
          />
        </Grid>
      </Grid>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  renderHeader() {
    return (
      <span className={this.props.classes.title}>
        {I18n.get('Account recovery requires verified contact information')}
      </span>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  renderBody() {
    return this.state.verifyAttr ? this.submitView() : this.verifyView()
  }

  renderFooter() {
    const { authData } = this.props
    return (
      <>
        <Grid item xs={12} className={this.props.classes.largeSpacing}>
          {this.state.verifyAttr ? (
            <LoaderButton
              clickHandler={this.submit}
              loading={this.state.loading}
              buttonText={I18n.get('Submit')}
            />
          ) : (
            <LoaderButton
              clickHandler={this.verify}
              loading={this.state.loading}
              buttonText={I18n.get('Verify')}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <p className={this.props.classes.forgotPasswordText}>
            <Link
              className={this.props.classes.skipLink}
              onClick={() => this.changeState('signedIn', authData)}
              data-test={auth.verifyContact.skipLink}>
              {I18n.get('Skip')}
            </Link>
          </p>
        </Grid>
      </>
    )
  }

  showComponent() {
    const { classes, hide } = this.props
    if (hide && hide.includes(MaterialVerifyContact)) {
      return null
    }
    return (
      <MainMaterialLayout
        Header={this.renderHeader}
        Body={this.renderBody}
        Footer={this.renderFooter}
        classes={classes}
      />
    )
  }
}

MaterialVerifyContact.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'contained']),
}

MaterialVerifyContact.defaultProps = {
  variant: 'outlined',
}

export default withMaterialStyles(MaterialVerifyContact)
