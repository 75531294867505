import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRequiredAuthLayout } from '../../layout'

const useStyles = makeStyles(() => ({
  enabled: {
    display: 'block',
  },
  disabled: {
    display: 'none',
  },
}))

const HomePage = () => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles()
  return <></>
}

export default withRequiredAuthLayout(HomePage)
