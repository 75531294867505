import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useNavigate } from '@reach/router'
import { MaterialChangePassword } from '../security'
import { useLayoutContext } from '../context'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  back: {
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

const ChangePassword = () => {
  const classes = useStyles()
  const { loading, setLoading, currentUser } = useLayoutContext()
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(false)
  }, [setLoading])

  const handleReturn = () => {
    setLoading(true)
    setTimeout(() => {
      navigate(-1)
    }, 300)
  }

  const onSuccess = () => {
    setLoading(true)
    setTimeout(() => {
      navigate(-1)
    }, 300)
  }

  return (
    <>
      <div className={classes.root}>
        <Container>
          <Grid container spacing={2}>
            <Grid
              container
              className={classes.back}
              onClick={() => handleReturn()}>
              <Grid item>
                <ArrowBackIosIcon />
              </Grid>
              <Grid item>
                <Typography variant="link">Regresar</Typography>
              </Grid>
            </Grid>
            <MaterialChangePassword
              currentUser={currentUser}
              loading={loading}
              setLoading={setLoading}
              onSuccess={onSuccess}
            />
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default ChangePassword
