import { API } from 'aws-amplify'
import Debug from 'debug'
import { createApplicant, updateApplicant } from '../../graphql/mutations'

const debug = Debug('services:applicant:applicant')

// https://github.com/aws-amplify/amplify-cli/issues/1576#issuecomment-635128542
const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const postApplicant = async ({
  kind,
  name,
  surname,
  secondSurname,
  rfc,
  nationality,
  email,
  domicile,
  countryCallingCode,
  phoneNumber,
  extension,
}) => {
  try {
    const item = {
      kind,
      name,
      surname,
      secondSurname,
      rfc,
      nationality,
      email,
      domicile,
      countryCallingCode,
      phoneNumber,
      extension,
    }
    const { data } = await API.graphql({
      query: createApplicant,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    if (data) return data?.createApplicant
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const putApplicant = async ({
  id,
  kind,
  name,
  surname,
  secondSurname,
  rfc,
  nationality,
  email,
  domicile,
  countryCallingCode,
  phoneNumber,
  extension,
  _version,
}) => {
  try {
    const item = {
      id,
      kind,
      name,
      surname,
      secondSurname,
      rfc,
      nationality,
      email,
      domicile,
      countryCallingCode,
      phoneNumber,
      extension,
      _version,
    }
    const { data } = await API.graphql({
      query: updateApplicant,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    if (data) return data?.updateApplicant
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}
