export const mainPreset = {
  autoCollapseDisabled: true,
  collapsedBreakpoint: 'sm',
  heightAdjustmentDisabled: false,
  xs: {
    sidebar: {
      anchor: 'bottom', // bottom, left, up, right
      hidden: false,
      inset: false,
      variant: 'temporary', // permanent | persistent | temporary
      width: '100%',
      collapsible: false,
      // collapsedWidth: 64,
    },
    header: {
      // position: 'fixed', // sticky | fixed | relative
      clipped: false,
      offsetHeight: 56,
      persistentBehavior: 'none', // none | fit| flexible
    },
    content: {
      persistentBehavior: 'none',
    },
    footer: {
      persistentBehavior: 'none',
    },
  },
  sm: {
    sidebar: {
      anchor: 'left',
      hidden: false,
      inset: false,
      variant: 'temporary',
      width: 256,
      collapsible: true,
      collapsedWidth: 64,
    },
    header: {
      position: 'fixed',
      clipped: false,
      offsetHeight: 64,
      persistentBehavior: 'none',
    },
    content: {
      persistentBehavior: 'none',
    },
    footer: {
      persistentBehavior: 'none',
    },
  },
  md: {
    sidebar: {
      anchor: 'left',
      hidden: false,
      inset: false,
      variant: 'persistent',
      width: 256,
      collapsible: true,
      collapsedWidth: 64,
    },
    header: {
      position: 'fixed',
      clipped: false,
      offsetHeight: 64,
      persistentBehavior: 'fit',
    },
    content: {
      persistentBehavior: 'fit',
    },
    footer: {
      persistentBehavior: 'fit',
    },
  },
  lg: {
    sidebar: {
      anchor: 'left',
      hidden: false,
      inset: false,
      variant: 'persistent',
      width: 256,
      collapsible: true,
      collapsedWidth: 64,
    },
    header: {
      position: 'fixed',
      clipped: false,
      offsetHeight: 64,
      persistentBehavior: 'fit',
    },
    content: {
      persistentBehavior: 'fit',
    },
    footer: {
      persistentBehavior: 'fit',
    },
  },
  xl: {
    sidebar: {
      anchor: 'left',
      hidden: false,
      inset: false,
      variant: 'persistent',
      width: 256,
      collapsible: true,
      collapsedWidth: 64,
    },
    header: {
      position: 'fixed',
      clipped: false,
      offsetHeight: 64,
      persistentBehavior: 'fit',
    },
    content: {
      persistentBehavior: 'fit',
    },
    footer: {
      persistentBehavior: 'fit',
    },
  },
}

export const ipadPortraitPreset = {
  autoCollapseDisabled: true,
  collapsedBreakpoint: 'sm',
  // heightAdjustmentDisabled: false,
  heightAdjustmentDisabled: true,
  sm: {
    sidebar: {
      anchor: 'left',
      hidden: false,
      inset: false,
      variant: 'persistent',
      width: 256,
      collapsible: true,
      collapsedWidth: 64,
    },
    header: {
      position: 'fixed',
      clipped: false,
      offsetHeight: 64,
      persistentBehavior: 'fit',
    },
    content: {
      persistentBehavior: 'fit',
    },
    footer: {
      persistentBehavior: 'fit',
    },
  },
}
