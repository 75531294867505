import React from 'react'
import PropTypes from 'prop-types'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n, Logger } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'
import { SignUp } from 'aws-amplify-react'
import { auth } from 'aws-amplify-react/lib/Amplify-UI/data-test-attributes'
import {
  Grid,
  TextField,
  Link,
  InputAdornment,
  IconButton,
  Hidden,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InfoIcon from '@material-ui/icons/Info'
import parseHtml from 'html-react-parser'
import PhoneField from '../common/PhoneField'
import MainMaterialLayout from './MainMaterialLayout'
import LoaderButton from '../common/LoaderButton'

import { withMaterialStyles } from './MainMaterialStyles'

import { countryCodes } from '../common/country-dial-codes'

import { isValidAWSCognitoPassword } from '../../regex.validations'
import { AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE } from '../../config/constants'

const logger = new Logger('MaterialSignUp')

export class MaterialSignUp extends SignUp {
  constructor(props) {
    super(props)
    this.state = {
      requestPending: false,
      confirmPassword: '',
      password: '',
      showPassword: false,
      showConfirmPassword: false,
      storedEmail: this.props.customAuthData?.username,
      emailInput: '',
    }
    this.getDefaultDialCode = this.getDefaultDialCode.bind(this)
    this.signUp = this.signUp.bind(this)
    this.renderHeader = this.renderHeader.bind(this)
    this.renderBody = this.renderBody.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this)
    this.handleClickShowConfirmPassword = this.handleClickShowConfirmPassword.bind(
      this
    )
    this.handleMouseDownConfirmPassword = this.handleMouseDownConfirmPassword.bind(
      this
    )
  }

  initValueEvent() {
    const evt = {
      target: {
        name: 'username',
        value: this.state.storedEmail,
      },
    }
    if (this.state.storedEmail) this.handleInputChange(evt)
  }

  handleInputChange(evt) {
    if (evt.target.value && !this.state.storedEmail)
      if (evt.target.name === 'username') {
        this.setState({ emailInput: evt.target.value })
      }
    this.inputs = this.inputs || {}
    // eslint-disable-next-line no-underscore-dangle
    const _a = evt.target
    const { name } = _a
    const { value } = _a
    const { type } = _a
    const { checked } = _a
    // eslint-disable-next-line camelcase
    const check_type = ['radio', 'checkbox'].includes(type)
    // eslint-disable-next-line camelcase
    this.inputs[name] = check_type ? checked : value
    // eslint-disable-next-line camelcase
    this.inputs.checkedValue = check_type ? value : null
  }

  handleClickShowPassword = () => {
    const currentShowPassword = this.state.showPassword
    this.setState({ showPassword: !currentShowPassword })
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  handleClickShowConfirmPassword = () => {
    const currentShowConfirmPassword = this.state.showConfirmPassword
    this.setState({ showConfirmPassword: !currentShowConfirmPassword })
  }

  handleMouseDownConfirmPassword = (event) => {
    event.preventDefault()
  }

  cancelAuth() {
    this.props.authToggle(false)
  }

  confirmPasswordValidation(password) {
    return password === this.state.confirmPassword
  }

  confirmPasswordListener(event) {
    this.setState({ confirmPassword: event.target.value })
  }

  validatePasswordStrength(input) {
    return isValidAWSCognitoPassword.test(input)
  }

  renderConfirmPasswordField(key, variant) {
    const { openPasswordInformation } = this.props
    return (
      <Grid item xs={12} className={this.props.classes.mediumSpacing}>
        <TextField
          autoFocus={false}
          required
          type={this.state.showConfirmPassword ? 'text' : 'password'}
          name="confirmPassword"
          key={`confirm-${key}`}
          label={I18n.get('Confirm Password')}
          size="small"
          fullWidth
          onChange={(event) => this.confirmPasswordListener(event)}
          data-test={auth.signUp.nonPhoneNumberInput}
          variant={variant}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowConfirmPassword}
                  onMouseDown={this.handleMouseDownConfirmPassword}>
                  {this.state.showConfirmPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
                <IconButton
                  aria-label="info"
                  size="small"
                  onClick={() => {
                    openPasswordInformation()
                  }}>
                  <InfoIcon fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    )
  }

  getDefaultDialCode() {
    return this.props.signUpConfig &&
      this.props.signUpConfig.defaultCountryCode &&
      countryCodes.indexOf(`+${this.props.signUpConfig.defaultCountryCode}`) !==
        -1
      ? `+${this.props.signUpConfig.defaultCountryCode}`
      : 'MX'
  }

  // eslint-disable-next-line consistent-return
  signUp() {
    this.setState({ requestPending: true })
    const validation = this.validate()
    if (validation && validation.length > 0) {
      this.setState({ requestPending: false })
      return this.error(
        I18n.get(
          `The following fields need to be filled out: ${validation.join(', ')}`
        )
      )
    }
    if (!this.confirmPasswordValidation(this.inputs.password)) {
      this.setState({ requestPending: false })
      return this.error(I18n.get('Passwords do not match'))
    }
    if (!this.validatePasswordStrength(this.inputs.password)) {
      this.setState({ requestPending: false })
      return this.error(
        parseHtml(AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE)
      )
    }
    if (!Auth || typeof Auth.signUp !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }

    this.props.setSignUpAuthData({
      username: this.inputs.username,
      password: this.inputs.password,
    })

    // eslint-disable-next-line camelcase
    const signup_info = {
      username: this.inputs.username,
      password: this.inputs.password,
      attributes: { email: this.inputs.username },
    }

    const inputKeys = Object.keys(this.inputs)
    const inputVals = Object.values(this.inputs)

    inputKeys.forEach((key, index) => {
      if (
        !['username', 'password', 'checkedValue', 'dial_code'].includes(key)
      ) {
        if (
          key !== 'phone_line_number' &&
          key !== 'dial_code' &&
          key !== 'error'
        ) {
          const newKey = `${this.needPrefix(key) ? 'custom:' : ''}${key}`
          signup_info.attributes[newKey] = inputVals[index]
        }
      }
    })

    if (this.phone_number)
      signup_info.attributes.phone_number = this.phone_number

    let labelCheck = false
    this.signUpFields.forEach((field) => {
      if (field.label === this.getUsernameLabel()) {
        logger.debug(`Changing the username to the value of ${field.label}`)
        signup_info.username =
          signup_info.attributes[field.key] || signup_info.username
        labelCheck = true
      }
    })
    if (!labelCheck && !signup_info.username) {
      // if the customer customized the username field in the sign up form
      // He needs to either set the key of that field to 'username'
      // Or make the label of the field the same as the 'usernameAttributes'
      throw new Error(
        `Couldn't find the label: ${this.getUsernameLabel()}, in sign up fields according to usernameAttributes!`
      )
    }
    Auth.signUp(signup_info)
      .then((data) => {
        this.setState({ requestPending: false })
        this.props.notificationTrigger('¡El usuario se creó de forma exitosa!')
        // @ts-ignore
        this.changeState('confirmSignUp', data.user.username)
      })
      .catch((err) => {
        this.setState({ requestPending: false })
        return this.error(err)
      })
  }

  // eslint-disable-next-line class-methods-use-this
  renderHeader() {
    return (
      <span className={this.props.classes.title}>{I18n.get('Sign Up')}</span>
    )
  }

  renderBody() {
    this.initValueEvent()
    const { variant, openPasswordInformation } = this.props
    if (this.checkCustomSignUpFields()) {
      this.signUpFields = this.props.signUpConfig.signUpFields
    }
    this.sortFields()
    return this.signUpFields.map((field) => {
      return field.key !== 'phone_number' ? (
        <>
          <Grid item xs={12} className={this.props.classes.mediumSpacing}>
            <TextField
              id={field.key}
              autoFocus={
                this.signUpFields.findIndex((f) => {
                  return f.key === field.key
                }) === 0
              }
              required={field.required}
              // placeholder={I18n.get(field.placeholder)}
              type={
                // eslint-disable-next-line no-nested-ternary
                field.key === 'password'
                  ? this.state.showPassword
                    ? 'text'
                    : 'password'
                  : field.type
              }
              name={field.key}
              key={field.key}
              label={I18n.get(field.label)}
              defaultValue={
                this.state.storedEmail &&
                this.state.storedEmail !== '' &&
                field.key === 'username'
                  ? this.state.storedEmail
                  : null
              }
              disabled={field.key === 'username' && this.state.storedEmail}
              size="small"
              fullWidth
              onChange={this.handleInputChange}
              data-test={auth.signUp.nonPhoneNumberInput}
              variant={variant}
              InputProps={
                field.key === 'password'
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}>
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                          <IconButton
                            aria-label="info"
                            size="small"
                            onClick={() => {
                              openPasswordInformation()
                            }}>
                            <InfoIcon fontSize="inherit" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  : null
              }
            />
          </Grid>
          {field.key === 'password'
            ? this.renderConfirmPasswordField(field.key, variant)
            : null}
        </>
      ) : (
        <Grid item xs={12} className={this.props.classes.mediumSpacing}>
          <PhoneField
            id={field.key}
            name="phone_number"
            required={field.required}
            defaultCountry={this.getDefaultDialCode()}
            key="phone_number"
            label={field.label}
            // placeholder={field.placeholder}
            helperText=""
            variant={variant}
            size="small"
            fullWidth
            renderFlag
            renderDialCodeLabel
            onChangeText={this.onPhoneNumberChanged}
          />
        </Grid>
      )
    })
  }

  renderFooter() {
    const { showCancelButton } = this.props
    return (
      <>
        <Grid container spacing={2} className={this.props.classes.largeSpacing}>
          {showCancelButton && (
            <Grid item xs={12} sm={6}>
              <LoaderButton
                variant="contained"
                color="secondary"
                clickHandler={() => this.cancelAuth()}
                loading={this.state.requestPending}
                buttonText={I18n.get('Cancel Create Account')}
                fullWidth
              />
            </Grid>
          )}
          {!showCancelButton && (
            <Hidden xsDown>
              <Grid item sm={3} />
            </Hidden>
          )}
          <Grid item xs={12} sm={6}>
            <LoaderButton
              clickHandler={this.signUp}
              loading={this.state.requestPending}
              buttonText={I18n.get('Create Account')}
            />
          </Grid>
          {!showCancelButton && (
            <Hidden xsDown>
              <Grid item sm={3} />
            </Hidden>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <p className={this.props.classes.forgotPasswordText}>
            {I18n.get('Have an account?')}{' '}
            <Link
              className={this.props.classes.resetPasswordLink}
              onClick={() => this.changeState('signIn')}>
              <span
                className={
                  this.state.requestPending ? this.props.classes.disabled : null
                }>
                {I18n.get('Sign In')}
              </span>
            </Link>
          </p>
        </Grid>
      </>
    )
  }

  // eslint-disable-next-line no-unused-vars
  showComponent() {
    const { hide, classes } = this.props
    if (hide && hide.includes(MaterialSignUp)) {
      return null
    }
    return (
      <MainMaterialLayout
        Header={this.renderHeader}
        Body={this.renderBody}
        Footer={this.renderFooter}
        classes={classes}
      />
    )
  }
}

MaterialSignUp.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'contained']),
  customAuthData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showCancelButton: PropTypes.bool,
}

MaterialSignUp.defaultProps = {
  variant: 'outlined',
  customAuthData: undefined,
  showCancelButton: true,
}

export default withMaterialStyles(MaterialSignUp)
