import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ style }) => {
  return (
    <svg
      width={style.width}
      height={style.height}
      viewBox="0 0 28 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Carrito-2" transform="translate(-829.000000, -300.000000)">
          <g id="Group-14" transform="translate(829.000000, 300.000000)">
            <path
              d="M27.6363636,16.5860732 C27.6363636,17.8604634 26.6136364,18.9031463 25.3636364,18.9031463 L2.63636364,18.9031463 C1.38636364,18.9031463 0.363636364,17.8604634 0.363636364,16.5860732 L0.363636364,2.68363415 C0.363636364,1.4092439 1.38636364,0.366560976 2.63636364,0.366560976 L25.3636364,0.366560976 C26.6136364,0.366560976 27.6363636,1.4092439 27.6363636,2.68363415 L27.6363636,16.5860732 Z"
              id="Fill-23"
              fill="#5565AF"
            />
            <path
              d="M14,6.57631707 C13.1668182,5.61241463 11.95,5.00070732 10.5909091,5.00070732 C8.08090909,5.00070732 6.04545455,7.07541463 6.04545455,9.63485366 C6.04545455,12.1933659 8.08090909,14.269 10.5909091,14.269 C11.95,14.269 13.1668182,13.6572927 14,12.6929268 C13.2945455,11.8763902 12.8636364,10.8091463 12.8636364,9.63485366 C12.8636364,8.46056098 13.2945455,7.39285366 14,6.57631707"
              id="Fill-27"
              fill="#EA564B"
            />
            <path
              d="M14,6.57631707 C13.2945455,7.39285366 12.8636364,8.46102439 12.8636364,9.63485366 C12.8636364,10.8086829 13.2945455,11.8763902 14,12.6929268 C14.7054545,11.8763902 15.1363636,10.8091463 15.1363636,9.63485366 C15.1363636,8.46056098 14.7054545,7.39285366 14,6.57631707"
              id="Fill-29"
              fill="#EA564B"
            />
            <path
              d="M17.4090909,5.00070732 C16.05,5.00070732 14.8331818,5.61241463 14,6.57631707 C13.8031818,6.80431707 13.6309091,7.05363415 13.48,7.31778049 L14.52,7.31778049 C14.6854545,7.60741463 14.8140909,7.9197561 14.9159091,8.24460976 L13.0777273,8.24460976 C12.9854545,8.54258537 12.9186364,8.85121951 12.8872727,9.17143902 L15.105,9.17143902 C15.1204545,9.32436585 15.1359091,9.47729268 15.1359091,9.63485366 C15.1359091,9.7914878 15.1268182,9.94580488 15.1118182,10.0982683 L12.8868182,10.0982683 C12.9181818,10.4184878 12.985,10.727122 13.0772727,11.0250976 L14.9209091,11.0250976 C14.8195455,11.3513415 14.6836364,11.6609024 14.5181818,11.9519268 L13.4795455,11.9519268 C13.63,12.2151463 13.8027273,12.4649268 13.9995455,12.6929268 C14.8327273,13.6572927 16.0495455,14.269 17.4086364,14.269 C19.9186364,14.269 21.9540909,12.1933659 21.9540909,9.63485366 C21.9545455,7.07541463 19.9190909,5.00070732 17.4090909,5.00070732"
              id="Fill-31"
              fill="#FFCE32"
            />
            <path
              d="M25.3636364,0.366560976 L2.63636364,0.366560976 C1.38636364,0.366560976 0.363636364,1.4092439 0.363636364,2.68363415 L0.363636364,16.5860732 C0.363636364,17.8604634 1.38636364,18.9031463 2.63636364,18.9031463 L25.3636364,18.9031463 C26.6136364,18.9031463 27.6363636,17.8604634 27.6363636,16.5860732 L27.6363636,2.68363415 C27.6363636,1.4092439 26.6136364,0.366560976 25.3636364,0.366560976 M25.3636364,0.82997561 C26.3663636,0.82997561 27.1818182,1.66134146 27.1818182,2.68363415 L27.1818182,16.5860732 C27.1818182,17.6083659 26.3663636,18.4397317 25.3636364,18.4397317 L2.63636364,18.4397317 C1.63363636,18.4397317 0.818181818,17.6083659 0.818181818,16.5860732 L0.818181818,2.68363415 C0.818181818,1.66134146 1.63363636,0.82997561 2.63636364,0.82997561 L25.3636364,0.82997561"
              id="Fill-33"
              fill="#7684B7"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

Icon.propTypes = {
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
}

Icon.defaultProps = {
  style: {
    width: '28px',
    height: '19px',
  },
}

export default Icon
