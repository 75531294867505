import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
// import primary from '@material-ui/core/colors/blueGrey'
// import primary from '@material-ui/core/colors/blue'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#6ab7ff',
      main: '#1e88e5',
      dark: '#005cb2',
      contrastText: '#fff',
    },
    secondary: {
      light: '#718792',
      main: '#455a64',
      dark: '#1c313a',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    brandStatusColors: {
      created: '#499CEE',
      granted: '#6CC75F',
      denied: '#D82911',
      cancelled: '#A3A6A7',
    },
    brandStageColors: {
      received: '#499CEE',
      paid: '#499CEE',
      inProcess: '#EE9E49',
      presented: '#499CEE',
      finalized: '#6CC75F',
    },
  },
  toast: {
    position: 'relative',
  },
})

export default theme
