/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n, Logger } from '@aws-amplify/core'
import { ForgotPassword } from 'aws-amplify-react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import Auth from '@aws-amplify/auth'
import PropTypes from 'prop-types'

import {
  TextField,
  Grid,
  Link,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InfoIcon from '@material-ui/icons/Info'

import { auth } from 'aws-amplify-react/lib/Amplify-UI/data-test-attributes'

import MainMaterialLayout from './MainMaterialLayout'

import { withMaterialStyles } from './MainMaterialStyles'

import LoaderButton from '../common/LoaderButton'

const logger = new Logger('ForgotPassword')

export class MaterialForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      storedEmail: this.props.customAuthData?.username,
      emailInput: '',
      showPassword: false,
    }
    this.validAuthStates = ['signIn', 'signedOut', 'signedUp']
    this.renderHeader = this.renderHeader.bind(this)
    this.renderBody = this.renderBody.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.send = this.send.bind(this)
    this.submit = this.submit.bind(this)
  }

  handleClickShowPassword = () => {
    const currentShowPassword = this.state.showPassword
    this.setState({ showPassword: !currentShowPassword })
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  initValueEvent() {
    const evt = {
      target: {
        name: 'username',
        value: this.state.storedEmail,
      },
    }
    if (this.state.storedEmail) this.handleInputChange(evt)
  }

  handleInputChange(evt) {
    if (evt.target.value && !this.state.storedEmail)
      if (evt.target.name === 'username') {
        this.setState({ emailInput: evt.target.value })
      }

    this.inputs = this.inputs || {}
    // eslint-disable-next-line no-underscore-dangle
    const _a = evt.target
    const { name } = _a
    const { value } = _a
    const { type } = _a
    const { checked } = _a
    // eslint-disable-next-line camelcase
    const check_type = ['radio', 'checkbox'].includes(type)
    // eslint-disable-next-line camelcase
    this.inputs[name] = check_type ? checked : value
    // eslint-disable-next-line camelcase
    this.inputs.checkedValue = check_type ? value : null
  }

  send() {
    this.setState({ loading: true })
    const { authData = {} } = this.props
    const username =
      this.state.emailInput ||
      this.getUsernameFromInput() ||
      this.state.storedEmail ||
      authData.username
    this.setState({ username: '' })
    if (!Auth || typeof Auth.forgotPassword !== 'function') {
      this.setState({ loading: false })
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }
    Auth.forgotPassword(username)
      .then((data) => {
        this.props.notificationTrigger('El código se envió')
        logger.debug(data)
        this.setState({ delivery: data.CodeDeliveryDetails, loading: false })
      })
      .catch((err) => {
        this.setState({ loading: false })
        this.error(err)
      })
  }

  submit() {
    this.setState({ loading: true })
    const { authData = {}, notificationTrigger } = this.props
    const { code, password } = this.inputs
    const username =
      this.state.emailInput ||
      this.getUsernameFromInput() ||
      this.state.storedEmail ||
      authData.username

    if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
      this.setState({ loading: false })
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }
    Auth.forgotPasswordSubmit(username, code, password)
      .then((data) => {
        notificationTrigger('Contraseña reestablecida de forma exitosa!')
        logger.debug(data)
        this.changeState('signIn')
        this.setState({ delivery: null, loading: false })
      })
      .catch((err) => {
        this.setState({ loading: false })
        this.error(err)
      })
  }

  sendView() {
    this.initValueEvent()
    const { variant } = this.props
    return (
      <>
        <Grid item xs={12} className={this.props.classes.mediumSpacing}>
          <TextField
            id="username"
            name="username"
            label={I18n.get('Username')}
            type="text"
            size="small"
            value={
              this.state.storedEmail && this.state.storedEmail !== ''
                ? this.state.storedEmail
                : null
            }
            disabled={this.state.storedEmail}
            variant={variant}
            onChange={this.handleInputChange}
            autoComplete="off"
            autoFocus
            fullWidth
            required
          />
        </Grid>
      </>
    )
  }

  submitView() {
    const { variant, openPasswordInformation } = this.props
    return (
      <>
        <Grid item xs={12} className={this.props.classes.mediumSpacing}>
          <TextField
            id="code"
            name="code"
            label={I18n.get('Code')}
            type="text"
            size="small"
            placeholder=""
            variant={variant}
            onChange={this.handleInputChange}
            autoComplete="off"
            defaultValue=""
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} className={this.props.classes.mediumSpacing}>
          <TextField
            id="password"
            name="password"
            label={I18n.get('New password')}
            type={this.state.showPassword ? 'text' : 'password'}
            size="small"
            placeholder=""
            variant={variant}
            onChange={this.handleInputChange}
            autoComplete="off"
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}>
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => {
                      openPasswordInformation()
                    }}>
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  renderHeader() {
    return (
      <span className={this.props.classes.title}>
        {I18n.get('Reset Password')}
      </span>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  renderBody() {
    const { authData = {} } = this.props
    /* return this.state.delivery || authData.username
      ? this.submitView()
      : this.sendView() */
    return (
      <>
        <div
          className={
            this.state.delivery || authData.username
              ? this.props.classes.enabled
              : this.props.classes.disabled
          }>
          {this.submitView()}
        </div>
        <div
          className={
            this.state.delivery || authData.username
              ? this.props.classes.disabled
              : this.props.classes.enabled
          }>
          {this.sendView()}
        </div>
      </>
    )
  }

  renderFooter() {
    const { authData = {} } = this.props
    return (
      <>
        {this.state.delivery || authData.username ? (
          <Grid
            item
            xs={12}
            sm={12}
            className={this.props.classes.largeSpacing}>
            <LoaderButton
              clickHandler={this.submit}
              loading={this.state.loading}
              buttonText={I18n.get('Reset Password')}
            />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            className={this.props.classes.smallSpacing}>
            <LoaderButton
              clickHandler={this.send}
              loading={this.state.loading}
              buttonText={I18n.get('Send Code')}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          {this.state.delivery || authData.username ? (
            <>
              <Grid container spacing={2} xs={12}>
                <Grid item xs={6}>
                  <p className={this.props.classes.forgotPasswordText}>
                    <Link
                      className={this.props.classes.resetPasswordLink}
                      onClick={this.send}
                      data-test={auth.forgotPassword.resendCodeLink}>
                      <span
                        className={
                          this.state.loading
                            ? this.props.classes.disabled
                            : null
                        }>
                        {I18n.get('Resend Code')}
                      </span>
                    </Link>
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p className={this.props.classes.forgotPasswordText}>
                    <Link
                      className={this.props.classes.resetPasswordLink}
                      onClick={() =>
                        authData.username
                          ? this.changeState('signIn')
                          : this.setState({ delivery: null, loading: false })
                      }
                      data-test={auth.forgotPassword.backToSignInLink}>
                      <span
                        className={
                          this.state.loading
                            ? this.props.classes.disabled
                            : null
                        }>
                        {authData.username
                          ? I18n.get('Return to Login')
                          : I18n.get('Enter the username again')}
                      </span>
                    </Link>
                  </p>
                </Grid>
              </Grid>
            </>
          ) : (
            <p className={this.props.classes.forgotPasswordText}>
              <Link
                className={this.props.classes.resetPasswordLink}
                onClick={() => this.changeState('signIn')}
                data-test={auth.forgotPassword.backToSignInLink}>
                <span
                  className={
                    this.state.loading ? this.props.classes.disabled : null
                  }>
                  {I18n.get('Return to Login')}
                </span>
              </Link>
            </p>
          )}
        </Grid>
      </>
    )
  }

  showComponent() {
    const { classes, hide } = this.props
    if (hide && hide.includes(MaterialForgotPassword)) {
      return null
    }
    return (
      <MainMaterialLayout
        Header={this.renderHeader}
        Body={this.renderBody}
        Footer={this.renderFooter}
        classes={classes}
      />
    )
  }
}

MaterialForgotPassword.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'contained']),
  customAuthData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

MaterialForgotPassword.defaultProps = {
  variant: 'outlined',
  customAuthData: undefined,
}

export default withMaterialStyles(MaterialForgotPassword)
