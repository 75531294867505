/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBrandType = /* GraphQL */ `
  mutation CreateBrandType(
    $input: CreateBrandTypeInput!
    $condition: ModelBrandTypeConditionInput
  ) {
    createBrandType(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateBrandType = /* GraphQL */ `
  mutation UpdateBrandType(
    $input: UpdateBrandTypeInput!
    $condition: ModelBrandTypeConditionInput
  ) {
    updateBrandType(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrandType = /* GraphQL */ `
  mutation DeleteBrandType(
    $input: DeleteBrandTypeInput!
    $condition: ModelBrandTypeConditionInput
  ) {
    deleteBrandType(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClassTitle = /* GraphQL */ `
  mutation CreateClassTitle(
    $input: CreateClassTitleInput!
    $condition: ModelClassTitleConditionInput
  ) {
    createClassTitle(input: $input, condition: $condition) {
      id
      name
      description
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      items {
        items {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateClassTitle = /* GraphQL */ `
  mutation UpdateClassTitle(
    $input: UpdateClassTitleInput!
    $condition: ModelClassTitleConditionInput
  ) {
    updateClassTitle(input: $input, condition: $condition) {
      id
      name
      description
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      items {
        items {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteClassTitle = /* GraphQL */ `
  mutation DeleteClassTitle(
    $input: DeleteClassTitleInput!
    $condition: ModelClassTitleConditionInput
  ) {
    deleteClassTitle(input: $input, condition: $condition) {
      id
      name
      description
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      items {
        items {
          id
          name
          baseNumber
          classID
          status
          _score
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createClassProductOrService = /* GraphQL */ `
  mutation CreateClassProductOrService(
    $input: CreateClassProductOrServiceInput!
    $condition: ModelClassProductOrServiceConditionInput
  ) {
    createClassProductOrService(input: $input, condition: $condition) {
      id
      name
      baseNumber
      classID
      status
      _score
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      class {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateClassProductOrService = /* GraphQL */ `
  mutation UpdateClassProductOrService(
    $input: UpdateClassProductOrServiceInput!
    $condition: ModelClassProductOrServiceConditionInput
  ) {
    updateClassProductOrService(input: $input, condition: $condition) {
      id
      name
      baseNumber
      classID
      status
      _score
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      class {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteClassProductOrService = /* GraphQL */ `
  mutation DeleteClassProductOrService(
    $input: DeleteClassProductOrServiceInput!
    $condition: ModelClassProductOrServiceConditionInput
  ) {
    deleteClassProductOrService(input: $input, condition: $condition) {
      id
      name
      baseNumber
      classID
      status
      _score
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      class {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createFee = /* GraphQL */ `
  mutation CreateFee(
    $input: CreateFeeInput!
    $condition: ModelFeeConditionInput
  ) {
    createFee(input: $input, condition: $condition) {
      id
      amount
      concept
      type
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFee = /* GraphQL */ `
  mutation UpdateFee(
    $input: UpdateFeeInput!
    $condition: ModelFeeConditionInput
  ) {
    updateFee(input: $input, condition: $condition) {
      id
      amount
      concept
      type
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFee = /* GraphQL */ `
  mutation DeleteFee(
    $input: DeleteFeeInput!
    $condition: ModelFeeConditionInput
  ) {
    deleteFee(input: $input, condition: $condition) {
      id
      amount
      concept
      type
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTrademarkPreApplicationAnonymous = /* GraphQL */ `
  mutation CreateTrademarkPreApplicationAnonymous(
    $input: CreateTrademarkPreApplicationAnonymousInput!
    $condition: ModelTrademarkPreApplicationAnonymousConditionInput
  ) {
    createTrademarkPreApplicationAnonymous(
      input: $input
      condition: $condition
    ) {
      id
      name
      email
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      applicantName
      applicantSurname
      applicantSecondSurname
      applicantKind
      countryCallingCode
      phoneNumber
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productsServices {
        items {
          id
          productServiceID
          trademarkPreApplicationAnonymousID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateTrademarkPreApplicationAnonymous = /* GraphQL */ `
  mutation UpdateTrademarkPreApplicationAnonymous(
    $input: UpdateTrademarkPreApplicationAnonymousInput!
    $condition: ModelTrademarkPreApplicationAnonymousConditionInput
  ) {
    updateTrademarkPreApplicationAnonymous(
      input: $input
      condition: $condition
    ) {
      id
      name
      email
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      applicantName
      applicantSurname
      applicantSecondSurname
      applicantKind
      countryCallingCode
      phoneNumber
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productsServices {
        items {
          id
          productServiceID
          trademarkPreApplicationAnonymousID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteTrademarkPreApplicationAnonymous = /* GraphQL */ `
  mutation DeleteTrademarkPreApplicationAnonymous(
    $input: DeleteTrademarkPreApplicationAnonymousInput!
    $condition: ModelTrademarkPreApplicationAnonymousConditionInput
  ) {
    deleteTrademarkPreApplicationAnonymous(
      input: $input
      condition: $condition
    ) {
      id
      name
      email
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      applicantName
      applicantSurname
      applicantSecondSurname
      applicantKind
      countryCallingCode
      phoneNumber
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productsServices {
        items {
          id
          productServiceID
          trademarkPreApplicationAnonymousID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createTrademarkPreApplication = /* GraphQL */ `
  mutation CreateTrademarkPreApplication(
    $input: CreateTrademarkPreApplicationInput!
    $condition: ModelTrademarkPreApplicationConditionInput
  ) {
    createTrademarkPreApplication(input: $input, condition: $condition) {
      id
      name
      email
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      applicantName
      applicantSurname
      applicantSecondSurname
      applicantKind
      countryCallingCode
      phoneNumber
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productsServices {
        items {
          id
          productServiceID
          trademarkPreApplicationID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const updateTrademarkPreApplication = /* GraphQL */ `
  mutation UpdateTrademarkPreApplication(
    $input: UpdateTrademarkPreApplicationInput!
    $condition: ModelTrademarkPreApplicationConditionInput
  ) {
    updateTrademarkPreApplication(input: $input, condition: $condition) {
      id
      name
      email
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      applicantName
      applicantSurname
      applicantSecondSurname
      applicantKind
      countryCallingCode
      phoneNumber
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productsServices {
        items {
          id
          productServiceID
          trademarkPreApplicationID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const deleteTrademarkPreApplication = /* GraphQL */ `
  mutation DeleteTrademarkPreApplication(
    $input: DeleteTrademarkPreApplicationInput!
    $condition: ModelTrademarkPreApplicationConditionInput
  ) {
    deleteTrademarkPreApplication(input: $input, condition: $condition) {
      id
      name
      email
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      applicantName
      applicantSurname
      applicantSecondSurname
      applicantKind
      countryCallingCode
      phoneNumber
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      productsServices {
        items {
          id
          productServiceID
          trademarkPreApplicationID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const createTrademarkApplicationAnonymousPs = /* GraphQL */ `
  mutation CreateTrademarkApplicationAnonymousPs(
    $input: CreateTrademarkApplicationAnonymousPSInput!
    $condition: ModelTrademarkApplicationAnonymousPSConditionInput
  ) {
    createTrademarkApplicationAnonymousPS(
      input: $input
      condition: $condition
    ) {
      id
      productServiceID
      trademarkPreApplicationAnonymousID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      trademarkPreApplicationAnonymous {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateTrademarkApplicationAnonymousPs = /* GraphQL */ `
  mutation UpdateTrademarkApplicationAnonymousPs(
    $input: UpdateTrademarkApplicationAnonymousPSInput!
    $condition: ModelTrademarkApplicationAnonymousPSConditionInput
  ) {
    updateTrademarkApplicationAnonymousPS(
      input: $input
      condition: $condition
    ) {
      id
      productServiceID
      trademarkPreApplicationAnonymousID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      trademarkPreApplicationAnonymous {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteTrademarkApplicationAnonymousPs = /* GraphQL */ `
  mutation DeleteTrademarkApplicationAnonymousPs(
    $input: DeleteTrademarkApplicationAnonymousPSInput!
    $condition: ModelTrademarkApplicationAnonymousPSConditionInput
  ) {
    deleteTrademarkApplicationAnonymousPS(
      input: $input
      condition: $condition
    ) {
      id
      productServiceID
      trademarkPreApplicationAnonymousID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      trademarkPreApplicationAnonymous {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createTrademarkApplicationPs = /* GraphQL */ `
  mutation CreateTrademarkApplicationPs(
    $input: CreateTrademarkApplicationPSInput!
    $condition: ModelTrademarkApplicationPSConditionInput
  ) {
    createTrademarkApplicationPS(input: $input, condition: $condition) {
      id
      productServiceID
      trademarkPreApplicationID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      trademarkPreApplication {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const updateTrademarkApplicationPs = /* GraphQL */ `
  mutation UpdateTrademarkApplicationPs(
    $input: UpdateTrademarkApplicationPSInput!
    $condition: ModelTrademarkApplicationPSConditionInput
  ) {
    updateTrademarkApplicationPS(input: $input, condition: $condition) {
      id
      productServiceID
      trademarkPreApplicationID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      trademarkPreApplication {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const deleteTrademarkApplicationPs = /* GraphQL */ `
  mutation DeleteTrademarkApplicationPs(
    $input: DeleteTrademarkApplicationPSInput!
    $condition: ModelTrademarkApplicationPSConditionInput
  ) {
    deleteTrademarkApplicationPS(input: $input, condition: $condition) {
      id
      productServiceID
      trademarkPreApplicationID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      trademarkPreApplication {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const createTrademarkApplication = /* GraphQL */ `
  mutation CreateTrademarkApplication(
    $input: CreateTrademarkApplicationInput!
    $condition: ModelTrademarkApplicationConditionInput
  ) {
    createTrademarkApplication(input: $input, condition: $condition) {
      id
      brandDataID
      trademarkPreApplicationID
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      trademarkPreApplication {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandData {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandClasses {
        items {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const updateTrademarkApplication = /* GraphQL */ `
  mutation UpdateTrademarkApplication(
    $input: UpdateTrademarkApplicationInput!
    $condition: ModelTrademarkApplicationConditionInput
  ) {
    updateTrademarkApplication(input: $input, condition: $condition) {
      id
      brandDataID
      trademarkPreApplicationID
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      trademarkPreApplication {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandData {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandClasses {
        items {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const deleteTrademarkApplication = /* GraphQL */ `
  mutation DeleteTrademarkApplication(
    $input: DeleteTrademarkApplicationInput!
    $condition: ModelTrademarkApplicationConditionInput
  ) {
    deleteTrademarkApplication(input: $input, condition: $condition) {
      id
      brandDataID
      trademarkPreApplicationID
      paymentTransactionID
      trademarkApplicationStatus
      trademarkApplicationStage
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      trademarkPreApplication {
        id
        name
        email
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        applicantName
        applicantSurname
        applicantSecondSurname
        applicantKind
        countryCallingCode
        phoneNumber
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandData {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandClasses {
        items {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const createBrandData = /* GraphQL */ `
  mutation CreateBrandData(
    $input: CreateBrandDataInput!
    $condition: ModelBrandDataConditionInput
  ) {
    createBrandData(input: $input, condition: $condition) {
      id
      name
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      hasTransliteration
      transliteration
      hasTranslation
      translation
      hasBeenUsed
      dateOfInitialUse
      applicantID
      sameAddressForEstablishment
      establishmentDomicile {
        country
        postalCode
        state
        county
        suburb
        street
        externalNumber
        internalNumber
      }
      mustBill
      invoiceforApplicant
      applicantBillingID
      sameAddressForBilling
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      brandClasses {
        items {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      applicant {
        id
        name
        surname
        secondSurname
        kind
        rfc
        nationality
        countryCallingCode
        phoneNumber
        extension
        domicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        email
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brands {
          nextToken
          startedAt
        }
        invoicedBrands {
          nextToken
          startedAt
        }
      }
      applicantBilling {
        id
        name
        surname
        secondSurname
        kind
        rfc
        nationality
        countryCallingCode
        phoneNumber
        extension
        domicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        email
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brands {
          nextToken
          startedAt
        }
        invoicedBrands {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateBrandData = /* GraphQL */ `
  mutation UpdateBrandData(
    $input: UpdateBrandDataInput!
    $condition: ModelBrandDataConditionInput
  ) {
    updateBrandData(input: $input, condition: $condition) {
      id
      name
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      hasTransliteration
      transliteration
      hasTranslation
      translation
      hasBeenUsed
      dateOfInitialUse
      applicantID
      sameAddressForEstablishment
      establishmentDomicile {
        country
        postalCode
        state
        county
        suburb
        street
        externalNumber
        internalNumber
      }
      mustBill
      invoiceforApplicant
      applicantBillingID
      sameAddressForBilling
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      brandClasses {
        items {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      applicant {
        id
        name
        surname
        secondSurname
        kind
        rfc
        nationality
        countryCallingCode
        phoneNumber
        extension
        domicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        email
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brands {
          nextToken
          startedAt
        }
        invoicedBrands {
          nextToken
          startedAt
        }
      }
      applicantBilling {
        id
        name
        surname
        secondSurname
        kind
        rfc
        nationality
        countryCallingCode
        phoneNumber
        extension
        domicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        email
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brands {
          nextToken
          startedAt
        }
        invoicedBrands {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteBrandData = /* GraphQL */ `
  mutation DeleteBrandData(
    $input: DeleteBrandDataInput!
    $condition: ModelBrandDataConditionInput
  ) {
    deleteBrandData(input: $input, condition: $condition) {
      id
      name
      typeID
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      hasTransliteration
      transliteration
      hasTranslation
      translation
      hasBeenUsed
      dateOfInitialUse
      applicantID
      sameAddressForEstablishment
      establishmentDomicile {
        country
        postalCode
        state
        county
        suburb
        street
        externalNumber
        internalNumber
      }
      mustBill
      invoiceforApplicant
      applicantBillingID
      sameAddressForBilling
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      type {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      brandClasses {
        items {
          id
          brandName
          applicantName
          applicantSurname
          applicantSecondSurname
          typeID
          brandDataID
          classID
          trademarkApplicationID
          hasBeenRegisteredAbroad
          registrationDateAbroad
          registrationNumberAbroad
          countryOfRegistrationAbroad
          brandStage
          brandStatus
          trademarkStage
          requestNumber
          certificate
          _score
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      applicant {
        id
        name
        surname
        secondSurname
        kind
        rfc
        nationality
        countryCallingCode
        phoneNumber
        extension
        domicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        email
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brands {
          nextToken
          startedAt
        }
        invoicedBrands {
          nextToken
          startedAt
        }
      }
      applicantBilling {
        id
        name
        surname
        secondSurname
        kind
        rfc
        nationality
        countryCallingCode
        phoneNumber
        extension
        domicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        email
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brands {
          nextToken
          startedAt
        }
        invoicedBrands {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      brandName
      applicantName
      applicantSurname
      applicantSecondSurname
      typeID
      brandDataID
      classID
      trademarkApplicationID
      hasBeenRegisteredAbroad
      registrationDateAbroad
      registrationNumberAbroad
      countryOfRegistrationAbroad
      brandStage
      brandStatus
      trademarkStage
      requestNumber
      certificate
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      _score
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      class {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
      trademarkApplication {
        id
        brandDataID
        trademarkPreApplicationID
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        trademarkPreApplication {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandData {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      productsServices {
        items {
          id
          productServiceID
          brandID
          owner
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentRequests {
        items {
          id
          brandID
          paymentTransactionID
          concept
          description
          amount
          impiRights
          status
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notifications {
        items {
          id
          title
          body
          brandID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      brandName
      applicantName
      applicantSurname
      applicantSecondSurname
      typeID
      brandDataID
      classID
      trademarkApplicationID
      hasBeenRegisteredAbroad
      registrationDateAbroad
      registrationNumberAbroad
      countryOfRegistrationAbroad
      brandStage
      brandStatus
      trademarkStage
      requestNumber
      certificate
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      _score
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      class {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
      trademarkApplication {
        id
        brandDataID
        trademarkPreApplicationID
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        trademarkPreApplication {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandData {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      productsServices {
        items {
          id
          productServiceID
          brandID
          owner
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentRequests {
        items {
          id
          brandID
          paymentTransactionID
          concept
          description
          amount
          impiRights
          status
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notifications {
        items {
          id
          title
          body
          brandID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      brandName
      applicantName
      applicantSurname
      applicantSecondSurname
      typeID
      brandDataID
      classID
      trademarkApplicationID
      hasBeenRegisteredAbroad
      registrationDateAbroad
      registrationNumberAbroad
      countryOfRegistrationAbroad
      brandStage
      brandStatus
      trademarkStage
      requestNumber
      certificate
      files {
        bucket
        region
        key
        name
        level
        identityID
      }
      _score
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      class {
        id
        name
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        items {
          nextToken
          startedAt
        }
      }
      trademarkApplication {
        id
        brandDataID
        trademarkPreApplicationID
        paymentTransactionID
        trademarkApplicationStatus
        trademarkApplicationStage
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        trademarkPreApplication {
          id
          name
          email
          typeID
          applicantName
          applicantSurname
          applicantSecondSurname
          applicantKind
          countryCallingCode
          phoneNumber
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        paymentTransaction {
          id
          status
          transactionType
          chargeReferenceID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brandData {
        id
        name
        typeID
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        hasTransliteration
        transliteration
        hasTranslation
        translation
        hasBeenUsed
        dateOfInitialUse
        applicantID
        sameAddressForEstablishment
        establishmentDomicile {
          country
          postalCode
          state
          county
          suburb
          street
          externalNumber
          internalNumber
        }
        mustBill
        invoiceforApplicant
        applicantBillingID
        sameAddressForBilling
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        type {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        brandClasses {
          nextToken
          startedAt
        }
        applicant {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        applicantBilling {
          id
          name
          surname
          secondSurname
          kind
          rfc
          nationality
          countryCallingCode
          phoneNumber
          extension
          email
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      productsServices {
        items {
          id
          productServiceID
          brandID
          owner
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentRequests {
        items {
          id
          brandID
          paymentTransactionID
          concept
          description
          amount
          impiRights
          status
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notifications {
        items {
          id
          title
          body
          brandID
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createBrandProductsServices = /* GraphQL */ `
  mutation CreateBrandProductsServices(
    $input: CreateBrandProductsServicesInput!
    $condition: ModelBrandProductsServicesConditionInput
  ) {
    createBrandProductsServices(input: $input, condition: $condition) {
      id
      productServiceID
      brandID
      owner
      createdAt
      updatedAt
      deleted
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateBrandProductsServices = /* GraphQL */ `
  mutation UpdateBrandProductsServices(
    $input: UpdateBrandProductsServicesInput!
    $condition: ModelBrandProductsServicesConditionInput
  ) {
    updateBrandProductsServices(input: $input, condition: $condition) {
      id
      productServiceID
      brandID
      owner
      createdAt
      updatedAt
      deleted
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteBrandProductsServices = /* GraphQL */ `
  mutation DeleteBrandProductsServices(
    $input: DeleteBrandProductsServicesInput!
    $condition: ModelBrandProductsServicesConditionInput
  ) {
    deleteBrandProductsServices(input: $input, condition: $condition) {
      id
      productServiceID
      brandID
      owner
      createdAt
      updatedAt
      deleted
      _version
      _deleted
      _lastChangedAt
      productService {
        id
        name
        baseNumber
        classID
        status
        _score
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createApplicant = /* GraphQL */ `
  mutation CreateApplicant(
    $input: CreateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    createApplicant(input: $input, condition: $condition) {
      id
      name
      surname
      secondSurname
      kind
      rfc
      nationality
      countryCallingCode
      phoneNumber
      extension
      domicile {
        country
        postalCode
        state
        county
        suburb
        street
        externalNumber
        internalNumber
      }
      email
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brands {
        items {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      invoicedBrands {
        items {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateApplicant = /* GraphQL */ `
  mutation UpdateApplicant(
    $input: UpdateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    updateApplicant(input: $input, condition: $condition) {
      id
      name
      surname
      secondSurname
      kind
      rfc
      nationality
      countryCallingCode
      phoneNumber
      extension
      domicile {
        country
        postalCode
        state
        county
        suburb
        street
        externalNumber
        internalNumber
      }
      email
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brands {
        items {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      invoicedBrands {
        items {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteApplicant = /* GraphQL */ `
  mutation DeleteApplicant(
    $input: DeleteApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    deleteApplicant(input: $input, condition: $condition) {
      id
      name
      surname
      secondSurname
      kind
      rfc
      nationality
      countryCallingCode
      phoneNumber
      extension
      domicile {
        country
        postalCode
        state
        county
        suburb
        street
        externalNumber
        internalNumber
      }
      email
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brands {
        items {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      invoicedBrands {
        items {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createPaymentTransaction = /* GraphQL */ `
  mutation CreatePaymentTransaction(
    $input: CreatePaymentTransactionInput!
    $condition: ModelPaymentTransactionConditionInput
  ) {
    createPaymentTransaction(input: $input, condition: $condition) {
      id
      status
      transactionType
      chargeReferenceID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chargeReference {
        id
        openpayChargeID
        paymentTransactionID
        orderID
        amount
        reference
        method
        barcodeUrl
        documentUrl
        dueDate
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const updatePaymentTransaction = /* GraphQL */ `
  mutation UpdatePaymentTransaction(
    $input: UpdatePaymentTransactionInput!
    $condition: ModelPaymentTransactionConditionInput
  ) {
    updatePaymentTransaction(input: $input, condition: $condition) {
      id
      status
      transactionType
      chargeReferenceID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chargeReference {
        id
        openpayChargeID
        paymentTransactionID
        orderID
        amount
        reference
        method
        barcodeUrl
        documentUrl
        dueDate
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const deletePaymentTransaction = /* GraphQL */ `
  mutation DeletePaymentTransaction(
    $input: DeletePaymentTransactionInput!
    $condition: ModelPaymentTransactionConditionInput
  ) {
    deletePaymentTransaction(input: $input, condition: $condition) {
      id
      status
      transactionType
      chargeReferenceID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chargeReference {
        id
        openpayChargeID
        paymentTransactionID
        orderID
        amount
        reference
        method
        barcodeUrl
        documentUrl
        dueDate
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const createOpenPayChargeReference = /* GraphQL */ `
  mutation CreateOpenPayChargeReference(
    $input: CreateOpenPayChargeReferenceInput!
    $condition: ModelOpenPayChargeReferenceConditionInput
  ) {
    createOpenPayChargeReference(input: $input, condition: $condition) {
      id
      openpayChargeID
      paymentTransactionID
      orderID
      amount
      reference
      method
      barcodeUrl
      documentUrl
      dueDate
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOpenPayChargeReference = /* GraphQL */ `
  mutation UpdateOpenPayChargeReference(
    $input: UpdateOpenPayChargeReferenceInput!
    $condition: ModelOpenPayChargeReferenceConditionInput
  ) {
    updateOpenPayChargeReference(input: $input, condition: $condition) {
      id
      openpayChargeID
      paymentTransactionID
      orderID
      amount
      reference
      method
      barcodeUrl
      documentUrl
      dueDate
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOpenPayChargeReference = /* GraphQL */ `
  mutation DeleteOpenPayChargeReference(
    $input: DeleteOpenPayChargeReferenceInput!
    $condition: ModelOpenPayChargeReferenceConditionInput
  ) {
    deleteOpenPayChargeReference(input: $input, condition: $condition) {
      id
      openpayChargeID
      paymentTransactionID
      orderID
      amount
      reference
      method
      barcodeUrl
      documentUrl
      dueDate
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPaymentRequest = /* GraphQL */ `
  mutation CreatePaymentRequest(
    $input: CreatePaymentRequestInput!
    $condition: ModelPaymentRequestConditionInput
  ) {
    createPaymentRequest(input: $input, condition: $condition) {
      id
      brandID
      paymentTransactionID
      concept
      description
      amount
      impiRights
      status
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      Brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const updatePaymentRequest = /* GraphQL */ `
  mutation UpdatePaymentRequest(
    $input: UpdatePaymentRequestInput!
    $condition: ModelPaymentRequestConditionInput
  ) {
    updatePaymentRequest(input: $input, condition: $condition) {
      id
      brandID
      paymentTransactionID
      concept
      description
      amount
      impiRights
      status
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      Brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const deletePaymentRequest = /* GraphQL */ `
  mutation DeletePaymentRequest(
    $input: DeletePaymentRequestInput!
    $condition: ModelPaymentRequestConditionInput
  ) {
    deletePaymentRequest(input: $input, condition: $condition) {
      id
      brandID
      paymentTransactionID
      concept
      description
      amount
      impiRights
      status
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      Brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      paymentTransaction {
        id
        status
        transactionType
        chargeReferenceID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chargeReference {
          id
          openpayChargeID
          paymentTransactionID
          orderID
          amount
          reference
          method
          barcodeUrl
          documentUrl
          dueDate
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const createOpenPayWebhookNotification = /* GraphQL */ `
  mutation CreateOpenPayWebhookNotification(
    $input: CreateOpenPayWebhookNotificationInput!
    $condition: ModelOpenPayWebhookNotificationConditionInput
  ) {
    createOpenPayWebhookNotification(input: $input, condition: $condition) {
      id
      type
      verification_code
      transaction {
        id
        creation_date
        amount
        status
        description
        conciliated
        transaction_type
        error_message
        card {
          id
          type
          brand
          card_number
          holder_name
          expiration_month
          expiration_year
          allow_charges
          allow_payouts
          creation_date
          bank_name
          bank_code
        }
        method
        authorization
        order_id
        operation_type
        operation_date
        customer_id
        due_date
        currency
      }
      event_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOpenPayWebhookNotification = /* GraphQL */ `
  mutation UpdateOpenPayWebhookNotification(
    $input: UpdateOpenPayWebhookNotificationInput!
    $condition: ModelOpenPayWebhookNotificationConditionInput
  ) {
    updateOpenPayWebhookNotification(input: $input, condition: $condition) {
      id
      type
      verification_code
      transaction {
        id
        creation_date
        amount
        status
        description
        conciliated
        transaction_type
        error_message
        card {
          id
          type
          brand
          card_number
          holder_name
          expiration_month
          expiration_year
          allow_charges
          allow_payouts
          creation_date
          bank_name
          bank_code
        }
        method
        authorization
        order_id
        operation_type
        operation_date
        customer_id
        due_date
        currency
      }
      event_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOpenPayWebhookNotification = /* GraphQL */ `
  mutation DeleteOpenPayWebhookNotification(
    $input: DeleteOpenPayWebhookNotificationInput!
    $condition: ModelOpenPayWebhookNotificationConditionInput
  ) {
    deleteOpenPayWebhookNotification(input: $input, condition: $condition) {
      id
      type
      verification_code
      transaction {
        id
        creation_date
        amount
        status
        description
        conciliated
        transaction_type
        error_message
        card {
          id
          type
          brand
          card_number
          holder_name
          expiration_month
          expiration_year
          allow_charges
          allow_payouts
          creation_date
          bank_name
          bank_code
        }
        method
        authorization
        order_id
        operation_type
        operation_date
        customer_id
        due_date
        currency
      }
      event_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      title
      body
      brandID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      title
      body
      brandID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      title
      body
      brandID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      type
      brandID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      type
      brandID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      type
      brandID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        brandName
        applicantName
        applicantSurname
        applicantSecondSurname
        typeID
        brandDataID
        classID
        trademarkApplicationID
        hasBeenRegisteredAbroad
        registrationDateAbroad
        registrationNumberAbroad
        countryOfRegistrationAbroad
        brandStage
        brandStatus
        trademarkStage
        requestNumber
        certificate
        files {
          bucket
          region
          key
          name
          level
          identityID
        }
        _score
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        class {
          id
          name
          description
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        trademarkApplication {
          id
          brandDataID
          trademarkPreApplicationID
          paymentTransactionID
          trademarkApplicationStatus
          trademarkApplicationStage
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        brandData {
          id
          name
          typeID
          hasTransliteration
          transliteration
          hasTranslation
          translation
          hasBeenUsed
          dateOfInitialUse
          applicantID
          sameAddressForEstablishment
          mustBill
          invoiceforApplicant
          applicantBillingID
          sameAddressForBilling
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        productsServices {
          nextToken
          startedAt
        }
        paymentRequests {
          nextToken
          startedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
