import React, { createContext, useContext, useState, useEffect } from 'react'
import { useStorageState } from 'react-storage-hooks'
import PropTypes from 'prop-types'

const LayoutContext = createContext({
  loading: false,
  setLoading: () => {},
  authenticated: false,
  setAuthenticated: () => {},
  currentUser: {
    username: undefined,
    attributes: {
      email: undefined,
      email_verified: undefined,
      sub: undefined,
    },
  },
  setCurrentUser: () => {},
  authError: undefined,
  setAuthError: () => {},
  showAuthUI: false,
  setShowAuthUI: () => {},
  showAuthHeader: false,
  setShowAuthHeader: () => {},
  authState: undefined,
  setAuthState: () => {},
  signUpAuthData: undefined,
  setSignUpAuthData: () => {},
  firstSignUp: false,
  setFirstSignUp: () => {},
  showNotifications: false,
  setShowNotifications: () => {},
  showTrademarks: false,
  setShowTrademarks: () => {},
  showTrademarkDetail: false,
  setShowTrademarkDetail: () => {},
  orientation: {
    orientation: undefined,
    type: undefined,
    angle: undefined,
  },
  windowSize: {
    width: undefined,
    height: undefined,
  },
})

export const LayoutContextProvider = LayoutContext.Provider
export const LayoutContextConsumer = LayoutContext.Consumer

LayoutContext.displayName = 'LayoutContext'

export const useLayoutContext = () => {
  const context = useContext(LayoutContext)
  if (!context) {
    throw new Error(`Context cannot be used outside the Provider`)
  }
  return context
}

export const LayoutConsumer = ({ children }) => {
  return (
    <>
      {typeof children === 'function' ? (
        <LayoutContextConsumer>
          {(ctx) => {
            if (ctx === undefined) {
              throw new Error(
                'LayoutContextConsumer must be used within a LayoutConsumer'
              )
            }
            return children({ ...ctx })
          }}
        </LayoutContextConsumer>
      ) : (
        children
      )}
    </>
  )
}

LayoutConsumer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
}

LayoutConsumer.defaultProps = {
  children: undefined,
}

export const LayoutProvider = ({ children }) => {
  const [firstSignUpStorage, setFirstSignUpStorage] = useStorageState(
    localStorage,
    'firstSignUp',
    true
  )
  const [authenticatedStorage, setAuthenticatedStorage] = useStorageState(
    localStorage,
    'authenticated',
    true
  )
  const [loading, setLoading] = useState(false)
  const [authenticated, setAuthenticated] = useState(authenticatedStorage)
  const [currentUser, setCurrentUser] = useState(null)
  const [authError, setAuthError] = useState(null)
  const [showAuthUI, setShowAuthUI] = useState(false)
  const [showAuthHeader, setShowAuthHeader] = useState(false)
  const [authState, setAuthState] = useState('signIn')
  const [signUpAuthData, setSignUpAuthData] = useState({})
  const [firstSignUp, setFirstSignUp] = useState(firstSignUpStorage)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showTrademarks, setShowTrademarks] = useState(false)
  const [showTrademarkDetail, setShowTrademarkDetail] = useState(false)

  useEffect(() => {
    setFirstSignUpStorage(firstSignUp)
  }, [firstSignUp, setFirstSignUpStorage])

  useEffect(() => {
    setAuthenticatedStorage(authenticated)
  }, [authenticated, setAuthenticatedStorage])

  const [orientation, setOrientation] = useState({
    orientation: undefined,
    type: undefined,
    angle: undefined,
  })
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  return (
    <LayoutContextProvider
      value={{
        loading,
        setLoading,
        authenticated,
        setAuthenticated,
        currentUser,
        setCurrentUser,
        authError,
        setAuthError,
        showAuthUI,
        setShowAuthUI,
        showAuthHeader,
        setShowAuthHeader,
        authState,
        setAuthState,
        signUpAuthData,
        setSignUpAuthData,
        firstSignUp,
        setFirstSignUp,
        showNotifications,
        setShowNotifications,
        showTrademarks,
        setShowTrademarks,
        showTrademarkDetail,
        setShowTrademarkDetail,
        orientation,
        setOrientation,
        windowSize,
        setWindowSize,
      }}>
      {children}
    </LayoutContextProvider>
  )
}

LayoutProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

LayoutProvider.defaultProps = {
  children: undefined,
}

export const LayoutContextWrapper = (props) => {
  const { children } = props
  return <LayoutProvider>{children}</LayoutProvider>
}

LayoutContextWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

LayoutContextWrapper.defaultProps = {
  children: undefined,
}

export const withLayoutContext = (Component) => {
  const innerWithLayoutContext = (props) => {
    return (
      <LayoutContextWrapper>
        <Component {...props} />
      </LayoutContextWrapper>
    )
  }
  innerWithLayoutContext.propTypes = {}
  return innerWithLayoutContext
}
