import React from 'react'
/* eslint-disable-next-line no-unused-vars */
import {
  Dialog,
  IconButton,
  Typography,
  withStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

/* eslint-disable-next-line no-unused-vars */
const BrandActionModal = (props) => {
  const {
    title,
    content,
    enabled,
    closeHandler,
    /* eslint-disable-next-line no-unused-vars */
    notificationsEnabled,
    /* eslint-disable-next-line no-unused-vars */
    brand,
  } = props
  const [open, setOpen] = React.useState(enabled)
  const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('xs'))

  const handleClose = () => {
    closeHandler(false)
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        fullScreen={isMobileDevice}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableBackdropClick
        disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
          {React.cloneElement(content, { ...props })}
        </DialogContent>
      </Dialog>
    </div>
  )
}

BrandActionModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  enabled: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  notificationsEnabled: PropTypes.bool,
  brand: PropTypes.shape({}),
}

BrandActionModal.defaultProps = {
  enabled: false,
  notificationsEnabled: false,
  brand: undefined,
}

export default BrandActionModal
