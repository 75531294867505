import { API } from 'aws-amplify'
import Debug from 'debug'
import { getTrademarkApplication } from '../../graphql/custom-queries'
import { updateTrademarkApplication } from '../../graphql/mutations'
import { moveBrandsToStage } from './brand'

const debug = Debug('services:registration:trademarkApp')

// https://github.com/aws-amplify/amplify-cli/issues/1576#issuecomment-635128542
const PUBLIC_AUTH_MODE = 'AWS_IAM'
const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const readTrademarkApplication = async ({ id, authenticated }) => {
  try {
    const { data } = await API.graphql({
      query: getTrademarkApplication,
      variables: {
        id,
      },
      authMode: authenticated ? PRIVATE_AUTH_MODE : PUBLIC_AUTH_MODE,
    })
    if (data) return data?.getTrademarkApplication
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const putTrademarkApplication = async ({
  id,
  trademarkApplicationStatus,
  trademarkApplicationStage,
  _version,
  authenticated,
}) => {
  try {
    const item = {
      id,
      trademarkApplicationStatus,
      trademarkApplicationStage,
      _version,
    }
    const { data } = await API.graphql({
      query: updateTrademarkApplication,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: authenticated ? PRIVATE_AUTH_MODE : PUBLIC_AUTH_MODE,
    })
    if (data) return data?.updateTrademarkApplication
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const moveTrademarkToStage = async ({
  trademarkApp,
  trademarkApplicationStage,
  includeBrands,
  authenticated,
}) => {
  try {
    if (includeBrands) {
      await moveBrandsToStage({
        brands: trademarkApp?.brandClasses?.items,
        trademarkStage: trademarkApplicationStage,
      })
    }
    const updatedTrademarkApp = await putTrademarkApplication({
      id: trademarkApp?.id,
      trademarkApplicationStage,
      // eslint-disable-next-line no-underscore-dangle
      _version: trademarkApp?._version,
      authenticated,
    })
    const trademarkAppReaded = await readTrademarkApplication({
      id: updatedTrademarkApp?.id,
      authenticated,
    })
    return trademarkAppReaded
  } catch (e) {
    debug('e', e)
    return null
  }
}
