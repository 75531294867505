import React from 'react'
import { Button, Container, Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
/* eslint-disable-next-line no-unused-vars */
import { FormField, Title, LoaderOverlay, NumberFormatCustom } from '../common'
import {
  generatePaymentRequest,
  generateNotification,
  sendEmail,
} from '../../services'
import {
  concepts,
  notifications,
  actionEmailSubjects,
  actionEmailTemplates,
  REACT_APP_NOREPLY_EMAIL_ADDRESS,
} from '../../config'
import { useLayoutContext } from '../context'

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(2),
  },
  divider: {
    height: theme.spacing(3),
  },
  button: {
    width: '100%',
  },
}))

const BrandPaymentRequestModal = (props) => {
  const { closeHandler, notificationsEnabled, brand, updateBrand } = props
  const { handleSubmit, control, errors } = useForm({})
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { loading, setLoading } = useLayoutContext()

  /* eslint-disable-next-line consistent-return */
  const createPaymentRequest = async (
    concept,
    description,
    amount,
    impiRights
    /* eslint-disable-next-line consistent-return */
  ) => {
    try {
      return await generatePaymentRequest(
        brand?.id,
        '-', // brand?.trademarkApplication?.paymentTransactionID,
        concept,
        description,
        amount,
        impiRights,
        brand?.owner
      )
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al generar la orden de pago', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
  }

  const handleEmailSend = async (from, to, subject, message) => {
    try {
      await sendEmail(from, to, subject, message)
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al enviar el correo', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
  }

  /* eslint-disable-next-line consistent-return */
  const sendNotification = async (paymentRequestID) => {
    try {
      const notification = notifications.paymentRequestCreated(
        brand?.brandData?.name,
        brand?.id,
        paymentRequestID,
        brand?.class?.name
      )
      const notificationTitle = notification.title
      const notificationBody = notification.body
      // eslint-disable-next-line no-unused-vars
      const sendNotificationResponse = await generateNotification(
        brand?.id,
        notificationTitle,
        notificationBody,
        brand?.brandData?.owner
      )
    } catch (error) {
      return error
    }
  }

  /* eslint-disable-next-line consistent-return */
  const onSubmit = async (data) => {
    setLoading(true)
    let paymentRequest = null
    try {
      paymentRequest = await createPaymentRequest(
        concepts.professionalServices.value,
        data.description,
        data.amount,
        data.impiRights
      )
      enqueueSnackbar('¡La orden de pago fue generada exitosamente!', {
        preventDuplicate: true,
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al generar la orden de pago', {
        preventDuplicate: true,
        variant: 'error',
      })
    }

    try {
      await handleEmailSend(
        REACT_APP_NOREPLY_EMAIL_ADDRESS,
        brand?.owner,
        actionEmailSubjects.trademarkPaymentRequest,
        actionEmailTemplates.trademarkPaymentRequest(
          brand?.id,
          brand?.brandData?.name,
          brand?.class?.name
        )
      )
      enqueueSnackbar(
        'Se envió un correo para notificar al usuario de esta acción',
        {
          preventDuplicate: true,
          variant: 'success',
        }
      )
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al envíar un correo de notificación', {
        preventDuplicate: true,
        variant: 'error',
      })
    }

    if (notificationsEnabled) {
      try {
        await sendNotification(paymentRequest?.id)
      } catch (error) {
        enqueueSnackbar('Ocurrió un error al crear la notificación', {
          preventDuplicate: true,
          variant: 'error',
        })
      }
    }
    updateBrand(brand?.id)
    closeHandler(false)
    setLoading(false)
  }

  const handleClose = () => {
    closeHandler(false)
  }

  const renderModalContent = () => {
    return (
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField
              id="concept"
              name="concept"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="Concepto"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              inputProps={{ value: concepts.professionalServices.label }}
              disabled
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <FormField
              id="description"
              name="description"
              type="text"
              required
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="Descripción"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Ingresa la descripción',
                },
              }}
              multiline
              rows="4"
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <FormField
              label="Monto"
              name="amount"
              id="amount"
              size="small"
              variant="outlined"
              fullWidth
              required
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Ingresa el monto',
                },
              }}
              errors={errors}
              inputProps={{
                prefix: '$',
                thousandSeparator: true,
                // format: '$ ###, ###, ###.##',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            {/* https://stackoverflow.com/questions/48723887/mask-textfield-component-in-material-ui/48750119 */}
            <div className={clsx(classes.divider)} />
            <FormField
              label="Derechos del IMPI"
              name="impiRights"
              id="impiRights"
              size="small"
              variant="outlined"
              fullWidth
              control={control}
              errors={errors}
              inputProps={{
                prefix: '$',
                thousandSeparator: true,
                // format: '$ ###, ###, ###.##',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                className={clsx(classes.button)}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={clsx(classes.button)}>
                Generar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Container className={clsx(classes.mainContent)}>
      <LoaderOverlay open={loading} />
      <Title>Generar Orden de Pago</Title>
      <div className={clsx(classes.divider)} />
      {renderModalContent()}
    </Container>
  )
}

BrandPaymentRequestModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  updateBrand: PropTypes.func.isRequired,
}

export default BrandPaymentRequestModal
