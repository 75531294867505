import { API } from 'aws-amplify'
import Debug from 'debug'
import _ from 'lodash'
import {
  listBrandsByOwnerAndTrademarkStage,
  getBrand,
  searchBrandsQuery,
  searchBrandsEnhancedQuery,
  listBrandsByTrademarkApplication,
} from '../../graphql/custom-queries'
import { updateBrand } from '../../graphql/mutations'
import {
  trademarkApplicationStages,
  SEARCH_OPERATOR_MATCH,
  SEARCH_OPERATOR_MATCH_PHRASE_PREFIX,
} from '../../config'

const debug = Debug('services:brands:brands')

const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const getBrandsByOwnerAndTrademarkStatus = async (owner) => {
  try {
    const input = {
      owner,
      trademarkStage: { eq: trademarkApplicationStages.completed },
    }
    const result = await API.graphql({
      query: listBrandsByOwnerAndTrademarkStage,
      variables: {
        ...input,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return result.data.listBrandsByOwnerAndTrademarkStage.items
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const getBrandById = async (id) => {
  try {
    const result = await API.graphql({
      query: getBrand,
      variables: {
        id,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return result.data.getBrand
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const searchBrands = async ({
  searchOperator,
  typeID,
  brandName,
  applicantName,
  applicantSurname,
  applicantSecondSurname,
  limit = 100,
  nextToken,
}) => {
  const textSearchFields = {
    brandName,
    applicantName,
    applicantSurname,
    applicantSecondSurname,
  }
  const textSearchFilters = _.chain(textSearchFields)
    .toPairs(textSearchFields)
    .map((k) => {
      if (!k[1]) return null
      return {
        [k[0]]: {
          [searchOperator]: k[1],
        },
      }
    })
    .filter((sv) => sv)
    .value()
  const equalSearchFields = {
    typeID,
  }
  const equalSearchFilters = _.chain(equalSearchFields)
    .toPairs(equalSearchFields)
    .map((k) => {
      if (!k[1]) return null
      return {
        [k[0]]: {
          eq: k[1],
        },
      }
    })
    .filter((sv) => sv)
    .value()

  const searchFilters = _.concat(textSearchFilters, equalSearchFilters)

  const filter =
    searchFilters && searchFilters.length > 0
      ? {
          and: searchFilters,
        }
      : undefined

  try {
    const { data } = await API.graphql({
      query: searchBrandsQuery,
      variables: {
        filter,
        limit,
        nextToken,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return data?.searchBrands ? data?.searchBrands : {}
  } catch (e) {
    debug('error', e)
    return { error: e }
  }
}

// @see https://medium.com/@tobinc/aws-amplify-graphql-with-geo-point-and-custom-resources-free-elasticsearch-provider-d1742fbc4ceb
// @see https://dev.to/tingtingjh/aws-amplify-elasticsearch-query-for-interface-union-type-with-and-or-operations-1ogp
// @see https://medium.com/@gerard.sans/finding-the-nearest-locations-around-you-using-aws-amplify-part-2-ce4603605be6
// @see https://medium.com/@gerard.sans/aws-appsync-velocity-templates-guide-55b9d2bff053
// @see https://github.com/gsans/amplify-london-cycles
// @see https://github.com/aws-amplify/amplify-cli/issues/673
// @see https://stackoverflow.com/questions/57204857/how-to-send-json-array-through-graphql-and-aws-appsync-to-add-data-to-dynamo-tab
// @see https://www.elastic.co/es/blog/starts-with-phrase-matching/
// @see https://stackoverflow.com/questions/29741641/elasticsearch-starts-with-first-word-in-phrases

export const searchBrandsEnhanced = async ({
  searchOperator,
  typeID,
  certificate,
  requestNumber,
  brandName,
  applicantName,
  applicantSurname,
  applicantSecondSurname,
  limit = 100,
  nextToken,
}) => {
  const textSearchFields = {
    certificate,
    requestNumber,
    brandName,
    applicantName,
    applicantSurname,
    applicantSecondSurname,
  }
  const textSearchFilters = _.chain(textSearchFields)
    .toPairs(textSearchFields)
    .map((k) => {
      if (!k[1]) return null
      if (searchOperator === SEARCH_OPERATOR_MATCH) {
        return {
          bool: {
            should: [
              {
                match: {
                  [k[0]]: {
                    query: k[1],
                    max_expansions: 100,
                    boost: 10,
                  },
                },
              },
              {
                match: {
                  [`${k[0]}.folded`]: {
                    query: k[1],
                    max_expansions: 100,
                    boost: 8,
                  },
                },
              },
            ],
            boost: 1,
          },
        }
      }
      if (searchOperator === SEARCH_OPERATOR_MATCH_PHRASE_PREFIX) {
        return {
          bool: {
            should: [
              {
                match_phrase_prefix: {
                  [k[0]]: {
                    query: k[1],
                    max_expansions: 100,
                    boost: 10,
                  },
                },
              },
              {
                match_phrase_prefix: {
                  [`${k[0]}.folded`]: {
                    query: k[1],
                    max_expansions: 100,
                    boost: 8,
                  },
                },
              },
              {
                match_phrase_prefix: {
                  [`${k[0]}.startswith`]: {
                    query: k[1],
                    max_expansions: 100,
                    boost: 10,
                  },
                },
              },
            ],
            boost: 1,
          },
        }
      }
      return null
    })
    .filter((sv) => sv)
    .value()
  const equalSearchFields = {
    typeID,
  }
  const equalSearchFilters = _.chain(equalSearchFields)
    .toPairs(equalSearchFields)
    .map((k) => {
      if (!k[1]) return null
      return {
        match: {
          [`${k[0]}.keyword`]: {
            query: k[1],
          },
        },
      }
    })
    .filter((sv) => sv)
    .value()

  const searchFilters = _.concat(textSearchFilters, equalSearchFilters)

  const filter =
    searchFilters && searchFilters.length > 0
      ? {
          bool: {
            must: searchFilters,
          },
        }
      : undefined

  try {
    const { data } = await API.graphql({
      query: searchBrandsEnhancedQuery,
      variables: {
        filter: JSON.stringify(filter),
        limit,
        nextToken,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return data?.searchBrandsEnhanced ? data?.searchBrandsEnhanced : {}
  } catch (e) {
    debug('error', e)
    return { error: e }
  }
}

export const getBrandsByTrademarkApplication = async (
  trademarkApplicationID
) => {
  try {
    const input = {
      trademarkApplicationID,
    }
    const { data } = await API.graphql({
      query: listBrandsByTrademarkApplication,
      variables: {
        ...input,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return data?.listBrandByTrademarkApplication
      ? data?.listBrandByTrademarkApplication.items
      : []
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const updateBrandForSearch = async ({
  id,
  brandName,
  applicantName,
  applicantSurname,
  applicantSecondSurname,
  typeID,
  _version,
}) => {
  try {
    const item = {
      id,
      brandName,
      applicantName,
      applicantSurname,
      applicantSecondSurname,
      typeID,
      _version,
    }
    const { data } = await API.graphql({
      query: updateBrand,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    if (data) return data?.updateBrand
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const updateBrandsForSearch = async ({
  brands,
  brandName,
  applicantName,
  applicantSurname,
  applicantSecondSurname,
  typeID,
}) => {
  if (!brands || brands.length === 0) return []
  const updatedBrands = brands.map(async (brand) => {
    const updatedBrand = await updateBrandForSearch({
      id: brand.id,
      // eslint-disable-next-line no-underscore-dangle
      _version: brand._version,
      brandName,
      applicantName,
      applicantSurname,
      applicantSecondSurname,
      typeID,
    })
    return updatedBrand
  })
  const all = await Promise.all(updatedBrands)
  return all
}
