import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Grid,
  Typography,
  Checkbox,
  Chip,
  ListSubheader,
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  IconButton,
  Link,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined'
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CancelIcon from '@material-ui/icons/Cancel'
import InfoIcon from '@material-ui/icons/Info'
import clsx from 'clsx'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import throttle from 'lodash/throttle'
import sortBy from 'lodash/sortBy'
import { SearchField } from '../common'
import { searchProductsAndServicesEnhanced } from '../../services'
import { brandClassColors } from '../../config'
import { withSearchFieldContext, useSearchFieldContext } from '../context'
import { useInfoModal } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  tagsArea: {
    marginTop: theme.spacing(2),
  },
  chipIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  /* Styles applied to the group's label elements. */
  groupLabel: {
    backgroundColor: theme.palette.background.paper,
    top: -8,
  },
  /* Styles applied to the group's ul elements. */
  groupUl: {
    padding: 0,
    '&': {
      paddingLeft: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
      },
    },
  },
  /* Styles applied to the chips group's label elements. */
  chipsGroupLabel: {
    backgroundColor: theme.palette.background.paper,
    top: -8,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0.5),
    },
  },
  /* Styles applied to the chips group's ul elements. */
  chipsGroupUl: {
    padding: 0,
    '&': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    },
  },
  cardsGrid: {
    padding: theme.spacing(0.2),
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  cardItem: {
    padding: theme.spacing(0.1),
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  card: {
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    minWidth: '60px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '80px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '100px',
    },
  },
  cardContent: {
    padding: theme.spacing(0.2),
    minHeight: '0.8125rem',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
    },
  },
  cardText: {
    fontSize: '0.8125rem',
    textTransform: 'none',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.2),
    minHeight: '24px',
    maxHeight: '24px',
  },
  cardActionSmall: {
    minHeight: '18px',
    maxHeight: '18px',
  },
  /* Styles applied to the `deleteIcon` element. */
  deleteIcon: {
    padding: 0,
    height: 22,
    width: 22,
  },
  /* Styles applied to the `deleteIcon` element if `size="small"`. */
  deleteIconSmall: {
    height: 16,
    width: 16,
  },
  infoText: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    textTransform: 'none',
    justifyContent: 'center',
    textAlign: 'center',
  },
}))

// https://stackoverflow.com/questions/57685230/material-ui-dynamic-rule-names-with-makestyles
const useStyleClasses = makeStyles((theme) => {
  let stylesObject = {}

  Object.entries(brandClassColors).forEach(([key, value]) => {
    stylesObject = {
      ...stylesObject,
      ...{
        [`class_${key}`]: {
          color: value,
        },
        [`classChip_${key}`]: {
          // backgroundColor: value,
          // color: theme.palette.primary.contrastText,
          // padding: theme.spacing(1),
          color: value,
          marginLeft: theme.spacing(1),
          marginBottom: theme.spacing(1),
        },
        [`classDeleteIconChip_${key}`]: {
          color: value,
        },
        [`classIconChip_${key}`]: {
          color: value,
        },
      },
    }
  })
  return stylesObject
})

/*
const useStyleClasses = (theme) => {
  let stylesObject = {}

  Object.entries(brandClassColors).forEach(([key, value]) => {
    stylesObject = {
      ...stylesObject,
      ...{
        [`class_${key}`]: {
          color: value,
        },
        [`classChip_${key}`]: {
          backgroundColor: value,
        },
      },
    }
  })
  return makeStyles(stylesObject)() // note that we execute the life
}
*/

const ProductsAndServicesSearchField = (props) => {
  const {
    id,
    required,
    disabled,
    variant,
    className,
    fullWidth,
    label,
    name,
    size,
    noOptionsText,
    GroupIcon,
    Icon,
    InputIcon,
    InfomationIcon,
    renderOption,
    renderTags,
    disableRenderTags,
    ChipProps,
    renderGroup,
    renderChipsPreviewGroup,
    renderChipOption,
    ListChipsPreviewComponent,
    ListChipsPreviewProps,
    getOptionLabel,
    getOptionSelected,
    filterOptions,
    groupBy,
    freeSolo,
    control,
    rules,
    errors,
    multiple,
    disableCloseOnSelect,
    autoComplete,
    includeInputInList,
    filterSelectedOptions,
    clearText,
    waitForFetch,
    classID,
    ctxDefined,
    authenticated,
    limit,
  } = props
  const { currentOptions } = useSearchFieldContext()
  const classes = useStyles()
  const styleClasses = useStyleClasses()
  const icon = <CheckBoxOutlineBlankIcon fontSize={size} />
  const checkedIcon = <CheckBoxIcon fontSize={size} />
  const { openModal } = useInfoModal()

  const fetch = useMemo(
    () =>
      throttle(async (request, callback) => {
        const requestWithClassID = {
          ...request,
          classID,
          authenticated,
          limit,
        }
        const data = await searchProductsAndServicesEnhanced(requestWithClassID)
        const sortedData = sortBy(data, (item) => {
          return Number(item.classID)
        })
        callback(sortedData)
      }, waitForFetch),
    [waitForFetch, classID, authenticated, limit]
  )

  const defaultRenderOption = (option, { searchValue, selected }) => {
    // https://github.com/moroshko/autosuggest-highlight
    const matches = match(option.name, searchValue)
    const parts = parse(option.name, matches)

    return (
      <Grid container alignItems="center">
        <>
          {!filterSelectedOptions ? (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          ) : null}
        </>
        <Grid item>
          <Icon
            className={clsx(
              classes.icon,
              styleClasses[`class_${option.classID}`]
            )}
          />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={`'option_'${index}`}
              style={{
                fontWeight: part.highlight ? 800 : 400,
                color: brandClassColors[option.classID],
              }}>
              {part.text}
            </span>
          ))}
          <Typography
            variant="body2"
            color="textSecondary"
            className={clsx(styleClasses[`class_${option.classID}`])}>
            {option.name}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const defaultGetOptionLabel = (option) =>
    typeof option === 'string' ? option : option.name

  const defaultGetOptionSelected = (option, selectedValue) => {
    // Only ID and Class ID
    return (
      option.id === selectedValue.id && option.classID === selectedValue.classID
    )
  }

  // const defaultGroupBy = (option) => option.class.name
  const defaultGroupBy = (option) => Number(option.classID)

  const defaultRenderTags = (selectedValue, getTagProps) => {
    return (
      <div className={classes.tagsArea}>
        {selectedValue.map((option, index) => (
          <Chip
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            variant={variant}
            size={size}
            label={option.name}
            {...getTagProps({ index })}
            className={clsx(styleClasses[`classChip_${option.classID}`])}
            icon={
              <Icon
                className={clsx(
                  classes.chipIcon,
                  styleClasses[`classIconChip_${option.classID}`]
                )}
              />
            }
            deleteIcon={
              <CancelIcon
                className={clsx(
                  styleClasses[`classDeleteIconChip_${option.classID}`]
                )}
              />
            }
          />
        ))}
      </div>
    )
  }

  const defaultRenderGroup = (params) => {
    return (
      <li key={params.key}>
        <ListSubheader
          className={clsx(
            styleClasses[`class_${params.group}`],
            classes.groupLabel
          )}
          component="div">
          <GroupIcon
            className={clsx(
              classes.icon,
              classes.groupLabel,
              styleClasses[`class_${params.group}`]
            )}
          />
          {`Clase ${params.group}`}
        </ListSubheader>
        <ul className={classes.groupUl}>{params.children}</ul>
      </li>
    )
  }

  const defaultRenderChipsPreviewGroup = (
    { key, group, children },
    groupProps
  ) => {
    return (
      <>
        <li key={key}>
          <ListSubheader
            className={clsx(
              styleClasses[`class_${group}`],
              classes.chipsGroupLabel
            )}
            component="div">
            <Chip
              // eslint-disable-next-line react/no-array-index-key
              key={key}
              variant={variant}
              size={size}
              label={`Clase ${group}`}
              {...groupProps}
              className={clsx(styleClasses[`classChip_${group}`])}
              icon={
                <GroupIcon
                  className={clsx(
                    classes.chipIcon,
                    styleClasses[`classIconChip_${group}`]
                  )}
                />
              }
              deleteIcon={
                <CancelIcon
                  className={clsx(styleClasses[`classDeleteIconChip_${group}`])}
                />
              }
              {...ChipProps}
            />
          </ListSubheader>
          <ul className={classes.chipsGroupUl}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.cardsGrid}>
              {children}
            </Grid>
          </ul>
        </li>
      </>
    )
  }

  defaultRenderChipsPreviewGroup.propTypes = {
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    group: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }

  const getOptionLabelFunc = getOptionLabel || defaultGetOptionLabel

  const small = size === 'small'

  const cardActionClasses = clsx({
    [classes.cardActionSmall]: small,
  })

  const deleteIconClasses = clsx({
    [classes.deleteIconSmall]: small,
  })

  const defaultRenderChipOption = (option, optionProps) => {
    const { onDelete } = optionProps
    return (
      <Grid
        key={option.key}
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={3}
        className={classes.cardItem}>
        <Card
          className={clsx(
            classes.card,
            styleClasses[`classChip_${option.classID}`]
          )}
          variant={variant}>
          <CardActionArea>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardText}>
                {getOptionLabelFunc(option)}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions className={clsx(classes.cardAction, cardActionClasses)}>
            <Icon
              className={clsx(
                classes.deleteIcon,
                deleteIconClasses,
                styleClasses[`classDeleteIconChip_${option.classID}`]
              )}
            />
            <IconButton
              size="small"
              aria-label="Eliminar producto/servicio"
              className={clsx(
                styleClasses[`classDeleteIconChip_${option.classID}`]
              )}
              onClick={onDelete}>
              <CancelIcon
                className={clsx(classes.deleteIcon, deleteIconClasses)}
              />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    )
  }

  const openInformationIcon = () => {
    openModal({
      title: 'Clasniza',
      content: (
        <Typography className={classes.infoText}>
          Puedes encontrar mayor información aquí&nbsp;
          <Link
            href="https://clasniza.impi.gob.mx/"
            rel="noreferrer"
            target="_blank">
            clasniza
          </Link>
        </Typography>
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  const defaultInfomationIcon = () => {
    return (
      <IconButton
        size="small"
        aria-label="Clases Nisa"
        onClick={openInformationIcon}>
        <InfoIcon fontSize="inherit" />
      </IconButton>
    )
  }

  const InfomationIconComp = InfomationIcon || defaultInfomationIcon

  const getOptionSelectedFunc = getOptionSelected || defaultGetOptionSelected
  const renderGroupFunc = renderGroup || defaultRenderGroup
  const renderOptionFunc = renderOption || defaultRenderOption
  const renderTagsFunc = renderTags || defaultRenderTags
  const groupByFunc = groupBy || defaultGroupBy
  const renderChipsPreviewGroupFunc =
    renderChipsPreviewGroup || defaultRenderChipsPreviewGroup
  const renderChipOptionFunc = renderChipOption || defaultRenderChipOption

  return (
    <SearchField
      id={id}
      required={required}
      disabled={disabled}
      name={name}
      label={label}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      className={className}
      noOptionsText={noOptionsText}
      Icon={Icon}
      InputIcon={InputIcon}
      InfomationIcon={InfomationIconComp}
      renderOption={renderOptionFunc}
      renderTags={renderTagsFunc}
      disableRenderTags={disableRenderTags}
      ChipProps={ChipProps}
      renderGroup={renderGroupFunc}
      renderChipsPreviewGroup={renderChipsPreviewGroupFunc}
      renderChipOption={renderChipOptionFunc}
      ListChipsPreviewComponent={ListChipsPreviewComponent}
      ListChipsPreviewProps={ListChipsPreviewProps}
      getOptionLabel={getOptionLabelFunc}
      getOptionSelected={getOptionSelectedFunc}
      filterOptions={filterOptions}
      groupBy={groupByFunc}
      fetch={fetch}
      freeSolo={freeSolo}
      control={control}
      rules={rules}
      errors={errors}
      multiple={multiple}
      disableCloseOnSelect={disableCloseOnSelect}
      autoComplete={autoComplete}
      includeInputInList={includeInputInList}
      filterSelectedOptions={filterSelectedOptions}
      clearText={clearText}
      options={currentOptions}
      ctxDefined={ctxDefined}
    />
  )
}
ProductsAndServicesSearchField.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  noOptionsText: PropTypes.string,
  GroupIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    null,
    undefined,
  ]),
  Icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    null,
    undefined,
  ]),
  InputIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    null,
    undefined,
  ]),
  InfomationIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    null,
    undefined,
  ]),
  renderOption: PropTypes.func,
  renderTags: PropTypes.func,
  disableRenderTags: PropTypes.bool,
  ChipProps: PropTypes.shape({}),
  renderGroup: PropTypes.func,
  renderChipsPreviewGroup: PropTypes.func,
  renderChipOption: PropTypes.func,
  ListChipsPreviewComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    null,
    undefined,
  ]),
  ListChipsPreviewProps: PropTypes.shape({}),
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  filterOptions: PropTypes.func,
  groupBy: PropTypes.func,
  freeSolo: PropTypes.bool,
  control: PropTypes.shape({}),
  rules: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  multiple: PropTypes.bool.isRequired,
  disableCloseOnSelect: PropTypes.bool,
  autoComplete: PropTypes.bool,
  includeInputInList: PropTypes.bool,
  filterSelectedOptions: PropTypes.bool,
  clearText: PropTypes.string,
  waitForFetch: PropTypes.number,
  classID: PropTypes.string,
  ctxDefined: PropTypes.bool,
  authenticated: PropTypes.bool.isRequired,
  limit: PropTypes.number,
}
ProductsAndServicesSearchField.defaultProps = {
  id: 'search',
  required: false,
  disabled: false,
  variant: 'outlined',
  className: undefined,
  fullWidth: false,
  label: '',
  name: 'search',
  size: 'small',
  noOptionsText: 'Enter a term to show the matches',
  GroupIcon: ClassOutlinedIcon,
  Icon: WidgetsOutlinedIcon,
  InputIcon: SearchIcon,
  InfomationIcon: undefined,
  renderOption: undefined,
  renderTags: undefined,
  disableRenderTags: true,
  ChipProps: undefined,
  renderGroup: undefined,
  renderChipsPreviewGroup: undefined,
  renderChipOption: undefined,
  ListChipsPreviewComponent: 'ul',
  ListChipsPreviewProps: undefined,
  getOptionLabel: undefined,
  getOptionSelected: undefined,
  filterOptions: undefined,
  groupBy: undefined,
  freeSolo: false,
  control: undefined,
  rules: undefined,
  errors: undefined,
  disableCloseOnSelect: true,
  autoComplete: false,
  includeInputInList: false,
  filterSelectedOptions: false,
  clearText: 'Limpiar',
  waitForFetch: 200,
  classID: undefined,
  ctxDefined: false,
  limit: 400,
}

export default withSearchFieldContext(ProductsAndServicesSearchField)
