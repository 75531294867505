import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const ConfirmDialog = (props) => {
  const { title, content, enabled, onAccept, data, closeHandler } = props
  /* eslint-disable-next-line no-unused-vars */
  const [open, setOpen] = React.useState(enabled)

  const handleClose = () => {
    setOpen(false)
  }

  const handleAccept = async () => {
    closeHandler(false)
    await onAccept(data)
  }
  const handleReject = () => {
    closeHandler(false)
  }

  return (
    <div>
      <Dialog
        open={enabled}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleReject} color="secondary">
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleAccept}
            color="primary"
            autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  closeHandler: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
}

ConfirmDialog.defaultProps = {
  data: undefined,
  enabled: false,
}

export default ConfirmDialog
