import React from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import clsx from 'clsx'
import { useNavigate } from '@reach/router'
import { Typography, Button, IconButton, makeStyles } from '@material-ui/core'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import AccountBox from '@material-ui/icons/AccountBox'
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark'
import { isMobile } from 'react-device-detect'
import { useLayoutContext } from '../components/context'
import PopOverModal from '../components/common/PopOverModal'

const mobileResolution = window.width <= 500

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  headerTitle: {
    width: '100%',
    color: theme.palette.primary.contrastText,
  },
  loginButtonContainer: {},
  loginButton: {
    width: '100%',
    color: '#000',
    cursor: 'pointer',
    textTransform: 'none',
  },
  mobileLoginButton: {
    color: '#000',
    cursor: 'pointer',
    textTransform: 'none',
  },
  loginPopoverButton: {
    color: '#000',
    textTransform: 'none',
    float: 'right',
    cursor: 'pointer',
  },
  innerBtnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  innerLinkContainer: {
    width: '100%',
    marginTop: '5px',
  },
  registerLink: {
    fontSize: 11,
    color: '#388BF3',
    cursor: 'pointer',
  },
  home: {
    cursor: 'pointer',
  },
}))

const Header = () => {
  const classes = useStyles()
  const {
    showAuthUI,
    setShowAuthUI,
    authenticated,
    setAuthState,
    showAuthHeader,
  } = useLayoutContext()

  const navigate = useNavigate()

  const redirectHome = () => {
    navigate('/')
  }

  const activateAuthDialog = (authState, closeAction) => {
    closeAction()
    setShowAuthUI(true)
    setAuthState(authState)
  }

  const renderWebLoginButton = (closeAction) => {
    return (
      <Button
        onClick={() => activateAuthDialog('signIn', closeAction)}
        className={classes.loginButton}
        variant="contained"
        startIcon={<VpnKeyIcon />}>
        {I18n.get('Sign In')}
      </Button>
    )
  }

  const renderMobileLoginButton = (closeAction) => {
    return (
      <>
        <IconButton
          onClick={() => activateAuthDialog('signIn', closeAction)}
          className={classes.mobileLoginButton}
          variant="contained">
          <VpnKeyIcon />
        </IconButton>
      </>
    )
  }

  const renderLoginPopoverContent = (contentClass, closePopover) => {
    return (
      <div className={contentClass}>
        <div className={classes.innerBtnContainer}>
          {isMobile || mobileResolution
            ? renderMobileLoginButton(closePopover)
            : renderWebLoginButton(closePopover)}
        </div>
        <div className={classes.innerLinkContainer}>
          <Typography
            onClick={() => activateAuthDialog('signUp', closePopover)}
            className={classes.registerLink}>
            {I18n.get('No account? Sign up here')}
          </Typography>
        </div>
      </div>
    )
  }

  const renderLoginComponent = () => {
    return (
      <PopOverModal
        id="login-popover"
        buttonVariant="contained"
        buttonClass={classes.loginPopoverButton}
        buttonText={I18n.get('Sign in')}
        renderContent={renderLoginPopoverContent}
      />
    )
  }

  const renderAccountAvatarIcon = () => {
    return (
      <IconButton onClick={null} className={classes.loginButton}>
        <AccountBox />
      </IconButton>
    )
  }

  const loginComponent = showAuthUI ? () => {} : renderLoginComponent

  return (
    <>
      <BrandingWatermarkIcon
        className={clsx(classes.icon, classes.home)}
        onClick={() => {
          redirectHome()
        }}
      />
      <Typography className={classes.headerTitle} variant="h6" noWrap>
        Trademark Console
      </Typography>
      {showAuthHeader && (
        <div className={classes.loginButtonContainer}>
          {authenticated ? renderAccountAvatarIcon() : loginComponent()}
        </div>
      )}
    </>
  )
}

export default Header
