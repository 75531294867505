import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  useMediaQuery,
  Container,
  Button,
  Grid,
  Hidden,
  FormControl,
  FormLabel,
  Typography,
  IconButton,
  InputAdornment,
  Chip,
  Paper,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import InfoIcon from '@material-ui/icons/Info'
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined'
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import _ from 'lodash'
import { parse, format, subMonths, subDays, isFuture, isAfter } from 'date-fns'
import { useSnackbar } from 'notistack'
import {
  mapIcon,
  FormField,
  FileUpload,
  FormFieldDateTimePicker,
} from '../common'
import brandTypes from '../brandTypes'
import BrandTypeModal from './BrandTypeModal'
import { useLayoutContext, useTrademarkContext } from '../context'
import { useInfoModal } from '../../hooks'
import {
  downloadFileFromS3,
  uploadFilesToS3ByLevel,
  putBrand,
  getBrandById,
  putBrandData,
  putAllBrandProductsServices,
  removeAllBrandProductsServices,
  postAllBrandProductsServices,
  getFileFromS3,
  openFileAttachment,
  getBrandsByTrademarkApplication,
  updateBrandsForSearch,
} from '../../services'
import ProductsAndServicesSearchField from './ProductsAndServicesSearchField'
import { isEmail } from '../../regex.validations'
import { BRAND_STATUS_GRANTED, BRAND_STATUS_CANCELLED } from '../../config'

const useStyles = makeStyles((theme) => ({
  noLabel: {
    marginTop: theme.spacing(1),
  },
  brandSelectIcon: {
    fontSize: theme.typography.fontSize,
    marginRight: theme.typography.fontSize,
  },
  endAdornment: {
    marginRight: theme.spacing(1.5),
  },
  containerOptions: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  formLabel: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    marginLeft: theme.spacing(0.5),
  },
  formOption: {
    textAlign: 'left',
    marginLeft: theme.spacing(1.5),
  },
  brandFieldsContainer: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  brandFieldsGrid: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0),
  },
  className: {
    color: theme.palette.secondary.light,
    fontWeight: 500,
    fontSize: '1rem',
  },
  hidden: {
    display: 'none',
  },
  actionButtons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
  actionButtonContainer: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  actionButton: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5),
    },
  },
  saveButton: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.common.white,
    },
  },
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    justifyContent: 'center',
    textAlign: 'center',
  },
  fileChip: {
    backgroundColor: theme.palette.primary.light,
    cursor: 'pointer',
  },
  fileChipsMobile: {
    marginBottom: theme.spacing(2),
  },
  noFiles: {
    fontWeight: 600,
  },
  brandDataFileDownloads: {
    padding: theme.spacing(3),
    width: '100%',
  },
}))

// eslint-disable-next-line no-unused-vars
const BrandForm = ({ countries }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { authenticated, setLoading } = useLayoutContext()
  const {
    brandFormIsDirty,
    setBrandFormIsDirty,
    brand,
    setBrand,
  } = useTrademarkContext()
  const { openModal } = useInfoModal()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  const brandOptions = brandTypes.map((option) => {
    const { Icon } = mapIcon(option)
    return {
      value: option.id,
      label: option.name,
      icon: <Icon className={classes.brandSelectIcon} />,
    }
  })
  const applicantNameMapped = [
    brand?.brandData?.applicant?.name,
    brand?.brandData?.applicant?.surname,
    brand?.brandData?.applicant?.secondSurname,
  ]
    .filter(Boolean)
    .join(' ')
    .trim()

  const finalBrand = {
    id: brand?.id,
    trademarkApplicationID: brand?.trademarkApplicationID,
    // eslint-disable-next-line no-underscore-dangle
    _version: brand?._version,
    classID: brand?.classID,
    class: brand?.class,
    productsServices: brand?.productsServices?.items?.map((ps) => {
      return { ...ps.productService }
    }),
    hasBeenRegisteredAbroad: brand?.hasBeenRegisteredAbroad,
    registrationDateAbroad: brand?.registrationDateAbroad,
    registrationNumberAbroad: brand?.registrationNumberAbroad,
    countryOfRegistrationAbroad: brand?.countryOfRegistrationAbroad,
    brandDataID: brand?.brandData?.id,
    brandDataVersion: brand?.brandData?._version,
    requestNumber: brand?.requestNumber,
    certificate: brand?.certificate,
    applicantName: applicantNameMapped,
    brandType: brand?.brandData?.typeID,
    email: brand?.brandData?.applicant?.email,
    brandName: brand?.brandData?.name,
    brandFiles: [],
    acceptedBrandFiles: [],
    hasBeenUsed: brand?.brandData?.hasBeenUsed,
    dateOfInitialUse: brand?.brandData?.dateOfInitialUse,
    hasTranslation: brand?.brandData?.hasTranslation,
    translation: brand?.brandData?.translation,
    hasTransliteration: brand?.brandData?.hasTransliteration,
    transliteration: brand?.brandData?.transliteration,
  }

  const [selectedBrandType, setSelectedBrandType] = useState(
    brandTypes.find((b) => b.id === finalBrand?.brandType)
  )
  const {
    register,
    handleSubmit,
    control,
    errors,
    watch,
    formState,
    reset,
  } = useForm({
    defaultValues: { ...finalBrand },
    // mode: 'onChange',
  })
  const { dirtyFields } = formState
  const hasTransliterationWatched = watch('hasTransliteration')
  const hasTranslationWatched = watch('hasTranslation')
  const hasBeenUsedWatched = watch('hasBeenUsed')
  const hasBeenRegisteredAbroadWatched = watch('hasBeenRegisteredAbroad')
  const [filesLoaded, setFilesLoaded] = React.useState(null)
  const [acceptedFilesLoaded, setAcceptedFilesLoaded] = React.useState(null)
  const [loadingAcceptedFiles, setLoadingAcceptedFiles] = React.useState(true)
  const [
    newAcceptedFilesUploaded,
    setNewAcceptedFilesUploaded,
  ] = React.useState(false)
  const [loadingFiles, setLoadingFiles] = React.useState(true)
  const [newFilesUploaded, setNewFilesUploaded] = React.useState(false)

  const retrieveFiles = async (fileset) => {
    const fileSource =
      fileset === 'accepted' ? brand?.files : brand?.brandData?.files
    setLoading(true)
    if (fileset === 'accepted') {
      setLoadingAcceptedFiles(true)
      setAcceptedFilesLoaded(null)
    } else {
      setLoadingFiles(true)
      setFilesLoaded(null)
    }
    let files
    if (selectedBrandType?.requireFiles && fileSource) {
      const retrievedFilesAsync = fileSource?.map(async (f) => {
        return downloadFileFromS3({
          key: f.key,
          identityID: f.identityID,
          level: f.level,
          expires: 60,
        })
      })
      const retrievedFiles = await Promise.all(retrievedFilesAsync)
      files = retrievedFiles
    } else {
      files = []
    }
    // eslint-disable-next-line no-unused-expressions
    fileset === 'accepted'
      ? setAcceptedFilesLoaded(files)
      : setFilesLoaded(files)
    setLoading(false)
  }

  useEffect(() => {
    retrieveFiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    retrieveFiles('accepted')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      if (loadingFiles && dirtyFields.brandFiles) {
        setLoadingFiles(false)
      } else if (loadingAcceptedFiles && dirtyFields.acceptedBrandFiles) {
        setLoadingAcceptedFiles(false)
      } else {
        setBrandFormIsDirty(true)
      }
    } else {
      setBrandFormIsDirty(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dirtyFields,
    setBrandFormIsDirty,
    dirtyFields.id,
    // eslint-disable-next-line no-underscore-dangle
    dirtyFields._version,
    dirtyFields.classID,
    dirtyFields.productsServices,
    dirtyFields.hasBeenRegisteredAbroad,
    dirtyFields.registrationDateAbroad,
    dirtyFields.registrationNumberAbroad,
    dirtyFields.countryOfRegistrationAbroad,
    dirtyFields.brandDataID,
    dirtyFields.brandDataVersion,
    dirtyFields.requestNumber,
    dirtyFields.certificate,
    dirtyFields.applicantName,
    // BrandData
    dirtyFields.brandType,
    dirtyFields.email,
    dirtyFields.brandName,
    dirtyFields.brandFiles,
    dirtyFields.hasBeenUsed,
    dirtyFields.dateOfInitialUse,
    dirtyFields.hasTranslation,
    dirtyFields.translation,
    dirtyFields.hasTransliteration,
    dirtyFields.transliteration,
    // BrandDocumentation
    dirtyFields.acceptedBrandFiles,
  ])

  const openRestoreModal = () => {
    openModal({
      title: 'Restaurar información',
      content: (
        <div className={classes.infoText}>
          Los cambios generados no persistirán ¿Estás seguro de restaurar la
          información?
        </div>
      ),
      confirmationText: 'Confirmar',
      cancellationText: 'Cerrar',
    })
      .then(async () => {
        setBrandFormIsDirty(false)
        await retrieveFiles()
        reset()
      })
      .catch(() => {})
  }

  const brandTypeAssigned = async (event) => {
    const { value } = event.target
    const selectBrandType = brandTypes.find((b) => b.id === value)
    setSelectedBrandType(selectBrandType)
  }

  const openInformationModal = ({ id, title, text, images }) => {
    openModal({
      title: '',
      content: (
        <BrandTypeModal id={id} title={title} text={text} images={images} />
      ),
      confirmationText: 'Cerrar',
      cancellationText: null,
    })
  }

  const handleFileDownload = async (s3File) => {
    const generatedFile = await getFileFromS3({
      key: s3File.key,
      identityID: s3File.identityID,
      level: s3File.level,
      expires: 60,
      download: true,
    })
    openFileAttachment(
      generatedFile.Body,
      s3File.name || `${s3File?.key.substring(33, s3File?.key.length)}`,
      generatedFile.ContentType
    )
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const {
        id,
        _version,
        requestNumber,
        certificate,
        productsServices,
        hasBeenRegisteredAbroad,
        registrationDateAbroad,
        registrationNumberAbroad,
        countryOfRegistrationAbroad,
        // BrandData
        brandDataID,
        brandDataVersion,
        brandName,
        brandType,
        brandFiles,
        acceptedBrandFiles,
        hasBeenUsed,
        dateOfInitialUse,
        hasTranslation,
        translation,
        hasTransliteration,
        transliteration,
      } = data

      let uploadedFiles
      if (selectedBrandType?.requireFiles) {
        uploadedFiles = newFilesUploaded
          ? await uploadFilesToS3ByLevel(brandFiles, 'private', brand?.owner)
          : brand?.brandData?.files
      }

      const uploadedAcceptedFiles = newAcceptedFilesUploaded
        ? await uploadFilesToS3ByLevel(
            acceptedBrandFiles,
            'private',
            brand?.owner
          )
        : brand?.files

      // eslint-disable-next-line no-unused-vars
      const updatedBrandData = await putBrandData({
        id: brandDataID,
        name: selectedBrandType?.requireName ? brandName : '',
        typeID: brandType,
        files: uploadedFiles?.length > 0 ? uploadedFiles : undefined,
        hasBeenUsed,
        dateOfInitialUse: dateOfInitialUse
          ? format(dateOfInitialUse, 'yyyy-MM-ddxxx')
          : undefined,
        hasTranslation,
        translation,
        hasTransliteration,
        transliteration,
        _version: brandDataVersion,
      })

      const actualProductsServices = brand?.productsServices?.items?.map(
        (ps) => {
          return { ...ps.productService }
        }
      )
      // https://medium.com/@alvaro.saburido/set-theory-for-arrays-in-es6-eb2f20a61848
      const deletedValues = _.differenceBy(
        actualProductsServices,
        productsServices,
        'id'
      )
      const addedValues = _.differenceBy(
        productsServices,
        actualProductsServices,
        'id'
      )
      const sameProductsServices = _.intersectionBy(
        actualProductsServices,
        productsServices,
        'id'
      )
      const productsServicesToUpdate = brand?.productsServices?.items?.filter(
        (ps) => _.some(deletedValues, { id: ps?.productService.id })
      )
      const finalProductsServicesToUpdate = productsServicesToUpdate.map(
        // eslint-disable-next-line no-underscore-dangle
        (ps) => ({ ...ps, deleted: 'true' })
      )
      const updatedProductsServices = await putAllBrandProductsServices({
        productsAndServices: finalProductsServicesToUpdate,
      })
      // eslint-disable-next-line no-unused-vars
      const deletedProductsServices = await removeAllBrandProductsServices({
        productsAndServices: updatedProductsServices,
      })
      const addedProductsServices = await postAllBrandProductsServices({
        brandID: id,
        productsAndServices: addedValues,
        owner: brand?.owner,
      })
      const productsServicesToPreserve = brand?.productsServices?.items?.filter(
        (ps) => _.some(sameProductsServices, { id: ps?.productService.id })
      )
      // eslint-disable-next-line no-unused-vars
      const finalProductsServices = productsServicesToPreserve.concat(
        addedProductsServices
      )

      // eslint-disable-next-line no-unused-vars
      const updatedBrand = await putBrand({
        id,
        hasBeenRegisteredAbroad,
        registrationDateAbroad: registrationDateAbroad
          ? format(registrationDateAbroad, 'yyyy-MM-ddxxx')
          : undefined,
        registrationNumberAbroad,
        countryOfRegistrationAbroad,
        requestNumber,
        certificate,
        files:
          uploadedAcceptedFiles?.length > 0 ? uploadedAcceptedFiles : undefined,
        // eslint-disable-next-line no-underscore-dangle
        _version,
      })

      // Update Brand Data used by search
      if (dirtyFields?.brandType || dirtyFields?.brandName) {
        const brandSibblings = await getBrandsByTrademarkApplication(
          brand?.trademarkApplicationID
        )
        const finalBrandName = selectedBrandType?.requireName ? brandName : ''
        const dataForSearch = {
          brandName:
            dirtyFields?.brandType || dirtyFields?.brandName
              ? finalBrandName
              : undefined,
          typeID: dirtyFields?.brandType ? brandType : undefined,
        }
        // eslint-disable-next-line no-unused-vars
        const updatedBrandSibblings = await updateBrandsForSearch({
          brands: brandSibblings,
          ...dataForSearch,
        })
      }

      const brandReaded = await getBrandById(id)
      setBrand(null)
      if (brandReaded?.brandData?.dateOfInitialUse) {
        brandReaded.brandData.dateOfInitialUse = parse(
          brandReaded?.brandData?.dateOfInitialUse,
          'yyyy-MM-ddxxx',
          new Date()
        )
      }
      if (brandReaded?.registrationDateAbroad) {
        brandReaded.registrationDateAbroad = parse(
          brandReaded.registrationDateAbroad,
          'yyyy-MM-ddxxx',
          new Date()
        )
      }
      setBrand({ ...brandReaded })
      enqueueSnackbar('Información de la marca guardada correctamente', {
        preventDuplicate: true,
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar(
        'Ocurrió un error al intentar guardar los datos de la marca',
        {
          preventDuplicate: true,
          variant: 'warning',
        }
      )
    }
    setLoading(false)
  }

  const render = brand && filesLoaded && acceptedFilesLoaded
  if (!render) return null

  // https://github.com/react-hook-form/react-hook-form/issues/1025
  // https://github.com/react-hook-form/react-hook-form/issues/1005
  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit(onSubmit)()
        e.stopPropagation()
      }}>
      <Container className={classes.brandFieldsContainer}>
        <Grid container spacing={2}>
          {brandFormIsDirty && (
            <Grid item xs={12} className={classes.brandFieldsGrid}>
              <Typography variant="subtitle2" color="error">
                Es necesario que confirmes los cambios para continuar
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <FormField
              id="id"
              name="id"
              label="ID de Marca"
              disabled
              required
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: 'id',
                },
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              id="trademarkApplicationID"
              name="trademarkApplicationID"
              label="ID de Solicitud"
              disabled
              required
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: 'ID Solicitud',
                },
              }}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} className={clsx(classes.hidden)}>
            <FormField
              id="_version"
              name="_version"
              label="_version"
              disabled
              required
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel, classes.hidden)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: '_version',
                },
              }}
              errors={errors}
              type="hidden"
            />
          </Grid>
          <Grid item xs={12} className={clsx(classes.hidden)}>
            <FormField
              id="brandDataID"
              name="brandDataID"
              label="brandDataID"
              disabled
              required
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel, classes.hidden)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: 'brandDataID',
                },
              }}
              errors={errors}
              type="hidden"
            />
          </Grid>
          <Grid item xs={12} className={clsx(classes.hidden)}>
            <FormField
              id="brandDataVersion"
              name="brandDataVersion"
              label="brandDataVersion"
              disabled
              required
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel, classes.hidden)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: 'brandDataVersion',
                },
              }}
              errors={errors}
              type="hidden"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormField
              id="requestNumber"
              name="requestNumber"
              label="Número de solicitud"
              required
              disabled
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel)}
              inputRef={register({
                required: {
                  value: false,
                  message: 'Ingresa el número de solicitud',
                },
              })}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              id="certificate"
              name="certificate"
              label="Número de certificado"
              required
              disabled
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel)}
              inputRef={register({
                required: {
                  value: false,
                  message: 'Ingresa el número de certificado',
                },
              })}
              errors={errors}
            />
          </Grid>
          {acceptedFilesLoaded && acceptedFilesLoaded.length > 0 ? (
            <Grid item container>
              {brand?.files.map((file) => {
                return (
                  <Grid
                    item
                    key={file?.key}
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    className={clsx(
                      isMobileDevice ? classes.fileChipsMobile : null
                    )}>
                    <Chip
                      label={
                        file?.name || file?.key.substring(30, file?.key.length)
                      }
                      className={clsx(classes.fileChip)}
                      onClick={() => handleFileDownload(file)}
                    />
                  </Grid>
                )
              })}
            </Grid>
          ) : null}
          {brand?.brandStatus === BRAND_STATUS_GRANTED ||
          brand?.brandStatus === BRAND_STATUS_CANCELLED ? (
            <Grid item xs={12} md={12}>
              <FileUpload
                id="acceptedBrandFiles"
                name="acceptedBrandFiles"
                label="Documentación adjunta"
                required
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(
                  classes.margin,
                  classes.noLabel,
                  classes.brandFilesSelection
                )}
                acceptedFiles={['application/pdf']}
                filesLimit={5}
                maxFileSize={5 * 1000 * 1000}
                dropzoneText="Arrastra y suelta un archivo aquí o haz clic. Tamaño máximo 5MB por archivo. Máximo 5 archivos."
                previewText="Archivos seleccionados"
                showAlerts={false}
                showPreviews
                showPreviewsInDropzone={false}
                showFileNames
                showFileNamesInPreview
                useChipsForPreview={false}
                onChange={(f) => {
                  console.log('Files:', f)
                  if (!loadingAcceptedFiles) {
                    setBrandFormIsDirty(true)
                    setNewAcceptedFilesUploaded(true)
                  }
                }}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Selecciona al menos un archivo',
                  },
                  validate: (value) => {
                    return (
                      (Array.isArray(value) && value?.length > 0) ||
                      'Selecciona al menos un archivo'
                    )
                  },
                }}
                errors={errors}
                initialFiles={acceptedFilesLoaded}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={12}>
            <FormField
              id="applicantName"
              name="applicantName"
              label="Cliente"
              required
              disabled
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel)}
              inputRef={register({
                required: {
                  value: false,
                  message: 'Ingresa el nombre del cliente',
                },
              })}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              id="brandType"
              name="brandType"
              type="select"
              required
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin)}
              label="Tipo de marca"
              labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Selecciona un tipo de marca',
                },
              }}
              errors={errors}
              options={brandOptions}
              // eslint-disable-next-line no-unused-vars
              renderValue={(selected) => {
                // const brandType = brandTypes.find((b) => b.id === selected)
                const { Icon: BrandIcon } = mapIcon(selectedBrandType)
                return (
                  <span>
                    <BrandIcon className={classes.brandSelectIcon} />
                    {selectedBrandType.name}
                  </span>
                )
              }}
              onChange={brandTypeAssigned}
              IconComponent={() => {
                return null
              }}
              endAdornment={
                <InputAdornment position="end" className={classes.endAdornment}>
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => {
                      openInformationModal({
                        id: selectedBrandType.id,
                        title: selectedBrandType.name,
                        text: selectedBrandType.helperPopOverText,
                        images: selectedBrandType.helperImages,
                      })
                    }}>
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              id="email"
              name="email"
              label="Correo electrónico"
              type="email"
              required
              disabled
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel)}
              inputRef={register({
                required: {
                  value: true,
                  message: 'Ingresa tu correo electrónico',
                },
                minLength: {
                  value: 6,
                  message: 'Debe ser mayor o igual a 6 caracteres',
                },
                pattern: {
                  value: isEmail,
                  message: 'Debes ingresar un correo electrónico válido',
                },
              })}
              errors={errors}
            />
          </Grid>
          {selectedBrandType?.requireName ? (
            <Grid item xs={12} md={12}>
              <FormField
                id="brandName"
                name="brandName"
                label="Nombre de tu marca"
                required
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(classes.margin, classes.noLabel)}
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Ingresa el nombre de tu marca',
                  },
                })}
                errors={errors}
                multiline
                rows="4"
              />
            </Grid>
          ) : null}
          {selectedBrandType?.requireFiles ? (
            <Grid item xs={12} md={12}>
              <FileUpload
                id="brandFiles"
                name="brandFiles"
                label="Archivos adjuntos"
                required
                variant="outlined"
                size="small"
                fullWidth
                className={clsx(
                  classes.margin,
                  classes.noLabel,
                  classes.brandFilesSelection
                )}
                acceptedFiles={['image/gif']}
                filesLimit={5}
                maxFileSize={5 * 1000 * 1000}
                dropzoneText="Arrastra y suelta una imagen aquí o haz clic. Tamaño máximo 5MB por imagen. Máximo 5 archivos de imagen."
                previewText="Archivos seleccionados"
                showAlerts={false}
                showPreviews
                showPreviewsInDropzone={false}
                showFileNames
                showFileNamesInPreview
                useChipsForPreview={false}
                onChange={(f) => {
                  console.log('Files:', f)
                  if (!loadingFiles) {
                    setBrandFormIsDirty(true)
                    setNewFilesUploaded(true)
                  }
                }}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Selecciona al menos un archivo de imagen',
                  },
                  validate: (value) => {
                    return (
                      (Array.isArray(value) && value?.length > 0) ||
                      'Selecciona al menos un archivo de imagen'
                    )
                  },
                }}
                errors={errors}
                initialFiles={filesLoaded}
              />
            </Grid>
          ) : null}
          <Paper elevation="2" className={clsx(classes.brandDataFileDownloads)}>
            <Typography variant="subtitle1" gutterBottom>
              Descargar archivos adjuntos:
            </Typography>
            {filesLoaded && filesLoaded.length > 0 ? (
              <Grid item container>
                {brand?.brandData?.files.map((file) => {
                  return (
                    <Grid
                      item
                      key={file?.key}
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      className={clsx(
                        isMobileDevice ? classes.fileChipsMobile : null
                      )}>
                      <Chip
                        label={
                          file?.name ||
                          file?.key.substring(30, file?.key.length)
                        }
                        className={clsx(classes.fileChip)}
                        onClick={() => handleFileDownload(file)}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            ) : null}
          </Paper>

          <Grid item xs={12} className={classes.brandFieldsGrid}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.className}>
              Clase {brand?.classID}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ProductsAndServicesSearchField
              id="productsServices"
              name="productsServices"
              // required={false}
              required
              label={
                isMobileDevice
                  ? 'Producto(s) o servicio(s)'
                  : 'Producto(s) o servicio(s) que deseas proteger'
              }
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel)}
              noOptionsText={
                isMobileDevice
                  ? 'Ingresa un término'
                  : 'Ingresa un término para mostrar las coincidencias'
              }
              GroupIcon={ClassOutlinedIcon}
              Icon={WidgetsOutlinedIcon}
              InputIcon={SearchIcon}
              freeSolo={false}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Selecciona al menos un producto o servicio',
                },
                // https://github.com/react-hook-form/react-hook-form/issues/34
                // https://github.com/react-hook-form/react-hook-form/issues/1617
                validate: (value) => {
                  return (
                    (Array.isArray(value) && value?.length > 0) ||
                    'Selecciona al menos un producto o servicio'
                  )
                },
              }}
              errors={errors}
              // multiple={false}
              multiple
              autoComplete
              disableCloseOnSelect
              includeInputInList={false}
              filterSelectedOptions={false}
              // filterSelectedOptions
              waitForFetch={200}
              classID={brand?.classID}
              authenticated={authenticated}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl
              size="small"
              variant="outlined"
              className={clsx(classes.margin, classes.noLabel)}
              required={false}
              disabled={false}
              fullWidth>
              <Container className={clsx(classes.containerOptions)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel
                      component="legend"
                      className={clsx(
                        classes.margin,
                        classes.noLabel,
                        classes.formLabel
                      )}>
                      Descripción
                    </FormLabel>
                  </Grid>
                  {selectedBrandType?.requireFiles && (
                    <Grid item xs={12}>
                      <FormField
                        id="hasTransliteration"
                        name="hasTransliteration"
                        type="switch"
                        label={
                          <>
                            <Typography variant="body1" color="primary">
                              El nombre de tu marca incluye caracteres de otro
                              alfabeto
                              <IconButton
                                aria-label="info"
                                size="small"
                                onClick={() => {
                                  openInformationModal({
                                    id: 'hasTransliteration',
                                    title: 'Transliteración',
                                    text:
                                      'Ejemplos de marcas definidas en otros alfabetos.',
                                    images: [
                                      '/ayi-dumplings.png',
                                      '/pycckoe.png',
                                      '/arab-bank.png',
                                    ],
                                  })
                                }}>
                                <InfoIcon fontSize="inherit" />
                              </IconButton>
                            </Typography>
                          </>
                        }
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.formOption)}
                        labelClassName={clsx(classes.margin, classes.noLabel)}
                        control={control}
                        rules={{
                          required: {
                            value: false,
                            message: '',
                          },
                        }}
                        errors={errors}
                      />
                    </Grid>
                  )}
                  {hasTransliterationWatched && (
                    <Grid item xs={12} md={12}>
                      <FormField
                        id="transliteration"
                        name="transliteration"
                        label="Transliteración"
                        required
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        inputRef={register({
                          required: {
                            value: hasTransliterationWatched || false,
                            message: 'Ingresa la transliteración para tu marca',
                          },
                        })}
                        errors={errors}
                        multiline
                        rows="4"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormField
                      id="hasTranslation"
                      name="hasTranslation"
                      type="switch"
                      label={
                        <Typography variant="body1" color="primary">
                          El nombre de tu marca se encuentra en otro idioma
                          diferente al español
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.formOption)}
                      labelClassName={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: '',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  {hasTranslationWatched && (
                    <Grid item xs={12} md={12}>
                      <FormField
                        id="translation"
                        name="translation"
                        label="Traducción"
                        required
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        inputRef={register({
                          required: {
                            value: hasTranslationWatched || false,
                            message: 'Ingresa la traducción para tu marca',
                          },
                        })}
                        errors={errors}
                        multiline
                        rows="4"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormField
                      id="hasBeenUsed"
                      name="hasBeenUsed"
                      type="switch"
                      label={
                        <Typography variant="body1" color="primary">
                          La marca se ha utilizado previamente
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.formOption)}
                      labelClassName={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: '',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  {hasBeenUsedWatched && (
                    <Grid item xs={12} sm={6}>
                      <FormFieldDateTimePicker
                        id="dateOfInitialUse"
                        name="dateOfInitialUse"
                        type="date"
                        label="Fecha en la que se inició a utilizar"
                        required
                        variant="dialog"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="info"
                              size="small"
                              onClick={() => {
                                openInformationModal({
                                  id: 'dateOfInitialUse',
                                  title: 'Fecha en la que se inició a utilizar',
                                  text:
                                    'Señala una fecha que, en caso de ser necesario, pudieras demostrar.',
                                  images: [],
                                })
                              }}>
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          ),
                        }}
                        InputAdornmentProps={{ position: 'start' }}
                        control={control}
                        rules={{
                          required: {
                            value: hasBeenUsedWatched || false,
                            message:
                              'Ingresa la fecha en la que se inició a utilizar tu marca',
                          },
                          validate: {
                            futureDate: (value) => {
                              return (
                                !isFuture(value) ||
                                'La fecha debe ser menor o igual al dia actual'
                              )
                            },
                          },
                        }}
                        errors={errors}
                        inputVariant="outlined"
                        autoOk
                        disableFuture
                        format="dd/MM/yyyy"
                        invalidDateMessage="Fecha inválida"
                        invalidLabel="Fecha inválida"
                        mask="__/__/____"
                        cancelLabel="CANCELAR"
                        clearable
                        clearLabel="LIMPIAR"
                        okLabel="OK"
                        showTodayButton
                        todayLabel="HOY"
                      />
                    </Grid>
                  )}
                  <Hidden xsDown>
                    <Grid item sm={6} />
                  </Hidden>
                </Grid>
              </Container>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl
              size="small"
              variant="outlined"
              className={clsx(classes.margin, classes.noLabel)}
              required={false}
              disabled={false}
              fullWidth>
              <Container className={clsx(classes.containerOptions)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel
                      component="legend"
                      className={clsx(
                        classes.margin,
                        classes.noLabel,
                        classes.formLabel
                      )}>
                      Datos de la prioridad
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      id="hasBeenRegisteredAbroad"
                      name="hasBeenRegisteredAbroad"
                      type="switch"
                      label={
                        <>
                          <Typography variant="body1" color="primary">
                            Has solicitado esta marca en el extranjero
                            <IconButton
                              aria-label="info"
                              size="small"
                              onClick={() => {
                                openInformationModal({
                                  id: 'hasBeenRegisteredAbroad',
                                  title:
                                    'Has solicitado esta marca en el extranjero',
                                  text:
                                    'Marcar esta casilla solo si solicitaste la marca en el extranjero durante los últimos 6 meses.',
                                  images: [],
                                })
                              }}>
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Typography>
                        </>
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.formOption)}
                      labelClassName={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: '',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  {hasBeenRegisteredAbroadWatched && (
                    <Grid item xs={12} sm={6}>
                      <FormFieldDateTimePicker
                        id="registrationDateAbroad"
                        name="registrationDateAbroad"
                        type="date"
                        label="Fecha de presentación"
                        required
                        variant="dialog"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        control={control}
                        rules={{
                          required: {
                            value: hasBeenRegisteredAbroadWatched || false,
                            message: 'Ingresa la fecha en la que se registró',
                          },
                          validate: {
                            futureDate: (value) => {
                              return (
                                !isFuture(value) ||
                                'La fecha debe ser menor o igual al dia actual'
                              )
                            },
                            after6Months: (value) => {
                              return (
                                !isAfter(
                                  subDays(subMonths(new Date(), 6), 1),
                                  value
                                ) ||
                                'La fecha debe estar dentro de los últimos 6 meses'
                              )
                            },
                          },
                        }}
                        errors={errors}
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: 'start' }}
                        autoOk
                        disableFuture
                        format="dd/MM/yyyy"
                        invalidDateMessage="Fecha inválida"
                        invalidLabel="Fecha inválida"
                        mask="__/__/____"
                        cancelLabel="CANCELAR"
                        clearable
                        clearLabel="LIMPIAR"
                        okLabel="OK"
                        showTodayButton
                        todayLabel="HOY"
                        minDate={subMonths(new Date(), 6)}
                        minDateMessage="La fecha debe estar dentro de los últimos 6 meses"
                      />
                    </Grid>
                  )}
                  {hasBeenRegisteredAbroadWatched && (
                    <Grid item xs={12} sm={6}>
                      <FormField
                        id="registrationNumberAbroad"
                        name="registrationNumberAbroad"
                        label="Número"
                        required
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        inputRef={register({
                          required: {
                            value: hasBeenRegisteredAbroadWatched || false,
                            message: 'Ingresa el número de registro',
                          },
                        })}
                        errors={errors}
                      />
                    </Grid>
                  )}
                  {hasBeenRegisteredAbroadWatched && (
                    <Grid item xs={12} sm={6}>
                      <FormField
                        id="countryOfRegistrationAbroad"
                        name="countryOfRegistrationAbroad"
                        type="select"
                        label="País"
                        required
                        variant="outlined"
                        size="small"
                        fullWidth
                        className={clsx(classes.margin, classes.noLabel)}
                        control={control}
                        rules={{
                          required: {
                            value: hasBeenRegisteredAbroadWatched || false,
                            message: 'Selecciona el país de registro',
                          },
                        }}
                        errors={errors}
                        options={countries}
                      />
                    </Grid>
                  )}
                  <Hidden xsDown>
                    <Grid item sm={6} />
                  </Hidden>
                </Grid>
              </Container>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.actionButtons}>
              {brandFormIsDirty && (
                <>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.actionButtonContainer}>
                    <Grid
                      item
                      xs={10}
                      sm={4}
                      md={3}
                      lg={2}
                      className={classes.actionButton}>
                      <Button
                        onClick={() => {
                          openRestoreModal()
                        }}
                        variant="contained"
                        color="secondary"
                        fullWidth>
                        Restaurar
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      sm={4}
                      md={3}
                      lg={2}
                      className={classes.actionButton}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.saveButton}>
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </form>
  )
}

BrandForm.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
}

BrandForm.defaultProps = {}

export default BrandForm
