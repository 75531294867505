import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { makeStyles, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import clsx from 'clsx'
import { TRADEMARK_CANCEL_PHRASE } from '../../config'

import { FormField } from '../common'

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(2),
  },
  divider: {
    height: theme.spacing(3),
  },
  button: {
    width: '100%',
  },
  dialogText: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  error: {
    color: '#ff1744',
    marginLeft: '14px',
    marginRight: '14px',
    marginTop: '4px',
    fontSize: '0.75rem',
  },
}))

const CancelBrandDialog = (props) => {
  const { enabled, onAccept, closeHandler } = props
  /* eslint-disable-next-line no-unused-vars */
  const [open, setOpen] = React.useState(enabled)
  const { handleSubmit, control, errors } = useForm({})
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  // eslint-disable-next-line no-unused-vars
  const handleAccept = async (data) => {
    closeHandler(false)
    await onAccept()
  }
  const handleReject = () => {
    closeHandler(false)
  }

  const phraseMatches = (phrase) => {
    return (
      phrase === TRADEMARK_CANCEL_PHRASE ||
      `La frase no coincide con la registrada: ${TRADEMARK_CANCEL_PHRASE}`
    )
  }

  return (
    <div>
      <Dialog
        open={enabled}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Cancelación de Marca</DialogTitle>
        <form noValidate onSubmit={handleSubmit(handleAccept)}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography className={clsx(classes.dialogText)}>
                Para poder proceder con la cancelación, ingresa la frase:{' '}
                {TRADEMARK_CANCEL_PHRASE}
              </Typography>
              <div className={clsx(classes.divider)} />
              <FormField
                id="phrase"
                name="phrase"
                label="Frase"
                type="text"
                required
                variant="outlined"
                size="small"
                fullWidth
                placeholder={TRADEMARK_CANCEL_PHRASE}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `Ingresa la frase: ${TRADEMARK_CANCEL_PHRASE}`,
                  },
                  validate: phraseMatches,
                }}
                errors={errors}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleReject}
              color="secondary">
              Cancelar
            </Button>
            <Button variant="contained" type="submit" color="primary" autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}

CancelBrandDialog.propTypes = {
  onAccept: PropTypes.func.isRequired,
  brand: PropTypes.shape({}),
  closeHandler: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
}

CancelBrandDialog.defaultProps = {
  brand: undefined,
  enabled: false,
}

export default CancelBrandDialog
