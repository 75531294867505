import { API } from 'aws-amplify'
import Debug from 'debug'
import {
  createBrandProductsServices,
  deleteBrandProductsServices,
  updateBrandProductsServices,
  updateBrand,
  updateBrandData,
} from '../../graphql/mutations'

const debug = Debug('services:registration:brand')

// https://github.com/aws-amplify/amplify-cli/issues/1576#issuecomment-635128542
const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const postBrandProductsServices = async ({
  productServiceID,
  brandID,
  owner,
}) => {
  try {
    const item = {
      productServiceID,
      brandID,
      owner,
      deleted: 'false',
    }
    const { data } = await API.graphql({
      query: createBrandProductsServices,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    if (data) return data?.createBrandProductsServices
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const postAllBrandProductsServices = async ({
  brandID,
  productsAndServices,
  owner,
}) => {
  if (!productsAndServices || productsAndServices.length === 0) return []
  const createdProductsAndServices = productsAndServices.map(
    async (productAndService) => {
      const createdProductAndService = await postBrandProductsServices({
        productServiceID: productAndService.id,
        brandID,
        owner,
      })
      return createdProductAndService
    }
  )
  const all = await Promise.all(createdProductsAndServices)
  return all
}

export const putBrandProductsServices = async ({ id, _version, deleted }) => {
  try {
    const item = {
      id,
      _version,
      deleted,
    }
    const { data } = await API.graphql({
      query: updateBrandProductsServices,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    if (data) return data?.updateBrandProductsServices
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const putAllBrandProductsServices = async ({ productsAndServices }) => {
  if (!productsAndServices || productsAndServices.length === 0) return []
  const updatedProductsAndServices = productsAndServices.map(
    async (productAndService) => {
      const updatedProductAndService = await putBrandProductsServices({
        id: productAndService.id,
        // eslint-disable-next-line no-underscore-dangle
        _version: productAndService._version,
        deleted: productAndService.deleted,
      })
      return updatedProductAndService
    }
  )
  const all = await Promise.all(updatedProductsAndServices)
  return all
}

export const removeBrandProductsServices = async ({ id, _version }) => {
  try {
    const item = {
      id,
      _version,
    }
    const { data } = await API.graphql({
      query: deleteBrandProductsServices,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    if (data) return data?.deleteBrandProductsServices
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const removeAllBrandProductsServices = async ({
  productsAndServices,
}) => {
  if (!productsAndServices || productsAndServices.length === 0) return []
  const deletedProductsAndServices = productsAndServices.map(
    async (productAndService) => {
      const deletedProductAndService = await removeBrandProductsServices({
        id: productAndService.id,
        // eslint-disable-next-line no-underscore-dangle
        _version: productAndService._version,
      })
      return deletedProductAndService
    }
  )
  const all = await Promise.all(deletedProductsAndServices)
  return all
}

export const putBrand = async ({
  id,
  files,
  hasBeenRegisteredAbroad,
  registrationDateAbroad,
  registrationNumberAbroad,
  countryOfRegistrationAbroad,
  brandStage,
  brandStatus,
  trademarkStage,
  requestNumber,
  certificate,
  _version,
}) => {
  try {
    const item = {
      id,
      files,
      hasBeenRegisteredAbroad,
      registrationDateAbroad,
      registrationNumberAbroad,
      countryOfRegistrationAbroad,
      brandStage,
      brandStatus,
      trademarkStage,
      requestNumber,
      certificate,
      _version,
    }
    const { data } = await API.graphql({
      query: updateBrand,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    if (data) return data?.updateBrand
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const putBrandData = async ({
  id,
  name,
  typeID,
  files,
  hasTransliteration,
  transliteration,
  hasTranslation,
  translation,
  hasBeenUsed,
  dateOfInitialUse,
  applicantID,
  sameAddressForEstablishment,
  establishmentDomicile,
  mustBill,
  invoiceforApplicant,
  sameAddressForBilling,
  applicantBillingID,
  _version,
}) => {
  try {
    const item = {
      id,
      name,
      typeID,
      files,
      hasTransliteration,
      transliteration,
      hasTranslation,
      translation,
      hasBeenUsed,
      dateOfInitialUse,
      applicantID,
      sameAddressForEstablishment,
      establishmentDomicile,
      mustBill,
      invoiceforApplicant,
      sameAddressForBilling,
      applicantBillingID,
      _version,
    }
    const { data } = await API.graphql({
      query: updateBrandData,
      variables: {
        input: {
          ...item,
        },
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    if (data) return data?.updateBrandData
    return null
  } catch (e) {
    debug('e', e)
    return null
  }
}

export const moveBrandsToStage = async ({ brands, trademarkStage }) => {
  if (!brands || brands.length === 0) return []
  const updatedBrands = brands.map(async (brand) => {
    const updatedBrand = await putBrand({
      id: brand.id,
      // eslint-disable-next-line no-underscore-dangle
      _version: brand._version,
      trademarkStage,
    })
    return updatedBrand
  })
  const all = await Promise.all(updatedBrands)
  return all
}
