import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Authenticator } from 'aws-amplify-react'
// eslint-disable-next-line import/no-named-as-default
import MaterialSignUp from './MaterialSignUp'
import MaterialDeniedAccessComponent from './MaterialDeniedAccessComponent'

export const BaseAuthenticator = (props) => {
  const {
    Comp,
    DeniedAccessComp,
    acceptedCognitoGroups,
    includeGreetings,
    authenticatorComponents,
    authState,
    authData,
    federated,
    theme,
    signUpConfig,
  } = props
  const [state, setState] = useState({
    currentAuthState: authState || null,
    currentAuthData: authData || null,
  })
  useEffect(() => {
    setState({
      currentAuthState: authState,
      currentAuthData: authData,
    })
  }, [authState, authData])

  const [authConfig, setAuthConfig] = useState({})

  const finalSignUpConfig = signUpConfig
  const configHasKeys = finalSignUpConfig
    ? Object.keys(finalSignUpConfig).length > 0
    : false

  useEffect(() => {
    // Override signUpComponent
    const signUpType = MaterialSignUp
    const childrenAuthComponents = React.Children.map(
      authenticatorComponents,
      (child) => {
        const finalConfig = configHasKeys
          ? finalSignUpConfig
          : child.props.signUpConfig
        return React.cloneElement(child, {
          signUpConfig:
            child.type === signUpType ? finalConfig : child.props.signUpConfig,
        })
      }
    )
    if (typeof includeGreetings === 'object' && includeGreetings !== null) {
      setAuthConfig(Object.assign(authConfig, includeGreetings))
    } else {
      setAuthConfig({
        includeGreetings,
        authenticatorComponents: childrenAuthComponents,
        federated,
        theme,
        signUpConfig: finalSignUpConfig,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAuthStateChange = (currentAuthState, currentAuthData) => {
    setState({
      currentAuthState,
      currentAuthData,
    })
  }

  const renderComponent = Comp ? (
    <Comp
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      authState={state.currentAuthState}
      authData={state.currentAuthData}
      onStateChange={handleAuthStateChange}
    />
  ) : null

  const renderDeniedAccesComponent = DeniedAccessComp ? (
    <DeniedAccessComp // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      authState={state.currentAuthState}
      authData={state.currentAuthData}
      onStateChange={handleAuthStateChange}
    />
  ) : null

  let denyAcces = true
  if (acceptedCognitoGroups) {
    acceptedCognitoGroups.forEach((cg) => {
      if (
        state?.currentAuthData?.signInUserSession?.accessToken?.payload?.[
          'cognito:groups'
        ]?.indexOf(cg) > -1
      ) {
        denyAcces = false
      }
    })
  }

  return (
    <>
      {state.currentAuthState === 'signedIn' ? (
        <>
          {authConfig.includeGreetings ? (
            <Authenticator
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              authState={state.currentAuthState}
              authData={state.currentAuthData}
              theme={authConfig.theme}
              federated={authConfig.federated}
              hideDefault={
                authConfig.authenticatorComponents &&
                authConfig.authenticatorComponents.length > 0
              }
              signUpConfig={authConfig.signUpConfig}
              usernameAttributes={authConfig.usernameAttributes}
              onStateChange={handleAuthStateChange}
              // eslint-disable-next-line react/no-children-prop
              children={authConfig.authenticatorComponents || []}
            />
          ) : null}
          {denyAcces ? renderDeniedAccesComponent : renderComponent}
        </>
      ) : (
        <Authenticator
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          authState={state.currentAuthState}
          authData={state.currentAuthData}
          theme={authConfig.theme}
          federated={authConfig.federated}
          hideDefault={
            authConfig.authenticatorComponents &&
            authConfig.authenticatorComponents.length > 0
          }
          signUpConfig={authConfig.signUpConfig}
          usernameAttributes={authConfig.usernameAttributes}
          onStateChange={handleAuthStateChange}
          // eslint-disable-next-line react/no-children-prop
          children={authConfig.authenticatorComponents || []}
        />
      )}
    </>
  )
}

BaseAuthenticator.propTypes = {
  Comp: PropTypes.elementType,
  DeniedAccessComp: PropTypes.elementType,
  acceptedCognitoGroups: PropTypes.arrayOf([PropTypes.string]).isRequired,
  includeGreetings: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.node,
    PropTypes.element,
  ]),
  authenticatorComponents: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.element),
  ]),
  authState: PropTypes.oneOf([
    'signIn',
    'signUp',
    'confirmSignIn',
    'confirmSignUp',
    'forgotPassword',
    'requireNewPassword',
    'verifyContact',
    'signedIn',
    'signedUp',
    'signedOut',
    'loading',
    'TOTPSetup',
  ]),
  authData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  federated: PropTypes.shape({}),
  theme: PropTypes.shape({}),
  signUpConfig: PropTypes.shape({}),
}

BaseAuthenticator.defaultProps = {
  Comp: undefined,
  // eslint-disable-next-line react/display-name
  DeniedAccessComp: MaterialDeniedAccessComponent,
  includeGreetings: false,
  authenticatorComponents: [],
  authState: 'signIn',
  authData: null,
  federated: null,
  theme: null,
  signUpConfig: {},
}

const withCustomAuthenticator = (
  Comp,
  DeniedAccessComp,
  acceptedCognitoGroups,
  includeGreetings = false,
  authenticatorComponents = [],
  federated = null,
  theme = null,
  signUpConfig = {}
) => (props) => {
  return (
    <BaseAuthenticator
      Comp={Comp}
      DeniedAccessComp={DeniedAccessComp}
      acceptedCognitoGroups={acceptedCognitoGroups}
      includeGreetings={includeGreetings}
      authenticatorComponents={authenticatorComponents}
      federated={federated}
      theme={theme}
      signUpConfig={signUpConfig}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default withCustomAuthenticator
