const settingsTypes = [
  {
    id: '1',
    name: 'Contraseña',
    icon: 'FingerprintOutlined',
    helperText: 'Gestión de contraseña',
    helperPopOverText:
      'En esta sección puedes realizar el cambio de tu contraseña actual.',
    to: '/settings/change-password',
  },
]

export default settingsTypes
