import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Grid,
  Typography,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import countries, { countryCodes } from './country-dial-codes'

// const finalCountries = countries.sort((a, b) => (a.phone > b.phone ? 1 : -1))
const finalCountries = countries.sort((a, b) =>
  // eslint-disable-next-line no-nested-ternary
  a.phone.length > b.phone.length
    ? 1
    : // eslint-disable-next-line no-nested-ternary
    a.phone.length === b.phone.length
    ? a.phone > b.phone
      ? 1
      : -1
    : -1
)

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode
}

const countryToText = (country) => {
  // return `(${country.code}) +${country.phone}`
  return `+${country.phone}`
}

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'left',
  },
  formControl: {
    // margin: theme.spacing(1),
    // https://www.johnvincent.io/material-ui/material-ui-responsive/
    // https://stackoverflow.com/a/50780995/5776194
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  noLabel: {
    marginTop: theme.spacing(1),
  },
}))

const PhoneField = (props) => {
  const classes = useStyles()
  const {
    id,
    name,
    label,
    required,
    disabled,
    renderDialCodeLabel,
    dialCodeLabel,
    dialCodeName,
    renderFlag,
    variant,
    className,
    placeholder,
    size,
    fullWidth,
    defaultCountry,
    control,
    rules,
    rulesForDialCode,
    inputProps,
    InputProps,
    errors,
  } = props
  const countriesDialCodes = renderFlag
    ? finalCountries
    : // https://dev.to/marinamosti/removing-duplicates-in-an-array-of-objects-in-js-with-sets-3fep
      Array.from(new Set(finalCountries.map((a) => a.phone))).map((phone) => {
        return finalCountries.find((a) => a.phone === phone)
      })

  const defaultDialCodeName = dialCodeName || `${name}DialCode`

  const dialCodeValueName = 'dialCode'
  const [values, setValues] = useState({
    [dialCodeValueName]: defaultCountry,
    [name]: '',
  })

  const composePhoneNumber = (countryCode, phoneLineNumber) => {
    const finalCountryCode = countryCode || defaultCountry
    const selectedDialCode = countryToText(
      countriesDialCodes.find((c) => c.code === finalCountryCode)
    )
    return `${selectedDialCode || '+52'}${phoneLineNumber.replace(
      // /[-() ]/g,
      /[-()]/g,
      ''
    )}`
  }

  const handleInputChange = async (event) => {
    // https://sung.codes/blog/2018/12/07/setting-react-hooks-states-in-a-sync-like-manner/
    const { onChangeSelect, onChangeText } = props
    const { name: inputName, value: inputValue } = event.target
    const finalValues = {
      ...values,
      [inputName]: inputValue,
    }
    setValues(finalValues)

    if (inputName === dialCodeValueName && onChangeSelect) {
      onChangeSelect(finalValues.dialCode)
    }
    if (onChangeText) {
      onChangeText(composePhoneNumber(finalValues.dialCode, finalValues[name]))
    }
  }

  const renderSelectedCountry = (selected) => (
    <div>
      <span>
        {countryToFlag(selected)}{' '}
        {countryToText(countriesDialCodes.find((c) => c.code === selected))}
      </span>
    </div>
  )

  const renderCountryOptions = () => {
    return countriesDialCodes.map((country) => (
      <MenuItem key={country.code} value={country.code}>
        {renderFlag ? (
          <ListItemIcon>
            <span>{countryToFlag(country.code)}</span>
          </ListItemIcon>
        ) : null}
        <ListItemText
          disableTypography
          primary={<Typography>{countryToText(country)}</Typography>}
        />
      </MenuItem>
    ))
  }

  const error = _.get(errors, name)
  const errorDialCode = _.get(errors, defaultDialCodeName)

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={5} md={4} lg={3}>
        <FormControl
          size={size}
          variant={variant}
          className={clsx(className, classes.formControl)}
          error={control ? !!errorDialCode : false}
          required={required}
          disabled={disabled}
          fullWidth={fullWidth}>
          {renderDialCodeLabel ? (
            <InputLabel id="dialCodeLabel">{dialCodeLabel}</InputLabel>
          ) : null}
          {control ? (
            <Controller
              control={control}
              name={defaultDialCodeName}
              rules={rulesForDialCode}
              render={({ onChange, value }) => {
                return (
                  <Select
                    id={defaultDialCodeName}
                    name={defaultDialCodeName}
                    labelId="dialCodeLabel"
                    label={dialCodeLabel}
                    variant={variant}
                    error={!!errorDialCode}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    value={value}
                    renderValue={renderSelectedCountry}
                    onChange={(event) => {
                      handleInputChange(event)
                      onChange(event.target.value)
                    }}
                    type="select">
                    {renderCountryOptions()}
                  </Select>
                )
              }}
            />
          ) : (
            <Select
              id={defaultDialCodeName}
              name={defaultDialCodeName}
              labelId="dialCodeLabel"
              label={dialCodeLabel}
              variant={variant}
              disabled={disabled}
              fullWidth={fullWidth}
              value={values.dialCode}
              renderValue={renderSelectedCountry}
              onChange={handleInputChange}
              type="select">
              {renderCountryOptions()}
            </Select>
          )}
          {errorDialCode?.message ? (
            <FormHelperText>{errorDialCode.message}</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={7} md={8} lg={9}>
        {control ? (
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ onChange, value }) => {
              return (
                <TextField
                  id={id}
                  name={name}
                  label={label}
                  type="tel"
                  placeholder={placeholder}
                  required={required}
                  disabled={disabled}
                  variant={variant}
                  size={size}
                  fullWidth={fullWidth}
                  className={clsx(className)}
                  value={value}
                  onChange={(event) => {
                    handleInputChange(event)
                    onChange(event.target.value)
                  }}
                  inputMode="tel"
                  autoCorrect="off"
                  autoComplete="tel"
                  error={!!error}
                  helperText={error?.message}
                  inputProps={inputProps}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={InputProps}
                />
              )
            }}
          />
        ) : (
          <TextField
            id={id}
            name={name}
            label={label}
            type="tel"
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            variant={variant}
            size={size}
            fullWidth={fullWidth}
            className={clsx(className)}
            value={values[name]}
            onChange={handleInputChange}
            inputMode="tel"
            autoCorrect="off"
            autoComplete="tel"
            inputProps={inputProps}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={InputProps}
          />
        )}
      </Grid>
    </Grid>
  )
}
PhoneField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  renderDialCodeLabel: PropTypes.bool,
  dialCodeLabel: PropTypes.string,
  dialCodeName: PropTypes.string,
  renderFlag: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  fullWidth: PropTypes.bool,
  defaultCountry: PropTypes.oneOf(countryCodes),
  onChangeText: PropTypes.func,
  onChangeSelect: PropTypes.func,
  control: PropTypes.shape({}),
  rules: PropTypes.shape({}),
  rulesForDialCode: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  errors: PropTypes.shape({}),
}
PhoneField.defaultProps = {
  name: 'phone',
  label: undefined,
  required: false,
  disabled: undefined,
  renderDialCodeLabel: true,
  dialCodeLabel: 'Code',
  dialCodeName: undefined,
  renderFlag: true,
  variant: 'outlined',
  className: undefined,
  placeholder: undefined,
  size: 'small',
  fullWidth: false,
  defaultCountry: 'MX',
  onChangeText: undefined,
  onChangeSelect: undefined,
  control: undefined,
  rules: undefined,
  rulesForDialCode: undefined,
  inputProps: undefined,
  InputProps: undefined,
  errors: undefined,
}
export default PhoneField
