import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ConfirmProvider } from 'material-ui-confirm'
import DeviceOrientation from 'react-screen-orientation'
import ReactResizeDetector from 'react-resize-detector'
import { isMobile } from 'react-device-detect'
import { SnackbarProvider } from 'notistack'
import SideBarContent from '../components/account/SideBarContent'
import { BaseLayout } from './BaseLayout'
import Header from './Header'
import NavHeader from './NavHeader'
import Footer from './Footer'
import {
  useLayoutContext,
  LayoutConsumer,
  LayoutContextWrapper,
  TrademarkRegistrationFlowProvider,
} from '../components/context'
import LoaderOverlay from '../components/common/LoaderOverlay'
import { useAmplifyAuth } from '../hooks'

const Layout = (props) => {
  const theme = useTheme()
  const { loading, setOrientation, setWindowSize } = useLayoutContext()

  useAmplifyAuth()

  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <LayoutConsumer>
        {(ctx) => (
          <>
            <TrademarkRegistrationFlowProvider>
              <LoaderOverlay open={loading} ctx={ctx} />
              <DeviceOrientation
                onOrientationChange={(orientation, type, angle) => {
                  setOrientation({ orientation, type, angle })
                }}
              />
              <ReactResizeDetector
                onResize={(width, height) => {
                  setWindowSize({ width, height })
                }}
              />
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                dense={isMobile || isMobileDevice}>
                <BaseLayout
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  ctx={ctx}
                />
              </SnackbarProvider>
            </TrademarkRegistrationFlowProvider>
          </>
        )}
      </LayoutConsumer>
    </>
  )
}

Layout.propTypes = {
  HeaderContent: PropTypes.elementType,
  NavHeaderContent: PropTypes.elementType,
  NavContent: PropTypes.elementType,
  MainContent: PropTypes.elementType,
  FooterContent: PropTypes.elementType,
}

Layout.defaultProps = {
  HeaderContent: undefined,
  NavHeaderContent: undefined,
  NavContent: undefined,
  MainContent: undefined,
  FooterContent: undefined,
}

export const MainLayout = (props) => {
  return (
    <ConfirmProvider>
      <LayoutContextWrapper>
        <Layout
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          HeaderContent={Header}
          NavHeaderContent={NavHeader}
          NavContent={SideBarContent}
          FooterContent={Footer}
        />
      </LayoutContextWrapper>
    </ConfirmProvider>
  )
}

MainLayout.propTypes = {
  MainContent: PropTypes.elementType,
}

MainLayout.defaultProps = {
  MainContent: undefined,
}

const withMainLayout = (MainContent) => (props) => {
  return (
    <MainLayout
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      MainContent={MainContent}
    />
  )
}

export default withMainLayout
