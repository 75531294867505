import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
  },
  popoverContent: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  imagesContainer: {
    justifyContent: 'center',
  },
  popoverImg: {
    width: '75px',
  },
  infoTitle: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  divider: {
    height: '75px',
  },
}))

const BrandTypeModal = (props) => {
  const classes = useStyles()
  const { id, title, text, images } = props
  return (
    <Grid container spacing={1} className={classes.popoverContent}>
      <Grid item xs={12}>
        <Typography className={classes.infoTitle}>{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.infoText}>{text}</Typography>
      </Grid>
      <div className={classes.divider} />
      <Grid container spacing={2} className={classes.imagesContainer}>
        {images &&
          images.map((image) => {
            return (
              <Grid item xs={3} key={id}>
                <img className={classes.popoverImg} src={image} alt="helper" />
              </Grid>
            )
          })}
      </Grid>
    </Grid>
  )
}

BrandTypeModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string),
}

BrandTypeModal.defaultProps = {
  images: [],
}

export default BrandTypeModal
