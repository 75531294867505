import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import { Fade, Slide } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import {
  Root,
  Header,
  Sidebar,
  Content,
  Footer,
  CollapseBtn,
  SidebarTrigger,
} from '@mui-treasury/layout'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import json2mq from 'json2mq'
import { mainPreset, ipadPortraitPreset } from '../config/layoutPresets'
import { useLayoutContext, useTrademarkContext } from '../components/context'
import { AuthEntry } from '../components/security'

const mainConfig = 'mainPreset'
const ipadPortraitConfig = 'ipadPortraitPreset'
const presets = { mainPreset, ipadPortraitPreset }

const maxHeightMobileLandscape = 480

const useStyles = makeStyles((theme) => ({
  // https://stackoverflow.com/questions/53772429/material-ui-how-can-i-style-the-scrollbar-with-css-in-js
  /*
  '@global': {
    '*::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      borderRadius: '8px',
    },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '2px solid white',
      backgroundColor: 'rgba(0, 0, 0, .5)',
    },
  },
  */
  /*
  '@global': {
    '*': {
      'scrollbar-width': 'thin',
    },
    '*::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
  },
  */
  '@global': {
    [`${theme.breakpoints.up('md')}`]: {
      '*::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: '0.6em',
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        borderRadius: '8px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey',
        borderRadius: '8px',
        border: '2px solid white',
      },
    },
    // https://stackoverflow.com/questions/9836151/what-is-this-css-selector-class-span
    // https://www.impressivewebs.com/css3-attribute-selectors-substring-matching/
    // https://code.tutsplus.com/tutorials/the-30-css-selectors-you-must-memorize--net-16048
    // https://www.w3.org/TR/selectors-3/
    'div [class^="Toast"]': {
      position: 'relative',
    },
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(7),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [`${theme.breakpoints.down('sm')} and (orientation: portrait)`]: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [`${theme.breakpoints.down('xs')} and (orientation: portrait)`]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(3),
    },
    // https://material-ui.com/customization/breakpoints/
    // https://stackoverflow.com/a/57425397/5776194
    [`${theme.breakpoints.up('sm')} and (orientation: portrait)`]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    [`${theme.breakpoints.up('md')} and (orientation: portrait)`]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  paper: {
    [`${theme.breakpoints.down('xs')} and (orientation: portrait)`]: {
      maxHeight: '50%',
    },
    // [`@media screen and (max-width:${theme.breakpoints.values.md}px) and (max-height: ${theme.breakpoints.values.sm}px) and (orientation: landscape)`]: {
    [`@media screen and (max-width:${theme.breakpoints.values.md}px) and (max-height: ${maxHeightMobileLandscape}px) and (orientation: landscape)`]: {
      width: '100% !important',
    },
  },
  footer: {
    padding: 10,
    flexDirection: 'column-reverse',
    [`${theme.breakpoints.up('sm')}`]: {
      maxHeight: 90,
    },
    backgroundColor: theme.palette.grey[200],
  },
  enabled: {
    display: 'block',
  },
  disabled: {
    display: 'none',
  },
  authEntry: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddinBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
  },
}))

export const BaseLayout = (props) => {
  const {
    currentUser,
    authenticated,
    showAuthUI,
    authState,
    firstSignUp,
  } = useLayoutContext()
  const { setBrand } = useTrademarkContext()
  const classes = useStyles()
  const theme = useTheme()
  const {
    HeaderContent,
    NavHeaderContent,
    NavContent,
    MainContent,
    FooterContent,
    ctx,
  } = props
  /* eslint-disable-next-line no-unused-vars */
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))
  /* eslint-disable-next-line no-unused-vars */
  const isTabletDevice = useMediaQuery(theme.breakpoints.between('xs', 'md'))
  const isMobileDeviceAndLandscape = useMediaQuery(
    json2mq([
      {
        screen: true,
        maxWidth: theme.breakpoints.values.md,
        // maxHeight: theme.breakpoints.values.sm,
        maxHeight: maxHeightMobileLandscape,
        orientation: 'landscape',
      },
    ])
  )
  const isMobileDeviceAndPortrait = useMediaQuery(
    json2mq([
      {
        screen: true,
        // maxWidth: theme.breakpoints.values.md,
        maxWidth: maxHeightMobileLandscape,
        maxHeight: theme.breakpoints.values.md,
        orientation: 'portrait',
      },
    ])
  )
  const isTabletDeviceAndPortrait = useMediaQuery(
    json2mq([
      {
        screen: true,
        minWidth: theme.breakpoints.values.sm,
        maxWidth: theme.breakpoints.values.md,
        orientation: 'portrait',
      },
    ])
  )
  const [preset, setPreset] = useState(mainConfig)

  useEffect(() => {
    if (isTabletDeviceAndPortrait) {
      setPreset(ipadPortraitConfig)
    } else {
      setPreset(mainConfig)
    }
  }, [isTabletDeviceAndPortrait])

  const clearData = () => {
    setBrand(null)
  }

  return (
    <div id="baseLayout" className={classes.root}>
      <Root
        config={presets[preset]}
        omitThemeProvider
        // initialOpened={false}
        // initialCollapsed={false}
      >
        {({
          headerStyles,
          sidebarStyles,
          // eslint-disable-next-line no-unused-vars
          containerStyles,
          collapsed,
          setCollapsed,
          opened,
          setOpened,
        }) => (
          <>
            {/* <CssBaseline /> */}
            <Header className={classes.header}>
              <Toolbar>
                {authenticated ? (
                  <SidebarTrigger className={headerStyles.leftTrigger}>
                    {opened ? <ChevronLeftIcon /> : <MenuIcon />}
                  </SidebarTrigger>
                ) : null}
                <HeaderContent ctx={ctx} />
              </Toolbar>
            </Header>
            {authenticated ? (
              <Sidebar PaperProps={{ className: classes.paper }}>
                {(isMobileDeviceAndLandscape || isMobileDeviceAndPortrait) && (
                  <SidebarTrigger>
                    {!collapsed && opened && <CloseIcon />}
                  </SidebarTrigger>
                )}
                <NavHeaderContent collapsed={collapsed} ctx={ctx} />
                <div className={sidebarStyles.container}>
                  <NavContent
                    ctx={ctx}
                    currentUser={currentUser}
                    isAuthenticated={authenticated}
                    setOpened={setOpened}
                    setCollapsed={setCollapsed}
                    clearData={clearData}
                  />
                </div>
                {(!isMobileDeviceAndLandscape || isTabletDeviceAndPortrait) && (
                  <CollapseBtn className={sidebarStyles.collapseBtn}>
                    {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </CollapseBtn>
                )}
              </Sidebar>
            ) : null}
            <Content className={classes.content}>
              <Slide
                mountOnEnter
                unmountOnExit
                in={showAuthUI}
                timeout={{ appear: 300, enter: 300, exit: 100 }}>
                <div className={classes.authEntry}>
                  <AuthEntry
                    authState={authState}
                    theme={theme}
                    firstSignUp={firstSignUp}
                  />
                </div>
              </Slide>
              <Fade
                in={!showAuthUI}
                timeout={{ appear: 50, enter: 50, exit: 50 }}>
                <div
                  className={showAuthUI ? classes.disabled : classes.enabled}>
                  <MainContent {...props} ctx={ctx} />
                </div>
              </Fade>
            </Content>
            <Footer className={classes.footer}>
              <FooterContent ctx={ctx} />
            </Footer>
          </>
        )}
      </Root>
    </div>
  )
}

BaseLayout.propTypes = {
  HeaderContent: PropTypes.elementType,
  NavHeaderContent: PropTypes.elementType,
  NavContent: PropTypes.elementType,
  MainContent: PropTypes.elementType,
  FooterContent: PropTypes.elementType,
  ctx: PropTypes.shape({}),
}

BaseLayout.defaultProps = {
  HeaderContent: undefined,
  NavHeaderContent: undefined,
  NavContent: undefined,
  MainContent: undefined,
  FooterContent: undefined,
  ctx: undefined,
}

const withBaseLayout = (
  HeaderContent,
  NavHeaderContent,
  NavContent,
  MainContent,
  FooterContent
) => (props) => (
  <BaseLayout
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    HeaderContent={HeaderContent}
    NavHeaderContent={NavHeaderContent}
    NavContent={NavContent}
    MainContent={MainContent}
    FooterContent={FooterContent}
  />
)

export default withBaseLayout
