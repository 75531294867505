import React, { createContext, useContext, useState, useEffect } from 'react'
import { useStorageState } from 'react-storage-hooks'
import PropTypes from 'prop-types'

const defaultSearchValues = {
  searchOperator: undefined,
  brandType: null,
  brandName: null,
  applicantKind: null,
  applicantName: null,
  applicantSurname: null,
  applicantSecondSurname: null,
}

const defaultContextValue = {
  brandSearchValues: defaultSearchValues,
  setBrandSearchValues: () => {},
  defaultSearchValues,
}

export const TrademarkSearchContext = createContext(defaultContextValue)

export const TrademarkSearchContextProvider = TrademarkSearchContext.Provider
export const TrademarkSearchContextConsumer = TrademarkSearchContext.Consumer

TrademarkSearchContext.displayName = 'TrademarkSearchFlowCtx'

export const useTrademarkSearchContext = () => {
  const context = useContext(TrademarkSearchContext)
  if (!context) {
    throw new Error(`Context cannot be used outside the Provider`)
  }
  return context
}

export const TrademarkSearchFlowConsumer = (props) => {
  const { children } = props
  return (
    <>
      {typeof children === 'function' ? (
        <TrademarkSearchContextConsumer>
          {(ctx) => {
            if (ctx === undefined) {
              throw new Error(
                'TrademarkSearchContextConsumer must be used within a TrademarkSearchFlowConsumer'
              )
            }
            return children({ ...ctx })
          }}
        </TrademarkSearchContextConsumer>
      ) : (
        children
      )}
    </>
  )
}

TrademarkSearchFlowConsumer.defaultProps = {
  children: undefined,
}

TrademarkSearchFlowConsumer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
}

export const TrademarkSearchFlowProvider = (props) => {
  const { children } = props

  // https://github.com/soyguijarro/react-storage-hooks
  const [
    brandSearchValuesStorage,
    setBrandSearchValuesStorage,
  ] = useStorageState(localStorage, 'brandSearchValues')

  const [brandSearchValues, setBrandSearchValues] = useState(
    brandSearchValuesStorage
  )

  useEffect(() => {
    setBrandSearchValuesStorage(brandSearchValues)
  }, [brandSearchValues, setBrandSearchValuesStorage])

  return (
    <TrademarkSearchContextProvider
      value={{
        brandSearchValues,
        setBrandSearchValues,
        defaultSearchValues,
      }}>
      {children}
    </TrademarkSearchContextProvider>
  )
}

TrademarkSearchFlowProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

TrademarkSearchFlowProvider.defaultProps = {
  children: undefined,
}

export const TrademarkSearchContextWrapper = (props) => {
  const { children } = props
  return <TrademarkSearchFlowProvider>{children}</TrademarkSearchFlowProvider>
}

TrademarkSearchContextWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

TrademarkSearchContextWrapper.defaultProps = {
  children: undefined,
}

export const withTrademarkSearchFlowContex = (Component) => {
  const innerWithTrademarkSearchFlowContex = (props) => {
    return (
      <TrademarkSearchContextWrapper>
        <Component {...props} />
      </TrademarkSearchContextWrapper>
    )
  }
  innerWithTrademarkSearchFlowContex.propTypes = {}
  return innerWithTrademarkSearchFlowContex
}
