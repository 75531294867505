import React, { createContext, useContext, useState, cloneElement } from 'react'
import PropTypes from 'prop-types'

export const SearchFieldContext = createContext({
  inputValue: undefined,
  setInputValue: () => {},
  currentOptions: undefined,
  setCurrentOptions: () => {},
  value: undefined,
  setValue: () => {},
})

export const SearchFieldContextProvider = SearchFieldContext.Provider
export const SearchFieldContextConsumer = SearchFieldContext.Consumer

SearchFieldContext.displayName = 'SearchFieldContext'

export const useSearchFieldContext = () => {
  const context = useContext(SearchFieldContext)
  if (!context) {
    throw new Error(`Context cannot be used outside the Provider`)
  }
  return context
}

export const SearchFieldConsumer = (props) => {
  const { children } = props
  return (
    <>
      {typeof children === 'function' ? (
        <SearchFieldContextConsumer>
          {(ctx) => {
            if (ctx === undefined) {
              throw new Error(
                'SearchFieldContextConsumer must be used within a SearchFieldConsumer'
              )
            }
            return children({ ...ctx, ctxDefined: true })
          }}
        </SearchFieldContextConsumer>
      ) : (
        children
      )}
    </>
  )
}

SearchFieldConsumer.defaultProps = {
  children: undefined,
}

SearchFieldConsumer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
}

export const SearchFieldProvider = (props) => {
  const { children, multiple } = props
  const [inputValue, setInputValue] = useState('')
  const [currentOptions, setCurrentOptions] = useState([])
  const [value, setValue] = useState(multiple ? [] : null)

  return (
    <SearchFieldContextProvider
      value={{
        inputValue,
        setInputValue,
        currentOptions,
        setCurrentOptions,
        value,
        setValue,
      }}>
      {children}
    </SearchFieldContextProvider>
  )
}

SearchFieldProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  multiple: PropTypes.bool.isRequired,
}

SearchFieldProvider.defaultProps = {
  children: undefined,
}

export const SearchFieldContextWrapper = (props) => {
  const { children } = props
  if (Array.isArray(children)) {
    throw new Error(
      'SearchFieldContextWrapper expect a unique child, not an array.'
    )
  }
  const { props: childProps } = children
  const { multiple } = childProps
  if (
    typeof multiple === 'undefined' ||
    multiple === null ||
    typeof multiple !== 'boolean'
  ) {
    throw new Error(
      `The property [multiple] for child component '${children.type.name}' is not defined. Define property [multiple] boolean value [true|false]. Example: <${children.type.name} multiple={false} />`
    )
  }
  return (
    <SearchFieldProvider {...childProps}>
      <SearchFieldConsumer>
        {(contexts) => {
          // https://reactgo.com/react-pass-props-children/
          return cloneElement(children, {
            // this properties are available as a props in child components
            ...contexts,
          })
        }}
      </SearchFieldConsumer>
    </SearchFieldProvider>
  )
}

SearchFieldContextWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

SearchFieldContextWrapper.defaultProps = {
  children: undefined,
}

export const withSearchFieldContext = (Component) => {
  const innerWithSearchFieldContext = (props) => {
    return (
      <SearchFieldContextWrapper>
        <Component {...props} />
      </SearchFieldContextWrapper>
    )
  }
  innerWithSearchFieldContext.propTypes = {
    multiple: PropTypes.bool.isRequired,
  }
  return innerWithSearchFieldContext
}

export default withSearchFieldContext
