/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'

export const signUpFields = [
  {
    label: I18n.get('Email'),
    key: 'username',
    required: true,
    displayOrder: 1,
    type: 'string',
    custom: false,
  },
  {
    label: I18n.get('Password'),
    key: 'password',
    required: true,
    displayOrder: 2,
    type: 'password',
    custom: false,
  },
]

export default signUpFields
