import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const LoaderButton = (props) => {
  const {
    color,
    variant,
    clickHandler,
    loading,
    buttonText,
    fullWidth,
    className,
    type,
  } = props
  const classes = useStyles()

  const buttonClassname = clsx({
    [classes.buttonSuccess]: loading,
  })

  const handleButtonClick = (e) => {
    clickHandler(e)
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant={variant}
          color={color}
          className={clsx(buttonClassname, className)}
          disabled={loading}
          onClick={handleButtonClick}
          fullWidth={fullWidth}
          type={type}>
          {buttonText}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  )
}

LoaderButton.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'contained']),
  clickHandler: PropTypes.func,
  loading: PropTypes.bool,
  buttonText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
}
LoaderButton.defaultProps = {
  color: 'primary',
  variant: 'contained',
  clickHandler: () => {},
  loading: false,
  buttonText: undefined,
  fullWidth: true,
  className: undefined,
  type: 'button',
}

export default LoaderButton
