/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { I18n, Logger } from '@aws-amplify/core'
import { RequireNewPassword } from 'aws-amplify-react'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import Auth from '@aws-amplify/auth'
import PropTypes from 'prop-types'

import {
  TextField,
  Link,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InfoIcon from '@material-ui/icons/Info'

import { auth } from 'aws-amplify-react/lib/Amplify-UI/data-test-attributes'

import MainMaterialLayout from './MainMaterialLayout'

import { withMaterialStyles } from './MainMaterialStyles'

import LoaderButton from '../common/LoaderButton'

const logger = new Logger('RequireNewPassword')

export class MaterialRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props)
    this.state = { loading: false, showPassword: false }
    this.validAuthStates = ['requireNewPassword']
    this.renderHeader = this.renderHeader.bind(this)
    this.renderBody = this.renderBody.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.change = this.change.bind(this)
  }

  objectWithProperties(obj, keys) {
    const target = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (keys.indexOf(key) === -1) {
        // eslint-disable-next-line no-continue
        continue
      }
      if (!Object.prototype.hasOwnProperty.call(obj, key)) {
        // eslint-disable-next-line no-continue
        continue
      }
      target[key] = obj[key]
    }
    return target
  }

  handleClickShowPassword = () => {
    const currentShowPassword = this.state.showPassword
    this.setState({ showPassword: !currentShowPassword })
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  change() {
    this.setState({ loading: true })
    const user = this.props.authData
    const { password } = this.inputs
    const { requiredAttributes } = user.challengeParam
    const attrs = this.objectWithProperties(this.inputs, requiredAttributes)

    if (!Auth || typeof Auth.completeNewPassword !== 'function') {
      this.setState({ loading: false })
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      )
    }
    Auth.completeNewPassword(user, password, attrs)
      .then((userResp) => {
        this.setState({ loading: false })
        logger.debug('complete new password', userResp)
        if (userResp.challengeName === 'SMS_MFA') {
          this.changeState('confirmSignIn', userResp)
        } else if (userResp.challengeName === 'MFA_SETUP') {
          logger.debug('TOTP setup', userResp.challengeParam)
          this.changeState('TOTPSetup', userResp)
        } else {
          this.checkContact(userResp)
        }
      })
      .catch((err) => {
        this.setState({ loading: false })
        this.error(err)
      })
  }

  // eslint-disable-next-line class-methods-use-this
  renderHeader() {
    return (
      <span className={this.props.classes.title}>
        {I18n.get('Change Password')}
      </span>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  renderBody() {
    const user = this.props.authData
    const { variant, openPasswordInformation } = this.props
    const { requiredAttributes } = user.challengeParam
    return (
      <>
        <Grid item className={this.props.classes.mediumSpacing} xs={12}>
          <TextField
            autoFocus
            fullWidth
            required
            label={I18n.get('New Password')}
            key="password"
            name="password"
            type={this.state.showPassword ? 'text' : 'password'}
            size="small"
            onChange={this.handleInputChange}
            data-test={auth.requireNewPassword.newPasswordInput}
            variant={variant}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}>
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => {
                      openPasswordInformation()
                    }}>
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {requiredAttributes.map((attribute) => (
          <Grid
            key={`grid_'${attribute}`}
            item
            className={this.props.classes.mediumSpacing}
            xs={12}>
            <TextField
              label={I18n.get(this.convertToPlaceholder(attribute))}
              key={attribute}
              name={attribute}
              type="text"
              size="small"
              onChange={this.handleInputChange}
              fullWidth
            />
          </Grid>
        ))}
      </>
    )
  }

  renderFooter() {
    return (
      <>
        <Grid item xs={12} sm={12} className={this.props.classes.largeSpacing}>
          <LoaderButton
            clickHandler={this.change}
            loading={this.state.loading}
            buttonText={I18n.get('Change')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <p className={this.props.classes.subtext}>
            <Link
              className={this.props.classes.subtextLink}
              onClick={() => this.changeState('signIn')}>
              {I18n.get('Return to Login')}
            </Link>
          </p>
        </Grid>
      </>
    )
  }

  showComponent() {
    const { classes, hide } = this.props
    if (hide && hide.includes(MaterialRequireNewPassword)) {
      return null
    }
    return (
      <MainMaterialLayout
        Header={this.renderHeader}
        Body={this.renderBody}
        Footer={this.renderFooter}
        classes={classes}
      />
    )
  }
}

MaterialRequireNewPassword.propTypes = {
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'contained']),
}

MaterialRequireNewPassword.defaultProps = {
  variant: 'outlined',
}

export default withMaterialStyles(MaterialRequireNewPassword)
