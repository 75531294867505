import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ style }) => {
  return (
    <svg
      width={style.width}
      height={style.height}
      viewBox="0 0 28 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Carrito-2" transform="translate(-865.000000, -300.000000)">
          <g id="Group-21" transform="translate(865.000000, 300.000000)">
            <path
              d="M28,16.5860732 C28,17.8604634 26.9772727,18.9031463 25.7272727,18.9031463 L3,18.9031463 C1.75,18.9031463 0.727272727,17.8604634 0.727272727,16.5860732 L0.727272727,2.68363415 C0.727272727,1.4092439 1.75,0.366560976 3,0.366560976 L25.7272727,0.366560976 C26.9772727,0.366560976 28,1.4092439 28,2.68363415 L28,16.5860732 Z"
              id="Fill-1"
              fill="#5EC1EC"
            />
            <path
              d="M27.3322727,1.04731707 C27.7440909,1.46717073 28,2.04643902 28,2.68363415 L28,9.63485366 L28,16.5860732 C28,17.2232683 27.7445455,17.8025366 27.3322727,18.2223902 C26.92,18.6422439 26.3522727,18.9031463 25.7272727,18.9031463 L14.3636364,18.9031463 L3,18.9031463 C2.375,18.9031463 1.80681818,18.6427073 1.395,18.2223902 L27.3322727,1.04731707 Z"
              id="Fill-3"
              fill="#5BBBE6"
            />
            <path
              d="M8.26272727,10.1561951 L8.08090909,9.45041463 C8.03818182,9.29378049 7.98727273,9.09126829 7.92636364,8.84195122 C7.86590909,8.59263415 7.82590909,8.41421951 7.80636364,8.30670732 C7.78954545,8.40680488 7.755,8.57131707 7.70409091,8.80070732 C7.65318182,9.03009756 7.54,9.48239024 7.365,10.1566585 L8.26272727,10.1561951 Z M8.70681818,11.9157805 L8.49909091,11.109439 L7.12863636,11.109439 L6.915,11.9157805 L5.66272727,11.9157805 L7.03909091,7.59768293 L8.55954545,7.59768293 L9.95272727,11.9157805 L8.70681818,11.9157805 Z"
              id="Fill-5"
              fill="#FFFFFF"
            />
            <path
              d="M12.2472727,11.9157805 L11.3845455,8.81785366 L11.3586364,8.81785366 C11.3990909,9.34521951 11.4190909,9.75487805 11.4190909,10.0477561 L11.4190909,11.9153171 L10.4095455,11.9153171 L10.4095455,7.61436585 L11.9268182,7.61436585 L12.8072727,10.6682683 L12.8304545,10.6682683 L13.6927273,7.61436585 L15.2136364,7.61436585 L15.2136364,11.9153171 L14.1659091,11.9153171 L14.1659091,10.0296829 C14.1659091,9.93190244 14.1677273,9.82346341 14.1704545,9.70343902 C14.1731818,9.58341463 14.1863636,9.29053659 14.2095455,8.82387805 L14.1836364,8.82387805 L13.3322727,11.9157805 L12.2472727,11.9157805 Z"
              id="Fill-7"
              fill="#FFFFFF"
            />
            <polygon
              id="Fill-9"
              fill="#FFFFFF"
              points="18.5086364 11.9157805 16.0040909 11.9157805 16.0040909 7.61482927 18.5086364 7.61482927 18.5086364 8.54768293 17.1431818 8.54768293 17.1431818 9.22426829 18.4072727 9.22426829 18.4072727 10.1566585 17.1431818 10.1566585 17.1431818 10.968561 18.5086364 10.968561"
            />
            <polygon
              id="Fill-11"
              fill="#FFFFFF"
              points="23.0472727 11.9157805 21.7290909 11.9157805 20.9059091 10.571878 20.0927273 11.9157805 18.8027273 11.9157805 20.2018182 9.7154878 18.8859091 7.61482927 20.15 7.61482927 20.9118182 8.94436585 21.6440909 7.61482927 22.9454545 7.61482927 21.6095455 9.80631707"
            />
            <path
              d="M8.26272727,10.1561951 L8.08090909,9.45041463 C8.03818182,9.29378049 7.98727273,9.09126829 7.92636364,8.84195122 C7.86590909,8.59263415 7.82590909,8.41421951 7.80636364,8.30670732 C7.78954545,8.40680488 7.755,8.57131707 7.70409091,8.80070732 C7.65318182,9.03009756 7.54,9.48239024 7.365,10.1566585 L8.26272727,10.1561951 Z M8.70681818,11.9157805 L8.49909091,11.109439 L7.12863636,11.109439 L6.915,11.9157805 L5.66272727,11.9157805 L7.03909091,7.59768293 L8.55954545,7.59768293 L9.95272727,11.9157805 L8.70681818,11.9157805 Z"
              id="Fill-13"
              fill="#FFFFFF"
            />
            <path
              d="M12.2472727,11.9157805 L11.3845455,8.81785366 L11.3586364,8.81785366 C11.3990909,9.34521951 11.4190909,9.75487805 11.4190909,10.0477561 L11.4190909,11.9153171 L10.4095455,11.9153171 L10.4095455,7.61436585 L11.9268182,7.61436585 L12.8072727,10.6682683 L12.8304545,10.6682683 L13.6927273,7.61436585 L15.2136364,7.61436585 L15.2136364,11.9153171 L14.1659091,11.9153171 L14.1659091,10.0296829 C14.1659091,9.93190244 14.1677273,9.82346341 14.1704545,9.70343902 C14.1731818,9.58341463 14.1863636,9.29053659 14.2095455,8.82387805 L14.1836364,8.82387805 L13.3322727,11.9157805 L12.2472727,11.9157805 Z"
              id="Fill-15"
              fill="#FFFFFF"
            />
            <polygon
              id="Fill-17"
              fill="#FFFFFF"
              points="18.5086364 11.9157805 16.0040909 11.9157805 16.0040909 7.61482927 18.5086364 7.61482927 18.5086364 8.54768293 17.1431818 8.54768293 17.1431818 9.22426829 18.4072727 9.22426829 18.4072727 10.1566585 17.1431818 10.1566585 17.1431818 10.968561 18.5086364 10.968561"
            />
            <polygon
              id="Fill-19"
              fill="#FFFFFF"
              points="23.0472727 11.9157805 21.7290909 11.9157805 20.9059091 10.571878 20.0927273 11.9157805 18.8027273 11.9157805 20.2018182 9.7154878 18.8859091 7.61482927 20.15 7.61482927 20.9118182 8.94436585 21.6440909 7.61482927 22.9454545 7.61482927 21.6095455 9.80631707"
            />
            <path
              d="M25.7272727,0.366560976 L3,0.366560976 C1.75,0.366560976 0.727272727,1.4092439 0.727272727,2.68363415 L0.727272727,16.5860732 C0.727272727,17.8604634 1.75,18.9031463 3,18.9031463 L25.7272727,18.9031463 C26.9772727,18.9031463 28,17.8604634 28,16.5860732 L28,2.68363415 C28,1.4092439 26.9772727,0.366560976 25.7272727,0.366560976 M25.7272727,0.82997561 C26.73,0.82997561 27.5454545,1.66134146 27.5454545,2.68363415 L27.5454545,16.5860732 C27.5454545,17.6083659 26.73,18.4397317 25.7272727,18.4397317 L3,18.4397317 C1.99727273,18.4397317 1.18181818,17.6083659 1.18181818,16.5860732 L1.18181818,2.68363415 C1.18181818,1.66134146 1.99727273,0.82997561 3,0.82997561 L25.7272727,0.82997561"
              id="Fill-21"
              fill="#9BCEE0"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

Icon.propTypes = {
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
}

Icon.defaultProps = {
  style: {
    width: '28px',
    height: '19px',
  },
}

export default Icon
