import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ style }) => {
  return (
    <svg
      width={style.width}
      height={style.height}
      viewBox="0 0 106 89"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <ellipse id="path-1" cx="53" cy="7" rx="53" ry="7" />
        <polygon
          id="path-3"
          points="-1.3500312e-13 0.0866841532 -1.3500312e-13 3.96326923 5.49576905 3.96326923 5.49576905 0.0866841532 -1.35003119e-13 0.0866841532"
        />
        <polygon
          id="path-5"
          points="7.61304454 0.0982051282 0.0379312945 0.0982051282 0.0379312945 4.63360953 7.61304454 4.63360953 7.61304454 0.0982051282"
        />
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Desktop" transform="translate(-163.000000, -1383.000000)">
          <g id="Group-9" transform="translate(163.000000, 1381.000000)">
            <ellipse
              id="Oval"
              fillOpacity="0.5"
              fill="#E7E7E7"
              cx="53"
              cy="84"
              rx="53"
              ry="7"
            />

            <g
              id="Screen-Shot-2017-03-23-at-10.29.14-AM"
              opacity="0.5"
              transform="translate(0.000000, 77.000000)">
              <mask id="mask-2" fill="white" />
              <use id="Mask" fill="#E7E7E7" />
            </g>
            <g
              id="Group-14"
              opacity="0.2"
              transform="translate(22.223338, 17.910932) rotate(28.000000) translate(-22.223338, -17.910932) translate(13.223338, 2.410932)">
              <g id="Page-1" transform="translate(-0.000000, 0.000000)">
                <path
                  d="M15.2771526,25.6656795 L9.72321875,27.712859 C8.95990993,27.9943974 8.57002022,28.8390128 8.85246507,29.5995256 C9.07250184,30.1922179 9.63559007,30.5588846 10.2348364,30.5588846 C10.4045607,30.5588846 10.5775018,30.5293974 10.746068,30.4672179 L16.2997445,28.4200385 C17.063182,28.1387564 17.4530717,27.2938846 17.1706268,26.5333718 C16.8883107,25.7727308 16.0400754,25.3845256 15.2771526,25.6656795"
                  id="Fill-1"
                  fill="#007CCC"
                />
                <path
                  d="M4.84335662,16.1433462 C5.01308088,16.1433462 5.18602206,16.113859 5.35458824,16.0516795 L8.78291544,14.7880897 C9.54622426,14.5066795 9.93611397,13.6619359 9.65366912,12.9012949 C9.37122426,12.1407821 8.523375,11.7527051 7.76006618,12.0337308 L4.33186765,13.2973205 C3.56843015,13.578859 3.17854044,14.4234744 3.46098529,15.1841154 C3.68102206,15.7766795 4.24423897,16.1433462 4.84335662,16.1433462"
                  id="Fill-3"
                  fill="#007CCC"
                />
                <g id="Group-7" transform="translate(0.000000, 22.978718)">
                  <mask id="mask-4" fill="white" />
                  <g id="Clip-6" />
                  <path
                    d="M3.51019118,0.178269231 L0.962783088,1.11724359 C0.199345588,1.39878205 -0.190415441,2.24339744 0.0919007353,3.00403846 C0.3119375,3.59660256 0.875154412,3.96326923 1.47427206,3.96326923 C1.644125,3.96326923 1.8169375,3.93378205 1.98563235,3.87160256 L4.53304044,2.93262821 C5.29634926,2.65108974 5.68623897,1.80647436 5.40379412,1.04596154 C5.12134926,0.285320513 4.2735,-0.103012821 3.51019118,0.178269231"
                    id="Fill-5"
                    fill="#007CCC"
                    mask="url(#mask-4)"
                  />
                </g>
                <g id="Group-10" transform="translate(4.761029, 0.030000)">
                  <mask id="mask-6" fill="white" />
                  <g id="Clip-9" />
                  <path
                    d="M7.5236489,3.79157692 C7.78704963,3.11324359 7.44863051,2.35055128 6.76793199,2.08811538 L1.83704963,0.187217949 C1.15622243,-0.0752179487 0.390854779,0.261961538 0.127325368,0.940166667 C-0.136075368,1.6185 0.20234375,2.38119231 0.883170956,2.64362821 L5.81392463,4.54452564 C6.49475184,4.80696154 7.26024816,4.46991026 7.5236489,3.79157692"
                    id="Fill-8"
                    fill="#007CCC"
                    mask="url(#mask-6)"
                  />
                </g>
                <path
                  d="M12.1375496,18.1888462 C12.0748842,17.4642308 12.6136526,16.8261538 13.3410607,16.7637179 C14.0683401,16.7012821 14.7086342,17.2382051 14.7712996,17.9628205 C14.8339651,18.6874359 14.2951967,19.3255128 13.5679173,19.3879487 C12.8406379,19.4502564 12.2002151,18.9134615 12.1375496,18.1888462"
                  id="Fill-15"
                  fill="#73CED9"
                />
                <path
                  d="M8.92833272,21.9891154 C8.86566728,21.2645 9.40443566,20.6264231 10.1318437,20.5639872 C10.8591232,20.5015513 11.4994173,21.0384744 11.5620827,21.7630897 C11.6247482,22.4877051 11.0859798,23.1257821 10.3587004,23.1882179 C9.63142096,23.2505256 8.99099816,22.7137308 8.92833272,21.9891154"
                  id="Fill-17"
                  fill="#73CED9"
                />
              </g>
            </g>
            <g
              id="Group-14"
              opacity="0.2"
              transform="translate(84.734204, 55.919239) rotate(222.000000) translate(-84.734204, -55.919239) translate(79.734204, 48.419239)">
              <g id="Page-1" transform="translate(0.000000, 0.000000)">
                <path
                  d="M7.27715257,9.66567949 L1.72321875,11.712859 C0.959909926,11.9943974 0.570020221,12.8390128 0.852465074,13.5995256 C1.07250184,14.1922179 1.63559007,14.5588846 2.2348364,14.5588846 C2.40456066,14.5588846 2.57750184,14.5293974 2.74606801,14.4672179 L8.29974449,12.4200385 C9.06318199,12.1387564 9.45307169,11.2938846 9.17062684,10.5333718 C8.88831066,9.77273077 8.04007537,9.38452564 7.27715257,9.66567949"
                  id="Fill-1"
                  fill="#007CCC"
                />
                <path
                  d="M4.13754963,2.18884615 C4.07488419,1.46423077 4.61365257,0.826153846 5.34106066,0.763717949 C6.06834007,0.701282051 6.70863419,1.23820513 6.77129963,1.96282051 C6.83396507,2.6874359 6.29519669,3.32551282 5.56791728,3.38794872 C4.84063787,3.45025641 4.20021507,2.91346154 4.13754963,2.18884615"
                  id="Fill-15"
                  fill="#73CED9"
                />
                <path
                  d="M0.928332721,5.98911538 C0.865667279,5.2645 1.40443566,4.62642308 2.13184375,4.56398718 C2.85912316,4.50155128 3.49941728,5.03847436 3.56208272,5.76308974 C3.62474816,6.48770513 3.08597978,7.12578205 2.35870037,7.18821795 C1.63142096,7.25052564 0.990998162,6.71373077 0.928332721,5.98911538"
                  id="Fill-17"
                  fill="#73CED9"
                />
              </g>
            </g>
            <g
              id="Group-12"
              transform="translate(36.000000, 25.000000)"
              stroke="#0074BE">
              <rect
                id="Rectangle-2"
                strokeWidth="3.66666667"
                strokeLinecap="round"
                strokeLinejoin="round"
                x="0.5"
                y="0"
                width="33"
                height="23.8333333"
              />
              <path
                d="M0.5,9.16666667 L33.5,9.16666667"
                id="Line"
                strokeWidth="7.33333333"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

Icon.propTypes = {
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
}

Icon.defaultProps = {
  style: {
    width: '106px',
    height: '89px',
  },
}

export default Icon
