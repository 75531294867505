import React from 'react'
/* eslint-disable import/no-extraneous-dependencies */
import { I18n } from '@aws-amplify/core'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import Settings from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { MaterialSignOut } from '../security'
import { useLayoutContext } from '../context'
import { ACCEPTED_COGNITO_GROUPS } from '../../config'

const acceptedCognitoGroups = JSON.parse(ACCEPTED_COGNITO_GROUPS)

const useStyles = makeStyles((theme) => ({
  menuItem: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  menuItemSelected: {
    textDecoration: 'none',
    '&:link, &:visited, &:active': {
      textDecoration: 'none',
    },
    '& > div': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      color: theme.palette.primary.main,
    },
  },
}))

const NavLink = (props) => {
  const classes = useStyles()

  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        // the object returned here is passed to the
        // anchor element's props
        return {
          className: clsx(
            classes.menuItem,
            isCurrent && classes.menuItemSelected
          ),
        }
      }}
    />
  )
}

const SideBarContent = (props) => {
  const { setShowNotifications } = useLayoutContext()
  const {
    currentUser,
    isAuthenticated,
    setOpened,
    setCollapsed,
    clearData,
  } = props

  let denyAcces = true
  if (acceptedCognitoGroups) {
    acceptedCognitoGroups.forEach((cg) => {
      if (
        currentUser?.signInUserSession?.accessToken?.payload?.[
          'cognito:groups'
        ]?.indexOf(cg) > -1
      ) {
        denyAcces = false
      }
    })
  }

  const list = [
    {
      primaryText: 'Trademarks',
      icon: <NewReleasesIcon />,
      onClickItem: () => {
        setOpened(false)
      },
      to: '/trademarks',
    },
  ]

  return (
    <List>
      {!denyAcces &&
        list.map(({ primaryText, icon, onClickItem, to }) => {
          return (
            <NavLink key={primaryText} to={to}>
              <ListItem button onClick={onClickItem}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={I18n.get(primaryText)} />
              </ListItem>
            </NavLink>
          )
        })}
      {isAuthenticated ? (
        <>
          {!denyAcces && (
            <>
              <Divider />
              <NavLink key="Settings" to="/settings">
                <ListItem button>
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get('Settings')} />
                </ListItem>
              </NavLink>
            </>
          )}
          <MaterialSignOut
            variant="contained"
            selected={false}
            setOpenedMenu={setOpened}
            setCollapsedMenu={setCollapsed}
            disableNotificationsView={setShowNotifications}
            clearData={clearData}
          />
        </>
      ) : null}
    </List>
  )
}

SideBarContent.propTypes = {
  currentUser: PropTypes.shape({
    username: PropTypes.string,
    attributes: PropTypes.shape({
      email: PropTypes.string,
      email_verified: PropTypes.bool,
      sub: PropTypes.string,
    }),
    signInUserSession: PropTypes.shape({
      accessToken: PropTypes.shape({
        payload: PropTypes.arrayOf([PropTypes.string]),
      }),
    }),
  }),
  isAuthenticated: PropTypes.bool,
  setOpened: PropTypes.func,
  setCollapsed: PropTypes.func,
  clearData: PropTypes.func,
}
SideBarContent.defaultProps = {
  currentUser: undefined,
  isAuthenticated: false,
  setOpened: () => {},
  setCollapsed: () => {},
  clearData: () => {},
}

export default SideBarContent
