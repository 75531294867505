import { API } from 'aws-amplify'
import Debug from 'debug'
import { createPaymentRequest } from '../../graphql/mutations'

const debug = Debug('services:paymentRequest:paymentRequest')

const PRIVATE_AUTH_MODE = 'AMAZON_COGNITO_USER_POOLS'

export const generatePaymentRequest = async (
  brandID,
  paymentTransactionID,
  concept,
  description,
  amount,
  impiRights,
  owner
) => {
  try {
    const newPaymentRequest = {
      brandID,
      paymentTransactionID,
      concept,
      description,
      amount,
      impiRights,
      owner,
    }
    const result = await API.graphql({
      query: createPaymentRequest,
      variables: {
        input: newPaymentRequest,
      },
      authMode: PRIVATE_AUTH_MODE,
    })
    return result.data.createPaymentRequest
  } catch (e) {
    debug('e', e)
    return null
  }
}
