import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core'
import { Copyright } from '../components'
import { VERSION } from '../config'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '5px auto',
    width: 120,
  },
  version: {
    fontSize: '0.6rem',
  },
}))

const Footer = (props) => {
  const classes = useStyles()
  const { name, site, variant } = props
  return (
    <div style={{ maxWidth: 700, margin: 'auto', textAlign: 'center' }}>
      <Copyright name={name} site={site} variant="caption" align="center" />
      <Typography
        variant={variant}
        align="center"
        gutterBottom
        color="textSecondary"
        className={classes.version}>
        {' - v'}
        {VERSION}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant={variant}
            align="center"
            gutterBottom
            color="textSecondary">
            Acerca de
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant={variant}
            align="center"
            gutterBottom
            color="textSecondary">
            Blog
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant={variant}
            align="center"
            gutterBottom
            color="textSecondary">
            Términos y Condiciones
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant={variant}
            align="center"
            gutterBottom
            color="textSecondary">
            Contacto
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

Footer.propTypes = {
  name: PropTypes.string,
  site: PropTypes.string,
  variant: PropTypes.string,
}

Footer.defaultProps = {
  name: 'ePPM [Console]',
  site: 'https://material-ui.com/',
  variant: 'caption',
}

export default Footer
