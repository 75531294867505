import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ style }) => {
  return (
    <svg
      width={style.width}
      height={style.height}
      viewBox="0 0 28 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Carrito-2" transform="translate(-793.000000, -300.000000)">
          <g id="Group" transform="translate(793.000000, 300.000000)">
            <path
              d="M27.2727273,16.5860732 C27.2727273,17.8604634 26.25,18.9031463 25,18.9031463 L2.27272727,18.9031463 C1.02272727,18.9031463 0,17.8604634 0,16.5860732 L0,2.68363415 C0,1.4092439 1.02272727,0.366560976 2.27272727,0.366560976 L25,0.366560976 C26.25,0.366560976 27.2727273,1.4092439 27.2727273,2.68363415 L27.2727273,16.5860732 Z"
              id="Fill-80"
              fill="#F3F4F4"
            />
            <path
              d="M0.454545455,5.00070732 L0.454545455,2.68363415 C0.454545455,1.4092439 1.47727273,0.366560976 2.72727273,0.366560976 L24.5454545,0.366560976 C25.7954545,0.366560976 26.8181818,1.4092439 26.8181818,2.68363415 L26.8181818,5.00070732"
              id="Fill-81"
              fill="#5565AF"
            />
            <path
              d="M26.8181818,14.269 L26.8181818,16.5860732 C26.8181818,17.8604634 25.7954545,18.4397317 24.5454545,18.4397317 L2.72727273,18.4397317 C1.47727273,18.4397317 0.454545455,17.8604634 0.454545455,16.5860732 L0.454545455,14.269"
              id="Fill-82"
              fill="#E6A124"
            />
            <path
              d="M7.90318182,11.2007317 C8.08772727,10.6678049 8.21727273,10.3262683 8.29363636,10.176122 L9.82772727,6.99014634 L10.95,6.99014634 L8.29363636,12.2832683 L7.10772727,12.2832683 L6.65681818,6.99014634 L7.70454545,6.99014634 L7.88545455,10.176122 C7.89454545,10.284561 7.89954545,10.4458293 7.89954545,10.6576098 C7.89409091,10.9064634 7.88727273,11.0876585 7.87818182,11.2007317 L7.90318182,11.2007317 Z"
              id="Fill-83"
              fill="#5565AF"
            />
            <polygon
              id="Fill-84"
              fill="#5565AF"
              points="10.4813636 12.2832683 11.5895455 6.99014634 12.6727273 6.99014634 11.5645455 12.2832683"
            />
            <path
              d="M15.9927273,10.7192439 C15.9927273,11.2243659 15.8168182,11.622439 15.4672727,11.9157805 C15.1168182,12.209122 14.6418182,12.355561 14.0431818,12.355561 C13.5195455,12.355561 13.0986364,12.247122 12.7790909,12.0297805 L12.7790909,11.0376098 C13.2354545,11.2980488 13.66,11.4287317 14.0504545,11.4287317 C14.315,11.4287317 14.5227273,11.3782195 14.6718182,11.274878 C14.8209091,11.1724634 14.8954545,11.0325122 14.8954545,10.8531707 C14.8954545,10.7498293 14.8795455,10.6585366 14.8477273,10.5797561 C14.8159091,10.5019024 14.7695455,10.4286829 14.7109091,10.360561 C14.6513636,10.2929024 14.505,10.170561 14.2704545,9.99121951 C13.9436364,9.75163415 13.7136364,9.51621951 13.5813636,9.28126829 C13.4481818,9.0472439 13.3822727,8.79514634 13.3822727,8.5245122 C13.3822727,8.21309756 13.455,7.9355122 13.6022727,7.68990244 C13.7486364,7.44521951 13.9572727,7.2547561 14.2290909,7.11804878 C14.5,6.98180488 14.8113636,6.91368293 15.165,6.91368293 C15.6781818,6.91368293 16.1486364,7.0332439 16.575,7.27190244 L16.1877273,8.11531707 C15.8186364,7.93690244 15.4777273,7.84746341 15.165,7.84746341 C14.9677273,7.84746341 14.8072727,7.90168293 14.6822727,8.01058537 C14.5563636,8.11902439 14.4940909,8.26314634 14.4940909,8.44156098 C14.4940909,8.58892683 14.5331818,8.7177561 14.6113636,8.82712195 C14.6895455,8.93695122 14.8645455,9.08246341 15.1368182,9.26319512 C15.4227273,9.45643902 15.6377273,9.66960976 15.7795455,9.9022439 C15.9213636,10.1362683 15.9927273,10.4078293 15.9927273,10.7192439"
              id="Fill-85"
              fill="#5565AF"
            />
            <path
              d="M19.4227273,10.0820488 L19.3304545,8.81507317 C19.3063636,8.49902439 19.295,8.19363415 19.295,7.89890244 L19.295,7.76868293 C19.1904545,8.05831707 19.0613636,8.36509756 18.9077273,8.68809756 L18.24,10.0820488 L19.4227273,10.0820488 Z M19.4763636,11.0232439 L17.7968182,11.0232439 L17.1859091,12.2832683 L16.0495455,12.2832683 L18.7695455,6.96836585 L20.0940909,6.96836585 L20.6159091,12.2832683 L19.5613636,12.2832683 L19.4763636,11.0232439 Z"
              id="Fill-86"
              fill="#5565AF"
            />
            <path
              d="M25,0.366560976 L2.27272727,0.366560976 C1.02272727,0.366560976 0,1.4092439 0,2.68363415 L0,16.5860732 C0,17.8604634 1.02272727,18.9031463 2.27272727,18.9031463 L25,18.9031463 C26.25,18.9031463 27.2727273,17.8604634 27.2727273,16.5860732 L27.2727273,2.68363415 C27.2727273,1.4092439 26.25,0.366560976 25,0.366560976 M25,0.82997561 C26.0027273,0.82997561 26.8181818,1.66134146 26.8181818,2.68363415 L26.8181818,16.5860732 C26.8181818,17.6083659 26.0027273,18.4397317 25,18.4397317 L2.27272727,18.4397317 C1.27,18.4397317 0.454545455,17.6083659 0.454545455,16.5860732 L0.454545455,2.68363415 C0.454545455,1.66134146 1.27,0.82997561 2.27272727,0.82997561 L25,0.82997561"
              id="Fill-88"
              fill="#F8F8F9"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

Icon.propTypes = {
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
}

Icon.defaultProps = {
  style: {
    width: '28px',
    height: '19px',
  },
}

export default Icon
