import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Container,
  Button,
  Grid,
  Hidden,
  FormControl,
  FormLabel,
  Typography,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import _ from 'lodash'
import { parse } from 'date-fns'
import { useSnackbar } from 'notistack'
// eslint-disable-next-line import/no-extraneous-dependencies
import { I18n } from '@aws-amplify/core'
import { useTrademarkContext, useLayoutContext } from '../context'
import { FormField, PhoneField, NumberFormatCustom } from '../common'
import { isEmail } from '../../regex.validations'
import {
  getDataFromPostalCode,
  getBrandById,
  putBrandData,
  putApplicant,
  getBrandsByTrademarkApplication,
  updateBrandsForSearch,
} from '../../services'
import {
  kindOfPerson as kindOfPersonCatalog,
  PHYSICAL_PERSON,
  MORAL_PERSON,
  COUNTRY_CODE_MEXICO,
  DOMICILE_TYPE_APPLICANT,
  DOMICILE_TYPE_ESTABLISHMENT,
  DOMICILE_TYPE_APPLICANT_BILLING,
  APPLICANT_TYPE_APPLICANT,
  APPLICANT_TYPE_APPLICANT_BILLING,
} from '../../config'
import { useInfoModal } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  noLabel: {
    marginTop: theme.spacing(1),
  },
  containerOptions: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  formLabel: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    marginLeft: theme.spacing(0.5),
  },
  formOption: {
    textAlign: 'left',
    marginLeft: theme.spacing(1.5),
  },
  brandFieldsContainer: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  brandFieldsGrid: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0),
  },
  hidden: {
    display: 'none',
  },
  actionButtons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
  actionButtonContainer: {
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    alignItems: 'center',
  },
  actionButton: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5),
    },
  },
  saveButton: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
      color: theme.palette.common.white,
    },
  },
  infoText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    justifyContent: 'center',
    textAlign: 'center',
  },
  mustBill: {
    marginTop: theme.spacing(2),
  },
}))

const DomicileForm = ({
  objectName,
  sameAddressName,
  emailName,
  formLabel,
  domicileType,
  countryOptions,
  selectedCountry,
  selectedPostalCode,
  sameAddressSelected,
  control,
  trigger,
  errors,
  setValue,
  domicileValues,
  siblingObjectName,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'))

  const { setLoading } = useLayoutContext()
  const [findingPostalCode, setFindingPostalCode] = useState(undefined)
  const [stateOptions, setStateOptions] = useState(undefined)
  const [countyOptions, setCountyOptions] = useState(undefined)
  const [suburbOptions, setSuburbOptions] = useState(undefined)
  const [actualCountrySelected, setActualCountrySelected] = useState(undefined)
  const renderAddressFieldsAsSelects = selectedCountry === COUNTRY_CODE_MEXICO
  const renderSameDomicileField =
    domicileType === DOMICILE_TYPE_ESTABLISHMENT ||
    domicileType === DOMICILE_TYPE_APPLICANT_BILLING
  const renderEmailField =
    domicileType === DOMICILE_TYPE_APPLICANT ||
    domicileType === DOMICILE_TYPE_APPLICANT_BILLING
  // const renderAddressFields =
  //   !sameAddressSelected || domicileType === DOMICILE_TYPE_APPLICANT
  const renderAddressFields = true
  const [previousSameAddress, setPreviousSameAddress] = useState(null)
  const [actualSameAddress, setActualSameAddress] = useState(null)

  const retrievePostalCodeData = async (postalCode) => {
    setLoading(true)
    if (postalCode) {
      setFindingPostalCode(true)
      const postalCodeData = await getDataFromPostalCode({ postalCode })
      if (!Array.isArray(postalCodeData) || postalCodeData.length === 0) {
        setStateOptions([])
        setCountyOptions([])
        setSuburbOptions([])
      } else {
        const finalStateOptions = postalCodeData.map((p) => {
          return {
            value: p.idEstado,
            label: p.estado,
          }
        })
        setValue(`${objectName}.state`, finalStateOptions[0].value)
        const finalCountyOptions = postalCodeData.map((p) => {
          return {
            value: p.idMunicipio,
            label: p.municipio,
          }
        })
        setValue(`${objectName}.county`, finalCountyOptions[0].value)
        const finalSuburbOptions = _.chain(postalCodeData)
          .map((p) => {
            return {
              value: p.idColonia,
              label: p.colonia,
            }
          })
          .orderBy((o) => o.label)
          .value()
        if (finalSuburbOptions.length === 1) {
          setValue(`${objectName}.suburb`, finalSuburbOptions[0].value)
          if (siblingObjectName) {
            setValue(`${siblingObjectName}.suburb`, finalSuburbOptions[0].value)
          }
        } else if (
          !sameAddressSelected &&
          domicileType === DOMICILE_TYPE_ESTABLISHMENT
        ) {
          setValue(`${objectName}.suburb`, domicileValues?.suburb)
        } else if (
          (sameAddressSelected ||
            domicileType !== DOMICILE_TYPE_ESTABLISHMENT) &&
          !domicileValues?.suburb
        ) {
          setValue(`${objectName}.suburb`, undefined)
        } else if (domicileValues?.suburb) {
          setValue(`${objectName}.suburb`, domicileValues?.suburb)
        }

        setStateOptions([finalStateOptions[0]])
        setCountyOptions([finalCountyOptions[0]])
        setSuburbOptions(finalSuburbOptions)
        if (_.get(errors, `${objectName}.state`)) {
          await trigger(`${objectName}.state`)
        }
        if (_.get(errors, `${objectName}.county`)) {
          await trigger(`${objectName}.county`)
        }
        if (_.get(errors, `${objectName}.suburb`)) {
          await trigger(`${objectName}.suburb`)
        }
      }
      setActualCountrySelected(selectedCountry)
      setFindingPostalCode(false)
    } else {
      if (actualCountrySelected === COUNTRY_CODE_MEXICO) {
        const preserveValues =
          (domicileType === DOMICILE_TYPE_ESTABLISHMENT ||
            domicileType === DOMICILE_TYPE_APPLICANT_BILLING) &&
          ((previousSameAddress === null &&
            typeof sameAddressSelected === 'undefined') ||
            (previousSameAddress === null &&
              actualSameAddress === null &&
              sameAddressSelected) ||
            (previousSameAddress === null &&
              actualSameAddress === false &&
              sameAddressSelected) ||
            (previousSameAddress === true && actualSameAddress === false))
        setValue(
          `${objectName}.state`,
          preserveValues ? domicileValues?.state : ''
        )
        setValue(
          `${objectName}.county`,
          preserveValues ? domicileValues?.county : ''
        )
        setValue(
          `${objectName}.suburb`,
          preserveValues ? domicileValues?.suburb : ''
        )
        // setValue(`${objectName}.state`, '')
        // setValue(`${objectName}.county`, '')
        // setValue(`${objectName}.suburb`, '')
      } else if (
        (domicileType === DOMICILE_TYPE_ESTABLISHMENT ||
          domicileType === DOMICILE_TYPE_APPLICANT_BILLING) &&
        sameAddressSelected
      ) {
        setValue(`${objectName}.state`, domicileValues?.state)
        setValue(`${objectName}.county`, domicileValues?.county)
        setValue(`${objectName}.suburb`, domicileValues?.suburb)
      }
      setActualCountrySelected(selectedCountry)
      setStateOptions([])
      setCountyOptions([])
      setSuburbOptions([])
      if (actualSameAddress !== sameAddressSelected) {
        setPreviousSameAddress(actualSameAddress)
        setActualSameAddress(sameAddressSelected)
      }
    }
    setLoading(false)
  }

  const loadInitialData = async (postalCode) => {
    await retrievePostalCodeData(postalCode)
  }

  useEffect(() => {
    if (domicileType === DOMICILE_TYPE_ESTABLISHMENT) {
      const postalCodeForMexico = sameAddressSelected
        ? domicileValues?.postalCode
        : selectedPostalCode
      const postalCode =
        selectedCountry === COUNTRY_CODE_MEXICO &&
        selectedPostalCode?.length === 5
          ? postalCodeForMexico
          : null
      if (sameAddressSelected) {
        setValue(`${objectName}.country`, domicileValues?.country)
        setValue(`${objectName}.postalCode`, domicileValues?.postalCode)
        setValue(`${objectName}.state`, domicileValues?.state)
        setValue(`${objectName}.county`, domicileValues?.county)
        // setValue(`${objectName}.suburb`, domicileValues?.suburb)
        setValue(`${objectName}.street`, domicileValues?.street)
        setValue(`${objectName}.externalNumber`, domicileValues?.externalNumber)
        setValue(`${objectName}.internalNumber`, domicileValues?.internalNumber)
      }
      loadInitialData(postalCode)
    }
    if (
      domicileType !== DOMICILE_TYPE_ESTABLISHMENT &&
      selectedCountry === COUNTRY_CODE_MEXICO &&
      selectedPostalCode?.length === 5
    ) {
      loadInitialData(selectedPostalCode)
    } else {
      loadInitialData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameAddressSelected, selectedCountry, selectedPostalCode])

  return (
    <Grid item xs={12} md={12}>
      <FormControl
        size="small"
        variant="outlined"
        className={clsx(classes.margin, classes.noLabel, classes.formOptions)}
        required={false}
        disabled={false}
        fullWidth>
        <Container className={clsx(classes.containerOptions)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel
                component="legend"
                className={clsx(
                  classes.margin,
                  classes.noLabel,
                  classes.formLabel
                )}>
                {formLabel}
              </FormLabel>
            </Grid>
            {renderSameDomicileField && (
              <Grid item xs={12}>
                <FormField
                  id={sameAddressName}
                  name={sameAddressName}
                  type="switch"
                  label={
                    <Typography variant="body1" color="primary">
                      Utilizar el mismo domicilio del solicitante
                    </Typography>
                  }
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={clsx(classes.margin, classes.formOption)}
                  labelClassName={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: '',
                    },
                  }}
                  errors={errors}
                />
              </Grid>
            )}
            {renderAddressFields && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormField
                    id={`${objectName}.country`}
                    name={`${objectName}.country`}
                    type="select"
                    label="País"
                    required
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Selecciona el país del domicilio',
                      },
                    }}
                    errors={errors}
                    options={countryOptions}
                    onChange={async (e) => {
                      if (siblingObjectName) {
                        setValue(
                          `${siblingObjectName}.country`,
                          e?.target?.value
                        )
                      }
                      // if (e?.target?.value === COUNTRY_CODE_MEXICO) {
                      //   if (
                      //     selectedPostalCode &&
                      //     selectedPostalCode !== '' &&
                      //     selectedPostalCode.length === 5
                      //   ) {
                      //     await retrievePostalCodeData(selectedPostalCode)
                      //   } else {
                      //     await retrievePostalCodeData(null)
                      //   }
                      // } else {
                      //   await retrievePostalCodeData(null)
                      // }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    id={`${objectName}.postalCode`}
                    name={`${objectName}.postalCode`}
                    label="Código postal"
                    type="text"
                    required
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa el código postal del domicilio',
                      },
                      validate: {
                        length: (value) => {
                          return (
                            selectedCountry !== COUNTRY_CODE_MEXICO ||
                            (selectedCountry === COUNTRY_CODE_MEXICO &&
                              value?.length === 5) ||
                            'Debes ingresar 5 caracteres'
                          )
                        },
                      },
                    }}
                    errors={errors}
                    onChange={async (e) => {
                      // Strange Issue
                      const selectedValue = e?.target?.value
                      if (siblingObjectName) {
                        setValue(
                          `${siblingObjectName}.postalCode`,
                          selectedValue
                        )
                      }
                      // if (
                      //   selectedCountry === COUNTRY_CODE_MEXICO &&
                      //   selectedValue &&
                      //   selectedValue !== '' &&
                      //   selectedValue.length === 5
                      // ) {
                      //   await retrievePostalCodeData(selectedValue)
                      // } else {
                      //   await retrievePostalCodeData(null)
                      // }

                      // Strange Issue
                      // const selectedValue = e?.target?.value
                      // if (
                      //   selectedCountry === COUNTRY_CODE_MEXICO &&
                      //   (await trigger(`${objectName}.postalCode`))
                      // ) {
                      //   if (selectedValue !== '') {
                      //     await retrievePostalCodeData(selectedValue)
                      //   } else {
                      //     await retrievePostalCodeData(null)
                      //   }
                      // } else {
                      //   await retrievePostalCodeData(null)
                      // }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    id={`${objectName}.state`}
                    name={`${objectName}.state`}
                    label="Entidad federativa"
                    type={renderAddressFieldsAsSelects ? 'select' : 'text'}
                    required
                    disabled={
                      renderAddressFieldsAsSelects ||
                      (renderSameDomicileField && sameAddressSelected)
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa la entidad federativa del domicilio',
                      },
                    }}
                    errors={errors}
                    IconComponent={() => {
                      return null
                    }}
                    options={stateOptions}
                    endAdornment={
                      <>
                        {findingPostalCode ? (
                          <CircularProgress
                            color="inherit"
                            size={18}
                            fontSize="smal"
                          />
                        ) : null}
                      </>
                    }
                    onChange={(e) => {
                      if (siblingObjectName) {
                        setValue(`${siblingObjectName}.state`, e.target.value)
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    id={`${objectName}.county`}
                    name={`${objectName}.county`}
                    label="Municipio"
                    type={renderAddressFieldsAsSelects ? 'select' : 'text'}
                    required
                    disabled={
                      renderAddressFieldsAsSelects ||
                      (renderSameDomicileField && sameAddressSelected)
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa el municipio del domicilio',
                      },
                    }}
                    errors={errors}
                    IconComponent={() => {
                      return null
                    }}
                    options={countyOptions}
                    endAdornment={
                      <>
                        {findingPostalCode ? (
                          <CircularProgress
                            color="inherit"
                            size={18}
                            fontSize="smal"
                          />
                        ) : null}
                      </>
                    }
                    onChange={(e) => {
                      if (siblingObjectName) {
                        setValue(`${siblingObjectName}.county`, e.target.value)
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    id={`${objectName}.suburb`}
                    name={`${objectName}.suburb`}
                    label="Colonia"
                    type={renderAddressFieldsAsSelects ? 'select' : 'text'}
                    required
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa la colonia del domicilio',
                      },
                    }}
                    errors={errors}
                    IconComponent={() => {
                      return null
                    }}
                    options={suburbOptions}
                    endAdornment={
                      <>
                        {findingPostalCode ? (
                          <CircularProgress
                            color="inherit"
                            size={18}
                            fontSize="smal"
                          />
                        ) : null}
                      </>
                    }
                    onChange={(e) => {
                      if (siblingObjectName) {
                        setValue(`${siblingObjectName}.suburb`, e.target.value)
                      }
                    }}
                  />
                </Grid>
                <Hidden xsDown>
                  <Grid item sm={6} />
                </Hidden>
                <Grid item xs={12} sm={12}>
                  <FormField
                    id={`${objectName}.street`}
                    name={`${objectName}.street`}
                    label="Calle"
                    type="text"
                    required
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa la calle del domicilio',
                      },
                    }}
                    errors={errors}
                    onChange={(e) => {
                      if (siblingObjectName) {
                        setValue(`${siblingObjectName}.street`, e.target.value)
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormField
                    id={`${objectName}.externalNumber`}
                    name={`${objectName}.externalNumber`}
                    label={isMobileDevice ? 'Num. ext.' : 'Número exterior'}
                    type="text"
                    required
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ingresa número exterior del domicilio',
                      },
                    }}
                    errors={errors}
                    onChange={(e) => {
                      if (siblingObjectName) {
                        setValue(
                          `${siblingObjectName}.externalNumber`,
                          e.target.value
                        )
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormField
                    id={`${objectName}.internalNumber`}
                    name={`${objectName}.internalNumber`}
                    label={isMobileDevice ? 'Num. int.' : 'Número interior'}
                    type="text"
                    required={false}
                    disabled={renderSameDomicileField && sameAddressSelected}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={clsx(classes.margin, classes.noLabel)}
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Ingresa número interior del domicilio',
                      },
                    }}
                    errors={errors}
                    onChange={(e) => {
                      if (siblingObjectName) {
                        setValue(
                          `${siblingObjectName}.internalNumber`,
                          e.target.value
                        )
                      }
                    }}
                  />
                </Grid>
                {renderEmailField && (
                  <Grid item xs={12} sm={6}>
                    <FormField
                      id={emailName}
                      name={emailName}
                      label="Correo electrónico"
                      type="email"
                      required
                      disabled={renderSameDomicileField && sameAddressSelected}
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Ingresa un correo electrónico',
                        },
                        minLength: {
                          value: 6,
                          message: 'Debe ser mayor o igual a 6 caracteres',
                        },
                        pattern: {
                          value: isEmail,
                          message:
                            'Debes ingresar un correo electrónico válido',
                        },
                      }}
                      errors={errors}
                      onChange={(e) => {
                        if (siblingObjectName) {
                          setValue(`${siblingObjectName}.email`, e.target.value)
                        }
                      }}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Container>
      </FormControl>
    </Grid>
  )
}

DomicileForm.propTypes = {
  objectName: PropTypes.string.isRequired,
  sameAddressName: PropTypes.string,
  emailName: PropTypes.string,
  formLabel: PropTypes.string.isRequired,
  domicileType: PropTypes.oneOf([
    DOMICILE_TYPE_APPLICANT,
    DOMICILE_TYPE_ESTABLISHMENT,
    DOMICILE_TYPE_APPLICANT_BILLING,
  ]).isRequired,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  selectedCountry: PropTypes.string,
  selectedPostalCode: PropTypes.string,
  sameAddressSelected: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  trigger: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  domicileValues: PropTypes.shape({
    country: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    county: PropTypes.string,
    suburb: PropTypes.string,
    street: PropTypes.string,
    externalNumber: PropTypes.string,
    internalNumber: PropTypes.string,
  }).isRequired,
  siblingObjectName: PropTypes.string,
}

DomicileForm.defaultProps = {
  selectedCountry: undefined,
  selectedPostalCode: undefined,
  sameAddressName: undefined,
  emailName: undefined,
  sameAddressSelected: undefined,
  siblingObjectName: undefined,
}

const ApplicantForm = ({
  objectName,
  invoiceforApplicantName,
  applicantType,
  applicantKind,
  kindOfPersonOptions,
  nationalityOptions,
  invoiceforApplicantSelected,
  control,
  errors,
}) => {
  const classes = useStyles()
  const renderInvoiceforApplicant =
    applicantType === APPLICANT_TYPE_APPLICANT_BILLING
  // const renderApplicantFields = !invoiceforApplicantSelected
  const renderApplicantFields = true

  return (
    <>
      {renderInvoiceforApplicant && (
        <Grid item xs={12}>
          <FormField
            id={invoiceforApplicantName}
            name={invoiceforApplicantName}
            type="switch"
            label={
              <Typography variant="body1" color="primary">
                La facturación es para el solicitante
              </Typography>
            }
            variant="outlined"
            size="small"
            fullWidth
            className={clsx(classes.margin, classes.formOption)}
            labelClassName={clsx(classes.margin, classes.noLabel)}
            control={control}
            rules={{
              required: {
                value: false,
                message: '',
              },
            }}
            errors={errors}
          />
        </Grid>
      )}
      {renderApplicantFields && (
        <Grid item xs={12} sm={6}>
          <FormField
            id={`${objectName}.kind`}
            name={`${objectName}.kind`}
            type="select"
            required
            disabled={invoiceforApplicantSelected}
            variant="outlined"
            size="small"
            fullWidth
            className={clsx(classes.margin)}
            label="Tipo de persona"
            labelClassName={clsx(classes.margin, classes.noLabel)}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Selecciona el tipo de persona',
              },
            }}
            value={applicantKind}
            errors={errors}
            options={kindOfPersonOptions}
          />
        </Grid>
      )}
      {renderInvoiceforApplicant && (
        <Grid item xs={12} sm={6}>
          <FormField
            id={`${objectName}.rfc`}
            name={`${objectName}.rfc`}
            label="RFC"
            type="text"
            required
            variant="outlined"
            size="small"
            fullWidth
            className={clsx(classes.margin, classes.noLabel)}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Ingresa el rfc para facturar',
              },
              validate: {
                length: (value) => {
                  const length = applicantKind === PHYSICAL_PERSON ? 13 : 12
                  return (
                    value?.length === length ||
                    `Debes ingresar ${length} caracteres`
                  )
                },
              },
            }}
            errors={errors}
          />
        </Grid>
      )}
      {!renderInvoiceforApplicant && (
        <Hidden xsDown>
          <Grid item sm={6} />
        </Hidden>
      )}
      {renderApplicantFields && (
        <>
          {applicantKind ? (
            <>
              <Grid item xs={12} md={12}>
                <FormField
                  id={`${objectName}.name`}
                  name={`${objectName}.name`}
                  label={
                    applicantKind === PHYSICAL_PERSON
                      ? 'Nombre(s)'
                      : 'Razón social'
                  }
                  type="text"
                  required
                  disabled={invoiceforApplicantSelected}
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message:
                        applicantKind === PHYSICAL_PERSON
                          ? 'Ingresa tu nombre'
                          : 'Ingresa tu razón social',
                    },
                    minLength: {
                      value: 3,
                      message: 'Debe ser mayor o igual a 3 caracteres',
                    },
                  }}
                  errors={errors}
                />
              </Grid>
              {applicantKind === PHYSICAL_PERSON ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      id={`${objectName}.surname`}
                      name={`${objectName}.surname`}
                      label="Primer apellido"
                      type="text"
                      required
                      disabled={invoiceforApplicantSelected}
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Ingresa tu primer apellido',
                        },
                        minLength: {
                          value: 2,
                          message: 'Debe ser mayor o igual a 2 caracteres',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      id={`${objectName}.secondSurname`}
                      name={`${objectName}.secondSurname`}
                      label="Segundo apellido"
                      type="text"
                      required={false}
                      disabled={invoiceforApplicantSelected}
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={clsx(classes.margin, classes.noLabel)}
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: 'Ingresa tu segundo apellido',
                        },
                        minLength: {
                          value: 2,
                          message: 'Debe ser mayor o igual a 2 caracteres',
                        },
                      }}
                      errors={errors}
                    />
                  </Grid>
                </>
              ) : null}
            </>
          ) : null}
          <Grid item xs={12} sm={6}>
            <FormField
              id={`${objectName}.nationality`}
              name={`${objectName}.nationality`}
              type="select"
              label="Nacionalidad"
              required
              disabled={invoiceforApplicantSelected}
              variant="outlined"
              size="small"
              fullWidth
              className={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: 'Selecciona la nacionalidad del solicitante',
                },
              }}
              errors={errors}
              options={nationalityOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneField
              id={`${objectName}.phoneNumber`}
              name={`${objectName}.phoneNumber`}
              label="Teléfono"
              dialCodeLabel="Código"
              key="phoneNumber"
              required
              disabled={invoiceforApplicantSelected}
              variant="outlined"
              size="small"
              fullWidth
              renderFlag
              renderDialCodeLabel
              dialCodeName={`${objectName}.countryCallingCode`}
              className={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Ingresa tu número de teléfono',
                },
              }}
              rulesForDialCode={{
                required: {
                  value: true,
                  message: 'Selecciona el código de tu país',
                },
              }}
              errors={errors}
              inputProps={{
                // eslint-disable-next-line no-nested-ternary
                format: '## #### ####',
                mask: ' ',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
        </>
      )}
    </>
  )
}

ApplicantForm.propTypes = {
  objectName: PropTypes.string.isRequired,
  invoiceforApplicantName: PropTypes.string,
  applicantType: PropTypes.oneOf([
    APPLICANT_TYPE_APPLICANT,
    APPLICANT_TYPE_APPLICANT_BILLING,
  ]).isRequired,
  applicantKind: PropTypes.oneOf([
    PHYSICAL_PERSON,
    MORAL_PERSON,
    null,
    undefined,
  ]),
  kindOfPersonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  nationalityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  invoiceforApplicantSelected: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
}

ApplicantForm.defaultProps = {
  invoiceforApplicantName: undefined,
  applicantKind: undefined,
  invoiceforApplicantSelected: undefined,
}

const ApplicantDataForm = ({ applicantType, countries, nationalities }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const kindOfPersonOptions = kindOfPersonCatalog.map((option) => {
    return {
      value: option.value,
      label: I18n.get(option.label),
    }
  })

  const { setLoading } = useLayoutContext()
  const { openModal } = useInfoModal()

  const {
    brandFormIsDirty,
    setBrandFormIsDirty,
    brand,
    setBrand,
  } = useTrademarkContext()
  const {
    handleSubmit,
    control,
    errors,
    watch,
    trigger,
    setValue,
    formState,
  } = useForm({ defaultValues: { brandData: brand?.brandData } })
  const { dirtyFields } = formState
  const applicantKind = watch('brandData.applicant.kind')
  const applicantBillingKind = watch('brandData.applicantBilling.kind')
  const applicantDomicileCountry = watch('brandData.applicant.domicile.country')
  const establishmentDomicileCountry = watch(
    'brandData.establishmentDomicile.country'
  )
  const applicantBillingDomicileCountry = watch(
    'brandData.applicantBilling.domicile.country'
  )
  const sameAddressForEstablishmentWatch = watch(
    'brandData.sameAddressForEstablishment'
  )
  const sameAddressForBillingWatch = watch('brandData.sameAddressForBilling')
  const applicantDomicilePostalCode = watch(
    'brandData.applicant.domicile.postalCode'
  )
  const establishmentDomicilePostalCode = watch(
    'brandData.establishmentDomicile.postalCode'
  )
  const applicantBillingDomicilePostalCode = watch(
    'brandData.applicantBilling.domicile.postalCode'
  )
  const invoiceforApplicantWatch = watch('brandData.invoiceforApplicant')
  const mustBillWatch = watch('brandData.mustBill')
  const applicantDomicileWatch = watch('brandData.applicant.domicile')
  // eslint-disable-next-line no-unused-vars
  const establishmentDomicileWatch = watch('brandData.establishmentDomicile')
  const applicantBillingDomicileWatch = watch(
    'brandData.applicantBilling.domicile'
  )

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      setBrandFormIsDirty(true)
    } else {
      setBrandFormIsDirty(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dirtyFields,
    setBrandFormIsDirty,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?.id,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?._version,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?.sameAddressForEstablishment,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?.establishmentDomicile,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?.mustBill,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?.invoiceforApplicant,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?.sameAddressForBilling,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?.applicant,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?.applicant?.domicile,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?.applicantBilling,
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    dirtyFields.brandData?.applicantBilling?.domicile,
  ])

  const openRestoreModal = () => {
    openModal({
      title: 'Restaurar información',
      content: (
        <div className={classes.infoText}>
          Los cambios generados no persistirán ¿Estás seguro de restaurar la
          información?
        </div>
      ),
      confirmationText: 'Confirmar',
      cancellationText: 'Cerrar',
    })
      .then(async () => {
        setLoading(true)
        setBrandFormIsDirty(false)
        const brandReaded = await getBrandById(brand?.id)
        setBrand(null)
        if (brandReaded?.brandData?.dateOfInitialUse) {
          brandReaded.brandData.dateOfInitialUse = parse(
            brandReaded?.brandData?.dateOfInitialUse,
            'yyyy-MM-ddxxx',
            new Date()
          )
        }
        if (brandReaded?.registrationDateAbroad) {
          brandReaded.registrationDateAbroad = parse(
            brandReaded.registrationDateAbroad,
            'yyyy-MM-ddxxx',
            new Date()
          )
        }
        setBrand({ ...brandReaded })
        setLoading(false)
      })
      .catch(() => {})
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const {
        brandData: {
          applicant,
          applicantBilling,
          establishmentDomicile,
          mustBill,
          invoiceforApplicant,
          sameAddressForBilling,
          sameAddressForEstablishment,
        },
      } = data
      const updateAllApplicantData = applicantType === APPLICANT_TYPE_APPLICANT
      const updateBilling = applicantType === APPLICANT_TYPE_APPLICANT_BILLING

      const finalApplicantRFC = invoiceforApplicant
        ? applicant?.rfc || ''
        : undefined

      const applicantInformation = {
        id: brand?.brandData?.applicant?.id,
        kind: updateAllApplicantData ? applicant?.kind : undefined,
        name: updateAllApplicantData ? applicant?.name || '' : undefined,
        surname: updateAllApplicantData ? applicant?.surname || '' : undefined,
        secondSurname: updateAllApplicantData
          ? applicant?.secondSurname || ''
          : undefined,
        rfc: updateBilling ? finalApplicantRFC : undefined,
        nationality: updateAllApplicantData
          ? applicant?.nationality
          : undefined,
        email: updateAllApplicantData ? applicant?.email : undefined,
        domicile: updateAllApplicantData ? applicant?.domicile : undefined,
        countryCallingCode: updateAllApplicantData
          ? applicant?.countryCallingCode
          : undefined,
        phoneNumber: updateAllApplicantData
          ? applicant?.phoneNumber
          : undefined,
        extension: updateAllApplicantData ? applicant?.extension : undefined,
        _version: brand?.brandData?.applicant?._version,
      }
      const updateApplicantBilling =
        (applicantType === APPLICANT_TYPE_APPLICANT &&
          brand?.brandData?.invoiceforApplicant) ||
        mustBill
      const invoiceforApplicantSelected =
        (applicantType === APPLICANT_TYPE_APPLICANT &&
          brand?.brandData?.invoiceforApplicant) ||
        invoiceforApplicant
      const sameAddressForBillingSelected =
        (applicantType === APPLICANT_TYPE_APPLICANT &&
          brand?.brandData?.sameAddressForBilling) ||
        sameAddressForBilling
      const keys = _.chain(applicantInformation)
        .toPairs(applicantInformation)
        .filter((k) => k[1])
        .map((k) => k[0])
        .value()
      if (keys.length > 2) {
        // eslint-disable-next-line no-unused-vars
        const applicantUpdated = await putApplicant(applicantInformation)
      }
      if (updateApplicantBilling) {
        const finalApplicantBillingRFC = invoiceforApplicantSelected
          ? applicant?.rfc || ''
          : applicantBilling?.rfc || ''
        const applicantBillingInformation = {
          id: brand?.brandData?.applicantBilling?.id,
          kind: invoiceforApplicantSelected
            ? applicant?.kind
            : applicantBilling?.kind,
          name: invoiceforApplicantSelected
            ? applicant?.name || ''
            : applicantBilling?.name || '',
          surname: invoiceforApplicantSelected
            ? applicant?.surname || ''
            : applicantBilling?.surname || '',
          secondSurname: invoiceforApplicantSelected
            ? applicant?.secondSurname || ''
            : applicantBilling?.secondSurname || '',
          rfc: updateBilling
            ? finalApplicantBillingRFC
            : brand?.brandData?.applicant?.rfc,
          nationality: invoiceforApplicantSelected
            ? applicant?.nationality
            : applicantBilling?.nationality,
          email: sameAddressForBillingSelected
            ? applicant?.email
            : applicantBilling?.email,
          domicile: sameAddressForBillingSelected
            ? applicant?.domicile
            : applicantBilling?.domicile,
          countryCallingCode: invoiceforApplicantSelected
            ? applicant?.countryCallingCode
            : applicantBilling?.countryCallingCode,
          phoneNumber: invoiceforApplicantSelected
            ? applicant?.phoneNumber
            : applicantBilling?.phoneNumber,
          extension: invoiceforApplicantSelected
            ? applicant?.extension
            : applicantBilling?.extension,
          _version: brand?.brandData?.applicantBilling?._version,
        }
        // eslint-disable-next-line no-unused-vars
        const updatedApplicantBilling = await putApplicant(
          applicantBillingInformation
        )
      }
      const finalEstablishmentDomicile = sameAddressForEstablishment
        ? applicant?.domicile
        : establishmentDomicile
      // eslint-disable-next-line no-unused-vars
      const updatedBrandData = await putBrandData({
        id: brand?.brandData?.id,
        mustBill: updateBilling ? mustBill : undefined,
        invoiceforApplicant: updateBilling
          ? invoiceforApplicant || false
          : undefined,
        sameAddressForBilling: updateBilling
          ? sameAddressForBilling || false
          : undefined,
        sameAddressForEstablishment: updateAllApplicantData
          ? sameAddressForEstablishment || false
          : undefined,
        establishmentDomicile: updateAllApplicantData
          ? finalEstablishmentDomicile
          : undefined,
        _version: brand?.brandData?._version,
      })

      // Update Brand Data used by search
      if (
        dirtyFields?.brandData?.applicant?.name ||
        dirtyFields?.brandData?.applicant?.surname ||
        dirtyFields?.brandData?.applicant?.secondSurname
      ) {
        const brandSibblings = await getBrandsByTrademarkApplication(
          brand?.trademarkApplicationID
        )
        const dataForSearch = {
          applicantName: dirtyFields?.brandData?.applicant?.name
            ? applicant?.name
            : undefined,
          applicantSurname: dirtyFields?.brandData?.applicant?.surname
            ? applicant?.surname
            : undefined,
          applicantSecondSurname: dirtyFields?.brandData?.applicant
            ?.secondSurname
            ? applicant?.secondSurname
            : undefined,
        }
        // eslint-disable-next-line no-unused-vars
        const updatedBrandSibblings = await updateBrandsForSearch({
          brands: brandSibblings,
          ...dataForSearch,
        })
      }

      const brandReaded = await getBrandById(brand?.id)
      setBrand(null)
      if (brandReaded?.brandData?.dateOfInitialUse) {
        brandReaded.brandData.dateOfInitialUse = parse(
          brandReaded?.brandData?.dateOfInitialUse,
          'yyyy-MM-ddxxx',
          new Date()
        )
      }
      if (brandReaded?.registrationDateAbroad) {
        brandReaded.registrationDateAbroad = parse(
          brandReaded.registrationDateAbroad,
          'yyyy-MM-ddxxx',
          new Date()
        )
      }
      setBrand({ ...brandReaded })
      enqueueSnackbar(
        `Información ${
          applicantType === APPLICANT_TYPE_APPLICANT
            ? 'del solicitante'
            : 'para facturación'
        } guardada correctamente`,
        {
          preventDuplicate: true,
          variant: 'success',
        }
      )
    } catch (e) {
      enqueueSnackbar(
        `Ocurrió un error al intentar guardar la información ${
          applicantType === APPLICANT_TYPE_APPLICANT
            ? 'del solicitante'
            : 'para facturación'
        }`,
        {
          preventDuplicate: true,
          variant: 'warning',
        }
      )
    }
    setLoading(false)
  }

  const render = brand && applicantKind && countries && nationalities
  if (!render) return null

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Container className={classes.brandFieldsContainer}>
        <Grid container spacing={2}>
          {brandFormIsDirty && (
            <Grid item xs={12} className={classes.brandFieldsGrid}>
              <Typography variant="subtitle2" color="error">
                Es necesario que confirmes los cambios para continuar
              </Typography>
            </Grid>
          )}
          {applicantType === APPLICANT_TYPE_APPLICANT && (
            <>
              <Grid item xs={12} md={12}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={clsx(classes.margin, classes.noLabel)}
                  required={false}
                  disabled={false}
                  fullWidth>
                  <Container className={clsx(classes.containerOptions)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormLabel
                          component="legend"
                          className={clsx(
                            classes.margin,
                            classes.noLabel,
                            classes.formLabel
                          )}>
                          Solicitante
                        </FormLabel>
                      </Grid>
                      <ApplicantForm
                        objectName="brandData.applicant"
                        applicantType={APPLICANT_TYPE_APPLICANT}
                        applicantKind={applicantKind}
                        nationalityOptions={nationalities}
                        kindOfPersonOptions={kindOfPersonOptions}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                      />
                    </Grid>
                  </Container>
                </FormControl>
              </Grid>
              <DomicileForm
                objectName="brandData.applicant.domicile"
                emailName="brandData.applicant.email"
                formLabel="Domicilio del solicitante"
                domicileType={DOMICILE_TYPE_APPLICANT}
                countryOptions={countries}
                selectedCountry={applicantDomicileCountry}
                selectedPostalCode={applicantDomicilePostalCode}
                control={control}
                trigger={trigger}
                errors={errors}
                setValue={setValue}
                domicileValues={applicantDomicileWatch}
                siblingObjectName={
                  sameAddressForEstablishmentWatch
                    ? 'brandData.establishmentDomicile'
                    : undefined
                }
              />
              {brand?.brandData?.hasBeenUsed && (
                <DomicileForm
                  objectName="brandData.establishmentDomicile"
                  // objectName={
                  //   sameAddressForEstablishmentWatch
                  //     ? 'brandData.establishmentDomicileCopy'
                  //     : 'brandData.establishmentDomicile'
                  // }
                  sameAddressName="brandData.sameAddressForEstablishment"
                  formLabel="Domicilio del establecimiento"
                  domicileType={DOMICILE_TYPE_ESTABLISHMENT}
                  countryOptions={countries}
                  selectedCountry={
                    sameAddressForEstablishmentWatch
                      ? applicantDomicileCountry
                      : establishmentDomicileCountry
                  }
                  selectedPostalCode={
                    sameAddressForEstablishmentWatch
                      ? applicantDomicilePostalCode
                      : establishmentDomicilePostalCode
                  }
                  sameAddressSelected={sameAddressForEstablishmentWatch}
                  control={control}
                  trigger={trigger}
                  errors={errors}
                  setValue={setValue}
                  domicileValues={
                    sameAddressForEstablishmentWatch
                      ? applicantDomicileWatch
                      : brand?.brandData?.establishmentDomicile
                  }
                />
              )}
            </>
          )}
          {applicantType === APPLICANT_TYPE_APPLICANT_BILLING && (
            <>
              <Grid
                item
                xs={12}
                className={clsx(!brandFormIsDirty && classes.mustBill)}>
                <FormField
                  id="brandData.mustBill"
                  name="brandData.mustBill"
                  type="switch"
                  label={
                    <Typography variant="body1" color="primary">
                      Deseo facturar
                    </Typography>
                  }
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={clsx(classes.margin, classes.formOption)}
                  labelClassName={clsx(classes.margin, classes.noLabel)}
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: '',
                    },
                  }}
                  errors={errors}
                />
              </Grid>
              {mustBillWatch && (
                <Grid item xs={12} md={12}>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={clsx(classes.margin, classes.noLabel)}
                    required={false}
                    disabled={false}
                    fullWidth>
                    <Container className={clsx(classes.containerOptions)}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormLabel
                            component="legend"
                            className={clsx(
                              classes.margin,
                              classes.noLabel,
                              classes.formLabel
                            )}>
                            Datos para facturación
                          </FormLabel>
                        </Grid>
                        <ApplicantForm
                          objectName={
                            invoiceforApplicantWatch
                              ? 'brandData.applicant'
                              : 'brandData.applicantBilling'
                          }
                          invoiceforApplicantName="brandData.invoiceforApplicant"
                          applicantType={APPLICANT_TYPE_APPLICANT_BILLING}
                          applicantKind={
                            invoiceforApplicantWatch
                              ? applicantKind
                              : applicantBillingKind
                          }
                          nationalityOptions={nationalities}
                          kindOfPersonOptions={kindOfPersonOptions}
                          invoiceforApplicantSelected={invoiceforApplicantWatch}
                          control={control}
                          trigger={trigger}
                          errors={errors}
                        />
                      </Grid>
                    </Container>
                  </FormControl>
                </Grid>
              )}
              {mustBillWatch && (
                <DomicileForm
                  objectName={
                    sameAddressForBillingWatch
                      ? 'brandData.applicant.domicile'
                      : 'brandData.applicantBilling.domicile'
                  }
                  sameAddressName="brandData.sameAddressForBilling"
                  emailName={
                    sameAddressForBillingWatch
                      ? 'brandData.applicant.email'
                      : 'brandData.applicantBilling.email'
                  }
                  formLabel="Domicilio para facturación"
                  domicileType={DOMICILE_TYPE_APPLICANT_BILLING}
                  countryOptions={countries}
                  selectedCountry={
                    sameAddressForBillingWatch
                      ? applicantDomicileCountry
                      : applicantBillingDomicileCountry
                  }
                  selectedPostalCode={
                    sameAddressForBillingWatch
                      ? applicantDomicilePostalCode
                      : applicantBillingDomicilePostalCode
                  }
                  sameAddressSelected={sameAddressForBillingWatch}
                  control={control}
                  trigger={trigger}
                  errors={errors}
                  setValue={setValue}
                  domicileValues={
                    sameAddressForBillingWatch
                      ? applicantDomicileWatch
                      : applicantBillingDomicileWatch
                  }
                />
              )}
            </>
          )}

          <Grid item xs={12} md={12}>
            <div className={classes.actionButtons}>
              {brandFormIsDirty && (
                <>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.actionButtonContainer}>
                    <Grid
                      item
                      xs={10}
                      sm={4}
                      md={3}
                      lg={2}
                      className={classes.actionButton}>
                      <Button
                        onClick={() => {
                          openRestoreModal()
                        }}
                        variant="contained"
                        color="secondary"
                        fullWidth>
                        Restaurar
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      sm={4}
                      md={3}
                      lg={2}
                      className={classes.actionButton}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.saveButton}>
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </form>
  )
}

ApplicantDataForm.propTypes = {
  applicantType: PropTypes.oneOf([
    APPLICANT_TYPE_APPLICANT,
    APPLICANT_TYPE_APPLICANT_BILLING,
  ]).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  nationalities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
}

ApplicantDataForm.defaultProps = {}

export default ApplicantDataForm
