import React from 'react'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  box: {
    mx: 'auto',
    bgcolor: '#fff',
    width: 52,
    height: 52,
    borderRadius: 16,
    // boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.4)',
    fontSize: 14,
    fontFamily:
      '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '52px',
    fontStyle: 'italic',
  },
}))

const TextLogo = (props) => {
  const classes = useStyles()
  return (
    <Box
      className={classes.box}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default TextLogo
