import React, { useState } from 'react'
import { Button, Container, Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { FormField, Title, ConfirmDialog, LoaderOverlay } from '../common'
import { useLayoutContext } from '../context'

import { generateNotification, putBrand, sendEmail } from '../../services'
import {
  notifications,
  BRAND_STAGE_PRESENTED,
  REACT_APP_NOREPLY_EMAIL_ADDRESS,
  actionEmailSubjects,
  actionEmailTemplates,
} from '../../config'

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(2),
  },
  divider: {
    height: theme.spacing(3),
  },
  button: {
    width: '100%',
  },
}))

const BrandImpiModal = (props) => {
  const { closeHandler, notificationsEnabled, brand, updateBrand } = props
  const { register, handleSubmit, control, errors } = useForm({})
  const classes = useStyles()
  const [prompConfirmation, setPropmtConfirmation] = useState(false)
  const [submittedData, setSubmittedData] = useState(undefined)
  const { enqueueSnackbar } = useSnackbar()
  const { loading, setLoading } = useLayoutContext()

  /* eslint-disable-next-line consistent-return */
  const sendNotification = async (name, requestNumber) => {
    try {
      const notification = notifications.impiRegistrationProcessInitiated(
        brand?.id,
        name,
        requestNumber,
        brand?.class?.name
      )
      const notificationTitle = notification.title
      const notificationBody = notification.body
      // eslint-disable-next-line no-unused-vars
      const sendNotificationResponse = await generateNotification(
        brand?.id,
        notificationTitle,
        notificationBody,
        brand?.brandData?.owner
      )
    } catch (error) {
      return error
    }
  }

  const handleEmailSend = async (from, to, subject, message) => {
    try {
      await sendEmail(from, to, subject, message)
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al enviar el correo', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
  }

  /* eslint-disable-next-line consistent-return */
  const initiateBrandPresentation = async (requestNumber) => {
    try {
      await putBrand({
        id: brand?.id,
        brandStage: BRAND_STAGE_PRESENTED,
        requestNumber,
        _version: brand?._version,
      })
    } catch (error) {
      enqueueSnackbar(
        'Ocurrió un error al iniciar el proceso de presentación de tu marca',
        {
          preventDuplicate: true,
          variant: 'error',
        }
      )
      return error
    }
  }

  /* eslint-disable-next-line consistent-return */
  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await initiateBrandPresentation(data.requestNumber)
      enqueueSnackbar(
        '¡Se ha inicializado el proceso de presentación de tu marca de forma exitosa!',
        {
          preventDuplicate: true,
          variant: 'success',
        }
      )
    } catch (error) {
      enqueueSnackbar(
        '¡Ocurrió un error al iniciar el proceso de presentación de tu marca!',
        {
          preventDuplicate: true,
          variant: 'error',
        }
      )
      return error
    }

    try {
      await handleEmailSend(
        REACT_APP_NOREPLY_EMAIL_ADDRESS,
        brand?.owner,
        actionEmailSubjects.trademarkRegistrationProcessStarted,
        actionEmailTemplates.trademarkRegistrationInitialized(
          brand?.id,
          brand?.brandData?.name,
          brand?.class?.name
        )
      )
      enqueueSnackbar(
        'Se envió un correo para notificar al usuario de esta acción',
        {
          preventDuplicate: true,
          variant: 'success',
        }
      )
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al envíar un correo de contacto', {
        preventDuplicate: true,
        variant: 'error',
      })
    }

    if (notificationsEnabled) {
      try {
        await sendNotification(brand?.brandData?.name, data.requestNumber)
      } catch (error) {
        enqueueSnackbar('Ocurrió un error al crear la notificación', {
          preventDuplicate: true,
          variant: 'error',
        })
      }
    }
    updateBrand(brand?.id)
    setPropmtConfirmation(false)
    setLoading(false)
    closeHandler(false)
  }

  const handleConfirmation = (data) => {
    setSubmittedData(data)
    setPropmtConfirmation(true)
  }

  const handleClose = () => {
    closeHandler(false)
  }

  const renderModalContent = () => {
    return (
      <form noValidate onSubmit={handleSubmit(handleConfirmation)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField
              id="brandName"
              name="brandName"
              type="text"
              required
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="Nombre"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              inputRef={register({
                required: {
                  value: true,
                  message: 'Ingresa el nombre de la marca',
                },
              })}
              inputProps={{ value: brand?.brandData?.name }}
              disabled
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <FormField
              id="requestNumber"
              name="requestNumber"
              type="text"
              required
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="Número de Solicitud"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Ingresa el número de solicitud',
                },
              }}
              errors={errors}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                className={clsx(classes.button)}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={clsx(classes.button)}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Container className={clsx(classes.mainContent)}>
      <LoaderOverlay open={loading} />
      <ConfirmDialog
        title="Número de Solicitud"
        content="¿Deseas continuar con este número de solicitud?"
        enabled={prompConfirmation}
        onAccept={onSubmit}
        data={submittedData}
        closeHandler={setPropmtConfirmation}
      />
      <Title>Registro de Marca ante el IMPI</Title>
      <div className={clsx(classes.divider)} />
      {renderModalContent()}
    </Container>
  )
}

BrandImpiModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  updateBrand: PropTypes.func.isRequired,
}

export default BrandImpiModal
