import React from 'react'
import PropTypes from 'prop-types'

import { MainAuthenticator } from '../components/security'
import { MainLayout } from './MainLayout'

export const RequiredAuthLayout = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MainLayout MainContent={MainAuthenticator} {...props} />
  )
}

RequiredAuthLayout.propTypes = {
  Comp: PropTypes.elementType,
}

RequiredAuthLayout.defaultProps = {
  Comp: undefined,
}

const withRequiredAuthLayout = (Comp) => (props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <RequiredAuthLayout Comp={Comp} {...props} />
}

export default withRequiredAuthLayout
