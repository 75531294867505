import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    transition: '0.3s',
  },
  avatar: {
    transition: '0.3s',
  },
  divider: {
    paddingBottom: 16,
  },
}))

const NavHeader = ({ collapsed, ctx }) => {
  const classes = useStyles()
  const { authenticated, currentUser } = ctx
  return (
    <>
      <div className={classes.root} style={{ padding: collapsed ? 8 : 16 }}>
        <Avatar
          className={classes.avatar}
          style={{
            width: collapsed ? 48 : 60,
            height: collapsed ? 48 : 60,
          }}
        />
        {collapsed ? (
          <></>
        ) : (
          <>
            <div className={classes.divider} style={{ paddingBottom: 16 }} />
            <Typography variant="h6" noWrap>
              ePPM
            </Typography>
            <Typography color="textSecondary" noWrap gutterBottom>
              {authenticated ? currentUser?.attributes?.email : ''}
            </Typography>
          </>
        )}
      </div>
      <Divider />
    </>
  )
}

NavHeader.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  ctx: PropTypes.shape({
    authenticated: PropTypes.bool,
    currentUser: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }).isRequired,
}
NavHeader.defaultProps = {}

export default NavHeader
