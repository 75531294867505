import React, { useState } from 'react'
import { Button, Container, Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import {
  FormField,
  Title,
  RichTextEditor,
  FileUpload,
  ConfirmDialog,
  LoaderOverlay,
} from '../common'

import {
  richTextEditorConfig,
  notifications,
  BRAND_STAGE_FINALIZED,
  BRAND_STATUS_GRANTED,
  REACT_APP_NOREPLY_EMAIL_ADDRESS,
} from '../../config'
import {
  uploadFilesToS3ByLevel,
  generateNotification,
  sendEmail,
  putBrand,
} from '../../services'
import { useLayoutContext } from '../context'

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(2),
  },
  divider: {
    height: theme.spacing(3),
  },
  button: {
    width: '100%',
  },
}))

const BrandFinalizeModal = (props) => {
  const { closeHandler, notificationsEnabled, brand, updateBrand } = props
  const { handleSubmit, control, errors } = useForm({})
  const [prompConfirmation, setPropmtConfirmation] = useState(false)
  const [submittedData, setSubmittedData] = useState(undefined)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { loading, setLoading } = useLayoutContext()

  const finalizeBrandRegistration = async (files, certificate) => {
    try {
      const updateBrandStageResponse = await putBrand({
        id: brand?.id,
        brandStage: BRAND_STAGE_FINALIZED,
        brandStatus: BRAND_STATUS_GRANTED,
        files: files?.length > 0 ? files : undefined,
        certificate,
        _version: brand?._version,
      })
      return updateBrandStageResponse
    } catch (error) {
      enqueueSnackbar(
        'Ocurrió un error al finalizar el proceso de registro de tu marca',
        {
          preventDuplicate: true,
          variant: 'error',
        }
      )
      return error
    }
  }

  /* eslint-disable-next-line consistent-return */
  const handleEmailSend = async (from, to, subject, message) => {
    try {
      const sendEmailResponse = await sendEmail(from, to, subject, message)
      return sendEmailResponse
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al enviar el correo', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
  }

  /* eslint-disable-next-line consistent-return */
  const sendNotification = async (certificateNumber) => {
    try {
      const notification = notifications.brandRegistrationFinalized(
        brand?.id,
        brand?.brandData?.name,
        certificateNumber,
        brand?.class?.name
      )
      const notificationTitle = notification.title
      const notificationBody = notification.body
      // eslint-disable-next-line no-unused-vars
      const sendNotificationResponse = await generateNotification(
        brand?.id,
        notificationTitle,
        notificationBody,
        brand?.brandData?.owner
      )
    } catch (error) {
      return error
    }
  }

  /* eslint-disable-next-line consistent-return */
  const uploadFiles = async (brandFiles) => {
    try {
      const fileUploadResponse = await uploadFilesToS3ByLevel(
        brandFiles,
        'private',
        brand?.owner
      )
      enqueueSnackbar('¡Los archivos fueron cargados de forma exitosa!', {
        preventDuplicate: true,
        variant: 'success',
      })
      return fileUploadResponse
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al cargar los archivos', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
  }

  /* eslint-disable-next-line consistent-return */
  const onSubmit = async (data) => {
    setLoading(true)
    let uploadFilesResponse = null
    try {
      uploadFilesResponse = await uploadFiles(data.brandFiles)
      enqueueSnackbar('¡Los archivos fueron cargados de forma exitosa!', {
        preventDuplicate: true,
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al cargar los archivos', {
        preventDuplicate: true,
        variant: 'error',
      })
    }

    try {
      // eslint-disable-next-line no-unused-vars
      const updateBrandStageResponse = await finalizeBrandRegistration(
        uploadFilesResponse,
        data.certificate
      )
      enqueueSnackbar(
        '¡La solicitud de registro de la marca ha sido finalizado de forma exitosa!',
        {
          preventDuplicate: true,
          variant: 'success',
        }
      )
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al finalizar el registro de marca', {
        preventDuplicate: true,
        variant: 'error',
      })
    }

    try {
      // eslint-disable-next-line no-unused-vars
      const emailSendResponse = await handleEmailSend(
        REACT_APP_NOREPLY_EMAIL_ADDRESS,
        brand?.owner,
        data.subject,
        data.editor
      )
      enqueueSnackbar(
        '¡El correo de notificación de finalización de la solicitud de registro de marca se envió de forma exitosa!',
        {
          preventDuplicate: true,
          variant: 'success',
        }
      )
    } catch (error) {
      enqueueSnackbar('Ocurrió un error al enviar el correo', {
        preventDuplicate: true,
        variant: 'error',
      })
    }

    if (notificationsEnabled) {
      try {
        await sendNotification(data.certificate)
      } catch (error) {
        enqueueSnackbar('Ocurrió un error al crear la notificación', {
          preventDuplicate: true,
          variant: 'error',
        })
      }
    }
    updateBrand(brand?.id)
    setLoading(false)
    closeHandler(false)
  }

  const handleConfirmation = (data) => {
    setSubmittedData(data)
    setPropmtConfirmation(true)
  }

  const handleClose = () => {
    closeHandler(false)
  }

  const renderModalContent = () => {
    return (
      <form noValidate onSubmit={handleSubmit(handleConfirmation)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormField
              id="from"
              name="from"
              type="text"
              required
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="De"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: 'Ingresa el correo del remitente',
                },
              }}
              inputProps={{ value: REACT_APP_NOREPLY_EMAIL_ADDRESS }}
              disabled
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <FormField
              id="subject"
              name="subject"
              type="text"
              required
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="Asunto"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Ingresa el asunto',
                },
              }}
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <FormField
              id="to"
              name="to"
              type="text"
              required
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="Para"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: false,
                  message: 'Ingresa el destinatario',
                },
              }}
              inputProps={{ value: brand?.owner }}
              disabled
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <FormField
              id="certificate"
              name="certificate"
              type="text"
              required
              variant="outlined"
              size="small"
              fullWidth
              // className={clsx(classes.margin)}
              label="Certificado"
              // labelClassName={clsx(classes.margin, classes.noLabel)}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Ingresa el número de certificado',
                },
              }}
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <FileUpload
              id="brandFiles"
              name="brandFiles"
              label="Archivos adjuntos"
              required
              variant="outlined"
              size="small"
              fullWidth
              /* className={clsx(
                classes.margin,
                classes.noLabel,
                classes.brandFilesSelection
              )} */
              acceptedFiles={['application/pdf']}
              filesLimit={5}
              maxFileSize={5 * 1000 * 1000}
              dropzoneText="Arrastra y suelta un archivo aquí o haz clic. Tamaño máximo 5MB por documento. Máximo 5 archivos PDF."
              previewText="Archivos seleccionados"
              showAlerts={false}
              showPreviews
              showPreviewsInDropzone={false}
              showFileNames
              showFileNamesInPreview
              useChipsForPreview={false}
              onChange={(f) => console.log('Files:', f)}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Selecciona al menos un archivo PDF',
                },
              }}
              errors={errors}
            />
            <div className={clsx(classes.divider)} />
            <RichTextEditor
              initialConfig={
                richTextEditorConfig.trademarkPlatformConsoleConfig
              }
              control={control}
              name="editor"
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: 'Ingresa el cuerpo del mensaje',
                },
              }}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                className={clsx(classes.button)}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={clsx(classes.button)}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <Container className={clsx(classes.mainContent)}>
      <LoaderOverlay open={loading} />
      <ConfirmDialog
        title="Certificado"
        content="¿Deseas continuar con el certificado presentado?"
        enabled={prompConfirmation}
        onAccept={onSubmit}
        data={submittedData}
        closeHandler={setPropmtConfirmation}
      />
      <Title>Concluir Solicitud de Registro de Marca</Title>
      <div className={clsx(classes.divider)} />
      {renderModalContent()}
    </Container>
  )
}

BrandFinalizeModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  updateBrand: PropTypes.func.isRequired,
}

export default BrandFinalizeModal
