import React from 'react'
import { Container, Grid, Fade } from '@material-ui/core'
import PropTypes from 'prop-types'

const MainMaterialLayout = (props) => {
  // const classes = useStyles()
  const { Header, Body, Footer, classes } = props
  return (
    <Fade in timeout={{ appear: 250, enter: 250, exit: 250 }}>
      <Container className={classes?.mainMaterialLayoutContainer} maxWidth="xs">
        <Container spacing={3}>
          <Grid item name="headerSection">
            <Header />
          </Grid>
          <Grid item name="bodySection">
            <Body />
          </Grid>
          <Grid item name="footerSection">
            <Footer />
          </Grid>
        </Container>
      </Container>
    </Fade>
  )
}

MainMaterialLayout.propTypes = {
  Header: PropTypes.func,
  Body: PropTypes.func,
  Footer: PropTypes.func,
  classes: PropTypes.shape({
    mainMaterialLayoutContainer: PropTypes.string,
  }),
}

MainMaterialLayout.defaultProps = {
  Header: undefined,
  Body: undefined,
  Footer: undefined,
  classes: { mainMaterialLayoutContainer: '' },
}

export default MainMaterialLayout
