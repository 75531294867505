/* eslint-disable import/no-named-as-default */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Typography } from '@material-ui/core'
import Title from '../common/text/Title'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}))

const MaterialDeniedAccessComponent = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title>No autorizado</Title>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography color="secondary" variant="subtitle1">
              No autorizado para esta funcionalidad. Puede cerrar su sesión en
              las opciones del menú izquierdo.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

MaterialDeniedAccessComponent.propTypes = {}

export default MaterialDeniedAccessComponent
