export const APP_ID = process.env.REACT_APP_ID || 'console'
export const VERSION = process.env.REACT_APP_VERSION
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const ACCEPTED_COGNITO_GROUPS =
  process.env.REACT_APP_ACCEPTED_COGNITO_GROUPS ||
  '["ADMINISTRATOR", "OPERATOR"]'

export const TRADEMARK_CANCEL_PHRASE =
  process.env.REACT_APP_TRADEMARK_CANCEL_PHRASE || 'cancelar'

export const MAX_ROWS_PER_LOAD = 20

export const SEARCH_OPERATOR_MATCH = 'match'
export const SEARCH_OPERATOR_MATCH_PHRASE_PREFIX = 'matchPhrasePrefix'

export const searchOperatorOptions = [
  {
    value: SEARCH_OPERATOR_MATCH,
    label: 'Contiene',
  },
  {
    value: SEARCH_OPERATOR_MATCH_PHRASE_PREFIX,
    label: 'Frase',
  },
]

export const brandClassColors = {
  '1': '#1E313B',
  '2': '#728793',
  '3': '#48668A',
  '4': '#251340',
  '5': '#A8A5D4',
  '6': '#3E57C7',
  '7': '#443854',
  '8': '#8FA1FF',
  '9': '#67AACC',
  '10': '#80C5CF',
  '11': '#B1C299',
  '12': '#53AD8E',
  '13': '#068055',
  '14': '#09330A',
  '15': '#383523',
  '16': '#6E756C',
  '17': '#115553',
  '18': '#04471C',
  '19': '#A1BE72',
  '20': '#448204',
  '21': '#3B262D',
  '22': '#6E0E30',
  '23': '#A17283',
  '24': '#7D4250',
  '25': '#3D156A',
  '26': '#56638A',
  '27': '#595774',
  '28': '#9D9CA9',
  '29': '#2EACEE',
  '30': '#096291',
  '31': '#B37452',
  '32': '#8A4137',
  '33': '#B55405',
  '34': '#DDB771',
  '35': '#E9BE52',
  '36': '#FB8F67',
  '37': '#D15151',
  '38': '#794F40',
  '39': '#92290F',
  '40': '#C26969',
  '41': '#3CDADD',
  '42': '#F37089',
  '43': '#73C864',
  '44': '#A766BD',
  '45': '#EE2929',
}

export const OPENPAY_ERRORS = {
  1000: 'Ocurrió un error interno en el servidor del Proveedor de Pagos.',
  1001: 'El pago no se puede realizar con las condiciones de pago dadas. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  1002: 'La llamada no está autenticada o la autenticación es incorrecta.',
  1003: 'El pago no se puede realizar con las condiciones de pago dadas. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  1004: 'Un servicio necesario para el procesamiento de la transacción no se encuentra disponible.',
  1005: 'Uno de los recursos requeridos no existe.',
  1006: 'Ya existe una transacción con el mismo ID de orden.',
  1007: 'La transferencia de fondos entre una cuenta de banco o tarjeta y la cuenta del Proveedor de Pagos no fue aceptada.',
  1008: 'Una de las cuentas requeridas en la petición se encuentra desactivada.',
  1009: 'El cuerpo de la petición es demasiado grande.',
  1010: 'La clave pública se está utilizando para realizar una solicitud que requiere la clave privada, o la clave privada se está utilizando desde el Frontend.',
  1011: 'Se solicita un recurso que está marcado como eliminado.',
  1012: 'El monto de la transacción está fuera de los limites permitidos.',
  1013: 'La operación no está permitida para el recurso.',
  1014: 'La cuenta está inactiva.',
  1015: 'No se ha obtenido respuesta desde el gateway.',
  1016: 'El email del comercio ya ha sido procesado.',
  1017: 'El gateway no se encuentra disponible en ese momento.',
  1018: 'El número de intentos de cargo es mayor al permitido.',
  1020: 'El número de decimales no está permitido para esta moneda.',
  2001: 'La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente.',
  2002: 'La tarjeta con este número ya se encuentra registrada en el cliente.',
  2003: 'El cliente con este identificador externo (External ID) ya existe.',
  2004: 'El dígito verificador del número de tarjeta es inválido de acuerdo al algoritmo Luhn.',
  2005: 'La fecha de expiración de la tarjeta es anterior a la fecha actual.',
  2006: 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado.',
  2007: 'El número de tarjeta es de prueba, solamente puede usarse en el modo de pruebas del Proveedor de Pagos.',
  2008: 'La tarjeta no es valida para puntos.',
  2009: 'El código de seguridad de la tarjeta (CVV2) es inválido.',
  2010: 'Falló la autenticación de 3D Secure.',
  2011: 'EL tipo de tarjeta no está soportado.',
  3001: 'La tarjeta fue declinada. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3002: 'La tarjeta ha expirado. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3003: 'La tarjeta no tiene fondos suficientes. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3004: 'La tarjeta ha sido identificada como una tarjeta robada. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3005: 'La tarjeta ha sido rechazada por el sistema antifraudes. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3006: 'La operación no está permitida para este cliente o esta transacción.',
  3007: 'Depreciado. La tarjeta fue declinada. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3008: 'La tarjeta no es soportada en transacciones en línea. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3009: 'La tarjeta fue reportada como perdida. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3010: 'El banco ha restringido la tarjeta. Intente con otra tarjeta de Débito/Crédito o seleccione una ficha de depósito.',
  3011: 'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.',
  3012: 'Se requiere solicitar al banco autorización para realizar este pago.',
  3201: 'El comercio no maneja promociones.',
  3203: 'Promoción no válida para este tipo de tarjeta.',
  3204: 'El monto de la transacción es menor que el mínimo para la promoción.',
  3205: 'Promoción no permitida.',
  4001: 'La cuenta del Provedor de Pagos no tiene fondos suficientes.',
  4002: 'La operación no puede ser completada hasta que sean pagadas las comisiones pendientes.',
  5001: 'La orden con este identificador externo ya existe.',
  6001: 'El webhook del Provedor de Pagos ya ha sido procesado.',
  6002: 'No se ha podido conectar con el servicio de webhook del Provedor de Pagos.',
  6003: 'El servicio del Provedor de Pagos ha respondido con errores.',
}

export const PHYSICAL_PERSON = 'physical'
export const MORAL_PERSON = 'moral'

export const kindOfPerson = [
  { value: PHYSICAL_PERSON, label: 'Physical' },
  { value: MORAL_PERSON, label: 'Moral' },
]

export const TAX_RATE = 0.16

export const PAYMENT_METHOD_CARD = 'C'
export const PAYMENT_METHOD_STORE = 'T'
export const PAYMENT_METHOD_DEPOSIT = 'S'

export const paymentMethods = [
  {
    value: PAYMENT_METHOD_CARD,
    label: 'Tarjeta de crédito/débito',
    icon: 'CreditCardOutlined',
  },
  {
    value: PAYMENT_METHOD_DEPOSIT,
    label: 'Depósito',
    icon: 'AccountBalanceOutlined',
  },
  {
    value: PAYMENT_METHOD_STORE,
    label: 'Pago en tiendas',
    icon: 'StoreOutlined',
  },
]

export const CARD_ISSUER_VISA = 'visa'
export const CARD_ISSUER_MASTERCARD = 'mastercard'
export const CARD_ISSUER_AMEX = 'amex'

export const acceptedCards = [
  CARD_ISSUER_VISA,
  CARD_ISSUER_MASTERCARD,
  CARD_ISSUER_AMEX,
]

export const OPENPAY_MERCHANT_ID = process.env.REACT_APP_OPENPAY_MERCHANT_ID
export const OPENPAY_PUBLIC_API_KEY =
  process.env.REACT_APP_OPENPAY_PUBLIC_API_KEY
export const OPENPAY_IS_SANBOX = process.env.REACT_APP_OPENPAY_IS_SANBOX

export const PAYMENT_TX_STATUS_PAID = 'paid'
export const PAYMENT_TX_STATUS_NOT_PAID = 'notPaid'

export const BRAND_STAGE_RECEIVED = 'received'
export const BRAND_STAGE_PAID = 'paid'
export const BRAND_STAGE_IN_PROCESS = 'inProcess'
export const BRAND_STAGE_PRESENTED = 'presented'
export const BRAND_STAGE_FINALIZED = 'finalized'

export const FEE_TYPE_TRADEMARK_REGISTRATION = 'trademarkRegistrationFee'
export const FEE_TYPE_IMPI_RIGHTS = 'impiRightsFee'

export const FEE_STATUS_ENABLED = 'enabled'
export const FEE_STATUS_DISABLED = 'disabled'

export const AWS_COGNITO_PASSWORD_STRENGTH_VALIDATION_MESSAGE = `
<p>
Formato de seguridad válido para la contraseña:<br/>
- Mínimo 8 caracteres<br/>
- Al menos un caracter:<br/>
    · Minúscula<br/>
    · Mayúscula<br/>
    · Número<br/>
    · Especial: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \`
</p>
  `

export const COUNTRY_CODE_MEXICO = 'MX'

export const DOMICILE_TYPE_APPLICANT = 'APPLICANT'
export const DOMICILE_TYPE_ESTABLISHMENT = 'ESTABLISHMENT'
export const DOMICILE_TYPE_APPLICANT_BILLING = 'APPLICANT_BILLING'

export const APPLICANT_TYPE_APPLICANT = 'APPLICANT'
export const APPLICANT_TYPE_APPLICANT_BILLING = 'APPLICANT_BILLING'

export const trademarkApplicationStatuses = {
  created: { value: 'created', label: 'Creado' },
  inProgress: { value: 'inProgress', label: 'En trámite' },
  completed: { value: 'completed', label: 'Finalizado' },
}

export const trademarkApplicationStages = {
  registration: 'registration',
  payment: 'payment',
  supplementalData: 'supplementalData',
  supplementalApplicantData: 'supplementalApplicantData',
  completed: 'completed',
}

export const TRADEMARK_LIFECYCLE_STAGES = [
  {
    stage: 'received',
    stageIndex: 0,
    label: 'Recibido',
    description: 'Solicitud Recibida',
  },
  {
    stage: 'paid',
    stageIndex: 1,
    label: 'Pagado',
    description: 'Solicitud Pagada',
  },
  {
    stage: 'inProcess',
    stageIndex: 2,
    label: 'En proceso',
    description: 'Solicitud en Proceso',
  },
  {
    stage: 'presented',
    stageIndex: 3,
    label: 'Presentada',
    description: 'Solicitud Presentada',
  },
  {
    stage: 'finalized',
    stageIndex: 4,
    label: 'Finalizada',
    description: 'Solicitud Finalizada',
  },
]

export const applicantKind = {
  physical: {
    id: 'physical',
    label: 'Físico',
  },
  moral: {
    id: 'moral',
    label: 'Moral',
  },
}

export const OSTypes = {
  android: 'Android',
  ios: 'iOS',
  windowsPhone: 'Windows Phone',
  unkown: 'unkown',
}

export const VALUE_NOT_AVAILABLE = 'N/D'

export const trademarkDetailActions = {
  inProcess: {
    value: 'IN_PROCESS',
    label: 'En trámite',
  },
  initializeImpiProcess: {
    value: 'INITIALIZE_IMPI_PROCESS',
    label: 'Solicitud Presentada',
  },
  contact: {
    value: 'CONTACT',
    label: 'Contactar',
  },
  generatePaymentRequest: {
    value: 'GENERATE_PAYMENT_REQUEST',
    label: 'Generar Orden de Pago',
  },
  finalizeProcess: {
    value: 'FINALIZE_PROCESS',
    label: 'Concluir Trámite',
  },
  brandDenied: {
    value: 'BRAND_DENIED',
    label: 'Marca no Otorgada',
  },
}

export const { REACT_APP_TINYMCE_API_KEY } = process.env
export const { REACT_APP_TINYMCE_LANG_ES_MX } = process.env

export const richTextEditorConfig = {
  trademarkPlatformConsoleConfig: {
    plugins: 'link code',
    menubar: false,
    language: 'es_MX',
    language_url: REACT_APP_TINYMCE_LANG_ES_MX,
    toolbar: 'undo redo | bold italic underline | link anchor',
    height: 400,
  },
  defaultConfig: {
    menubar: 'file edit insert',
    plugins: 'image link',
    language: 'es_MX',
    language_url: REACT_APP_TINYMCE_LANG_ES_MX,
    toolbar: 'undo redo | bold italic underline | link anchor',
    height: 400,
    file_picker_types: 'file media',
  },
}

export const notifications = {
  registrationProcessInitiated: (brandId, brandName, className) => {
    const title = `Inicio de Registro de Marca`
    const body = `<p>Se ha iniciado el proceso de registro para la marca: <strong>${brandName}</strong>, perteneciente a la clase <strong>${className}</strong>.</p><p>Para más información ingrese directo al detalle de la marca: <a href="detail/${brandId}">Ver detalle</a></p>`
    return {
      title,
      body,
    }
  },
  impiRegistrationProcessInitiated: (
    brandId,
    brandName,
    requestNumber,
    className
  ) => {
    const title = `Inicio de Registro de Marca ante IMPI`
    const body = `<p>Se ha iniciado el proceso de registro para la marca: <strong>${brandName}</strong>, perteneciente a la clase <strong>${className}</strong>, con el número de registro: <strong>${requestNumber}</strong> ante el IMPI.</p><p>Para más información ingrese directo al detalle de la marca: <a href="detail/${brandId}">Ver detalle</a></p>`
    return {
      title,
      body,
    }
  },
  paymentRequestCreated: (brandName, brandID, paymentRequestID, className) => {
    const title = `Orden de Pago`
    const body = `<p>Se generó una nueva orden de pago para la marca: <strong>${brandName}</strong>, perteneciente a la clase <strong>${className}</strong><br><a href="detail/${brandID}/paymentRequest/${paymentRequestID}">Ir a orden de pago</a></p>`
    return {
      title,
      body,
    }
  },
  brandRegistrationFinalized: (
    brandId,
    brandName,
    certificateNumber,
    className
  ) => {
    const title = `Solicitúd de Registro de Marca Concluida`
    const body = `<p>La solicitud de registro de la marca <strong>${brandName}</strong>, perteneciente a la clase <strong>${className}</strong>, ha sido finalizado. El número de certificado otorgado es: <strong>${certificateNumber}<strong><br></p></p><p>Para más información ingrese directo al detalle de la marca: <a href="detail/${brandId}">Ver detalle</a></p>`
    return {
      title,
      body,
    }
  },
}

export const { REACT_APP_NOREPLY_EMAIL_ADDRESS } = process.env

export const concepts = {
  professionalServices: {
    value: 'professional_services',
    label: 'Servicios Profesionales',
  },
}

export const BRAND_STATUS_CREATED = 'created'
export const BRAND_STATUS_GRANTED = 'granted'
export const BRAND_STATUS_DENIED = 'denied'
export const BRAND_STATUS_CANCELLED = 'cancelled'

export const brandStatuses = {
  created: {
    value: 'created',
    label: 'Creada',
    color: '#4A90E4',
  },
  granted: {
    value: 'granted',
    label: 'Otorgada',
    color: '#67CB35',
  },
  denied: {
    value: 'denied',
    label: 'Rechazada',
    color: '#D8522E',
  },
  cancelled: {
    value: 'cancelled',
    label: 'Cancelada',
    color: '#B9AEAB',
  },
}

export const actionEmailSubjects = {
  registrationProcessStarted: 'Inicio de Solicitud de Registro de Marca',
  trademarkRegistrationProcessStarted:
    'Inicio de Solicitud de Registro de Marca ante el IMPI',
  trademarkPaymentRequest: 'Solicitud de Pago',
}

export const actionEmailTemplates = {
  registrationProcessStarted: (brandId, brandName, className) => {
    return `<p>El proceso de solicitud de registro de la marca <strong>${brandName}</strong>, perteneciente a la clase <strong>${className}</strong>, se inició de forma exitosa.</p><p>Para más información, ingresa directamente al detalle de la marca: <a href="${BASE_URL}/detail/${brandId}">Ver detalle</a></p>`
  },
  trademarkRegistrationInitialized: (brandId, brandName, className) => {
    return `<p>El proceso de solicitud de registro de la marca <strong>${brandName}</strong>, perteneciente a la clase <strong>${className}</strong>, ante el IMPI, se inició de forma exitosa.</p><p>Para más información, ingresa directamente al detalle de la marca: <a href="${BASE_URL}/detail/${brandId}">Ver detalle</a></p>`
  },
  trademarkPaymentRequest: (brandId, brandName, className) => {
    return `<p>Se generó una solicitud de pago para la marca: <strong>${brandName}</strong>, perteneciente a la clase <strong>${className}</strong></p><p>Para más información, ingresa directamente al detalle de la marca: <a href="${BASE_URL}/detail/${brandId}">Ver detalle</a></p>`
  },
}

export const paymentRequestStatus = {
  accepted: {
    value: 'accepted',
    label: 'Aceptada',
  },
  rejected: {
    value: 'rejected',
    label: 'Rechazada',
  },
}
