import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { Router } from '@reach/router'
import clsx from 'clsx'
import { withRequiredAuthLayout } from '../../layout'
import { BrandsList, BrandDetail } from '../../components'
import { TrademarkSearchFlowProvider } from '../../components/context'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}))

const Brands = () => {
  const classes = useStyles()
  return (
    <>
      <TrademarkSearchFlowProvider>
        <Container className={clsx(classes.container)}>
          {/* 2. You can render a router anywhere
        down the tree, you don't have to have
        a top-level router with every route
        declared up front, this is quite helpful
        for really large apps */}
          <Router>
            <BrandsList path="/" />
            <BrandDetail path=":trademarkId/detail" />
          </Router>
        </Container>
      </TrademarkSearchFlowProvider>
    </>
  )
}

export default withRequiredAuthLayout(Brands)
