import React, { useState } from 'react'
import clsx from 'clsx'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Paper, Grid, Chip } from '@material-ui/core'
import { dateFormat } from '../common/Forms'
import { concepts, paymentRequestStatus } from '../../config'
import PaymentRequestDetail from './BrandPaymentRequestDetailModal'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  paymentRequestContainer: {
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: '#CCE1EE',
    },
  },
  paymentRequest: {
    padding: theme.spacing(2),
  },
  listContainer: {
    maxHeight: theme.spacing(50),
    overflow: 'scroll',
  },
  pending: {
    backgroundColor: '#E3CB1F',
  },
  accepted: {
    backgroundColor: '#3CBB44',
  },
  rejected: {
    backgroundColor: '#E3522B',
  },
  chipText: {
    fontWeight: 'bold',
    color: '#FFF',
  },
}))

const PaymentRequestList = (props) => {
  const { paymentRequests } = props
  const classes = useStyles()
  const [detailsEnabled, setDetailsEnabled] = useState(false)
  const [selectedPaymentRequest, setSelectedPaymentRequest] = useState(
    undefined
  )

  const viewPaymentRequestDetail = (paymentRequest) => {
    setSelectedPaymentRequest(paymentRequest)
    setDetailsEnabled(true)
  }

  return (
    <div className={clsx(classes.root)}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="payment-requests-content"
          id="payment-requests">
          <Typography className={classes.heading}>Órdenes de Pago</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            className={clsx(
              paymentRequests && paymentRequests.length > 0
                ? classes.listContainer
                : null
            )}>
            {paymentRequests && paymentRequests.length > 0 ? (
              paymentRequests.map((paymentRequest) => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={paymentRequest?.id}
                    className={clsx(classes.paymentRequestContainer)}>
                    <Paper
                      className={clsx(classes.paymentRequest)}
                      onClick={() => viewPaymentRequestDetail(paymentRequest)}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} md={8} lg={8}>
                          <Typography>
                            {dateFormat(paymentRequest?.createdAt, 'datetime')}{' '}
                            - <span>{concepts.professionalServices.label}</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={4}>
                          <Chip
                            className={clsx(
                              classes[
                                paymentRequest?.status
                                  ? paymentRequest?.status
                                  : 'pending'
                              ],
                              classes.chipText
                            )}
                            label={
                              paymentRequest?.status
                                ? paymentRequestStatus[paymentRequest?.status]
                                    .label
                                : 'Pendiente'
                            }
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              })
            ) : (
              <Typography>No hay órdenes de pago registradas</Typography>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <PaymentRequestDetail
        enabled={detailsEnabled}
        paymentRequest={selectedPaymentRequest}
        closeHandler={setDetailsEnabled}
      />
    </div>
  )
}

PaymentRequestList.propTypes = {
  paymentRequests: PropTypes.shape({}),
}

PaymentRequestList.defaultProps = {
  paymentRequests: undefined,
}

export default PaymentRequestList
